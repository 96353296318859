import React, {useState, useEffect} from 'react';

import {SButton} from '../../components/buttons/Button';
import {STable} from '../../components/tables/STable';
import {STextField} from '../inputs/STextField';

import {CreateNewCustomerAccountForm} from '../form/CreateNewCustomerAccountForm';
import {ICustomer} from '../../model/customer/Customer';
import {IPagedActionResults} from '../../model/ActionResults';
import {useLoadCustomers} from '../../services/useCustomersAPI';

import {Grid, makeStyles} from '@material-ui/core';
import ArrowForwardTwoTone from '@material-ui/icons/ArrowForwardTwoTone';

const useLinkToAccountCustomerStyles = makeStyles(theme => ({
  saveButton: {
    position: 'absolute',
    right: '40px',
    bottom: '20px',
  },
  dialog: {
    justifySelf: 'center',
    position: 'absolute',
    minWidth: '900px',
  },
  dialogTitle: {
    borderBottom: 'none',
  },
  dialogContent: {
    padding: '8px 40px',
  },
  subHeading: {
    lineHeight: '1.357',
    color: '#999999',
    fontWeight: 500,
    fontSize: '0.875em',
  },
  dialogActions: {
    borderTop: '1px solid #F1F1F1',
    margin: '0px 40px',
    padding: '8px 0px',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25em',
    color: '#4D4D4D',
    marginBottom: '1.25em',
  },
  redLabel: {
    fontWeight: 400,
    fontSize: '0.875em',
    color: '#B23535',
    margin: '1.25em 0',
  },
  lightLabel: {
    fontWeight: 400,
    fontSize: '0.875em',
    color: '#cccccc',
    margin: '0 0 1.25em 0 ',
  },
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
  formValue: {
    fontSize: '1rem',
    color: '#999999',
    paddingBottom: '1rem',
  },
  cardLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '0.5rem',
  },
  cardValue: {
    fontSize: '1rem',
    color: '#999999',
    paddingBottom: '0.5rem',
  },
  collapseTitle: {
    fontSize: '1.25em',
    color: '#1c78ad',
    fontWeight: 500,
  },
  debtorCard: {
    backgroundColor: '#EAEFF2',
    borderRadius: '5px',
    padding: '10px',
    width: '462px',
  },
  tabSwitch: {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 0,
    borderBottom: '2px solid #1c78ad',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  searchIcon: {
    cursor: 'pointer',
  },
}));

export const LinkToAccountCustomer = (props: any) => {
  const {response, linkClick} = props;
  const classes = useLinkToAccountCustomerStyles();
  const [search, setSearch] = useState<string>();

  const [customerData, setCustomerData] = useState<ICustomer[]>();
  const [classIdCheck, setClassIdCheck] = useState<boolean>(false);
  const [popUpID, setPopUpID] = useState<number | null>();
  const {loading} = useLoadCustomers(true);

  useEffect(() => {
    if (search && search?.length > 2) {
      const filter = {
        search,
        MaxResults: 5,
        IsShowAccountCustomersOnly: true,
      };
      props.getCustomerList(filter).then((res: IPagedActionResults<ICustomer[]>) => {
        setCustomerData(res.Value);
      });
    }
  }, [search]);

  useEffect(() => {
    if (response?.IsSuccess && props.customerLink) {
      props.fetchCustomerInfo(props.customerId);
      props.onClose();
    } else if (response?.IsSuccess && props.customerLinked) {
      props.getCustomersList();
      props.onClose();
    }
  }, [response]);

  return (
    <>
      {classIdCheck === false && (
        <div>
          <p className={`${classes.title} mt-3`}>Search for an existing Account Customer to link to</p>
          <Grid container spacing={1}>
            <Grid item sm={2} container alignItems="flex-end" className="flex">
              <p className={`${classes.formLabel}`}>Account Customer: </p>
            </Grid>
            <Grid item sm={5}>
              <STextField
                placeholder="Search"
                value={search}
                onChange={e => {
                  setSearch(e.target.value || ' ');
                }}
                fullWidth
              />
            </Grid>

            <Grid item sm={1}>
              <ArrowForwardTwoTone
                data-autoid={`arrowForward${props.customerId}`}
                className={`${classes.searchIcon}`}
                onClick={() => {
                  setSearch(search || ' ');
                  const filter = {
                    search,
                    MaxResults: 5,
                    IsShowAccountCustomersOnly: true,
                  };
                  props.getCustomerList(filter).then((res: IPagedActionResults<ICustomer[]>) => {
                    setCustomerData(res.Value);
                  });
                }}
              />
            </Grid>
          </Grid>
          <p className={`${classes.lightLabel}`}>
            *This will search for all the Account Customers in your Financial System.
          </p>
          {search && (
            <>
              <p className={`${classes.title}`}>Search Result</p>
              <STable
                columns={[
                  {
                    title: 'Acc Customer Ref',
                    field: 'InventoryCode',
                    render: (rowData: ICustomer) => {
                      return <p className={classes.subHeading}>{rowData?.RefNumber}</p>;
                    },
                  },
                  {
                    title: 'Name',
                    field: 'Name',
                    render: (rowData: ICustomer) => {
                      return <p className={classes.subHeading}>{rowData?.CompanyName}</p>;
                    },
                  },
                  {
                    title: 'Phone Number',
                    field: 'category',
                    render: (rowData: ICustomer) => {
                      return <p className={classes.subHeading}>{rowData?.Phone1}</p>;
                    },
                  },
                  {
                    title: 'Links',
                    field: 'category',
                    render: (rowData: ICustomer) => {
                      return <p className={classes.subHeading}>{rowData?.CountOfLinkedCustomers}</p>;
                    },
                  },
                  {
                    title: 'Billing Address',
                    field: 'brand',
                    render: (rowData: ICustomer) => {
                      return <p className={classes.subHeading}>{rowData?.BillingAddressString}</p>;
                    },
                  },
                  {
                    title: 'Action',
                    field: 'IsActive',
                    align: 'center',
                    render: (rowData: ICustomer) => {
                      return (
                        <>
                          <SButton
                            data-autoid={`btnLink${props.customerId}-${rowData.ID}`}
                            color="blueShade"
                            border="1px solid #1C78AD"
                            textColor="white"
                            lineHeight="s"
                            type="button"
                            width="76px"
                            fontSize="xs"
                            onClick={() => {
                              if (rowData.ClassID) {
                                linkClick(props?.customerId, rowData?.ID);
                              } else {
                                setPopUpID(rowData?.ID);
                                setClassIdCheck(true);
                              }
                            }}
                          >
                            Link
                          </SButton>
                        </>
                      );
                    },
                  },
                ]}
                data={customerData ? customerData : []}
                emptyDataMessage={'No Data'}
                stickyHeader={true}
                selection={false}
                loading={loading}
              ></STable>
            </>
          )}
        </div>
      )}
      <div>
        {classIdCheck === true && (
          <CreateNewCustomerAccountForm {...props} customerData={customerData} popUpID={popUpID} />
        )}
      </div>
    </>
  );
};
