import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useFormikContext} from 'formik';
import {isEmpty} from 'lodash';
import IconCrossClaims from '../../../assets/svg/IconCrossClaims';
import IconTickClaims from '../../../assets/svg/IconTickClaims';
import {SCheckbox} from '../../../components/inputs/SCheckbox';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import AppContext from '../../../context/app/appContext';
import {IClaimsReasonResponse, IClaimRequestDetailsResponse} from '../../../model/claims/ClaimsAndReturns';
import {ClaimAndReturnRowAction, ClaimActions, DEFAULT_TAX_RATE} from '../../../model/constants/Constants';
import useConfigAPI from '../../../services/useConfigAPI';
import {ProductSearchInput} from './ClaimsProductSearchInput';
import {IClaimRequestFormValues} from './CreateAndEditClaimRequest';

interface IAdhocLineValues {
  inventoryID: number | null;
  code: string;
  description: string;
  uoM: string;
  quantity: string;
  price: string;
  reason: string;
  action: string;
  rowAction: ClaimAndReturnRowAction;
  availableQty: number | undefined;
  isTaxExempt?: boolean;
  taxPercent?: number;
  isTaxableFieldDiabled?: boolean;
  activeInput?: string;
}

const adhocLineInitialValues: IAdhocLineValues = {
  inventoryID: null,
  code: '',
  description: '',
  uoM: 'EA',
  quantity: '',
  price: '',
  reason: '',
  action: ClaimActions.Credit,
  rowAction: ClaimAndReturnRowAction?.Create,
  availableQty: undefined,
  isTaxExempt: false,
  taxPercent: DEFAULT_TAX_RATE, // Default Tax Rate
};

interface IClaimsAddAdhocLineProps {
  supplierId?: number;
  isSubmitting: boolean;
  claimLineItemReason?: string;
  getCurrencyStrFromDecimal: (num: string) => string;
  reasonDropdownOptions?: IClaimsReasonResponse[];
  handleSubmit: (
    values: IClaimRequestFormValues,
    isReturnResponse?: boolean,
  ) => Promise<IClaimRequestDetailsResponse | undefined>;
  updateFormValue: (claimRequestDetails?: IClaimRequestDetailsResponse) => void;
}

export const ClaimsAddAdhocLine = (props: IClaimsAddAdhocLineProps) => {
  // Props
  const {
    getCurrencyStrFromDecimal,
    handleSubmit,
    updateFormValue,
    reasonDropdownOptions,
    claimLineItemReason,
    supplierId,
    isSubmitting,
  } = props;

  // State
  const [adhocLineValues, setAdhocLineValues] = useState<IAdhocLineValues>(adhocLineInitialValues);
  const [adhocLineErrors, setAdhocLineErrors] = useState<any>();

  // Context
  const {tenantInfo} = useContext(AppContext);

  // Hooks
  const {values: formikValues} = useFormikContext<IClaimRequestFormValues>();

  // APIs
  const {getConfigV2, setConfig} = useConfigAPI();

  const isPriceInputFocused = adhocLineValues?.activeInput === 'price';

  useEffect(() => {
    if (claimLineItemReason) setAdhocLineValues(prev => ({...prev, reason: claimLineItemReason}));
  }, [claimLineItemReason]);

  useEffect(() => {
    fetchTenantDefaultTaxRate();
  }, [tenantInfo?.TenantUserDetails?.TenantID]);

  const fetchTenantDefaultTaxRate = async () => {
    try {
      if (!tenantInfo?.TenantUserDetails?.TenantID) return;
      const configResponse = await getConfigV2({
        GroupName: 'General',
        KeyName: 'TaxRate',
        TenantID: tenantInfo?.TenantUserDetails?.TenantID,
      });

      let taxRate = parseFloat(configResponse) ? parseFloat(configResponse) * 100 : undefined;

      if (!taxRate) {
        await setConfig({
          GroupName: 'General',
          KeyName: 'TaxRate',
          TenantID: tenantInfo?.TenantUserDetails?.TenantID,
          Value: 0.1,
        });
        taxRate = DEFAULT_TAX_RATE;
      }

      if (taxRate) {
        setAdhocLineValues(prev => ({...prev, taxPercent: configResponse}));
      }
    } catch {}
  };

  const handleAdhocLineValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e?.target;
    setAdhocLineValues(prev => ({...prev, [name]: value}));
  };

  const validateAdhocValues = () => {
    const error: any = {};
    (Object.keys(adhocLineValues) as Array<keyof IAdhocLineValues>)?.forEach(l => {
      if (adhocLineValues[l] === '') {
        error[l] = 'Required';
      }
    });

    if (Number(adhocLineValues?.quantity) <= 0) {
      error['quantity'] = 'Please enter valid number';
    }

    if (!isEmpty(error)) {
      setAdhocLineErrors(error);
      return false;
    }
    return true;
  };

  const handleAddAdhocLine = async () => {
    const isValid = validateAdhocValues();

    if (isValid) {
      const qty = Number(adhocLineValues?.quantity);
      const price = Number(adhocLineValues?.price);
      const lineNeedsToAdd = {
        ...adhocLineValues,
        quantity: qty,
        price: price,
        lineTotalEx: qty * price,
        taxPercent: adhocLineValues?.taxPercent,
        rowAction: ClaimAndReturnRowAction?.Create,
        availableQty: null,
        reason: adhocLineValues?.reason || '',
      };

      const payload = {
        ...formikValues,
        lineItems: [
          {
            ...lineNeedsToAdd,
            invoiceLineID: null,
            notes: '',
            shortDescription: '',
          },
        ],
      };

      const claimRequestDetailResponse = await handleSubmit(payload, true);

      if (claimRequestDetailResponse) {
        updateFormValue(claimRequestDetailResponse);
        setAdhocLineValues({...adhocLineInitialValues, reason: claimLineItemReason || ''});
        setAdhocLineErrors({});
      }
    }
  };

  return (
    <tr className={`h-10`}>
      <td align="left" width="7%">
        <input
          className={clsx(
            'm-0 h-[38px] w-full !rounded-[3px] !p-2.5 text-left font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
            {'border-none': !adhocLineErrors?.code},
            {'!border !border-red-700 focus:border-red-700': adhocLineErrors?.code},
          )}
          type="text"
          disabled
          placeholder="Item code"
          data-autoid="txtAdhocLine-ItemCode"
          onChange={handleAdhocLineValueChange}
          name="code"
          value={adhocLineValues?.code}
        />
      </td>
      <td className={clsx('bg-[#FAFAFA]')} align="left" width="20%">
        <ProductSearchInput
          supplierId={supplierId}
          placeholder="Product name"
          data-autoid="txtAdhocLine-ProductName"
          type="text"
          onChange={handleAdhocLineValueChange}
          name="description"
          value={adhocLineValues?.description}
          onChangeSuggesion={() => {
            setAdhocLineValues(prev => ({
              ...prev,
              inventoryID: null,
              uoM: 'EA',
              code: 'Generic Item',
            }));
          }}
          onClickSearchResult={p => {
            setAdhocLineValues(prev => ({
              ...prev,
              inventoryID: p?.inventoryID,
              code: p?.code || '',
              description: p?.description || '',
              uoM: p?.uoM || '',
              isTaxExempt: p?.isTaxExempt,
              isTaxableFieldDiabled: true,
              reason: claimLineItemReason || '',
            }));
          }}
          className={clsx(
            'm-0 h-[38px] w-full !rounded-[3px] !p-2.5 text-left font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
            {'border-none': !adhocLineErrors?.description},
            {'border !border-red-700 focus:border-red-700': adhocLineErrors?.description},
          )}
        />
      </td>
      <td align="right" width="5%">
        <div className="relative flex items-center justify-end">
          <input
            disabled
            type="text"
            placeholder="UoM"
            data-autoid="txtAdhocLine-UoM"
            className={clsx(
              'm-0 h-[38px] w-full !rounded-[3px] !p-2.5 text-right font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
              {'border-none': !adhocLineErrors?.uoM},
              {'!border-red-700 focus:border-red-700': adhocLineErrors?.uoM},
            )}
            onChange={handleAdhocLineValueChange}
            name="uoM"
            value={adhocLineValues?.uoM}
          />
        </div>
      </td>
      <td className={clsx('bg-[#FAFAFA]')} align="right" width="10%">
        <div className="relative flex items-center justify-end">
          <input
            type="number"
            placeholder="Quantity"
            data-autoid="txtAdhocLine-Quantity"
            className={clsx(
              'm-0 h-[38px] w-full !rounded-[3px] !p-2.5 text-right font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
              {'border-none': !adhocLineErrors?.quantity},
              {'border !border-red-700 focus:border-red-700': adhocLineErrors?.quantity},
            )}
            onChange={handleAdhocLineValueChange}
            name="quantity"
            value={adhocLineValues?.quantity}
          />
        </div>
      </td>
      <td align="right" width="10%">
        {isPriceInputFocused ? (
          <input
            type="number"
            placeholder="Price"
            data-autoid={`txtAdhocLine-Price`}
            className={clsx(
              'm-0 h-[38px] w-full !rounded-[3px] !p-2.5 text-right font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
              {'border-none': !adhocLineErrors?.price},
              {'border !border-red-700 focus:border-red-700': adhocLineErrors?.price},
            )}
            name="price"
            value={adhocLineValues?.price}
            onChange={e => {
              const isMoreThan4DigitAterDecimal = e?.target?.value?.split('.')[1]?.length > 4;
              if (isMoreThan4DigitAterDecimal) return;
              handleAdhocLineValueChange(e);
            }}
            onBlur={() => setAdhocLineValues(p => ({...p, activeInput: undefined}))}
          />
        ) : (
          <input
            type="text"
            placeholder="Price"
            data-autoid={`txtAdhocLine-Price`}
            onFocus={() => setAdhocLineValues(p => ({...p, activeInput: 'price'}))}
            className={clsx(
              'm-0 h-[38px] w-full !rounded-[3px] !p-2.5 text-right font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
              {'border-none': !adhocLineErrors?.price},
              {'border !border-red-700 focus:border-red-700': adhocLineErrors?.price},
            )}
            value={getCurrencyStrFromDecimal(String(Math.trunc(Number(adhocLineValues.price) * 100) / 100))}
          />
        )}
      </td>
      <td className={clsx('bg-[#FAFAFA]')} align="right" width="10%">
        <p
          data-autoid="txtAdhocLine-claimtotal"
          className={clsx('p-2.5 font-poppins text-xs font-semibold leading-normal text-black-800')}
        >
          {String(Math.trunc(Number(adhocLineValues?.quantity) * Number(adhocLineValues?.price) * 100) / 100)}
        </p>
      </td>
      <td align="center" width="10%">
        <div className="ml-3 w-[38px]">
          <SCheckbox
            name="AdhocLine-TaxExempt"
            disabled={adhocLineValues?.isTaxableFieldDiabled}
            checked={adhocLineValues?.isTaxExempt}
            value={adhocLineValues?.isTaxExempt}
            label={''}
            onChange={(_e, isChecked) => {
              setAdhocLineValues(prev => ({
                ...prev,
                isTaxExempt: isChecked,
                taxPercent: isChecked ? undefined : adhocLineValues?.taxPercent,
              }));
            }}
          />
        </div>
      </td>
      <td align="left" width="15%">
        <select
          data-autoid={`ddlAdhocLineReason`}
          className={clsx(
            'm-0 h-[38px] w-full rounded-[3px] border-none border-[#777] px-2 font-poppins text-xs font-semibold outline-none focus:border-[1px] focus:border-solid focus:border-[#777]',
            {'border-none': !adhocLineErrors?.reason},
            {'!border-red-700 focus:border-red-700': adhocLineErrors?.reason},
          )}
          name="reason"
          onChange={handleAdhocLineValueChange}
          value={adhocLineValues?.reason}
        >
          {reasonDropdownOptions?.map((r, i) => {
            return (
              <>
                {r?.subReasons?.length ? (
                  <optgroup label={r?.parentReason?.reason}>
                    {r?.subReasons?.map((sr, index) => (
                      <option key={index} value={sr?.reason}>
                        {sr?.reason}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  <option
                    key={'lblSelectClaimAction-' + i}
                    data-autoid={`ddlItemAdhocLineReason- ${i}`}
                    value={r?.parentReason?.reason}
                  >
                    {r?.parentReason?.reason}
                  </option>
                )}
              </>
            );
          })}
        </select>
      </td>
      <td className={clsx('!bg-[#FFF]')} align="left" width="15%">
        <select
          data-autoid={`ddlAdhocLineAvtion`}
          className={clsx(
            'border-none focus:border-[1px] focus:border-solid focus:border-[#777]',
            'm-0 h-[38px] w-full rounded-[3px] border-[#777] px-2 font-poppins text-xs font-semibold outline-none',
            {'border-none': !adhocLineErrors?.action},
            {'!border-red-700 focus:border-red-700': adhocLineErrors?.action},
          )}
          name="action"
          onChange={handleAdhocLineValueChange}
          value={adhocLineValues?.action}
        >
          {(Object.keys(ClaimActions) as Array<keyof typeof ClaimActions>).map((key, index) => {
            return (
              <option
                key={'lblSelectClaimAction' + index}
                data-autoid={`ddlItemAdhocLineReason- ${index}`}
                value={ClaimActions[key]}
              >
                {key?.replace(/([A-Z])/g, ' $1').trim()}
              </option>
            );
          })}
        </select>
      </td>
      <td
        align="center"
        className={clsx('px-2.5 font-poppins text-xs font-semibold leading-normal text-black-800 !opacity-100')}
      >
        <div className="flex flex-row items-center justify-start gap-3">
          {isSubmitting ? (
            <div className="relative pr-5">
              <LoadingIndicator size="sm" isLoading={isSubmitting} />
            </div>
          ) : (
            <button type="button" data-autoid="btnAdhocLine" onClick={handleAddAdhocLine}>
              <IconTickClaims className={clsx('cursor-pointer')} />
            </button>
          )}
          <button
            type="button"
            data-autoid="btnCancelAdhocLine"
            onClick={() => setAdhocLineValues(adhocLineInitialValues)}
          >
            <IconCrossClaims className={clsx('cursor-pointer')} />
          </button>
        </div>
      </td>
    </tr>
  );
};
