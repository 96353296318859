import React from 'react';

import {CreateCatalogueLeftHeader} from './CreateCatalogueHeader';
import {CreateCatalogueLeftTable} from './CreateCatalogueLeftTable';

interface ICreateCatalogueLeftPanel {
  isCollapseRightPanel: boolean;
  setIsCollapseRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCatalogueLeftPanel = (props: ICreateCatalogueLeftPanel) => {
  const {isCollapseRightPanel, setIsCollapseRightPanel} = props;

  return (
    <div className={`h-full rounded bg-white p-2.5 text-black-800`}>
      <CreateCatalogueLeftHeader
        isCollapseRightPanel={isCollapseRightPanel}
        setIsCollapseRightPanel={setIsCollapseRightPanel}
      />
      <CreateCatalogueLeftTable />
    </div>
  );
};
