import React, {useState} from 'react';

import {Layout} from '../../../../components/layout/Layout';
import {CreateCatalogueLeftPanel} from './CreateCatalogueLeftPanel';
import {CreateCatalogueRightPanel} from './CreateCatalogueRightPanel';

export const CreateCatalogue = () => {
  const [isCollapseRightPanel, setIsCollapseRightPanel] = useState<boolean>(false);

  const _leftPanel = (
    <CreateCatalogueLeftPanel
      isCollapseRightPanel={isCollapseRightPanel}
      setIsCollapseRightPanel={setIsCollapseRightPanel}
    />
  );

  const _rightPanel = <CreateCatalogueRightPanel />;

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
      <Layout leftPanel={_leftPanel} mainPanel={_rightPanel} splitWidthType={isCollapseRightPanel ? 4 : 'AR'} />
    </div>
  );
};
