import React, {useContext, useState, useEffect} from 'react';
import {Formik, FormikHelpers} from 'formik';

import * as Yup from 'yup';
import {Button} from 'spenda-ui-react';
import CloseIcon from '@material-ui/icons/Close';
import {Grid, Box, DialogActions, Divider, DialogContent, DialogTitle, makeStyles, IconButton} from '@material-ui/core';

import useTenantAPI from '../../services/useTenantAPI';
import {ITenant, LogoTypeEnum, ILogo} from '../../model/Tenant';
import {Toast} from '../../utils/Toast';
import {validateABN} from '../../utils/validators';
import AppContext from '../../context/app/appContext';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {STabsDialog} from '../tabs/STabsDialog';
import {CreateNewCustomerAccountForm} from '../form/CreateNewCustomerAccountForm';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {LinkToAccountCustomer} from './LinkToAccountCustomerLinkForm';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';
import {ICustomer} from '../../model/customer/Customer';
import {BusinessDetailsFormValues} from '../onboarding/BusinessDetails';

interface ILinkOperationalCustomerDialogProps {
  setShowLinkOperationalCustomerDialog?: Function;
  onClose?: (f: boolean) => boolean;
  open?: boolean;
  customerId?: number;
  getCustomer?: Function;
  linkClick?: () => void;
  responseCustomerLinking?: any;
  customerDataGeneric?: Function;
  customerLink?: boolean;
  customerLinked?: boolean;
  setChangeNewCustomer?: boolean;
  setCustomer: (customer: ICustomer) => void;
}

const useLinkOperationalCustomerDialogStyles = makeStyles(theme => ({
  dialog: {
    justifySelf: 'center',
    position: 'absolute',
    minWidth: '900px',
  },
  dialogTitle: {
    borderBottom: 'none',
  },
  dialogContent: {
    padding: '8px 40px',
  },
  dialogActions: {
    borderTop: '1px solid #F1F1F1',
    margin: '0px 40px',
    padding: '8px 0px',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    color: '#4D4D4D',
    marginBottom: '20px',
  },
  lightLabel: {
    fontWeight: 400,
    fontSize: '0.875em',
    color: '#cccccc',
    margin: '0 0 8.25em 0 ',
  },
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '10px',
  },
  formValue: {
    fontSize: '1rem',
    color: '#999999',
    paddingBottom: '1rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const LinkOperationalCustomerDialog: React.FunctionComponent<any> = (
  props: ILinkOperationalCustomerDialogProps,
) => {
  const appCtx = useContext(AppContext);
  const {responseCustomerLinking, linkClick} = props;

  const newTenant: BusinessDetailsFormValues = {
    companyName: '',
    abn: '',
    companyPhone: '',
    website: '',
    mySpendaUrl: '',
    companyLogo: undefined,
    companyIcon: undefined,
    companyBanner: undefined,
  } as BusinessDetailsFormValues;

  const tenantAPI = useTenantAPI();

  const {getCustomer} = useCustomersAPI();
  const [customerData, setCustomerData] = useState<ICustomer>();
  const {list: getCustomerList} = useCustomersAPI();

  const onSaveTenant = async (companyDetails: BusinessDetailsFormValues) => {
    if (!companyDetails) {
      return;
    }

    let isSaved = false;

    const tenantDetails = {
      Name: companyDetails.companyName,
      ABN: companyDetails.abn,
      Phone: companyDetails.companyPhone,
      WebSiteURL: companyDetails.website,
      MarketPlacePath: companyDetails.mySpendaUrl,
      Logos: [] as ILogo[],
    } as ITenant;

    if (companyDetails.companyBanner || companyDetails.companyBanner === '') {
      tenantDetails.Logos.push({
        URI: companyDetails.companyBanner || '',
        ThumbnailURL: companyDetails.companyBannerThumb || '',
        LogoTypeID: LogoTypeEnum.Banner,
        GUID: '',
      });
    }
    if (companyDetails.companyIcon || companyDetails.companyIcon === '') {
      tenantDetails.Logos.push({
        URI: companyDetails.companyIcon || '',
        ThumbnailURL: companyDetails.companyIconThumb || '',
        LogoTypeID: LogoTypeEnum.Icon,
        GUID: '',
      });
    }
    if (companyDetails.companyLogo || companyDetails.companyLogo === '') {
      tenantDetails.Logos.push({
        URI: companyDetails.companyLogo || '',
        ThumbnailURL: companyDetails.companyLogoThumb || '',
        LogoTypeID: LogoTypeEnum.Logo,
        GUID: '',
      });
    }
    isSaved = (await tenantAPI.saveTenantDetails(tenantDetails)).IsSuccess;

    if (isSaved) {
      appCtx.setTenant(tenantDetails);
      Toast.info('Business details have been saved.');
      if (props.onClose) props.onClose(true);
    }
  };

  const onSubmit = (values: BusinessDetailsFormValues, helpers: FormikHelpers<BusinessDetailsFormValues>): void => {
    onSaveTenant(values).finally(() => {
      helpers.setSubmitting(false);
    });
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Business Name is required.')
      .max(100, 'Last Name cannot be longer than 100 characters.'),
    companyPhone: Yup.string().required('Business Phone is required.'),
    abn: Yup.string()
      .test('abn', 'Please provide a valid ABN.', function (value: string | undefined) {
        if (!value) {
          return true;
        }
        let result = validateABN(value);
        if (!result) {
          return true;
        } else {
          return result.valid;
        }
      })
      .required('ABN is required'),
  });

  const closeDialog = () => {
    setCustomerTab(0);
    if (props.onClose) {
      props.onClose(true);
    }
  };

  useEffect(() => {
    if (props.customerId) {
      getCustomer(props.customerId).then(
        res => {
          setCustomerData(res);
        },
        (_error: string) => {
          if (props?.setShowLinkOperationalCustomerDialog) {
            props?.setShowLinkOperationalCustomerDialog?.(false);
          }
        },
      );
    }
  }, [props.customerId]);

  const [customerTab, setCustomerTab] = useState<number>(0);

  const classes = useLinkOperationalCustomerDialogStyles();
  return (
    <Formik initialValues={newTenant} onSubmit={onSubmit} validationSchema={validationSchema}>
      {
        <SDialog open={props.open || false} classes={{paper: classes.dialog}}>
          <DialogTitle classes={{root: classes.dialogTitle}}>
            <Box display="flex" alignItems="top" justifyContent="end">
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => closeDialog()}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <h2>Define Posting Behaviour for {customerData?.CompanyName} </h2>
            </Box>
          </DialogTitle>
          <DialogContent className={`${classes.dialogContent}`}>
            <p className={`${classes.title}`}>Operational Customer Details</p>
            <Grid container spacing={1}>
              <Grid item sm={2} container className="flex">
                <p className={`${classes.formLabel}`}>Name:</p>
              </Grid>
              <Grid item sm={3}>
                <p className={`${classes.formValue}`}>{customerData?.Name || '-'}</p>
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} container className="flex">
                <p className={`${classes.formLabel}`}>Email:</p>
              </Grid>
              <Grid item sm={3}>
                <p className={`${classes.formValue}`}>{customerData?.PrimaryContactEmailAddress || '-'}</p>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={2} container className="flex">
                <p className={`${classes.formLabel}`}>Billing Address:</p>
              </Grid>
              <Grid item sm={6}>
                <p className={`${classes.formValue}`}>{customerData?.BillingAddressString || '-'}</p>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={2} container className="flex">
                <p className={`${classes.formLabel}`}>Phone No:</p>
              </Grid>
              <Grid item sm={3}>
                <p className={`${classes.formValue}`}>{customerData?.PrimaryContactPhone || '-'}</p>
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} container className="flex">
                <p className={`${classes.formLabel}`}>Source:</p>
              </Grid>
              <Grid item sm={3}>
                <p className={`${classes.formValue}`}>{customerData?.CreatedWith || '-'}</p>
              </Grid>
            </Grid>
            <p className="mb-[30px] mt-5 font-normal text-error ">
              This Operational Customer is NOT currently assigned to a <b>Customer Class.</b>
            </p>
            <Divider
              className="w-full"
              style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
            />
            <p className={`${classes.title}`}>Choose ONE of these three options</p>
            <STabsDialog
              value={customerTab}
              handleChange={newValue => setCustomerTab(newValue)}
              tabs={[
                {
                  label: 'Link To Account Customer',
                  content: (
                    <LinkToAccountCustomer
                      {...props}
                      setCustomerTab={setCustomerTab}
                      getCustomerList={getCustomerList}
                      customerData={customerData}
                      response={responseCustomerLinking}
                      linkClick={linkClick}
                    />
                  ),
                },
                {
                  label: 'Create New Account Customer',
                  content: (
                    <CreateNewCustomerAccountForm
                      {...props}
                      setShowLinkOperationalCustomerDialog={props?.setShowLinkOperationalCustomerDialog}
                      setCustomerTab={setCustomerTab}
                      customerData={customerData}
                      addCustomer={props.customerLink}
                      setChangeNewCustomer={props.setChangeNewCustomer}
                    />
                  ),
                },
                {
                  label: 'Assign A Generic Class',
                  content: (
                    <AssignAGenericClass
                      {...props}
                      setCustomerTab={setCustomerTab}
                      customerDataGeneric={props.customerDataGeneric}
                      setShowLinkOperationalCustomerDialog={props?.setShowLinkOperationalCustomerDialog}
                      data-autoid="txtAssignAGenericClass"
                    />
                  ),
                },
              ]}
            ></STabsDialog>
          </DialogContent>
          <DialogActions classes={{root: classes.dialogActions}}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button onClick={closeDialog} variant="outlined" className="bg-white">
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </SDialog>
      }
    </Formik>
  );
};

const AssignAGenericClass = (props: any) => {
  const classes = useLinkOperationalCustomerDialogStyles();
  const handleSave = async () => {
    if (props.customerId && props.customerClassFilter?.ID) {
      await props.linkGeneric(props.customerId, props.customerClassFilter?.ID ? props.customerClassFilter?.ID : 0);
    }
    props.handleFilter(null);
  };

  return (
    <>
      <p className={`${classes.title} mt-3`}>Choose A Generic Customer Class for this Operational Customer</p>
      <Grid container spacing={1}>
        <Grid item sm={2} alignItems="flex-end" className="flex">
          <p className={`${classes.formLabel}`}>Customer Class: </p>
        </Grid>
        <Grid item sm={4}>
          <CustomerClassesAutocomplete
            styles={{paddingBottom: '12px'}}
            PostingBehaviour={'Generic'}
            customerClassType={true}
            selected={props.customerClassFilter ? props.customerClassFilter : null}
            clearClick={() => props.handleFilter(null)}
            onSuggestionSelected={(e, selected) => props.handleFilter(selected.suggestion)}
          />
        </Grid>
      </Grid>
      <p className={`${classes.lightLabel}`}>
        *This will assign the operational customer to a Customer Class with Generic posting behaviour.
      </p>
      <Button
        type="submit"
        form="company-details-form"
        className="!absolute bottom-2 right-10"
        onClick={handleSave}
        disabled={props.customerClassFilter?.ID ? false : true}
      >
        Save
      </Button>
    </>
  );
};
