import React from 'react';
import {Input} from 'spenda-ui-react';

import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface ICreateCatalogueDialog {
  handleCancel?: () => void;
  handleContinue: () => void;
}

export const CreateCatalogueDialog = (props: ICreateCatalogueDialog) => {
  const {handleCancel, handleContinue} = props;

  return (
    <AlertDialogSlideV2
      dialogActionsAlignment="justify-between"
      dialogClassess="!w-full !min-w-[20%] !max-w-[560px]"
      dialogBodyClassess="my-10 px-11 py-9 text-black-800"
      headingTextSize="h1"
      title="Catalogue name"
      actions={[
        {
          action: handleCancel,
          label: 'Cancel',
          variant: 'outlined',
        },
        {
          action: handleContinue,
          label: 'Continue',
        },
      ]}
    >
      <Input name="catalogueName" label="Catalogue name" containerProps={{className: 'mx-auto max-w-[265px]'}} />
    </AlertDialogSlideV2>
  );
};
