import React, {useEffect, useState} from 'react';
import {ARTable} from '../ARTable';
import {Button, Typography} from 'spenda-ui-react';
import {DatTypes} from '../../../model/constants/Constants';
import {
  IStagingPurchaseInvoiceTaxAdjustment,
  useStagedTransactionsAPI,
} from '../../../services/useStagedTransactionsAPI';
import {
  IStagedDebitNoteDetail,
  IStagedDebitNoteDetailLine,
  IStagedPurchaseInvoiceDetail,
  IStagedPurchaseInvoiceDetailLine,
  IStagedTransactions,
} from '../../../model/requires-attention/StagedTransactions';
import moment from 'moment';
import IconCapricorn from '../../../assets/svg/IconCapricorn';
import {roundTo} from '../../../utils/formatter';
import LoadingIndicator from '../../ui/LoadingIndicator';
import {ILoading} from '../modals/InvoiceFromFinancialAdaptorDialog';
import {VerifyingTaxAmountDialog} from '../modals/VerifyingTaxAmountDialog';
import {
  ISelectedRow,
  getComparedStagedData,
} from '../../../screens/AccountsReceivable/requires-attention/RequiresAttention';
import {PostAsNewInvoiceModal} from '../modals/PostAsANewInvoiceModal';
import {OnHoldModal} from '../modals/OnHoldModal';

interface ICapTransactionReceiptTemplate {
  selectedStagedTransaction: IStagedTransactions;
  financialAdaptorDisplayName: string | undefined;
  isMatchedDocument: boolean;
  onClickCancleBtn: () => void;
  setTotalCapricornAmount: (value: number | undefined) => void;
  PSBLSTAGING?: boolean;
  isLoading: ILoading;
  handleDone: () => void;
}

export interface IOnHoldSelectedRow {
  refNo: string;
  messageID: number;
  documentID: number;
  datTypeID: number;
  status: string;
  notes: string | null;
}

export const InvoiceFromCapricornTemplate = (props: ICapTransactionReceiptTemplate) => {
  // Props
  const {
    selectedStagedTransaction,
    financialAdaptorDisplayName,
    isMatchedDocument,
    onClickCancleBtn,
    setTotalCapricornAmount,
    PSBLSTAGING,
    isLoading,
    handleDone,
  } = props;

  // States
  const [details, setDetails] = useState<IStagedPurchaseInvoiceDetail | IStagedDebitNoteDetail>();
  const [isVerifytaxAmountDialog, setIsVerifytaxAmountDialog] = useState<boolean>(false);
  const [isShowPostingPopup, setIsShowPostingPopup] = useState(false);
  const [showOnHoldModal, setShowOnHoldModal] = useState<boolean>(false);
  const [isOnHold, setIsOnHold] = useState<boolean>(false);
  const [postAsNewClickedData, setPostAsNewClickedData] = useState<ISelectedRow>();
  const [onHoldClickedData, setOnHoldClickedData] = useState<IOnHoldSelectedRow>();
  const [taxAdjusments, setTaxAdjusments] = useState<IStagingPurchaseInvoiceTaxAdjustment[]>([]);

  const {vendorName, refNumber, totalInc, totalTax, datTypeID, documentID, messageID, notes, status} =
    getComparedStagedData(selectedStagedTransaction);
  const isPurchaseInvoice = datTypeID === DatTypes.StagedPurchaseInvoice;
  const isDebitNote = datTypeID === DatTypes.StagedDebitNote;

  // APIs
  const {
    getStagedPurchaseInvoiceDetails,
    getStagedDebitNoteDetails,
    isLoading: isDetailsLoading,
  } = useStagedTransactionsAPI();

  const fetchData = async (id: any) => {
    try {
      if (isPurchaseInvoice) {
        const response = await getStagedPurchaseInvoiceDetails(id);
        setDetails(response);
        setTotalCapricornAmount(response?.totalInc);
      } else if (isDebitNote) {
        const response = await getStagedDebitNoteDetails(id);
        setDetails(response);
        setTotalCapricornAmount(response?.totalInc);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    fetchData(documentID);
  }, [documentID]);

  const handleVerifyTaxAmountDone = (updatedSelectedRows: ISelectedRow[]) => {
    const taxAdjusmentsData = updatedSelectedRows?.map(row => ({
      totalTax: row?.totalTax,
      stagingPurchaseInvoiceID: row?.documentID,
    }));
    setTaxAdjusments(taxAdjusmentsData);
    setIsVerifytaxAmountDialog(false);
    setIsShowPostingPopup(true);
  };

  const handlePostAsNewButtonClick = () => {
    const taxTableData: ISelectedRow = {
      supplier: vendorName || '',
      refNo: refNumber,
      messageID: messageID,
      documentID: documentID,
      totalInc: totalInc,
      totalTax: totalTax,
      datTypeID: datTypeID,
    };
    setPostAsNewClickedData(taxTableData);
    if (isPurchaseInvoice && PSBLSTAGING) {
      setIsVerifytaxAmountDialog(true);
    } else setIsShowPostingPopup(true);
  };

  const handleOnHoldButtonClick = () => {
    const OnHoldData: IOnHoldSelectedRow = {
      refNo: refNumber,
      messageID: messageID,
      documentID: documentID,
      notes: notes,
      status: status,
      datTypeID: datTypeID,
    };
    setIsOnHold(false);
    setOnHoldClickedData(OnHoldData);
    setShowOnHoldModal(true);
  };

  const handleOnHoldDone = async () => {
    setShowOnHoldModal(false);
    handleDone();
  };

  const handlePostAsNewInvoiceDone = async () => {
    setIsShowPostingPopup(false);
    handleDone();
  };

  const transactionColumns = [
    {
      title: '',
      key: 'sno',
      width: '5%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (_rowData: IStagedPurchaseInvoiceDetailLine | IStagedDebitNoteDetailLine, index: number) => (
        <Typography
          data-autoid={`lblSNo`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {index + 1}
        </Typography>
      ),
    },
    {
      title: 'Description',
      key: 'descrription',
      width: '80%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IStagedPurchaseInvoiceDetailLine | IStagedDebitNoteDetailLine) => (
        <Typography
          data-autoid={`lblDescription`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {rowData?.description}
        </Typography>
      ),
    },
    {
      title: 'Total',
      key: 'total',
      width: '15%',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IStagedPurchaseInvoiceDetailLine | IStagedDebitNoteDetailLine) => (
        <Typography
          data-autoid={`lblTotal`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {roundTo(rowData?.lineTotalEx, 2, true)}
        </Typography>
      ),
    },
  ];

  return (
    <div className="flex h-full max-h-[1080px] w-[638px] max-w-[1020px] flex-col rounded-[10px] bg-white shadow-md">
      <div className="header flex h-16 flex-col items-center justify-center border-b border-[#ccc]">
        <Typography variant="h2" className="text-black">
          Transaction from Capricorn
        </Typography>
      </div>
      <div className="body relative flex h-[calc(100%-60px)] flex-col items-center justify-center !px-2.5">
        {isDetailsLoading ? (
          <LoadingIndicator isLoading={isDetailsLoading} size={'md'} />
        ) : (
          <div className="flex h-full w-full flex-col items-center justify-start px-10 pb-16">
            <Typography variant="small" className="py-[30px] text-center text-black">
              {isMatchedDocument
                ? 'Here are the invoices we have identified as potential match.'
                : isPurchaseInvoice
                  ? `To match the transaction from Capricorn with the corresponding invoice(s) in ${financialAdaptorDisplayName}, please choose the correct invoice(s) and click 'Match & Link'. If you don't see the corresponding invoice(s) listed, click 'Post as a new invoice'.`
                  : `To match the transaction from Capricorn with the corresponding credit note(s) in ${financialAdaptorDisplayName}, please choose the correct credit note(s) and click 'Match & Link'. If you don't see the corresponding credit note(s) listed, click 'Post as a new'.`}
            </Typography>
            <div className="flex h-[calc(100%-150px)] w-full flex-col overflow-y-auto">
              <div className="flex h-auto w-full flex-col overflow-y-auto overflow-x-hidden bg-white px-2">
                <div className="flex h-auto w-full flex-row items-start justify-between border-b border-b-spenda-dashbg pb-3">
                  <div className="flex w-[50%] flex-col items-start justify-start">
                    <IconCapricorn />
                    <Typography
                      variant="paragraph"
                      className="pt-3 text-lg font-medium text-black-800"
                      data-autoid={`lblSupplierName`}
                    >
                      {details?.supplierName}
                    </Typography>
                    <Typography variant="xsmall" className=" pb-2 text-black-800" data-autoid={`lblSupplierId`}>
                      Supplier ID: {details?.supplierID}
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblPostingSupplierName`}>
                      {details?.postingSupplierName}
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblPostingSupplierABN`}>
                      ABN: {details?.postingSupplierABN}
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblPostingSupplierPhone`}>
                      Phone: {details?.postingSupplierPhone}
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblPostingSupplierEmail`}>
                      Email: {details?.postingSupplierEmailAddress}
                    </Typography>
                    <Typography
                      variant="xsmall"
                      className=" text-black-800"
                      data-autoid={`lblPostingSupplierWebSiteURL`}
                    >
                      Web: {details?.postingSupplierWebSiteURL}
                    </Typography>
                  </div>
                  <div className="flex w-[50%] flex-col items-end justify-end">
                    <div className="flex flex-row items-center justify-between gap-4">
                      <Typography variant="paragraph" className="pb-1 text-[18px] font-bold text-black-800">
                        Transaction Receipt
                      </Typography>
                    </div>
                    <div
                      data-autoid="txtTransactionStatus"
                      className={`mb-2 flex h-[50px] w-[140px] flex-col items-center justify-center self-end rounded-[8px] border-2 border-spenda-labeltext text-center font-poppins text-black`}
                    >
                      {isPurchaseInvoice ? (
                        <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblAmountDue`}>
                          Amount Due
                        </Typography>
                      ) : (
                        <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblAmount`}>
                          Amount
                        </Typography>
                      )}
                      <Typography
                        variant="paragraph"
                        className="font-medium text-black-800"
                        data-autoid={`lblTotalInc`}
                      >
                        {roundTo(details?.totalInc, 2, true)}
                      </Typography>
                    </div>
                    {isPurchaseInvoice ? (
                      <Typography
                        variant="xsmall"
                        className=" pb-1 text-lg font-medium text-black-800"
                        data-autoid={`lblInvoiceRefNo`}
                      >
                        Invoice #: {details?.refNumber}
                      </Typography>
                    ) : (
                      <Typography
                        variant="xsmall"
                        className=" pb-1 text-lg font-medium text-black-800"
                        data-autoid={`lblCreditNote`}
                      >
                        Credit Note #: {details?.refNumber}
                      </Typography>
                    )}
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblRefNo`}>
                      Ref Number:{' '}
                      {isPurchaseInvoice
                        ? (details as IStagedPurchaseInvoiceDetail)?.vendorInvoiceRefNumber
                        : (details as IStagedDebitNoteDetail)?.vendorDebitNoteRefNumber}
                    </Typography>
                    {isPurchaseInvoice ? (
                      <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblInvoiceDate`}>
                        Invoice Date:{' '}
                        {moment((details as IStagedPurchaseInvoiceDetail)?.invoiceDate).format('DD MMM YYYY')}
                      </Typography>
                    ) : (
                      <Typography variant="xsmall" className=" text-black-800" data-autoid={`lbCreditNoteDate`}>
                        Credit Note Date:{' '}
                        {moment((details as IStagedDebitNoteDetail)?.stagingDebitNoteDate).format('DD MMM YYYY')}
                      </Typography>
                    )}
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblDueDate`}>
                      Due Date: {moment(details?.dueDate).format('DD MMM YYYY')}
                    </Typography>
                  </div>
                </div>
                <div className="mt-2.5 flex h-auto w-full flex-row items-start justify-between pb-[34px]">
                  <div className="flex w-[50%] flex-col items-start justify-start">
                    <Typography
                      variant="paragraph"
                      className="pb-1 font-medium text-black-800"
                      data-autoid={`lblBillTo`}
                    >
                      Bill To: {details?.billToName}
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblContact`}>
                      Contact: {details?.billToContactName}
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblBillToEmailAddress`}>
                      {details?.billToEmailAddress}
                    </Typography>
                  </div>
                  <div className="flex w-[50%] flex-row items-start justify-end gap-6">
                    <div className="flex flex-col items-start">
                      <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                        Deliver to:
                      </Typography>
                      <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblShipAddress&City`}>
                        {details?.shipStreetAddress} {details?.shipCity}
                      </Typography>
                      <Typography
                        variant="xsmall"
                        className=" text-black-800"
                        data-autoid={`lblShipStateCountry&PostCode`}
                      >
                        {details?.shipState} {details?.shipCountry} {details?.shipPostCode}
                      </Typography>
                    </div>
                    <div className="flex flex-col items-start">
                      <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                        Billing:
                      </Typography>
                      <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblBillAddress&City`}>
                        {details?.billStreetAddress} {details?.billCity}
                      </Typography>
                      <Typography
                        variant="xsmall"
                        className=" text-black-800"
                        data-autoid={`lblBillStateCountry&PostCode`}
                      >
                        {details?.billState} {details?.billCountry} {details?.billPostCode}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className="mb-[40px] flex h-auto w-full flex-col">
                  <ARTable
                    tableClass="!mx-0"
                    rows={details?.lines || []}
                    columns={transactionColumns}
                    isLoading={isDetailsLoading}
                    dataAutoId="LineItem"
                    getRowDataAutoId={(rowData: IStagedDebitNoteDetail | IStagedPurchaseInvoiceDetail) =>
                      `rowLineItem${rowData?.refNumber}`
                    }
                  />
                  <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end border-b border-b-[#e3e3e3] px-[6px]">
                    <Typography variant="xsmall" className=" text-black-800">
                      Sub Total
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblTotalEx`}>
                      {roundTo(details?.totalEx, 2, true)}
                    </Typography>
                  </div>
                  <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end px-[6px] ">
                    <Typography variant="xsmall" className=" text-black-800">
                      Tax
                    </Typography>
                    <Typography variant="xsmall" className=" text-black-800" data-autoid={`lblTotalTax`}>
                      {roundTo(details?.totalTax, 2, true)}
                    </Typography>
                  </div>
                  <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px] ">
                    <Typography variant="xsmall" className=" font-bold text-black-800">
                      Total
                    </Typography>
                    <Typography variant="xsmall" className=" font-bold text-black-800" data-autoid={`lblTotal`}>
                      {roundTo(details?.totalInc, 2, true)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="absolute bottom-2.5 flex h-[60px] w-[97%] flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-2.5">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            color="primary"
            onClick={onClickCancleBtn}
            className="bg-white"
          >
            Cancel
          </Button>
          <div className="flex flex-row gap-2">
            <Button
              data-autoid={`btnOnHold`}
              variant="filled"
              color="primary"
              onClick={() => handleOnHoldButtonClick()}
            >
              On Hold
            </Button>
            <Button
              data-autoid={`btnPostAsANewInvoice`}
              variant="filled"
              color="primary"
              loading={isLoading?.postAsNewLoading}
              onClick={() => handlePostAsNewButtonClick()}
            >
              Post as New
            </Button>
          </div>
        </div>
      </div>
      {showOnHoldModal && onHoldClickedData && (
        <OnHoldModal
          isOnHold={isOnHold}
          isOpen={showOnHoldModal}
          onClose={() => setShowOnHoldModal(false)}
          selectedRows={onHoldClickedData}
          handleDone={handleOnHoldDone}
        />
      )}
      {isShowPostingPopup && postAsNewClickedData && (
        <PostAsNewInvoiceModal
          isOpen={isShowPostingPopup}
          onClose={() => setIsShowPostingPopup(false)}
          handleDone={handlePostAsNewInvoiceDone}
          selectedRows={[postAsNewClickedData]}
          taxAdjusments={taxAdjusments}
          PSBLSTAGING={PSBLSTAGING}
        />
      )}
      {isVerifytaxAmountDialog && PSBLSTAGING && postAsNewClickedData && (
        <VerifyingTaxAmountDialog
          isOpen={isVerifytaxAmountDialog}
          handleDone={handleVerifyTaxAmountDone}
          onClose={() => setIsVerifytaxAmountDialog(false)}
          selectedRows={[postAsNewClickedData]}
        />
      )}
    </div>
  );
};
