import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {IconButton, Option, Select, Typography} from 'spenda-ui-react';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';

import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Inventory} from '../../model/inventory/Inventory';
import useAccountAPI from '../../services/useAccountAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {useInventoryClassAPI} from '../../services/useInventoryClassesAPI';
import {DatTypes} from '../../model/constants/Constants';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {IAccounts} from '../../model/accounts/Accounts';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import PostingInventoryIcon from '../../assets/svg/accounts-payable/PostingInventoryIcon';

interface AddMissingProductInfoProps {
  inventory: Inventory;
  onClose: (actionType: 'Save' | 'Close') => void;
}

interface AddMissingProductInfoFormValues {
  InventoryClassID?: string;
  InventoryClassName?: string;
  ExpenseAccount?: string;
  RevenueAccount?: string;
  ID: number;
}

const validationSchema = Yup.object({
  InventoryClassID: Yup.string(),
  InventoryClassName: Yup.string().required('Inventory class is required'),
  ExpenseAccount: Yup.string().required('Purchasing account is required'),
  RevenueAccount: Yup.string().required('Sales account is required'),
});

export default function AddMissingProductInfo({inventory, onClose}: AddMissingProductInfoProps) {
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<AddMissingProductInfoFormValues>(
    {} as AddMissingProductInfoFormValues,
  );
  const [expenseAccounts, setExpenseAccounts] = useState<Partial<IAccounts[]>>();
  const [revenueAccounts, setRevenueAccounts] = useState<Partial<IAccounts[]>>();
  const [inventoryClasses, setInventoryClasses] = useState<Partial<InventoryClass[]>>();

  const {listAccounts, isLoading: accountsLoading} = useAccountAPI();
  const {dashboard, isLoading: classLoading} = useInventoryClassAPI();
  const {updateInventory} = useInventoryAPI();

  const isLoading = accountsLoading || classLoading;

  const getAllOptions = async () => {
    const response = await Promise.all([
      listAccounts({
        'Types[]': ['Expense', 'Revenue', 'Direct_Costs', 'Sales'],
        MaxResults: 1000,
      }),
      dashboard({
        DatTypeID: DatTypes.InventoryClass,
        MaxResults: 1000,
      }),
    ]);

    // want to store that class = 'income' and other class are in expense account
    setExpenseAccounts(response[0].Value.filter(item => item?.Type !== 'Revenue'));
    setRevenueAccounts(response[0].Value.filter(item => item?.Type === 'Revenue'));
    setInventoryClasses(response[1].Value);
    setInitialValues({
      ExpenseAccount: inventory?.ExpenseAccount ?? undefined,
      RevenueAccount: inventory?.RevenueAccount ?? undefined,
      InventoryClassID: inventory?.InventoryClassID ?? undefined,
      InventoryClassName: inventory?.InventoryClassName ?? undefined,
      ID: inventory.ID,
    });
  };

  useEffect(() => {
    getAllOptions();
  }, []);

  const onSubmit = (values: AddMissingProductInfoFormValues) => {
    updateInventory({...inventory, ...values, IsActive: true})
      .then(() => {
        onClose('Save');
      })
      .catch(() => {
        onClose('Close');
      });
  };

  const AddItem = ({isClass}: {isClass?: boolean}) => (
    <IconButton
      variant="text"
      color="primary"
      onClick={() => {
        history.push(`/inventory/products/edit/${initialValues.ID}?tab=${isClass ? '0' : '1'}`);
      }}
      className="active:bg-transparent"
      title="Add more"
    >
      <PostingInventoryIcon />
    </IconButton>
  );

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize validationSchema={validationSchema}>
      {({values, setValues, handleSubmit, setFieldValue, errors, isSubmitting, handleBlur, touched}) => (
        <Form name="missingProductInfo">
          <AlertDialogSlideV2
            title="Missing information"
            dialogActionsAlignment="justify-between"
            data-autoid="dlgMissingProductInfo"
            size="lg"
            actions={[
              {
                label: 'Cancel',
                action: () => onClose('Close'),
                variant: 'outlined',
              },
              {
                label: 'Save & Close',
                type: 'submit',
                loading: isSubmitting,
                disabled: isSubmitting,
                action: handleSubmit,
              },
            ]}
          >
            <>
              <div className="mx-10 flex justify-between rounded-lg bg-[#FAFAFA] py-8 pl-5 pr-9">
                <div className="flex flex-col">
                  <Typography variant="h3" className="text-base text-black-800">
                    Product Name
                  </Typography>
                  <Typography className="text-base text-black-800" data-autoid="txtInventoryName">
                    {inventory?.ShortDescription ?? '--'}
                  </Typography>
                </div>
                <div className="flex flex-col items-center">
                  <Typography variant="h3" className="text-base text-black-800">
                    Code
                  </Typography>
                  <Typography className="text-base text-black-800" data-autoid="txtInventoryCode">
                    {inventory?.InventoryCode ?? '--'}
                  </Typography>
                </div>
                <div className="flex flex-col items-center ">
                  <Typography variant="h3" className="text-base text-black-800">
                    SKU
                  </Typography>
                  <Typography className="text-base text-black-800" data-autoid="txtInventorySKU">
                    {inventory?.SKU ?? '--'}
                  </Typography>
                </div>
                <div className="flex flex-col items-center ">
                  <Typography variant="h3" className="text-base text-black-800">
                    UoM
                  </Typography>
                  <Typography className="text-base text-black-800" data-autoid="txtInventoryUoM">
                    {inventory?.UoM ?? '--'}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="h3" className="text-base text-black-800">
                    Price(ex)
                  </Typography>
                  <Typography className="self-end text-base text-black-800" data-autoid="txtInventorySellPriceEx">
                    {inventory?.StandardSellPriceEx ?? '--'}
                  </Typography>
                </div>
              </div>
              <Typography variant="small" className="pt-5 text-center font-normal text-black-800">
                It looks like this product is missing important details. Please provide the following information to
                proceed.
              </Typography>

              <LoadingIndicator isLoading={isLoading} size={'md'} />

              <div className="flex flex-col items-center gap-y-5 py-9">
                <div className="flex">
                  <Select
                    name="InventoryClassName"
                    label={
                      errors.InventoryClassName && touched.InventoryClassName
                        ? errors.InventoryClassName
                        : 'Select an inventory class'
                    }
                    error={Boolean(errors.InventoryClassName) && touched.InventoryClassName}
                    containerProps={{
                      className: 'w-[345px]',
                    }}
                    menuProps={{
                      className: 'max-h-[110px]',
                    }}
                    selected={() => values.InventoryClassName}
                    value={values.InventoryClassName}
                    onBlur={handleBlur}
                    onChange={(value: string) => {
                      const selectedClass = inventoryClasses?.find(item => item?.Name === value);
                      setValues({
                        ...values,
                        InventoryClassID: selectedClass?.ID,
                        InventoryClassName: selectedClass?.Name,
                      });
                    }}
                  >
                    {inventoryClasses?.length ? (
                      inventoryClasses?.map(item => (
                        <Option key={item?.ID} value={item?.Name} data-autoid={`option-${item?.ID}`}>
                          {item?.Name}
                        </Option>
                      ))
                    ) : (
                      <Option disabled value="not an option">
                        No inventory classes found
                      </Option>
                    )}
                  </Select>
                  <AddItem isClass />
                </div>

                <div className="flex">
                  <Select
                    name="RevenueAccount"
                    label={
                      errors.RevenueAccount && touched.RevenueAccount ? errors.RevenueAccount : 'Select a sales account'
                    }
                    error={Boolean(errors.RevenueAccount) && touched.RevenueAccount}
                    onBlur={handleBlur}
                    containerProps={{
                      className: 'w-[345px]',
                    }}
                    menuProps={{
                      className: 'max-h-[110px]',
                    }}
                    value={values.RevenueAccount}
                    onChange={(value: string) => setFieldValue('RevenueAccount', value)}
                    selected={() => values.RevenueAccount}
                  >
                    {revenueAccounts?.length ? (
                      revenueAccounts?.map(item => (
                        <Option key={item?.ID} value={item?.Code} data-autoid={`option-${item?.ID}`}>
                          {item?.Code} - {item?.Name}
                        </Option>
                      ))
                    ) : (
                      <Option disabled value="not an option">
                        No Revenue account found
                      </Option>
                    )}
                  </Select>
                  <AddItem />
                </div>

                <div className="flex">
                  <Select
                    name="ExpenseAccount"
                    label={
                      errors.ExpenseAccount && touched.ExpenseAccount
                        ? errors.ExpenseAccount
                        : 'Select a purchasing account'
                    }
                    error={Boolean(errors.ExpenseAccount) && touched.ExpenseAccount}
                    containerProps={{
                      className: 'w-[345px]',
                    }}
                    menuProps={{
                      className: 'max-h-[110px]',
                    }}
                    value={values.ExpenseAccount}
                    onBlur={handleBlur}
                    onChange={(value: string) => setFieldValue('ExpenseAccount', value)}
                    selected={() => values.ExpenseAccount}
                  >
                    {expenseAccounts?.length ? (
                      expenseAccounts?.map(item => (
                        <Option key={item?.ID} value={item?.Code} data-autoid={`option-${item?.ID}`}>
                          {item?.Code} - {item?.Name}
                        </Option>
                      ))
                    ) : (
                      <Option disabled value="not an option">
                        No Expense Account found
                      </Option>
                    )}
                  </Select>
                  <AddItem />
                </div>
              </div>
            </>
          </AlertDialogSlideV2>
        </Form>
      )}
    </Formik>
  );
}
