import React from 'react';
import {Button, Typography} from 'spenda-ui-react';

import {ARTable} from '../../../../components/AccountsReceivable/ARTable';
import {WidgetDeleteLogo} from '../../../../assets/svg/WidgetDeleteLogo';
import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';

export const CreateCatalogueLeftTable = () => {
  const columns = [
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (_index: number) => {
        return <RoundedCheckbox />;
      },
    },
    {
      title: 'Inventory code',
      key: 'inventoryCode',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <Typography className="font-medium" data-autoid={`txtCode-${index}`}>
            Code 1
          </Typography>
        );
      },
    },
    {
      title: 'Short description',
      key: 'shortDescription',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtShortDesc-${index}`}>Short Desc 1</Typography>;
      },
    },
    {
      title: 'SKU',
      key: 'SKU',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtSKU-${index}`}>SKU 1</Typography>;
      },
    },
    {
      title: 'UoM',
      key: 'UoM',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtUOM-${index}`}>UOM 1</Typography>;
      },
    },
    {
      title: 'Class name',
      key: 'className',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtClassName-${index}`}>ABC</Typography>;
      },
    },
    {
      title: 'Variants',
      key: 'variants',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtVariant-${index}`}>Variant 1</Typography>;
      },
    },
    {
      title: 'Brand',
      key: 'brand',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtBrand-${index}`}>Brand 1</Typography>;
      },
    },
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <span className="cursor-pointer active:bg-transparent" data-autoid={`btnRemove-${index}`}>
            <WidgetDeleteLogo />
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <div className="my-2.5 flex gap-5">
        <RoundedCheckbox label="Select all" />
        <Button disabled variant="outlined">
          Modified selected(0)
        </Button>
      </div>

      <ARTable isLoading={false} rows={Array(4).fill(2)} columns={columns} tableClass="!-mx-2.5 !w-[calc(100%+20px)]" />
    </div>
  );
};
