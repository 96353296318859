import {ITheme} from '../../model/theme/Theme';

const CapricornLMITheme: ITheme = {
  rootClassName: 'capricorn',
  content: {
    header: {
      moduleName: true,
    },
    settings: {
      betaFeatures: true,
      manageUsers: true,
    },
    zohoChat: true,
  },
};

export default CapricornLMITheme;
