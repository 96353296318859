import moment from 'moment';
import React, {useContext} from 'react';
import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import StartConvo from '../../../components/conversationChatBox/StartConvo';
import AppContext from '../../../context/app/appContext';
import ARContext from '../../../context/ar-context/ARContext';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {ARSelectableTransactions, ConversationType, DatTypes, StartConvoType} from '../../../model/constants/Constants';
import {ICustomerOutstandingStatementGroupByVendorList} from '../../../model/supplier/SupplierTransaction';
import {PriceFormat} from '../../../utils/formatter';
import {Typography} from 'spenda-ui-react';
import IconScheduledPayments from '../../../assets/svg/IconScheduledPayments';
import {IHeaderDetails} from '../../../components/AccountsReceivable/ARHeader';
import {ITxData} from '../../../components/AccountsReceivable/TransactionView';
import ARTooltip from '../../../components/data-display/ARTootip';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ICustomerOutstandingStatement} from '../../../model/customer/CustomerStatements';
import {clsx} from 'clsx';
import {IconDropDownArrow} from '../../../assets/svg/IconDropDownArrow';

interface IGroupVendorViewProps {
  supplierId: number;
  isLoading: boolean;
  transactionList: ICustomerOutstandingStatementGroupByVendorList[];
  expandedVendor: ICustomerOutstandingStatementGroupByVendorList | undefined;
  setExpandedVendor: React.Dispatch<React.SetStateAction<ICustomerOutstandingStatementGroupByVendorList | undefined>>;
  onSelect: (tx: ICustomerOutstandingStatement) => Promise<void>;
  onViewTransaction: (currenViewingTx: ITxData) => void;
  headerData?: IHeaderDetails;
  isVendorAllTransactionSelected: (txList: ICustomerOutstandingStatement[]) => boolean;
  handleSelectVendorAllTransactions: (txList: ICustomerOutstandingStatement[]) => void;
}

const PsblVendorView = (props: IGroupVendorViewProps) => {
  // Props
  const {
    supplierId,
    isLoading,
    transactionList,
    expandedVendor,
    headerData,
    setExpandedVendor,
    onSelect,
    onViewTransaction,
    handleSelectVendorAllTransactions,
    isVendorAllTransactionSelected,
  } = props;

  // Context
  const {conversations} = useContext(PurchasingContext);
  const {psblBatch} = useContext(ARContext);
  const {marketplaceSupplier} = useContext(AppContext);

  // Feature flags
  const {scheduledPaymentsV2} = useFeatureFlags().supplierDriven();

  // Functions
  const handleRenderNestedTable = (
    row: ICustomerOutstandingStatementGroupByVendorList,
    order: 'asc' | 'desc',
    orderBy: string,
    isPaginated?: boolean,
  ) => {
    if (expandedVendor?.name !== row?.name) return;
    orderBy = orderBy.replace('Child-', '');
    return (
      <ARTable
        isHighlightRowOnHover
        isNestedTable
        rows={expandedVendor?.groupedList}
        columns={vendorNestedColumns}
        isLoading={isLoading}
        order={order}
        orderBy={orderBy}
        isPaginated={isPaginated}
        dataAutoId="PSBLVendorViewNested"
        getRowDataAutoId={rowData => `rowPSBLVendorViewNested${rowData?.refNumber}`}
      />
    );
  };

  const vendorColumns = [
    {
      title: '',
      key: 'vendor-checkbox',
      columClassName: 'text-spenda-labeltext !max-w-[50px] !min-w-[50px]',
      rowClassName: `p-0`,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: ICustomerOutstandingStatementGroupByVendorList) => {
        return (
          <span
            className={`cursor-pointer items-center font-poppins text-base font-normal`}
            onClick={() => handleSelectVendorAllTransactions(rowData?.groupedList)}
            data-autoid={`chkTransactionRow`}
          >
            <ARSelectToggler
              key={`checkbox${rowData.name}`}
              isSelected={isVendorAllTransactionSelected(rowData?.groupedList)}
            />
          </span>
        );
      },
    },
    {
      title: 'Date',
      key: 'Child-transDate',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext min-w-[115px]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: (rowData: ICustomerOutstandingStatementGroupByVendorList) => (
        <div className="relative flex flex-row items-center justify-between">
          <div className="absolute left-0">
            <div className="flex w-full flex-row items-center ">
              <div
                className={`togglerIcon mr-[8px] flex !h-[14px] !max-h-[14px] !min-h-[14px] !w-[14px] !min-w-[14px] !max-w-[14px] cursor-pointer items-center justify-center rounded-[3px] border border-primary ${
                  rowData?.name !== expandedVendor?.name ? '' : '!rotate-180'
                }`}
                onClick={() => {
                  if (rowData?.name === expandedVendor?.name) setExpandedVendor(undefined);
                  else setExpandedVendor(rowData);
                }}
              >
                <IconDropDownArrow />
              </div>
              <Typography
                data-autoid={`lblVendorName`}
                className={`max-w-[600px] overflow-hidden overflow-ellipsis whitespace-nowrap font-medium`}
                variant="paragraph"
              >
                {rowData.name ? rowData.name : headerData?.supplierTenantName}
              </Typography>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Doc Type',
      key: 'Child-transactionType',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext min-w-[190px]',
      rowClassName: 'p-2.5',
      isSortable: false,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: () => <></>,
    },
    {
      title: 'Ref ID',
      key: 'Child-refNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext min-w-[115px]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: () => <></>,
    },
    {
      title: 'Due Date',
      key: 'Child-dueDate',
      width: '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext min-w-[115px]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: () => <></>,
    },
    {
      title: 'Invoice Total',
      key: 'Child-totalInc',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext min-w-[115px]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: () => <></>,
    },
    {
      title: 'Payment',
      key: 'Child-paymentsAllocated',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext min-w-[115px]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: () => <></>,
    },
    {
      title: 'Balance',
      key: 'Child-balance',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext min-w-[115px]',
      rowClassName: 'p-2.5',
      isSortable: true,
      getRowClassConditionally: (rowData: ICustomerOutstandingStatementGroupByVendorList) =>
        rowData?.name === expandedVendor?.name ? '!bg-[#F4F9FB]' : '!bg-[#fff]',
      rowRenderer: (rowData: ICustomerOutstandingStatementGroupByVendorList) => (
        <div className="relative h-[20px]">
          <Typography
            data-autoid={`lblBalance`}
            className={`inline overflow-ellipsis font-medium ${rowData?.totalBalance == 0 ? 'opacity-50' : ''}`}
            variant="paragraph"
          >
            {PriceFormat(rowData?.totalBalance)}
          </Typography>
        </div>
      ),
    },
  ];

  const vendorNestedColumns = [
    {
      title: '',
      key: 'checkbox-vendor-nested',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9] !max-w-[50px] !min-w-[50px]',
      rowClassName: 'p-0 !bg-[#F4F7F8] !max-w-[50px] !min-w-[50px]',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => {
        const isShowCheckbox = ARSelectableTransactions.includes(rowData.transactionTypeID);
        if (!isShowCheckbox) return <></>;

        const isNotSelectable =
          scheduledPaymentsV2 &&
          typeof rowData?.balanceIncludingScheduledIPAs === 'number' &&
          rowData?.balanceIncludingScheduledIPAs <= 0;

        return (
          <>
            {isNotSelectable ? (
              <ARTooltip
                arrow
                title={
                  <React.Fragment>
                    <h4 className="min-w-[200px] text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                      This {rowData?.transactionType} can not be selected as it’s already scheduled for payment.
                    </h4>
                  </React.Fragment>
                }
              >
                <span
                  className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
                  data-autoid={`chkTransactionRow`}
                >
                  <ARSelectToggler
                    key={`checkbox${rowData.transID}`}
                    isSelected={psblBatch?.selectedTxList?.findIndex(st => st.id === rowData.transID) > -1}
                  />
                </span>
              </ARTooltip>
            ) : (
              <span
                className={`cursor-pointer items-center font-poppins text-base font-normal`}
                data-autoid={`chkTransactionRow`}
                onClick={() => onSelect(rowData)}
              >
                <ARSelectToggler
                  key={`checkbox${rowData.transID}`}
                  isSelected={psblBatch?.selectedTxList?.findIndex(st => st.id === rowData.transID) > -1}
                />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Date',
      key: 'transDate',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F8FBFD] min-w-[115px]',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblTransDate`}
          className="inline overflow-ellipsis break-all font-medium"
          variant="paragraph"
        >
          {rowData?.transDate ? moment(rowData?.transDate).format('DD MMM YYYY') : ''}
        </Typography>
      ),
    },
    {
      title: 'Doc Type',
      key: 'transactionType',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F4F7F8] min-w-[190px]',
      isSortable: false,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="flex flex-row items-center justify-between">
          <Typography
            data-autoid={`lblTransactionType`}
            className="inline overflow-ellipsis break-all font-medium"
            variant="paragraph"
          >
            {rowData.transactionType}
          </Typography>
          <div className="flex flex-row items-center justify-end gap-2">
            {scheduledPaymentsV2 && rowData?.scheduledInvoicePaymentAllocationSummary?.length && (
              <ARTooltip
                arrow
                title={
                  <React.Fragment>
                    <h4 className="text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                      Payment Scheduled
                    </h4>
                    <ul className="mt-3">
                      {rowData?.scheduledInvoicePaymentAllocationSummary.map(s => (
                        <li key={s?.scheduledDateTime_utc} className="flex items-center justify-start gap-2 pb-1">
                          <p className="whitespace-nowrap font-poppins text-[10px] font-medium text-[#B2B2B2]">
                            Payment Date:
                          </p>
                          <p
                            data-autoid={`lblDateScheduledPayment`}
                            className="font-poppins text-[10px] font-semibold text-black-800"
                          >
                            {moment(s?.scheduledDateTime_utc)?.format('DD/MM/YYYY')}
                          </p>
                          <p
                            data-autoid={`lblAmountScheduledPayment`}
                            className="font-poppins text-[10px] font-semibold text-black-800"
                          >
                            {PriceFormat(s?.amount)}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                }
              >
                <button
                  className={clsx(`ml-2 flex h-[42px] w-9 cursor-pointer items-center justify-center bg-[#e6e4f5]`, {
                    'mr-8': !supplierId || rowData.transactionTypeID !== DatTypes.Invoice,
                  })}
                >
                  <IconScheduledPayments />
                </button>
              </ARTooltip>
            )}
            {rowData.transactionTypeID === DatTypes.Invoice && supplierId && (
              <StartConvo
                operationUniqueId={rowData?.transGUID}
                conversationWith={marketplaceSupplier?.SupplierName || ''}
                refNumber={rowData.refNumber}
                customerID={null}
                supplierID={supplierId}
                datTypeID={rowData.transactionTypeID}
                guid={rowData.transGUID}
                unreadMessages={rowData.unreadMessages}
                conversationId={rowData.conversationID}
                contextConvos={conversations}
                startConvoType={StartConvoType.ICON}
                convoType={ConversationType.DOCUMENT}
                transactionId={rowData?.transID}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Ref ID',
      key: 'refNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F8FBFD] min-w-[115px]',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="flex flex-col justify-start">
          <Typography
            onClick={() =>
              onViewTransaction({
                txDatType: rowData.transactionTypeID,
                txGuid: rowData.transGUID,
                txId: rowData.transID,
              })
            }
            data-autoid={`lnkRefNumber`}
            className="cursor-pointer overflow-ellipsis break-all font-medium text-primary underline"
            variant="paragraph"
          >
            {rowData.refNumber}
          </Typography>
          {rowData?.vendorRefNumber && (
            <Typography className="text-[#CCCCCC]" variant="xsmall">
              {rowData?.vendorRefNumber}
            </Typography>
          )}
        </div>
      ),
    },
    {
      title: 'Due Date',
      key: 'dueDate',
      width: '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F4F7F8] min-w-[115px]',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblDueDate`}
          className="inline overflow-ellipsis break-all font-medium"
          variant="paragraph"
        >
          {rowData?.dueDate && moment(rowData?.dueDate).format('DD MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Invoice Total',
      key: 'totalInc',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F8FBFD] min-w-[115px]',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblTotalInc`}
          className={`inline overflow-ellipsis break-all font-medium ${rowData?.totalInc == 0 ? 'opacity-50' : ''}`}
          variant="paragraph"
        >
          {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
            ? `(${PriceFormat(rowData?.totalInc)})`
            : PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Payment',
      key: 'paymentsAllocated',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F4F7F8] min-w-[115px]',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <Typography
          data-autoid={`lblAmountToPay`}
          className="inline overflow-ellipsis break-all font-medium"
          variant="paragraph"
        >
          {[DatTypes.Invoice].includes(rowData?.transactionTypeID) && rowData?.paymentsAllocated > 0
            ? `(${PriceFormat(rowData?.paymentsAllocated)})`
            : PriceFormat(rowData?.paymentsAllocated)}
        </Typography>
      ),
    },
    {
      title: 'Balance',
      key: 'balance',
      width: '14%',
      align: 'right',
      columClassName: 'text-spenda-labeltext !bg-[#F4F9FB] z-[9]',
      rowClassName: 'p-2.5 !bg-[#F8FBFD] min-w-[115px]',
      isSortable: true,
      rowRenderer: (rowData: ICustomerOutstandingStatement) => (
        <div className="relative h-[20px]">
          {scheduledPaymentsV2 && typeof rowData?.balanceIncludingScheduledIPAs === 'number' ? (
            <ARTooltip
              arrow
              title={
                <React.Fragment>
                  <h4 className="whitespace-nowrap text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                    Balance including scheduled payments
                  </h4>
                  <p
                    data-autoid={`lblBalanceIncludingScheduledPayments`}
                    className="mt-1 whitespace-nowrap text-center font-poppins text-[10px] font-medium text-spenda-primarytext"
                  >
                    {PriceFormat(rowData?.balanceIncludingScheduledIPAs)}
                  </p>
                </React.Fragment>
              }
            >
              <span
                data-autoid={`lblBalance`}
                className={`text- overflow-ellipsis break-all font-poppins text-base font-medium text-black-800 ${
                  rowData?.balance == 0 ? 'opacity-50' : ''
                }`}
              >
                {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
                  ? `(${PriceFormat(Math.abs(rowData?.balance))})`
                  : PriceFormat(rowData?.balance)}
              </span>
            </ARTooltip>
          ) : (
            <span
              data-autoid={`lblBalance`}
              className={`text- overflow-ellipsis break-all font-poppins text-base font-medium text-black-800 ${
                rowData?.balance == 0 ? 'opacity-50' : ''
              }`}
            >
              {[DatTypes.CreditNote, DatTypes.Payment].includes(rowData?.transactionTypeID)
                ? `(${PriceFormat(Math.abs(rowData?.balance))})`
                : PriceFormat(rowData?.balance)}
            </span>
          )}
        </div>
      ),
    },
  ];

  return (
    <ARTable
      isHighlightRowOnHover
      tableClass="mb-12"
      rows={transactionList}
      columns={vendorColumns}
      isLoading={isLoading}
      renderNestedTable={handleRenderNestedTable}
      dataAutoId="PSBLVendorView"
      getRowDataAutoId={rowData => `rowPSBLVendorView${rowData?.refNumber}`}
    />
  );
};

export default PsblVendorView;
