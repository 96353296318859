import React, {useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import COAWarningDialog from './COAWarningDialog';
import {PostingTypeOptions} from '../../../model/constants/Constants';

interface IMatchAndLinkConfirmationDialogProps {
  isOpen: boolean;
  formattedAdjustmentAmount: string;
  supplier: string | undefined;
  isLoading?: boolean;
  onClose: () => void;
  handleDone: () => void;
  handlePostingType: (selectedPostingType: PostingTypeOptions) => void;
  selectedPostingType: PostingTypeOptions | undefined;
  isCOARoundeingSelected?: boolean;
}

export const SelectMatchAndLinkMethod = (props: IMatchAndLinkConfirmationDialogProps) => {
  // Props
  const {
    isOpen,
    formattedAdjustmentAmount,
    supplier,
    isCOARoundeingSelected,
    selectedPostingType,
    isLoading,
    onClose,
    handleDone,
    handlePostingType,
  } = props;

  //State
  const [isCOAWarningDialog, setIsCOAWarningDialog] = useState(false);

  const handlePost = () => {
    if (selectedPostingType === PostingTypeOptions.DoNothing) {
      onClose();
      return;
    }
    if (selectedPostingType === PostingTypeOptions.PostAsARoundingAdjustment && !isCOARoundeingSelected) {
      setIsCOAWarningDialog(true);
      return;
    }
    handleDone();
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[922px]'}}
      isFullScreen
      header={<p className="font-poppins text-xl font-light text-[#333333]">How would you like us to post? </p>}
      body={
        <div className="my-12">
          <Typography
            variant="paragraph"
            className="mb-16 text-center font-light text-black-800"
            data-autoid={`lblSelectedInvoices`}
          >
            The total amount on the selected invoice doesn’t match, below are some options available.
          </Typography>
          <div className="relative flex flex-row justify-between">
            <button
              onClick={() => handlePostingType(PostingTypeOptions.PostAsASalesAdjustment)}
              className={`flex h-[149px] w-[210px] cursor-pointer flex-col items-center justify-center gap-4 rounded-[6px] border px-[24px] py-[30px] hover:border-primary-header hover:bg-primary-background ${
                selectedPostingType === PostingTypeOptions.PostAsASalesAdjustment
                  ? 'border-primary-header bg-primary-background'
                  : 'border-[#F1F1F1] bg-white'
              }`}
            >
              <p className="text-center font-poppins text-sm font-medium text-black-800">Post as a sales adjustment</p>
              <p className="text-center font-poppins text-xs font-normal text-black-800">
                We’ll create an invoice or credit note for {formattedAdjustmentAmount} in your {supplier} current
                statement account.
              </p>
            </button>
            <button
              onClick={() => handlePostingType(PostingTypeOptions.PostAsARoundingAdjustment)}
              className={`flex h-[149px] w-[210px] cursor-pointer flex-col items-center justify-center gap-4 rounded-[6px] border px-[24px] py-[30px] hover:border-primary-header hover:bg-primary-background ${
                selectedPostingType === PostingTypeOptions.PostAsARoundingAdjustment
                  ? 'border-primary-header bg-primary-background'
                  : 'border-[#F1F1F1] bg-white'
              }`}
            >
              <p className="text-center font-poppins text-sm font-medium text-black-800">
                Post as a rounding adjustment
              </p>
              <p className="text-center font-poppins text-xs font-normal text-black-800">
                We’ll create an adjustment of {formattedAdjustmentAmount} in the account you’ve configured for rounding.
              </p>
            </button>
            <button
              onClick={() => handlePostingType(PostingTypeOptions.PartiallyPayMyInvoice)}
              className={`flex h-[149px] w-[210px] cursor-pointer flex-col items-center justify-center gap-4 rounded-[6px] border px-[24px] py-[30px] hover:border-primary-header hover:bg-primary-background ${
                selectedPostingType === PostingTypeOptions.PartiallyPayMyInvoice
                  ? 'border-primary-header bg-primary-background'
                  : 'border-[#F1F1F1] bg-white'
              }`}
            >
              <p className="text-center font-poppins text-sm font-medium text-black-800">Partially pay my invoice</p>
              <p className="text-center font-poppins text-xs font-normal text-black-800">
                We’ll only pay {formattedAdjustmentAmount} from your {supplier} statement off the invoice in your
                financial system.
              </p>
            </button>
            <button
              onClick={() => handlePostingType(PostingTypeOptions.DoNothing)}
              className={`flex h-[149px] w-[210px] cursor-pointer flex-col items-center justify-center gap-4 rounded-[6px] border px-[24px] py-[30px] hover:border-primary-header hover:bg-primary-background ${
                selectedPostingType === PostingTypeOptions.DoNothing
                  ? 'border-primary-header bg-primary-background'
                  : 'border-[#F1F1F1] bg-white'
              }`}
            >
              <p className="text-center font-poppins text-sm font-medium text-black-800">Do nothing</p>
              <p className="text-center font-poppins text-xs font-normal text-black-800">
                I want to make a change in my financial system myself.
              </p>
            </button>
          </div>
          <COAWarningDialog isOpen={isCOAWarningDialog} onClose={() => setIsCOAWarningDialog(false)} />
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            data-autoid={`btnPost`}
            variant="filled"
            color="primary"
            onClick={handlePost}
            disabled={!selectedPostingType}
            loading={isLoading}
          >
            {selectedPostingType === PostingTypeOptions.DoNothing ? 'Close' : 'Post'}
          </Button>
        </div>
      }
    />
  );
};
