import React, {useRef, useState, useMemo, useCallback, useEffect} from 'react';
import {FormikProps} from 'formik';
import {chain, cloneDeep, debounce, isEmpty} from 'lodash';
import clsx from 'clsx';
import {Droppable, Draggable, DraggingStyle, NotDraggingStyle, DraggableStateSnapshot} from 'react-beautiful-dnd';
import Carousel from 'react-elastic-carousel';

import {ICreateRequisitionValues} from './CreateRequisitionDialog';
import {DatTypes} from '../../model/constants/Constants';
import {IRequisition, IRequisitionLine} from '../../model/requisition/Requisition';
import {Inventory} from '../../model/inventory/Inventory';
import {ISupplier} from '../../model/supplier/Supplier';
import {InventoryLineAutocomplete} from '../inventory/InventoryAutocomplete';
import {CreateSupplierDialog} from '../dialog/CreateSupplierDialog';
import {StyledCustomCheckbox} from '../inputs/SCheckbox';
import LoadingIndicator from '../ui/LoadingIndicator';
import {FilterMenuItem} from '../menu/FilterMenu';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import PriceCalculator from '../../utils/priceCalculator';
import {PriceFormat} from '../../utils/formatter';

import {
  ClickAwayListener,
  Grow,
  IconButton,
  InputBase,
  Popper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import IconSearch from '../../assets/svg/IconSearch';
import {IconBin} from '../../assets/svg/IconBin';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';

export const UNASSIGNED_SUPPLIER = 'UNASSIGNED';
export const SUPPLIER_ID_ATTRIBUTE = 'data-supplier-id';

const useRequisitionLinesStyles = makeStyles(() => ({
  table: {
    fontFamily: 'Poppins, sans-serif',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& .MuiTableRow-root': {
      height: 40,
      '& .MuiTableCell-head': {
        fontFamily: 'Poppins, sans-serif',
        color: '#999999',
        fontWeight: '600',
        fontSize: '14px',
        padding: '0px 8px',
        backgroundColor: '#F1F1F1',
        borderBottom: 'none',
        '&:first-child': {
          borderTopLeftRadius: '2px',
        },
        '&:last-child': {
          borderTopRightRadius: '2px',
        },
      },
      '& .MuiTableCell-body': {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        color: '#333333',
        padding: '0px 8px',
        borderBottom: '1px solid #EAEAEA50',
        border: '1px solid transparent',
        '&.active': {
          border: '1px solid #1C78AD',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
        '&.cell-input': {
          cursor: 'text',
        },
        '& .input-bordered.Mui-focused': {
          border: '1px solid #1C78AD !important',
        },
      },
    },
  },
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '8px',
    marginRight: '6px',
  },
  iconButtonActive: {
    borderRadius: '0 6px 6px 0',
    backgroundColor: '#1C78AD',
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      borderRadius: '0 6px 6px 0',
      backgroundColor: '#1C78AD',
    },
  },
}));

const StyledInputBase = withStyles({
  root: {
    fontFamily: 'Poppins, sans-serif',
    '& .MuiInputAdornment-root': {
      '& p': {
        color: '#333333',
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    '& input': {
      fontSize: '12px',
      color: '#333333',
      textAlign: 'right',
      backgroundColor: 'transparent !important',
    },
  },
})(InputBase);

const StyledTextField = withStyles({
  root: {
    border: '1px solid #1C78AD',
    fontFamily: 'Poppins',
    fontWeight: 600,
    borderRadius: '6px 0 0 6px',
    '&:has([aria-expanded="true"])': {
      borderRadius: '6px 0 0 0',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '6px',
      margin: 'auto',
      '&::before, &::after': {
        display: 'none',
      },
      '& input::placeholder': {
        color: '#BBBBBB',
      },
      '& input': {
        marginBottom: 0,
        color: '#333333',
      },
    },
  },
})(TextField);

const getItemStyle = (
  snapshot: DraggableStateSnapshot,
  isGhosting: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): React.CSSProperties => {
  const style: React.CSSProperties = {
    background: snapshot.isDragging ? '#FAFAFA' : '',
    border: snapshot.isDragging ? '1px solid #E0DFDF' : '',
    ...draggableStyle,
    margin: snapshot.isDragging ? 'auto' : '',
    boxShadow: snapshot.isDragging ? 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' : '',
    opacity: isGhosting ? '0.75' : '1',
  };

  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  style.transitionDuration = '0.001s';
  return style;
};

interface IRequisitionLines extends FormikProps<ICreateRequisitionValues> {
  requisitionSuppliers: Map<string, string>;
  editMode: boolean;
  draggingId: string;
  selectedIds: {[x: string]: Set<string>};
  setSelectedIds: (x: {[x: string]: Set<string>}) => void;
  saveLines: (lines: IRequisitionLine[], oldLines?: IRequisitionLine[]) => Promise<IRequisition>;
}

export const RequisitionLines = (props: IRequisitionLines) => {
  const classes = useRequisitionLinesStyles();
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const {values, setFieldValue, draggingId, requisitionSuppliers, editMode, selectedIds, setSelectedIds, saveLines} =
    props;

  const groupedLines = useMemo(
    () =>
      chain(values.Lines || [])
        .filter(line => Boolean(line.SupplierID))
        .groupBy('SupplierID')
        .value(),
    [values.Lines],
  );

  const debounceSaveLines = useCallback(
    debounce((lines: IRequisitionLine[]) => {
      saveLines(lines).then(requisition => {
        setFieldValue('Lines', requisition.Lines || []);
      });
    }, 500),
    [],
  );

  const handleGlobalClickAway = () => {
    setActiveIndex(-1);
  };

  const saveLineInventory = async (selected: Partial<Inventory> | null, _selection: any) => {
    const inventoryItem: IRequisitionLine = {
      SupplierName: selected?.DefaultSupplierName,
      SupplierID: selected?.DefaultSupplierID,
      InventoryID: selected?.ID,
      Code: selected?.InventoryCode,
      ShortDescription: selected?.ShortDescription,
      CostPriceEx: selected?.CostPriceEx,
      LineTotalEx: selected?.CostPriceEx,
      IsActive: true,
      Quantity: 1,
    };
    const lines = cloneDeep(values.Lines || []);
    const index = lines.findIndex(line => line.InventoryID === inventoryItem.InventoryID);
    if (index > -1) {
      lines[index].Quantity = (lines[index].Quantity || 0) + 1;
    } else {
      lines.push(inventoryItem);
    }
    const requisition = await saveLines(lines);
    setFieldValue('Lines', requisition.Lines);
  };

  const handleColumnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inventory: IRequisitionLine,
  ) => {
    if (/^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) {
      const lines = cloneDeep(values.Lines || []);
      const index = lines.findIndex(line => String(line.LineID) === String(inventory.LineID));
      if (index > -1) {
        lines[index].Quantity = Number(e.target.value);
        lines[index].LineTotalEx = Number(e.target.value) * (inventory?.CostPriceEx || 0);
        debounceSaveLines(lines);
        setFieldValue('Lines', lines);
      }
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement & {[SUPPLIER_ID_ATTRIBUTE]?: string}>) => {
    if (event.target.dataset.supplierId) {
      const selectedSet = new Set(selectedIds[event.target.dataset.supplierId]);

      for (let _data of groupedLines[event.target.dataset.supplierId]) {
        if (event.target.checked) {
          selectedSet.add(String(_data.LineID));
        } else {
          selectedSet.delete(String(_data.LineID));
        }
      }
      setSelectedIds({...selectedIds, [event.target.dataset.supplierId]: selectedSet});
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement & {[SUPPLIER_ID_ATTRIBUTE]?: string}>) => {
    if (event.target.dataset.supplierId) {
      const selectedSet = new Set(selectedIds[event.target.dataset.supplierId]);

      if (event.target.checked) {
        selectedSet.add(String(event.target.name));
      } else {
        selectedSet.delete(String(event.target.name));
      }

      setSelectedIds({...selectedIds, [event.target.dataset.supplierId]: selectedSet});
    }
  };

  return (
    <ClickAwayListener onClickAway={handleGlobalClickAway}>
      <div className="relative mb-14 flex flex-col">
        {editMode ? (
          <div className="mt-4 flex">
            <div
              className={clsx('w-[150px] border-collapse border border-solid border-gray-200 px-2 py-1', {
                '!border-spenda-sBlue': activeIndex === 0,
              })}
            >
              <InventoryLineAutocomplete
                placeholder="Enter code"
                searchCodeOnly={true}
                clearOnSelect={true}
                index={0}
                inputRefs={inputRefs}
                id="Inventory-Code"
                onFocus={() => setActiveIndex(0)}
                selected={null}
                onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
              />
            </div>
            <div
              className={clsx('flex-1 border border-solid border-gray-200 px-2 py-1', {
                '!border-spenda-sBlue': activeIndex === 1,
              })}
            >
              <InventoryLineAutocomplete
                placeholder="Enter product"
                clearOnSelect={true}
                index={1}
                inputRefs={inputRefs}
                id="Inventory-ShortDescription"
                onFocus={() => setActiveIndex(1)}
                selected={null}
                onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
              />
            </div>
          </div>
        ) : null}
        {isEmpty(requisitionSuppliers) ? (
          <Table className={clsx(classes.table, 'mt-4')}>
            <TableHead>
              <TableRow>
                {editMode ? (
                  <TableCell padding="checkbox" align="center">
                    <StyledCustomCheckbox disabled />
                  </TableCell>
                ) : null}
                <TableCell width="10%" style={{color: '#1C78AD', fontWeight: 400, whiteSpace: 'nowrap'}}>
                  Select all
                </TableCell>
                <TableCell align="center" width="99%">
                  [Name of Supplier]
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        ) : null}
        {!isEmpty(requisitionSuppliers)
          ? Array.from(requisitionSuppliers.keys()).map((key, index1) => {
              return (
                <Droppable key={key} droppableId={key}>
                  {(provided, snapshot) => (
                    <Table
                      className={clsx(classes.table, 'mt-4', {
                        'border border-dashed bg-gray-200': snapshot.isDraggingOver,
                      })}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <TableHead>
                        <TableRow>
                          {editMode ? (
                            <TableCell padding="checkbox" align="center" style={{minWidth: '40px'}}>
                              <StyledCustomCheckbox
                                inputProps={{[SUPPLIER_ID_ATTRIBUTE]: key, 'data-autoid': `chkSelectAll${key}`} as any}
                                checked={
                                  !isEmpty(groupedLines[key])
                                    ? groupedLines[key].every(v =>
                                        Array.from(selectedIds[key] || []).includes(String(v.LineID)),
                                      )
                                    : false
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                          ) : null}
                          <TableCell
                            style={{color: '#1C78AD', fontWeight: 400, whiteSpace: 'nowrap', minWidth: '110px'}}
                          >
                            {editMode
                              ? !isEmpty(groupedLines[key]) &&
                                groupedLines[key].every(v =>
                                  Array.from(selectedIds[key] || []).includes(String(v.LineID)),
                                )
                                ? 'Select none'
                                : 'Select all'
                              : null}
                          </TableCell>
                          <TableCell align="center" width="99%">
                            {requisitionSuppliers.get(key)}
                          </TableCell>
                          <TableCell key={`${key}_${1}`} />
                          <TableCell key={`${key}_${2}`} />
                          <TableCell key={`${key}_${3}`} />
                        </TableRow>
                      </TableHead>
                      <TableBody className="relative">
                        {(groupedLines[key] || []).map((inventory, index) => {
                          const isSelected: boolean = selectedIds[key]
                            ? selectedIds[key].has(String(inventory.LineID))
                            : false;
                          const isGhosting: boolean =
                            isSelected && Boolean(draggingId) && draggingId !== String(inventory.LineID);
                          return (
                            <Draggable
                              key={inventory.LineID}
                              draggableId={String(inventory.LineID)}
                              index={index}
                              isDragDisabled={!editMode}
                            >
                              {(provided, snapshot) => {
                                const shouldShowSelection: boolean = snapshot.isDragging && selectedIds[key]?.size > 1;
                                if (shouldShowSelection) {
                                  return (
                                    <TableRow
                                      key={inventory.LineID}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(snapshot, isGhosting, provided.draggableProps.style)}
                                    >
                                      <TableCell align="center" colSpan={6}>
                                        {selectedIds[key]?.size} items selected
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                                return (
                                  <TableRow
                                    key={inventory.LineID}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot, isGhosting, provided.draggableProps.style)}
                                  >
                                    {editMode ? (
                                      <TableCell align="center">
                                        <StyledCustomCheckbox
                                          inputProps={
                                            {
                                              [SUPPLIER_ID_ATTRIBUTE]: key,
                                              'data-autoid': `chkSelect${inventory.LineID}`,
                                            } as any
                                          }
                                          id={String(inventory.LineID)}
                                          name={String(inventory.LineID)}
                                          checked={isSelected}
                                          onChange={handleSelectChange}
                                        />
                                      </TableCell>
                                    ) : null}
                                    <TableCell>{inventory.Code}</TableCell>
                                    <TableCell>{inventory.ShortDescription}</TableCell>
                                    <TableCell
                                      key={`${inventory.LineID}_${index + index1 + 2}`}
                                      data-index={index + index1 + 2}
                                      data-id={inventory.LineID}
                                      className={clsx({'!border-spenda-sBlue': activeIndex === index + index1 + 2})}
                                      align="center"
                                      style={{
                                        minWidth: '100px',
                                        color: !inventory.Quantity && !editMode ? '#999999' : '#333333',
                                      }}
                                    >
                                      {!editMode ? inventory.Quantity || 'Quantity' : null}
                                      {editMode ? (
                                        <StyledInputBase
                                          fullWidth
                                          id={`Quantity-${inventory.LineID}`}
                                          name="Quantity"
                                          type="number"
                                          placeholder="0"
                                          inputRef={el => (inputRefs.current[index + index1 + 2] = el)}
                                          onFocus={() => setActiveIndex(index + index1 + 2)}
                                          value={PriceCalculator.roundNumber(inventory.Quantity || 0) || ''}
                                          onChange={e => {
                                            if (Number(e.target.value) >= 0) handleColumnChange(e, inventory);
                                          }}
                                          inputProps={{
                                            'data-autoid': `txtQuantity${index1}${index}`,
                                            'data-id': inventory.LineID,
                                            style: {textAlign: 'center'},
                                          }}
                                        />
                                      ) : null}
                                    </TableCell>
                                    <TableCell align="right" style={{minWidth: '100px'}}>
                                      {PriceFormat(inventory.LineTotalEx)}
                                    </TableCell>
                                    {editMode ? (
                                      <TableCell align="center">
                                        <DragIndicatorRoundedIcon style={{color: '#BBBBBB'}} />
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </TableBody>
                      <tbody className="hidden">{provided.placeholder}</tbody>
                    </Table>
                  )}
                </Droppable>
              );
            })
          : null}
      </div>
    </ClickAwayListener>
  );
};

const initialFilter = {
  IsShowActiveOnly: true,
  DatTypeID: DatTypes.Suppliers,
  IsApprovedForWeb: true,
  IsShowAccountCustomersOnly: false,
  MaxResults: 40,
  IsExactMatch: false,
  SortAsc: true,
  SortField: 'Name',
};

export const RequisitionAssignSupplier = (props: IRequisitionLines) => {
  const classes = useRequisitionLinesStyles();

  const [createSupplierDialog, setCreateSupplierDialog] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<{SupplierID: string; SupplierName: string}[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [supplierSearchRes, setSupplierSearchRes] = useState<{SupplierID: string; SupplierName: string}[]>([]);

  const {values, setFieldValue, editMode, draggingId, requisitionSuppliers, selectedIds, setSelectedIds, saveLines} =
    props;

  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});

  const {dashboard, isLoading} = useSupplierAPI();

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getSupplierList(newValue);
    }, 500),
    [],
  );

  const unassignedLines = useMemo(() => (values.Lines || []).filter(line => !line.SupplierID), [values.Lines]);

  useEffect(() => {
    const newSuppliers = Array.from(requisitionSuppliers.keys())
      .map(key => ({SupplierID: key, SupplierName: requisitionSuppliers.get(key) || ''}))
      .filter(o => !suppliers.some(i => i.SupplierID === o.SupplierID));

    setSuppliers([...newSuppliers, ...suppliers]);
  }, [requisitionSuppliers]);

  const getSupplierList = (newValue: string = '') => {
    dashboard({
      ...initialFilter,
      ...(newValue ? {SearchString: newValue} : {}),
    }).then((res: ISupplier[]) => {
      setSupplierSearchRes((res || []).map(r => ({SupplierID: String(r.ID), SupplierName: r.Name})));
    });
  };

  const handleCreateSupplier = () => {
    setCreateSupplierDialog(true);
  };

  const handleCreateSupplierClose = (supplier?: ISupplier) => {
    if (supplier) {
      setSuppliers([
        {
          SupplierID: String(supplier.ID),
          SupplierName: supplier.Name,
        },
        ...suppliers,
      ]);
    }
    setCreateSupplierDialog(false);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement & {[SUPPLIER_ID_ATTRIBUTE]?: string}>) => {
    if (event.target.dataset.supplierId) {
      const selectedSet = new Set(selectedIds[event.target.dataset.supplierId]);

      for (let _data of unassignedLines) {
        if (event.target.checked) {
          selectedSet.add(String(_data.LineID));
        } else {
          selectedSet.delete(String(_data.LineID));
        }
      }
      setSelectedIds({...selectedIds, [event.target.dataset.supplierId]: selectedSet});
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement & {[SUPPLIER_ID_ATTRIBUTE]?: string}>) => {
    if (event.target.dataset.supplierId) {
      const selectedSet = new Set(selectedIds[event.target.dataset.supplierId]);

      if (event.target.checked) {
        selectedSet.add(String(event.target.name));
      } else {
        selectedSet.delete(String(event.target.name));
      }
      setSelectedIds({...Object.assign(selectedIds, {[event.target.dataset.supplierId]: selectedSet})});
    }
  };

  const handleClickAway = () => {
    searchInputPopup.close();
    setSearchInput('');
    setSupplierSearchRes([]);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    if (event.target.value.length > 2) {
      debouncedSave(event.target.value);
    }
  };

  const handleDeleteClick = async () => {
    const ids: string[] = [];
    Object.values(selectedIds).forEach(_ids => ids.push(...Array.from(_ids)));
    if (ids.length) {
      const lines = (values.Lines || []).map(line => {
        if (ids.includes(String(line.LineID))) {
          line.IsActive = false;
          return line;
        }
        return line;
      });
      const requisition = await saveLines(lines);
      setFieldValue('Lines', requisition.Lines);
    }
  };

  const handleSupplierClick = async (sup: {SupplierID: string; SupplierName: string}) => {
    if (selectedIds[UNASSIGNED_SUPPLIER]?.size > 0) {
      const ids = Array.from(selectedIds[UNASSIGNED_SUPPLIER]);
      const lines = cloneDeep(values.Lines || []);
      const _lines = lines.map(line => {
        if (ids.includes(String(line.LineID))) {
          line.SupplierID = Number(sup.SupplierID);
          line.SupplierName = sup.SupplierName;
          return line;
        }
        return line;
      });
      setFieldValue('Lines', _lines);
      const requisition = await saveLines(_lines, cloneDeep(values.Lines || []));
      setFieldValue('Lines', requisition.Lines);
    } else {
      const index = suppliers.findIndex(_sup => _sup.SupplierID === sup.SupplierID);
      if (index > -1) {
        setSuppliers([
          ...suppliers.sort((x, y) => (x.SupplierID == sup.SupplierID ? -1 : y.SupplierID == sup.SupplierID ? 1 : 0)),
        ]);
      } else {
        setSuppliers([sup, ...suppliers]);
      }
    }
    setSearchInput('');
    searchInputPopup.close();
  };

  const handleSupplierBucketClick = async (
    e: React.MouseEvent<HTMLDivElement>,
    sup: {SupplierID: string; SupplierName: string},
  ) => {
    e.preventDefault();
    const ids: string[] = [];
    Object.values(selectedIds).forEach(_ids => ids.push(...Array.from(_ids)));
    const lines = cloneDeep(values.Lines || []);
    if (ids.length) {
      const _lines = lines.map(line => {
        if (ids.includes(String(line.LineID))) {
          line.SupplierID = Number(sup.SupplierID);
          line.SupplierName = sup.SupplierName;
          return line;
        }
        return line;
      });
      setFieldValue('Lines', _lines);
      const requisition = await saveLines(_lines, cloneDeep(values.Lines || []));
      setFieldValue('Lines', requisition.Lines);
    }
  };

  return (
    <>
      <div>
        <CreateSupplierDialog open={createSupplierDialog} onClose={handleCreateSupplierClose} />
        <div className="flex flex-row items-center justify-between pb-2" style={{borderBottom: '1px solid #D8D8D8'}}>
          <span
            className={clsx(
              'ml-2 overflow-hidden text-ellipsis whitespace-nowrap text-xl font-light text-spenda-primarytext',
              {
                'w-[200px]': searchInputPopup.isOpen,
              },
            )}
          >
            Please assign products
          </span>
          <div className="flex-1" />
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, {[classes.iconButtonActive]: searchInputPopup.isOpen})}
                data-autoid="btnSearchSupplier"
                {...bindToggle(searchInputPopup)}
              >
                <IconSearch />
              </IconButton>
              <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                {({TransitionProps}) => (
                  <Grow {...TransitionProps} timeout={250}>
                    <div className="relative">
                      <StyledTextField
                        fullWidth
                        autoFocus
                        placeholder="Select Supplier"
                        value={searchInput}
                        onChange={onChangeSearch}
                        InputProps={{
                          'aria-expanded': Boolean(supplierSearchRes.length) || isLoading || Boolean(searchInput),
                          inputProps: {
                            'data-autoid': 'txtSearchSupplier',
                          },
                        }}
                      />
                      {supplierSearchRes.length || isLoading || searchInput ? (
                        <ul className="absolute w-full rounded-b-md border !border-t-0 !border-spenda-sBlue bg-white">
                          {isLoading ? (
                            <li className="p-4">
                              <LoadingIndicator isLoading={true} size="sm" />
                            </li>
                          ) : null}
                          {!isLoading && searchInput && !supplierSearchRes.length ? (
                            <li className="p-2 font-poppins text-base">No supplier found</li>
                          ) : null}
                          {!isLoading
                            ? supplierSearchRes.map(supplier => (
                                <FilterMenuItem
                                  key={supplier.SupplierID}
                                  value={supplier.SupplierID}
                                  onClick={() => handleSupplierClick(supplier)}
                                  data-autoid={`mnuSupplier${supplier.SupplierID}`}
                                >
                                  {supplier.SupplierName}
                                </FilterMenuItem>
                              ))
                            : null}
                        </ul>
                      ) : null}
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
          <IconButton
            disableRipple
            className={classes.iconButton}
            onClick={handleCreateSupplier}
            data-autoid="btnAddSupplier"
          >
            <AddRoundedIcon style={{fontSize: '22px'}} />
          </IconButton>
        </div>
        {!isEmpty(suppliers) ? (
          <div className="pt-2" style={{borderBottom: '1px solid #D8D8D8'}}>
            <p className="text-xs">
              Try out our new drag and drop functionality by dragging an unassigned line item to the correct supplier in
              the buckets below or to left hand panel.
            </p>
            {/* @ts-ignore */}
            <Carousel showArrows={false} isRTL={false} itemsToShow={3} itemsToScroll={2} className="mt-2">
              {suppliers.map(supplier => (
                <Droppable key={supplier.SupplierID} droppableId={`${supplier.SupplierID}_`}>
                  {(provided, snapshot) => (
                    <div
                      className={clsx(
                        'mx-1 flex w-full cursor-pointer items-center justify-center rounded-lg border !border-spenda-sBlue bg-[#D3E5EF] bg-opacity-50 px-2 py-1 text-center text-base text-primary',
                        {
                          '!bg-[#C8E2ED]': snapshot.isDraggingOver,
                        },
                      )}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      onClick={e => handleSupplierBucketClick(e, supplier)}
                      data-autoid={`btnSupplier${supplier.SupplierID}`}
                    >
                      <p className="line-clamp-3">{supplier.SupplierName}</p>
                    </div>
                  )}
                </Droppable>
              ))}
            </Carousel>
          </div>
        ) : null}
        <Droppable key={UNASSIGNED_SUPPLIER} droppableId={UNASSIGNED_SUPPLIER}>
          {provided => (
            <Table className={clsx(classes.table, 'mt-4')} ref={provided.innerRef} {...provided.droppableProps}>
              <TableHead>
                <TableRow>
                  {editMode ? (
                    <TableCell padding="checkbox" align="center">
                      <StyledCustomCheckbox
                        inputProps={
                          {
                            [SUPPLIER_ID_ATTRIBUTE]: UNASSIGNED_SUPPLIER,
                            'data-autoid': `chkSelectAllUnassigned`,
                          } as any
                        }
                        checked={
                          !isEmpty(unassignedLines)
                            ? unassignedLines.every(v =>
                                Array.from(selectedIds[UNASSIGNED_SUPPLIER] || []).includes(String(v.LineID)),
                              )
                            : false
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell style={{color: '#1C78AD', fontWeight: 400, whiteSpace: 'nowrap'}}>
                    {!isEmpty(unassignedLines) &&
                    unassignedLines.every(v =>
                      Array.from(selectedIds[UNASSIGNED_SUPPLIER] || []).includes(String(v.LineID)),
                    )
                      ? 'Select none'
                      : 'Select all'}
                  </TableCell>
                  <TableCell align="center" width="99%" colSpan={2}>
                    Unassigned
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={'relative'}>
                {unassignedLines.map((inventory, index) => {
                  const isSelected: boolean = selectedIds[UNASSIGNED_SUPPLIER]
                    ? selectedIds[UNASSIGNED_SUPPLIER].has(String(inventory.LineID))
                    : false;
                  const isGhosting: boolean =
                    isSelected && Boolean(draggingId) && draggingId !== String(inventory.LineID);
                  return (
                    <Draggable key={inventory.LineID} draggableId={String(inventory.LineID)} index={index}>
                      {(provided, snapshot) => {
                        const shouldShowSelection: boolean =
                          snapshot.isDragging && selectedIds[UNASSIGNED_SUPPLIER]?.size > 1;
                        if (shouldShowSelection) {
                          return (
                            <TableRow
                              key={inventory.LineID}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot, isGhosting, provided.draggableProps.style)}
                            >
                              <TableCell align="center" colSpan={4}>
                                {selectedIds[UNASSIGNED_SUPPLIER]?.size} items selected
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return (
                          <TableRow
                            key={inventory.LineID}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot, isGhosting, provided.draggableProps.style)}
                          >
                            <TableCell>
                              {editMode ? (
                                <StyledCustomCheckbox
                                  inputProps={
                                    {
                                      [SUPPLIER_ID_ATTRIBUTE]: UNASSIGNED_SUPPLIER,
                                      'data-autoid': `chkSelect${inventory.LineID}`,
                                    } as any
                                  }
                                  id={String(inventory.LineID)}
                                  name={String(inventory.LineID)}
                                  checked={isSelected}
                                  onChange={handleSelectChange}
                                />
                              ) : null}
                            </TableCell>
                            <TableCell>{inventory.Code}</TableCell>
                            <TableCell>{inventory.ShortDescription}</TableCell>
                            <TableCell align="center" width="10%">
                              <DragIndicatorRoundedIcon style={{color: '#BBBBBB'}} />
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </TableBody>
              <tbody className="hidden">{provided.placeholder}</tbody>
            </Table>
          )}
        </Droppable>
      </div>
      <Droppable key="DELETE" droppableId="DELETE">
        {(provided, snapshot) => (
          <div className="flex justify-center">
            <span
              className={clsx('cursor-pointer rounded-full bg-[#D3E5EF]', {
                'p-2': snapshot.isDraggingOver,
                'p-3': !snapshot.isDraggingOver,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}
              onClick={handleDeleteClick}
              data-autoid="btnDeleteRequisitionItems"
            >
              {snapshot.isDraggingOver ? (
                <ClearRoundedIcon className="text-[#C55D44]" fontSize="large" />
              ) : (
                <IconBin fill="#1C78AD" />
              )}
            </span>
            <span className="hidden">{provided.placeholder}</span>
          </div>
        )}
      </Droppable>
    </>
  );
};
