import useHttp from '../hooks/useHttp';
import {IActionResultsPayments, IResponseWrapper} from '../model/ActionResults';
import {Actions} from '../model/constants/Constants';
import {
  IStagedTransactionsResponse,
  IStagedPurchaseInvoiceDetail,
  IStagedDebitNoteDetail,
} from '../model/requires-attention/StagedTransactions';

export const useStagedTransactionsAPI = () => {
  const {GET, POST, PUT, isLoading} = useHttp();

  const getStagedTransactions = async (): Promise<IStagedTransactionsResponse> => {
    return GET(`Spenda/Payments/AccountsPayable/StagedTransactions`).then((res: IStagedTransactionsResponse) => res);
  };

  const mergeSupplier = async (supplierID: number, supplierIDToArchive: number): Promise<void> => {
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/Merge`, {
      SupplierIDToArchive: supplierIDToArchive,
    });
  };

  const postCreditLineAccount = async (supplierID: number, creditLineAccountID: number): Promise<void> => {
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/SetCreditLineAccount`, {
      CreditLineAccountID: creditLineAccountID,
    });
  };
  const postVendor = async (
    supplierID: number,
    postingSupplierID: number,
    vendorName?: string,
    ruleID?: number,
    action: string = 'Add',
  ): Promise<void> => {
    const payload = {
      PostingSupplierID: postingSupplierID,
      Action: action,
      ...(ruleID && {ID: ruleID}),
      ...(vendorName && {VendorName: vendorName}),
    };
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/VendorPostingRule`, payload);
  };

  const postAsItIs = async (supplierID: number): Promise<void> => {
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/SetAsPostingSupplier`, {});
  };

  const approveStagedTransactions = async (messageIds: number[], IsPostAsNew?: boolean): Promise<void> => {
    return POST(`Spenda/Messaging/Messaging/ApproveMessages`, {MessageIDs: messageIds, IsPostAsNew: IsPostAsNew});
  };

  const rejectStagedTransactions = async (messageIds: number[]): Promise<void> => {
    return POST(`Spenda/Messaging/Messaging/RejectMessages`, {MessageIDs: messageIds});
  };

  const matchAndLinkTransaction = async (payload: IMatchAndLinkTransactionPayload): Promise<void> => {
    return POST(`Spenda/Messaging/Messaging/ApproveMessage`, payload);
  };

  const updateVendorMapping = async (supplierID: number, value: boolean): Promise<void> => {
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/SetVendorMapping`, {
      IsVendorMappingEnabled: value,
    });
  };

  const getStagedPurchaseInvoiceDetails = async (
    stagingPurchaseInvoiceID: number,
  ): Promise<IStagedPurchaseInvoiceDetail> => {
    return GET(`Spenda/Payments/AccountsPayable/StagingPurchaseInvoice/${stagingPurchaseInvoiceID}`).then(
      (res: IActionResultsPayments<IStagedPurchaseInvoiceDetail>) => res.value,
    );
  };

  const getStagedDebitNoteDetails = async (stagingDebitNoteID: number): Promise<IStagedDebitNoteDetail> => {
    return GET(`Spenda/Payments/AccountsPayable/StagingDebitNote/${stagingDebitNoteID}`).then(
      (res: IActionResultsPayments<IStagedDebitNoteDetail>) => res.value,
    );
  };

  const postInventoryPostingRule = async (
    supplierID: number,
    requestItems: {
      SourceSupplierID: number;
      InventoryCode: string;
      PostingInventoryID: string;
      Action: Actions;
      ID?: number;
    }[],
  ): Promise<void> => {
    // TODO: Temp changes for testing. requested by sunil
    const requestItemsArr = requestItems.map(item => {
      return {
        PostingSupplierID: supplierID,
        InventoryCode: item.InventoryCode,
        PostingInventoryID: item.PostingInventoryID,
        Action: item.Action,
        ID: item.ID,
      };
    });
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/InventoryPostingRule `, requestItemsArr);
  };

  const postSelectSupplierPostingInventory = async (
    supplierID: number,
    payload: IPostSelectSupplierPostingInventoryPayload,
  ): Promise<void> => {
    return POST(`Spenda/Payments/AccountsPayable/Supplier/${supplierID}/SetAPSettings `, payload);
  };

  const taxAdjustment = async (payload: ITaxAdjustmentPayload): Promise<IResponseWrapper<void>> => {
    return PUT(`Spenda/Payments/AccountsPayable/StagingPurchaseInvoice/TaxAdjustment`, payload);
  };

  const postOnHold = async (payload: IOnHoldPayload): Promise<void> => {
    return PUT(`Spenda/Messaging/Messaging/HoldMessage`, payload);
  };

  const updateOnHoldNotes = async (payload: IOnHoldPayload): Promise<void> => {
    return PUT(`Spenda/Messaging/Messaging/UpdateMessage`, payload);
  };

  return {
    getStagedTransactions,
    approveStagedTransactions,
    rejectStagedTransactions,
    updateVendorMapping,
    postCreditLineAccount,
    mergeSupplier,
    postVendor,
    isLoading,
    postAsItIs,
    getStagedDebitNoteDetails,
    getStagedPurchaseInvoiceDetails,
    postInventoryPostingRule,
    postSelectSupplierPostingInventory,
    matchAndLinkTransaction,
    taxAdjustment,
    postOnHold,
    updateOnHoldNotes,
  };
};

interface IMatchAndLinkTransactionPayload {
  messageID: number;
  linkedDocumentIDs: number[];
  adjustmentAmount: number;
  isPostAsNew: boolean;
  isRoundingAdjustment?: boolean;
}

interface IPostSelectSupplierPostingInventoryPayload {
  postingInventoryID: string;
  isInventoryMappingEnabled?: boolean;
}
interface IOnHoldPayload {
  DatTypeID?: number;
  MessageID: number;
  Notes: string | null;
}

export interface ITaxAdjustmentPayload {
  stagingPurchaseInvoiceTaxAdjustments: IStagingPurchaseInvoiceTaxAdjustment[];
}

export interface IStagingPurchaseInvoiceTaxAdjustment {
  stagingPurchaseInvoiceID: number;
  totalTax: number;
}
