import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, IconButton, Menu, MenuHandler, MenuList, SearchInput, Typography} from 'spenda-ui-react';

import {AUTH_INVENTORY_MANAGE_CATALOGUE} from '../../../../routes/InventoryRoutes';
import {BackIcon, CollapseUpIcon, ListCheckIcon, PlusButton, ReUploadCatalogueIcon} from '../../../../assets/svg';

interface ICreateCatalogueLeftHeader {
  isCollapseRightPanel: boolean;
  setIsCollapseRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCatalogueLeftHeader = (props: ICreateCatalogueLeftHeader) => {
  const {isCollapseRightPanel, setIsCollapseRightPanel} = props;

  const history = useHistory();

  const [isShowCatalogueMenu, setIsShowCatalogueMenu] = useState<boolean>(false);

  const handleBack = () => {
    history.push(AUTH_INVENTORY_MANAGE_CATALOGUE);
  };

  return (
    <header className="flex items-center justify-between border-b border-[#ECECEC] pb-2.5">
      <div className="flex items-center justify-start gap-2.5">
        <div className="cursor-pointer" onClick={handleBack}>
          <IconButton variant="outlined" ripple={false}>
            <BackIcon />
          </IconButton>
        </div>
        <Typography variant="h2" className="font-light">
          Genuine Catalogue
        </Typography>
        <IconButton variant="outlined" className="border-none p-0 shadow-none" ripple={false}>
          <PlusButton className="cursor-pointer" data-autoid="btnAddLine" />
        </IconButton>
      </div>
      <div className="flex items-center gap-2.5">
        <SearchInput
          reverse={true}
          clearIcon
          iconButtonProps={{
            name: 'Search',
          }}
          data-autoid="txtSearchInput"
          autoFocus
          placeholder="Search..."
        />
        <IconButton variant="outlined" name="listCheck" className="min-w-10">
          <ListCheckIcon />
        </IconButton>
        <Menu
          dismiss={{
            itemPress: false,
          }}
          open={isShowCatalogueMenu}
          handler={() => {
            setIsShowCatalogueMenu(!isShowCatalogueMenu);
          }}
          placement="bottom-end"
        >
          <MenuHandler
            className={`flex h-[40px] min-w-[246px] cursor-pointer items-center gap-4 rounded-md border border-success pl-4 ${isShowCatalogueMenu ? 'rounded-b-none' : null}`}
          >
            <div>
              <Typography
                data-autoid={'txtReUploadCatalogue'}
                className="flex items-center justify-start gap-2.5 font-semibold text-success"
              >
                <ReUploadCatalogueIcon className="min-w-3.5" data-autoid={`imgReUploadCatalogue`} /> Re-Upload Catalogue
              </Typography>
              <div className="flex items-center justify-end gap-2 ">
                <span className="border border-y-[10px] border-[#ccc]"></span>
                <div className={`${isShowCatalogueMenu ? 'rotate-180' : 'rotate-0'}`} data-autoid={`imgAction`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="fill-success">
                    <path
                      fillRule="evenodd"
                      d="M4.2889 5.4688h7.4533a.6666.6666 0 0 1 .4667 1.14l-3.72 3.7199a.6663.6663 0 0 1-.9467 0l-3.72-3.72a.6667.6667 0 0 1 .4667-1.14Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </MenuHandler>
          <MenuList className="-mt-[5px] flex min-w-[246px] flex-col rounded-t-none  border border-t-0 border-success p-0">
            <button className="rounded-none border-b border-[#ECECEC] px-2.5 py-1.5 text-left text-base font-semibold text-black-800 !outline-none hover:!bg-success/10 ">
              Export catalogue
            </button>
          </MenuList>
        </Menu>
        <Button className="min-w-[154px]">Edit Subscribers</Button>
        <IconButton
          variant="outlined"
          data-autoid={`btnShowHideRightPanel`}
          className="min-w-10"
          onClick={() => setIsCollapseRightPanel(!isCollapseRightPanel)}
        >
          <CollapseUpIcon
            data-autoid={`imgCollapse`}
            className={isCollapseRightPanel ? 'rotate-[270deg]' : `rotate-90`}
          />
        </IconButton>
      </div>
    </header>
  );
};
