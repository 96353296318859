import React, {useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {Typography} from 'spenda-ui-react';
import {debounce} from 'lodash';

import QuoteSmartSearch from './QuoteSmartSearch';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {IPagedActionResults} from '../../model/ActionResults';
import {Inventory} from '../../model/inventory/Inventory';
import {IQuoteLines, IQuotes} from '../../model/quotes/quotes';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {IServiceJob, IServiceJobLines} from '../../model/service-management/serviceJob';
import GreenCheckIcon from '../../assets/svg/accounts-payable/GreenCheckIcon';
import {AddMissingProductInfo} from '../dialog';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface QuoteInventoryAutocompleteProps<T> {
  by: 'product' | 'code';
  index: number;
  alreadyFilled: number[];
  calculateLineTotal: (
    Lines: Partial<IQuoteLines | IServiceJobLines>[],
    _values: T,
    shipping?: number | string,
    rowIndex?: number,
    discount?: string,
    discountMode?: DISCOUNT_MODE,
  ) => void;
  disabled?: boolean;
}

const QuoteInventoryAutocomplete = <T extends IQuotes | IServiceJob>(
  props: QuoteInventoryAutocompleteProps<T>,
): JSX.Element => {
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const [results, setResults] = useState<Partial<Inventory>[]>();
  const [showAddMissingProductInfo, setShowAddMissingProductInfo] = useState({
    show: false,
    inventory: {} as Inventory,
  });
  const {listInventory, listInventoryByCode, isLoading} = useInventoryAPI();

  const {values, setFieldValue} = useFormikContext<T>();

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getInventoryList(newValue);
    }, 750),
    [],
  );

  const saveLineInventory = async (selected: Partial<Inventory> | null, _selection: any) => {
    if (Array.isArray(values.lines)) {
      const _lines = values.lines.map((_line, _index) => {
        if (_index === props.index) {
          const data = {
            ..._line,
            inventoryID: selected?.ID,
            code: selected?.InventoryCode,
            shortDescription: selected?.ShortDescription,
            quantity: 1,
            uoM: selected?.UoM,
            description: selected?.Description,
            sellPriceEx: +selected?.StandardSellPriceEx?.toFixed(2)!,
            lineTotalEx: +selected?.StandardSellPriceEx?.toFixed(2)!,
            lineTotalExString: selected?.StandardSellPriceEx?.toFixed(2),
            costPriceEx: +selected?.CostPriceEx?.toFixed(2)!,
          };

          if (QuoteV289367) {
            return {
              ...data,
              margin: Number(selected?.StandardSellPriceEx) - Number(selected?.CostPriceEx),
              marginString: `${(Number(selected?.StandardSellPriceEx) - Number(selected?.CostPriceEx)).toFixed(2)}`,
              marginMode: DISCOUNT_MODE.DOLLAR,
            };
          } else {
            return data;
          }
        }
        return _line;
      });
      setFieldValue('lines', _lines);
      props.calculateLineTotal(
        _lines,
        values,
        values.shipping,
        props.index,
        values.discountString,
        values.discountMode,
      );
    }
  };

  const handleChange: ChangeEventHandler = e => {
    if (!e.target.value.trim()) return setResults([]);
    if (props.by === 'product') setFieldValue(`lines[${props.index}].product`, e.target.value);
    else setFieldValue(`lines[${props.index}].code`, e.target.value);
    if (e.target.value.length < 3) {
      return;
    }
    debouncedSave(e.target.value);
  };

  const getInventoryList = (query?: string) => {
    if (props.by === 'product') {
      listInventory({
        MaxResults: 100,
        ViewMode: 'Sales',
        IsSystem: false,
        ...(query ? {Search: query} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setResults(res?.Value ?? []);
      });
    } else {
      listInventoryByCode({
        MaxResults: 100,
        StartRow: 1,
        IsSystem: false,
        ...(query ? {SearchString: query} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setResults(res?.Value ?? []);
      });
    }
  };

  const handleSuggestionSelected = (item: Partial<Inventory>) => {
    if (!item) return true;

    if (QuoteV289367 && (!item?.InventoryClassID || !item?.RevenueAccount || !item?.ExpenseAccount)) {
      setShowAddMissingProductInfo({
        show: true,
        inventory: item,
      });
      return true;
    }

    setResults([item]);
    const selectedSuggestion = {
      suggestion: item,
      suggestionValue: `${item.InventoryCode} ${item.ShortDescription}`,
      suggestionIndex: props.index,
      sectionIndex: 0,
    };
    if (props.alreadyFilled && props.alreadyFilled.length) {
      if (!props.alreadyFilled.some((_m: number) => _m === item.ID)) {
        saveLineInventory(item, selectedSuggestion);
      } else {
        return false;
      }
    } else {
      saveLineInventory(item, selectedSuggestion);
    }
    return true;
  };

  const resultShow = (item: Partial<Inventory>) => {
    const isSelected = props.alreadyFilled && props.alreadyFilled.some((_m: number) => _m === item.ID);
    const isMissingInfo = QuoteV289367 && (!item?.InventoryClassID || !item?.RevenueAccount || !item?.ExpenseAccount);
    return (
      <Typography variant="xsmall" data-autoid={`ddlItem-${item.InventoryCode}`}>
        <span className="flex flex-row items-center">
          <span>
            <b>{item?.InventoryCode}</b> {item?.ShortDescription}
          </span>
          {isMissingInfo && <span className="ml-auto px-2.5 text-[18px] font-bold text-error">!</span>}
          {isSelected && <GreenCheckIcon className="w-min-[18px] ml-1" width="18px" height={'18px'} />}
        </span>
      </Typography>
    );
  };

  const handleOnClose = (actionType: string) => {
    if (actionType === 'Save') {
      const selectedSuggestion = {
        suggestion: showAddMissingProductInfo.inventory,
        suggestionValue: `${showAddMissingProductInfo.inventory.InventoryCode} ${showAddMissingProductInfo.inventory.ShortDescription}`,
        suggestionIndex: props.index,
        sectionIndex: 0,
      };
      saveLineInventory(showAddMissingProductInfo.inventory, selectedSuggestion);
    }
    setShowAddMissingProductInfo({show: false, inventory: {} as Inventory});
  };

  return (
    <>
      <QuoteSmartSearch
        key={props.by + props.index}
        results={results}
        placeholder={props.by === 'product' ? 'Search by name or code' : 'Search by code'}
        value={props.by === 'product' ? values?.lines[props.index]?.shortDescription : values?.lines[props.index]?.code}
        renderItem={resultShow}
        byCode={props.by === 'code'}
        onChange={handleChange}
        isLoading={isLoading}
        onSelect={handleSuggestionSelected}
        getInventoryList={getInventoryList}
        index={props.index}
        disabled={props.disabled}
      />
      {showAddMissingProductInfo.show && (
        <AddMissingProductInfo inventory={showAddMissingProductInfo.inventory} onClose={handleOnClose} />
      )}
    </>
  );
};
export default QuoteInventoryAutocomplete;
