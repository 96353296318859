import React, {useState, useCallback, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import clsx from 'clsx';
import Carousel from 'react-elastic-carousel';
import {debounce, pick} from 'lodash';
import * as Yup from 'yup';

import {AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL} from '../../routes/PurchasingRoutes';
import AppContext from '../../context/app/appContext';
import {DatTypes, PurchaseInvoicePriceEntryType} from '../../model/constants/Constants';
import {IPurchaseInvoiceDetail, IXeroPurchaseInvoice} from '../../model/purchase-invoice/purchaseInvoice';
import {ISupplier} from '../../model/supplier/Supplier';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {CurrencyTextField, STextField} from '../inputs/STextField';
import SDatePicker from '../pickers/DatePicker';
import LoadingIndicator from '../ui/LoadingIndicator';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {CreateSupplierDialog} from '../dialog/CreateSupplierDialog';
import usePurchaseInvoiceAPI from '../../services/usePurchaseInvoiceAPI';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {Toast} from '../../utils/Toast';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import {IconEdit} from '../../assets/svg/IconEdit';

interface ICreatePurchaseInvoice {
  open: boolean;
  onClose: (refetchList?: boolean) => void;
  purchaseInvoiceId?: string;
  isSupplierSelect?: boolean;
}

const useCreatePurchaseInvoiceDialog = makeStyles(() => ({
  dialogPaper: {
    minWidth: '771px',
    minHeight: '513px',
    fontFamily: 'Poppins, sans-serif',
  },
  dialogTitle: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontSize: '22px',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
    },
  },
  dialogContent: {},
  dialogActions: {
    margin: '8px',
    borderRadius: '8px',
    backgroundColor: '#ADADAD20',
    padding: '8px 12px',
    justifyContent: 'space-between',
  },
  textfield: {
    width: '354px !important',
    marginTop: '24px',
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      paddingRight: '0px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #1c78ad !important',
      },
      '& .MuiInputAdornment-root': {
        marginRight: '0px !important',
      },
    },
  },
  iconButtonRoot: {
    padding: '0px 6px',
    height: '45px',
    backgroundColor: '#1C78AD',
    borderRadius: '0 6px 6px 0',
    '& svg': {
      color: '#FFFFFF !important',
    },
    '&:hover': {
      padding: '0px 6px',
      height: '45px',
      backgroundColor: '#1C78AD',
      borderRadius: '0 6px 6px 0',
      '& svg': {
        color: '#FFFFFF !important',
      },
    },
  },
  carousel: {
    margin: '10px 0',
    minHeight: '50px',
    '&.rec .rec-slider-container': {
      margin: 0,
      '&.rec .rec-item-wrapper': {
        padding: '4px !important',
      },
    },
  },
  detailContainer: {
    paddingLeft: '132px',
    paddingRight: '132px',
  },
  taxTypeWrapper: {
    borderRadius: '6px',
    backgroundColor: '#EAEAEA',
    fontSize: '10px',
    color: '#444343',
    fontWeight: 500,
    padding: '6px 0 6px 0',
    '& button': {
      padding: '6px',
    },
  },
  taxTypeSelected: {
    borderRadius: '6px',
    backgroundColor: '#C68A19',
    color: '#FFFFFF',
  },
}));

const validationSchema = Yup.object({
  RefNumber: Yup.string().required('Invoice ref is required').max(50, 'Invoice ref max 50 char'),
  TransDate: Yup.date().required('Invoice date is required'),
  DueDate: Yup.date().required('Due date is required'),
  SupplierID: Yup.string().required('Select a supplier'),
  ExpectedTotalInc: Yup.number().when('taxType', (taxType: 'ex' | 'inc', schema: any) => {
    return taxType === 'inc'
      ? schema.required('Expected total inc is required').positive('Must be more than 0')
      : schema.nullable();
  }),
  ExpectedTotalEx: Yup.number().when('taxType', (taxType: 'ex' | 'inc', schema: any) => {
    return taxType === 'ex'
      ? schema.required('Expected total ex is required').positive('Must be more than 0')
      : schema.nullable();
  }),
  ExpectedTotalTax: Yup.number().required('Expected tax total is required').positive('Must be more than 0'),
});

const myArrow = ({type, onClick, isEdge}: {type: string; onClick: () => void; isEdge: boolean}) => {
  const pointer =
    type === 'PREV' ? (
      <ArrowLeftRoundedIcon style={{color: 'inherit', fontSize: '48px'}} />
    ) : (
      <ArrowRightRoundedIcon style={{color: 'inherit', fontSize: '48px'}} />
    );
  return (
    <IconButton
      data-autoid="btnClearEmail"
      onClick={onClick}
      disabled={isEdge}
      style={{padding: 0, color: isEdge ? '#1C78AD30' : '#1C78AD'}}
    >
      {pointer}
    </IconButton>
  );
};

const initialFilter = {
  IsShowActiveOnly: true,
  DatTypeID: DatTypes.Suppliers,
  IsApprovedForWeb: true,
  IsShowAccountCustomersOnly: false,
  MaxResults: 40,
  IsExactMatch: false,
  SortAsc: false,
  SortField: 'Name',
};

export interface ICreatePurchaseInvoiceValues extends Partial<IPurchaseInvoiceDetail> {}

const newPurchaseInvoice: ICreatePurchaseInvoiceValues = {
  RefNumber: '',
  TransDate: '',
  LineIDs: [],
  DueDate: '',
  Lines: [],
  WarehouseID: null,
  ExpectedTotalInc: '',
  SupplierID: undefined,
  ExpectedTotalTax: '',
  PriceEntryMode: PurchaseInvoicePriceEntryType.TaxInclusive,
};

export const CreatePurchaseInvoiceDialog = (props: ICreatePurchaseInvoice) => {
  const history = useHistory();
  const {tenantInfo} = useContext(AppContext);
  const classes = useCreatePurchaseInvoiceDialog();
  const {open, onClose, purchaseInvoiceId, isSupplierSelect} = props;

  const defaultWarehouseID = tenantInfo?.TenantUserDetails?.DefaultWarehouseID;

  const [purchaseInvoiceToEdit, setPurchaseInvoiceToEdit] =
    React.useState<ICreatePurchaseInvoiceValues>(newPurchaseInvoice);
  const [supplierId, setSupplierId] = useState<any>(undefined);
  const [createSupplierDialog, setCreateSupplierDialog] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier>();

  const {generateDeliveryReceipt, getPurchaseInvoiceById, updatePurchaseInvoiceById, isLoading} =
    usePurchaseInvoiceAPI();

  useEffect(() => {
    if (open && purchaseInvoiceId) {
      getPurchaseInvoiceById(Number(purchaseInvoiceId)).then(res => {
        setPurchaseInvoiceToEdit(res);
      });
    }
  }, [purchaseInvoiceId, open]);

  const handleCreateSupplier = () => {
    setCreateSupplierDialog(true);
  };

  const handleCreateSupplierClose = (supplier?: ISupplier) => {
    setCreateSupplierDialog(false);
    if (supplier) {
      setSelectedSupplier(supplier);
    }
    setSupplierId(null);
  };

  const handleCancelClick = (refetch: boolean = false) => {
    setSelectedSupplier(undefined);
    setSupplierId(undefined);
    onClose(refetch);
  };

  const handleEditSupplierClick = (supplier: ISupplier) => {
    setCreateSupplierDialog(true);
    setSupplierId(supplier.ID);
  };

  const onSubmit = async (
    values: ICreatePurchaseInvoiceValues,
    helpers: FormikHelpers<ICreatePurchaseInvoiceValues>,
  ): Promise<void> => {
    let payload: ICreatePurchaseInvoiceValues = pick(values, [
      'RefNumber',
      'TransDate',
      'PriceEntryMode',
      'LineIDs',
      'DueDate',
      'Lines',
      'ExpectedTotalTax',
      'ExpectedTotalInc',
    ]);
    payload.WarehouseID = String(defaultWarehouseID);
    payload.SupplierID = selectedSupplier?.ID;
    if (purchaseInvoiceId) {
      return updatePurchaseInvoiceById(purchaseInvoiceId, payload).then(res => {
        if (res && res.ID) {
          helpers.resetForm();
          handleCancelClick(true);
        }
      });
    } else {
      return generateDeliveryReceipt(payload).then(res => {
        if (res && res.ID) {
          helpers.resetForm();
          Toast.info('Purchase Invoice created');
          history.push(AUTH_PURCHASING_PURCHASE_INVOICE_DETAIL.replace(/:purchaseInvoiceID/g, String(res.ID)));
        }
      });
    }
  };

  return (
    <>
      <CreateSupplierDialog open={createSupplierDialog} onClose={handleCreateSupplierClose} supplierId={supplierId} />
      <SDialog classes={{paper: classes.dialogPaper}} fullWidth maxWidth="sm" open={open && !createSupplierDialog}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={purchaseInvoiceToEdit}
          onSubmit={onSubmit}
        >
          {props => (
            <>
              <DialogTitle className={clsx(classes.dialogTitle, 'text-center font-light text-spenda-primarytext')}>
                {!props.values.SupplierID ? 'Select Supplier for purchase invoice' : 'Purchase invoice details '}
              </DialogTitle>
              <DialogContent className="flex flex-col items-center justify-center">
                <LoadingIndicator
                  isLoading={isLoading}
                  position={{
                    height: '100% !important',
                    display: 'flex',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  size="md"
                  color="#1C78AD"
                />
                {!props.values.SupplierID || isSupplierSelect ? (
                  <SelectSupplier
                    {...props}
                    selectedSupplier={selectedSupplier}
                    handleEditSupplierClick={handleEditSupplierClick}
                    setSelectedSupplier={setSelectedSupplier}
                  />
                ) : null}
                {props.values.SupplierID && !isSupplierSelect ? <PurchaseInvoiceDetail {...props} /> : null}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <SecondaryButton label="Cancel" onClick={handleCancelClick} />
                <div>
                  {!props.values.SupplierID ? (
                    <SecondaryButton label="Create New" onClick={handleCreateSupplier} />
                  ) : null}
                  <PrimaryButton
                    margin="0 0 0 8px"
                    label="Save & Close"
                    disabled={
                      !props.isValid || props.isSubmitting || Boolean(props.status) || !props.touched?.RefNumber
                    }
                    onClick={props.handleSubmit}
                  />
                </div>
              </DialogActions>
            </>
          )}
        </Formik>
      </SDialog>
    </>
  );
};

const SelectSupplier = (
  props: FormikProps<ICreatePurchaseInvoiceValues> & {
    selectedSupplier?: ISupplier;
    handleEditSupplierClick: (supplier: ISupplier) => void;
    setSelectedSupplier: (supplier: ISupplier) => void;
  },
) => {
  const classes = useCreatePurchaseInvoiceDialog();
  const {errors, touched, selectedSupplier, handleEditSupplierClick, setSelectedSupplier, setFieldValue} = props;

  const [suggestionsInput, setSuggestionsInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState<ISupplier[]>([]);

  useEffect(() => {
    getSupplierList();
  }, []);

  useEffect(() => {
    if (selectedSupplier) {
      setSuggestions([selectedSupplier]);
    }
  }, [selectedSupplier]);

  const {dashboard, isLoading} = useSupplierAPI();

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getSupplierList(newValue);
    }, 500),
    [suggestions],
  );

  const handleMousedown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleDropDownClick = () => {
    if (suggestionsInput) {
      getSupplierList(suggestionsInput);
    }
  };

  const getSupplierList = (newValue: string = '') => {
    dashboard({
      ...initialFilter,
      ...(newValue ? {SearchString: newValue} : {}),
    }).then((res: ISupplier[]) => {
      setSuggestions(res || []);
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuggestionsInput(event.target.value);
    if (event.target.value.length > 2) {
      debouncedSave(event.target.value);
    }
  };

  const handleSupplierSelect = (supplier: ISupplier) => {
    setSelectedSupplier(supplier);
    setFieldValue('SupplierID', supplier.ID);
  };

  return (
    <>
      <p className="text-base text-spenda-primarytext">Please select the Supplier you have make the purchase from.</p>
      <STextField
        v2
        variant="outlined"
        name="SearchSuppliers"
        className={classes.textfield}
        value={suggestionsInput}
        onChange={onChange}
        placeholder="Search by name, phone or address"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.iconButtonRoot}
                onClick={handleDropDownClick}
                onMouseDown={handleMousedown}
                data-autoid="btnAutocompleteEndAdornment"
              >
                <SearchRoundedIcon color="inherit" fontSize="large" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={touched?.SupplierID && errors?.SupplierID}
        error={touched?.SupplierID && Boolean(errors?.SupplierID)}
      />
      {!suggestions.length && !isLoading && suggestionsInput ? <span>No results found</span> : null}
      {suggestions.length ? (
        // @ts-ignore
        <Carousel
          itemsToShow={4}
          itemsToScroll={1}
          isRTL={false}
          className={clsx(classes.carousel)}
          renderArrow={myArrow}
        >
          {suggestions.map(suggestion => (
            <div
              key={suggestion.ID}
              className={clsx(
                `relative flex h-[100px] w-[150px] cursor-pointer items-center justify-center rounded 
                bg-white p-1 font-poppins text-xs font-semibold text-primary shadow-[0_0_6px_0_#908E8E50]`,
                {
                  'bg-[#33333320]': selectedSupplier?.ID === suggestion.ID,
                },
              )}
              onClick={() => handleSupplierSelect(suggestion)}
              data-autoid={`btnSuggestion${suggestion.ID}`}
            >
              <p className="line-clamp-4 text-center">{suggestion.Name}</p>
              <IconButton
                className={clsx('!absolute right-0 top-0 !p-[6px]')}
                onClick={e => {
                  e.stopPropagation();
                  handleEditSupplierClick(suggestion);
                }}
                onMouseDown={handleMousedown}
                data-autoid={`btnEditSupplier${suggestion.ID}`}
              >
                <IconEdit iconColor="#1C78AD" height="13px" width="13px" />
              </IconButton>
            </div>
          ))}
        </Carousel>
      ) : null}
    </>
  );
};

const piInitialFilter = {
  IsExactMatch: true,
  Status: ['Overdue', 'Unfinalised', 'Finalised', 'Paid', 'Cancelled', 'Complete'],
};

const PurchaseInvoiceDetail = (props: FormikProps<ICreatePurchaseInvoiceValues>) => {
  const classes = useCreatePurchaseInvoiceDialog();
  const {values, errors, touched, handleChange, handleBlur, setFieldValue, setSubmitting, setStatus} = props;

  const {listPurchaseInvoice} = usePurchaseInvoiceAPI();

  useEffect(() => {
    if (values.RefNumber) {
      debouncedSave(values.RefNumber || '');
    }
  }, [values.RefNumber]);

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getPurchaseInvoiceList(newValue);
    }, 500),
    [],
  );

  const getPurchaseInvoiceList = (newValue: string = '') => {
    setSubmitting(true);
    listPurchaseInvoice({
      ...piInitialFilter,
      ...(newValue ? {SearchString: newValue} : {}),
    }).then((_res: IXeroPurchaseInvoice[]) => {
      const res = (_res || []).reduce((acc, curr) => {
        if (values.ID && curr.ID === values.ID) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as IXeroPurchaseInvoice[]);

      if (!res.length) {
        setStatus('');
      } else {
        setStatus('Ref number must be unique');
      }
      setSubmitting(false);
    });
  };

  const handlePriceEntryClick = (priceEntryMode: PurchaseInvoicePriceEntryType) => {
    setFieldValue('PriceEntryMode', priceEntryMode);
  };

  return (
    <Grid container spacing={2} className={clsx(classes.detailContainer)}>
      <Grid item sm={12}>
        <p className="text-center text-xs text-spenda-primarytext	">Please enter your Supplier’s invoice details.</p>
      </Grid>
      <Grid item sm={12}>
        <p className="mb-8 text-center text-xs	text-spenda-primarytext">
          I want to enter my invoice line totals{' '}
          <span className={classes.taxTypeWrapper}>
            <button
              className={clsx({
                [classes.taxTypeSelected]: values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxInclusive,
              })}
              onClick={() => handlePriceEntryClick(PurchaseInvoicePriceEntryType.TaxInclusive)}
              data-autoid="btnIncludingTax"
            >
              Including
            </button>
            <button
              className={clsx({
                [classes.taxTypeSelected]: values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxExclusive,
              })}
              onClick={() => handlePriceEntryClick(PurchaseInvoicePriceEntryType.TaxExclusive)}
              data-autoid="btnExcludingTax"
            >
              Excluding
            </button>
          </span>{' '}
          tax.
        </p>
      </Grid>
      <Grid item sm={6}>
        <STextField
          v2
          fullWidth
          autoFocus
          id="RefNumber"
          name="RefNumber"
          variant="outlined"
          label="Invoice Ref"
          placeholder="Invoice Ref"
          value={values.RefNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={(touched?.RefNumber && errors?.RefNumber) || props.status}
          error={touched?.RefNumber && Boolean(errors?.RefNumber)}
        />
      </Grid>
      <Grid item sm={6}>
        <SDatePicker
          v2
          autoOk
          variant="inline"
          id="TransDate"
          name="TransDate"
          inputVariant="outlined"
          label="Invoice Date"
          placeholder="dd MMM, yyyy"
          format="dd MMM, yyyy"
          value={values.TransDate}
          onChange={date => setFieldValue('TransDate', date)}
          helperText={touched?.TransDate && errors?.TransDate}
          error={touched?.TransDate && Boolean(errors?.TransDate)}
        />
      </Grid>
      <Grid item sm={6}>
        <CurrencyTextField
          fullWidth
          id="ExpectedTotalInc"
          name="ExpectedTotalInc"
          variant="outlined"
          label="Invoice total (inc)"
          placeholder="$0.00"
          value={values.ExpectedTotalInc}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={touched?.ExpectedTotalInc && errors?.ExpectedTotalInc}
          error={touched?.ExpectedTotalInc && Boolean(errors?.ExpectedTotalInc)}
        />
      </Grid>
      <Grid item sm={6}>
        <SDatePicker
          v2
          autoOk
          variant="inline"
          id="DueDate"
          name="DueDate"
          inputVariant="outlined"
          label="Due Date"
          placeholder="dd MMM, yyyy"
          format="dd MMM, yyyy"
          value={values.DueDate}
          onChange={date => setFieldValue('DueDate', date)}
          helperText={touched?.DueDate && errors?.DueDate}
          error={touched?.DueDate && Boolean(errors?.DueDate)}
        />
      </Grid>
      <Grid item sm={6}>
        <CurrencyTextField
          fullWidth
          id="ExpectedTotalTax"
          name="ExpectedTotalTax"
          variant="outlined"
          label="Invoice total tax"
          placeholder="$0.00"
          value={values.ExpectedTotalTax}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={touched?.ExpectedTotalTax && errors?.ExpectedTotalTax}
          error={touched?.ExpectedTotalTax && Boolean(errors?.ExpectedTotalTax)}
        />
      </Grid>
    </Grid>
  );
};
