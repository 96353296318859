import { ITenantSupplierPaymentMethod, IPaymentMethodTypes } from './../../model/payment/PaymentMethod';
import { ITenantModuleDetails, ParamentersToSelectMenu } from './../../model/tenant/TenantModuleDetails';
import { createContext, useContext } from 'react';
import { IUser, IUserTenant } from '../../model/user/User';
import { ISupplierMarket } from '../../model/SupplierMarket';
import { IActionRes } from '../../model/ActionResults';
import { IUserMenu } from '../../services/MenuService';
import { IConnectedSupplier } from '../../model/supplier/ConnectedSupplier';
import { ITenant, ITenantInfo } from '../../model/Tenant';
import { IMasterAdaptor } from '../../model/MasterAdaptor';
import { IMenuModule } from '../../model/menu/Menu';
import { IFeatureFlagsState } from '../../model/feature-flags/FeatureFlags';

export const createInitialAppContextType = () => {
  return {
    isShow2SASettings: false,
    loading: false,
    marketplaceSupplier: undefined as ISupplierMarket | undefined,
    menu: undefined as IUserMenu | undefined,
    paymentMethods: [] as ITenantSupplierPaymentMethod[],
    selectedAdaptor: undefined as IMasterAdaptor | undefined,
    selectedModule: undefined as ITenantModuleDetails | undefined,
    statementInvitationId: undefined as string | undefined,
    invoiceInvitationId: undefined as string | undefined,
    suppliers: [] as IConnectedSupplier[],
    supplierToLink: undefined as ISupplierMarket | undefined,
    tenant: undefined as ITenant | undefined,
    tenantInfo: undefined as ITenantInfo | undefined,
    user: undefined as IUser | undefined,
    workFlowId: undefined as number | undefined,
    featureFlags: [] as Partial<IFeatureFlagsState>,
    modules: [] as IMenuModule[],
    isPSBLPIBLEnable: false,
    isShowConfigureSettingsDialog: false,
    availablePaymentMethodTypes: [] as IPaymentMethodTypes[],
    userTenants: [] as IUserTenant[],
    isMobileDisclaimerShown: false,
    userRole: undefined as undefined | string,
    changePassword: (_newPassword: string, _websiteId: number, _token: string) =>
      Promise.resolve({ ErrorCode: -1, IsSuccess: true, Messages: [] } as IActionRes),
    loadMarketplace: (_marketplacePath?: string): Promise<ISupplierMarket | undefined> =>
      Promise.resolve(undefined as ISupplierMarket | undefined),
    loadUserSession: (_user: IUser) => Promise.resolve(true),
    logout: (): void => undefined,
    setConnectedSuppliers: (_supliers: IConnectedSupplier[]): void => undefined,
    setFeatureFlags: (_flags: Partial<IFeatureFlagsState>): void => { },
    setMarketplaceSupplier: (_supplier?: ISupplierMarket): void => undefined,
    setMenu: (_filters: ParamentersToSelectMenu, _prepayments72511?: boolean): void => undefined,
    setPaymentMethods: (_pm: ITenantSupplierPaymentMethod[]): void => undefined,
    setSelectedAdaptor: (_adaptor: IMasterAdaptor): void => undefined,
    setSelectedModule: (_m: Partial<ITenantModuleDetails>): void => undefined,
    setStatementInvitation: (_invitationId?: string): void => undefined,
    setInvoiceInvitation: (_invoiceInvitationId?: string): void => undefined,
    setSupplierToLink: (_marketplacePath?: string): void => undefined,
    setTenant: (_tenant: ITenant): void => { },
    setTenantInfo: (_tenant: ITenantInfo): void => { },
    setUser: (_user: IUser): void => { },
    setUserTenant: (_payload: { tenant: ITenant; user: IUser }): void => { },
    setUserTenantList: (_userTenants: IUserTenant[]): void => { },
    setWorkFlowId: (_workFlowId?: number): void => undefined,
    switchSupplier: (_modules: number[], _path: string): void => undefined,
    setModules: (_modules: IMenuModule[]): void => undefined,
    setIsPSBLPIBLEnable: (_condition: boolean): void => undefined,
    setIsShowConfigureSettingsDialog: (_condition: boolean): void => undefined,
    setAvailablePaymentMethodTypes: (_availablePaymentMethodTypes: IPaymentMethodTypes[]): void => undefined,
    setIsMobileDisclaimerShown: (_condition: boolean): void => undefined,
  };
};

export type AppContextType = ReturnType<typeof createInitialAppContextType>;

const AppContext = createContext<AppContextType>(createInitialAppContextType());

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
}

export default AppContext;
