import React, {useContext, useEffect, useState} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {IUserTenant} from '../../model/user/User';
import {AUTH_SUPPLIER_ROUTE} from '../../routes/AuthenticatedRoutes';
import useAccountAPI from '../../services/useAccountAPI';
import AppContext from '../../context/app/appContext';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {isWebsiteId17} from '../../config';
import {VisibleContent} from '../ui/VisibleContent';

interface IUserTenantDropDown {
  userName: string;
  tenantName: string;
}

export const UserTenantDropDown = (props: IUserTenantDropDown) => {
  const {userName, tenantName} = props;

  const {userTenants, setUserTenantList} = useContext(AppContext);

  const history = useHistory();
  const location = useLocation();
  const {switchUserTenant, listUserTenants} = useAccountAPI();
  const {isInSpendaFinanceContext} = useTenantInfo();

  const [isShowUserTenantList, setIsShowUserTenantList] = useState<boolean>(false);

  const isPSBL = location.pathname.includes(AUTH_SUPPLIER_ROUTE) || isInSpendaFinanceContext;
  const userHasMultipleTenants = (userTenants || []).filter(t => !t.IsSelected).length >= 1;

  const onToggleUserTenantList = () => {
    if (isWebsiteId17) return;
    setIsShowUserTenantList(!isShowUserTenantList);
  };

  const onChangeUserTenant = async (userTenant: IUserTenant) => {
    if (!userHasMultipleTenants) return;

    await switchUserTenant(userTenant.UserGlobalID);
    sessionStorage.setItem('isSwitchTenant', 'true');

    return history.go(0);
  };

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      if (userTenants && userTenants.length > 0) return;

      const res = await listUserTenants();

      if (mounted) {
        setUserTenantList(res?.Value);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="relative mr-4 flex flex-col leading-none text-white" onClick={onToggleUserTenantList}>
      <div className="my-1 text-right font-poppins text-base font-semibold text-black-800">{userName}</div>

      <VisibleContent keyPath="header.businessName">
        <>
          <div
            data-autoid={`btnBusinessName-${userName}`}
            className={`font-poppins text-base font-semibold text-primary ${
              userHasMultipleTenants ? 'cursor-pointer' : 'cursor-default'
            }`}
          >
            {tenantName}
            {(!userHasMultipleTenants && !isPSBL) || isWebsiteId17 ? (
              <></>
            ) : isShowUserTenantList ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </div>
          {isShowUserTenantList && (
            <>
              <div
                className="rounded-default absolute right-0 z-10 bg-white text-right"
                style={{top: '3.25rem', width: '250px'}}
              >
                {(userTenants || [])
                  .filter(t => !t.IsSelected)
                  .map((userTenant, index) => (
                    <div
                      className="cursor-pointer p-2 shadow-md hover:bg-spenda-dashbg"
                      key={`user-tenant-${index}`}
                      data-autoid={`user-tenant-${userTenant?.TenantName}`}
                      onClick={() => onChangeUserTenant(userTenant)}
                    >
                      <p className={'pt-1 font-poppins text-base font-semibold text-primary'}>
                        {userTenant?.TenantName}
                      </p>
                      <p className={'pt-1 font-poppins text-xs font-semibold text-spenda-headingtext'}>
                        {userTenant?.RoleName}
                      </p>
                    </div>
                  ))}
                {isPSBL && (
                  <Link data-autoid="lnkMenu" to="/menu">
                    <div className="cursor-pointer p-2 shadow-md hover:bg-spenda-dashbg">
                      <p className={'pt-1 font-poppins text-base font-semibold text-primary'}>{`Spenda Home`}</p>
                    </div>
                  </Link>
                )}
              </div>
            </>
          )}
        </>
      </VisibleContent>
    </div>
  );
};
