import React, {memo, useEffect, useMemo, useState} from 'react';
import {Dialog, IconButton} from '@material-ui/core';
import {ArrowBack, ArrowForward, Close} from '@material-ui/icons';
import {AttachmentTypeEnum} from '../../model/constants/Constants';
import clsx from 'clsx';
import {IClaimRequestLineItemsFormValues} from '../../screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';
import {PriceFormat} from '../../utils/formatter';
import {IClaimRequestDetailsResponse} from '../../model/claims/ClaimsAndReturns';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {IUploadedAttachment} from './EditEvidenceDialog';
import {Button, Input, Typography} from 'spenda-ui-react';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';

export interface IClaimImageNotes {
  index: number;
  note: string;
}

export interface IClaimViewUpdatedImagesDialogProps {
  showModal: boolean;
  onSuccess: (claimRequestId: number) => Promise<void>;
  handleClose: () => void;
  addedFilesToUpload?: File[];
  lineItemData?: IClaimRequestLineItemsFormValues;
  handleSaveForAttachmentAsDraft?: () => Promise<IClaimRequestDetailsResponse | undefined>;
  accountCustomerId?: number;
  linkedSupplierId?: number;
  uploadedAttachments: IUploadedAttachment[] | undefined;
  clickedImageIndex?: number;
}

const ClaimViewUpdatedImagesDialog = (props: IClaimViewUpdatedImagesDialogProps) => {
  // Props
  const {
    showModal,
    lineItemData,
    handleClose,
    handleSaveForAttachmentAsDraft,
    addedFilesToUpload,
    accountCustomerId,
    linkedSupplierId,
    uploadedAttachments,
    clickedImageIndex,
    onSuccess,
  } = props;

  // States
  const [viewingFileIndex, setViewingFileIndex] = useState<number>(0);
  const [notes, setNotes] = useState<string[]>([]);
  const [animateEffect, setAnimateEffect] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<string[]>();

  // APIs
  const {uploadAttachments} = useAttachmentsAPI();

  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();

  // Constants
  const isUploadingFile = addedFilesToUpload?.length;

  useEffect(() => {
    setViewingFileIndex(clickedImageIndex || 0);
  }, [clickedImageIndex]);

  useMemo(() => {
    if (!addedFilesToUpload?.length) return;
    Promise.all(
      addedFilesToUpload.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', e => {
            resolve(e?.target?.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        });
      }),
    ).then(images => {
      setUploadedImages(images as string[]);
    });
  }, [addedFilesToUpload]);

  const onClickTransactionArrow = (action: 'NEXT' | 'PREVIOUS') => {
    try {
      const imagesLength = isUploadingFile ? addedFilesToUpload?.length : uploadedAttachments?.length;

      if (
        !imagesLength ||
        viewingFileIndex === -1 ||
        (action === 'NEXT' && viewingFileIndex === imagesLength - 1) ||
        (action === 'PREVIOUS' && viewingFileIndex === 0)
      ) {
        return;
      }

      const nextOrPreviousImageIndex = viewingFileIndex + (action === 'NEXT' ? 1 : -1);
      setViewingFileIndex(nextOrPreviousImageIndex);
    } catch {}
  };

  const isArrowsAreDisabled = (action: 'NEXT' | 'PREVIOUS') => {
    try {
      const imagesLength = isUploadingFile ? addedFilesToUpload?.length : uploadedAttachments?.length;

      if (!imagesLength) return true;

      return action === 'NEXT' ? viewingFileIndex < 0 || viewingFileIndex === imagesLength - 1 : viewingFileIndex <= 0;
    } catch {}
  };

  const onSubmit = async () => {
    try {
      setIsUploading(true);

      let claimRequestId: undefined | number = lineItemData?.claimRequestID;
      let claimRequestLineId: undefined | number = lineItemData?.claimRequestLineID;
      let _accoundCustomerId: undefined | number = accountCustomerId;
      let claimRequestResponse: undefined | IClaimRequestDetailsResponse = undefined;

      if (!claimRequestId || !claimRequestLineId) {
        try {
          claimRequestResponse = await handleSaveForAttachmentAsDraft?.();
          const claimLineItem = claimRequestResponse?.lines?.find(
            l => l?.invoiceLineID === lineItemData?.invoiceLineID,
          );

          claimRequestId = claimRequestResponse?.claimRequestID;
          claimRequestLineId = claimLineItem?.claimRequestLineID;
          _accoundCustomerId = claimRequestResponse?.accountCustomerID || undefined;
        } catch {
          setIsUploading(false);
          return;
        }
      }

      if (!claimRequestId || !claimRequestLineId) return;

      if (addedFilesToUpload?.length) {
        const formData = new FormData();

        addedFilesToUpload?.forEach((attachment: File) => {
          formData.append('Files', attachment, attachment.name);
        });

        const attachments = addedFilesToUpload?.map((n, i) => {
          return {
            Caption: addedFilesToUpload?.[i]?.name,
            Note: notes?.[i] || '',
            Action: 0,
          };
        });

        const jsonRequest = {
          ClaimRequestID: claimRequestId,
          ClaimRequestLineID: claimRequestLineId,
          accountCustomerId: isInSupplierContext ? _accoundCustomerId : undefined,
          linkedSupplierId: isInBuyerContext ? linkedSupplierId : undefined,
          Attachments: attachments || [],
        };

        formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.ClaimsAndReturnsClaimRequest));
        formData.append('JsonRequest', JSON.stringify(jsonRequest));

        await uploadAttachments(formData, {'content-type': 'multipart/form-data'});
        await onSuccess(claimRequestId);
        setIsUploading(false);
      }
    } catch {
      setIsUploading(false);
    }
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {value} = e?.target;

    setNotes(prev => {
      prev[viewingFileIndex] = value;
      return prev;
    });
  };

  const nextButton = (
    <IconButton
      onClick={() => {
        onClickTransactionArrow('NEXT');
        setAnimateEffect(true);
      }}
      onAnimationEnd={() => setAnimateEffect(false)}
      disabled={isArrowsAreDisabled('NEXT')}
      data-autoid="btnClearEmail"
      className="!absolute !right-[30px] !top-[40%] !z-10 flex flex-row items-center justify-center p-0 text-white"
    >
      <div className="flex h-[48px] w-[48px] flex-col items-center justify-center rounded-full bg-[#E2E2E2] hover:bg-[#D3E5EF80]">
        <ArrowForward className="z-10 text-[40px] text-[#fff] hover:!text-[#1c78ad]" />
      </div>
    </IconButton>
  );

  const previousButton = (
    <IconButton
      onClick={() => {
        onClickTransactionArrow('PREVIOUS');
        setAnimateEffect(true);
      }}
      onAnimationEnd={() => setAnimateEffect(false)}
      disabled={isArrowsAreDisabled('PREVIOUS')}
      data-autoid="btnClearEmail"
      className="!absolute !left-[25px] !top-[40%] z-10 flex flex-row items-center justify-center p-0 text-white"
    >
      <div className="flex h-[48px] w-[48px] flex-col items-center justify-center rounded-full bg-[#E2E2E2] hover:bg-[#D3E5EF80]">
        <ArrowBack className="z-10 text-[40px] text-[#fff] hover:!text-[#1c78ad]" />
      </div>
    </IconButton>
  );

  const allImages = useMemo(
    () => (isUploadingFile ? uploadedImages : uploadedAttachments?.map(ua => ua?.uri)),
    [isUploadingFile, uploadedAttachments, uploadedImages],
  );

  return (
    <>
      <Dialog
        open={showModal}
        maxWidth="xl"
        className={`!z-[9999] rounded-[10px] [&>*:first-child]:bg-[#adadad20] [&>*:first-child]:backdrop-blur-[13.5914px]`}
      >
        <div className="flex h-[inherit] w-full flex-col items-start">
          <div className="flex h-[600px] w-full flex-row">
            <div className="relative flex flex-col items-center justify-start bg-[#000]">
              <div className="flex h-[450px] w-[512px] flex-row items-center justify-center overflow-auto overflow-x-hidden !bg-cover !bg-center !bg-no-repeat">
                <div className="flex">{previousButton}</div>
                <div
                  onAnimationEnd={() => setAnimateEffect(false)}
                  className={`${animateEffect && 'animateARImageSlider'}`}
                >
                  <img
                    src={
                      isUploadingFile
                        ? uploadedImages?.[viewingFileIndex]
                        : uploadedAttachments?.[viewingFileIndex]?.uri
                    }
                    className="h-auto w-full"
                    loading="lazy"
                    alt="sample"
                  />
                </div>
                <div className="flex">{nextButton}</div>
              </div>
              <div className="flex h-[120px] w-full flex-row items-center justify-center bg-[#000]">
                <div className="relative flex cursor-pointer items-center gap-2 rounded-[4px]">
                  {allImages?.map((u, i) => {
                    return (
                      <img
                        key={u}
                        onClick={() => setViewingFileIndex(i)}
                        src={u}
                        alt="sample"
                        loading="lazy"
                        className={clsx(
                          `cursor-pointer rounded-[4px] bg-cover bg-center bg-no-repeat`,
                          {
                            'h-[80px] w-[80px] border-4 border-[#fff]': i === viewingFileIndex,
                          },
                          {
                            'h-[70px] w-[70px] border-2 border-[#333333]': i > viewingFileIndex || i < viewingFileIndex,
                          },
                          {
                            'hidden h-[70px] w-[70px]':
                              viewingFileIndex > 0 &&
                              viewingFileIndex < allImages?.length - 1 &&
                              i !== viewingFileIndex &&
                              i !== viewingFileIndex - 1 &&
                              i !== viewingFileIndex + 1,
                          },
                          {
                            'hidden h-[70px] w-[70px]':
                              viewingFileIndex === 0 &&
                              i !== viewingFileIndex &&
                              i !== viewingFileIndex + 1 &&
                              i !== viewingFileIndex + 2,
                          },
                          {
                            'hidden h-[70px] w-[70px]':
                              viewingFileIndex === allImages?.length - 1 &&
                              i !== viewingFileIndex &&
                              i !== viewingFileIndex - 1 &&
                              i !== viewingFileIndex - 2,
                          },
                        )}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between">
              <div className={`'w-full' ml-2.5 h-auto rounded-[10px] bg-white py-2.5 pr-2.5`}>
                <div className="relative flex h-[50px] flex-row items-center justify-center border-[#F7F7F7] border-b-default p-[0px]">
                  <Typography className="font-light text-[#333333]" variant="h2">
                    Product details
                  </Typography>
                  {handleClose && (
                    <div
                      data-autoid="btnCloseAdvanceFilter"
                      onClick={() => (isUploading ? undefined : handleClose())}
                      className="absolute right-[0px] top-[0px] z-10 flex h-[40px] w-[40px] cursor-pointer flex-row items-center justify-center rounded-[6px] border-[1px] border-[#1c78ad]"
                    >
                      <Close className="!h-[22px] !w-[22px] text-primary" />
                    </div>
                  )}
                </div>
                <div className=" mr-2 py-2.5">
                  <div className="flex min-h-[450px] w-full !max-w-[394px] flex-col items-start p-2.5">
                    <div className="flex h-full w-[394px] flex-col rounded-[6px] bg-[#D3E5EF80] p-2.5 pb-10">
                      <Typography className="mb-3 font-medium text-black-800" variant="xsmall">
                        Name:{' '}
                        <Typography className="inline font-normal">
                          {isUploadingFile
                            ? lineItemData?.description
                            : uploadedAttachments?.[viewingFileIndex]?.description}
                        </Typography>
                      </Typography>
                      <Typography className="mb-3 font-medium text-black-800" variant="xsmall">
                        Code:{' '}
                        <Typography className="inline font-normal">
                          {isUploadingFile ? lineItemData?.code : uploadedAttachments?.[viewingFileIndex]?.code}
                        </Typography>
                      </Typography>
                      <Typography className="mb-3 font-medium text-black-800" variant="xsmall">
                        Qty:{' '}
                        <Typography className="inline font-normal">
                          {isUploadingFile ? lineItemData?.quantity : uploadedAttachments?.[viewingFileIndex]?.quantity}
                        </Typography>
                      </Typography>
                      <Typography className="mb-3  font-medium text-black-800" variant="xsmall">
                        Amount:{' '}
                        <Typography className="inline font-normal">
                          {PriceFormat(
                            isUploadingFile ? lineItemData?.price : uploadedAttachments?.[viewingFileIndex]?.price,
                          )}
                        </Typography>
                      </Typography>
                      <Typography className="mb-3 font-medium text-black-800" variant="xsmall">
                        Reason:{' '}
                        <Typography className="inline font-normal">
                          {isUploadingFile ? lineItemData?.reason : uploadedAttachments?.[viewingFileIndex]?.reason}
                        </Typography>
                      </Typography>
                      <Typography className="mb-3  font-medium text-black-800" variant="xsmall">
                        Action:{' '}
                        <Typography className="inline font-normal">
                          {isUploadingFile ? lineItemData?.action : uploadedAttachments?.[viewingFileIndex]?.action}
                        </Typography>
                      </Typography>
                      <Typography className="mb-3 font-medium text-black-800" variant="xsmall">
                        Image note: <Typography className="inline font-normal" children={undefined}></Typography>
                      </Typography>
                      <div className="h-16 w-full" data-autoid="txtClaimViewUpdatedImagesNotes">
                        <div className="relative w-full min-w-[200px] font-poppins text-sm font-normal">
                          <Input
                            size="lg"
                            disabled={!isUploadingFile}
                            key={viewingFileIndex}
                            onChange={handleNoteChange}
                            value={
                              isUploadingFile
                                ? notes?.[viewingFileIndex]
                                : uploadedAttachments?.[viewingFileIndex]?.note
                            }
                            displayLength
                            height="300px"
                            label="Add note"
                            id="addNote"
                            name="addNote"
                            type="text"
                            maxLength={40}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex h-[52px] items-center justify-center rounded-[6px] bg-[#EFEFEF] px-2.5`}>
                  <div
                    className={clsx(
                      'flex h-[60px] !w-full flex-row items-center',
                      {'justify-between': isUploadingFile},
                      {'justify-end': !isUploadingFile},
                    )}
                  >
                    <Button
                      disabled={isUploading}
                      data-autoid="btnClose"
                      variant="outlined"
                      color="primary"
                      className="bg-white"
                      onClick={() => handleClose()}
                    >
                      Close
                    </Button>
                    {isUploadingFile && (
                      <Button
                        disabled={isUploading}
                        variant="filled"
                        color="primary"
                        data-autoid="btnDone"
                        onClick={() => onSubmit()}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default memo(ClaimViewUpdatedImagesDialog);
