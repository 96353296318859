import React from 'react';
import {Switch} from 'react-router-dom';

import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {ProductsList} from '../screens/product-mangement/ProductsList';
import {BrandsList} from '../screens/product-mangement/BrandsList';
import {CategoriesList} from '../screens/product-mangement/CategoriesList';
import {ProductClassesList} from '../screens/product-mangement/ProductClassesList';
import {AddProductClass} from '../screens/product-mangement/AddProductClass';
import {AddProductScreen} from '../screens/product-mangement/AddProductScreen';
import {CatalogueDashboard} from '../screens/product-mangement/manage-catalogue/CatalogueDashboard';
import {CreateCatalogue} from '../screens/product-mangement/manage-catalogue/create-catalogue/CreateCatalogue';
import {ManageCatalogue} from '../screens/product-mangement/manage-catalogue/manage-catalogue/ManageCatalogue';

// Product Management Routes
export const AUTH_INVENTORY_PRODUCT_MANAGEMENT = '/inventory/product';
export const AUTH_INVENTORY_PRODUCTS_LIST = '/inventory/products';
export const AUTH_INVENTORY_PRODUCT_ADD = '/inventory/products/add';
export const AUTH_INVENTORY_PRODUCT_EDIT = '/inventory/products/edit/:productID';
export const AUTH_INVENTORY_CATEGORY_MANAGEMENT = '/inventory/products/categories';
export const AUTH_INVENTORY_BRAND_MANAGEMENT = '/inventory/products/brands';
export const AUTH_INVENTORY_PRODUCT_CLASSES_LIST = '/inventory/product/classes';
export const AUTH_INVENTORY_PRODUCT_CLASSES_ADD = '/inventory/product/classes/add';
export const AUTH_INVENTORY_PRODUCT_CLASSES_EDIT = '/inventory/product/classes/edit/:productClassID';
export const AUTH_INVENTORY_CATALOGUE_DASHBOARD = '/inventory/catalogue-dashboard';
export const AUTH_INVENTORY_MANAGE_CATALOGUE = `${AUTH_INVENTORY_CATALOGUE_DASHBOARD}/manage-catalogue`;
export const AUTH_INVENTORY_CATALOGUE_ID = `${AUTH_INVENTORY_CATALOGUE_DASHBOARD}/catalogue/id`;

export const InventoryRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_INVENTORY_PRODUCTS_LIST}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={ProductsList}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_PRODUCT_ADD}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={AddProductScreen}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_PRODUCT_EDIT}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={AddProductScreen}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_BRAND_MANAGEMENT}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={BrandsList}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_CATEGORY_MANAGEMENT}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={CategoriesList}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_PRODUCT_CLASSES_LIST}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={ProductClassesList}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_PRODUCT_CLASSES_ADD}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={AddProductClass}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_PRODUCT_CLASSES_EDIT}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={AddProductClass}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_CATALOGUE_DASHBOARD}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={CatalogueDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_MANAGE_CATALOGUE}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={ManageCatalogue}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
      <PrivateRoute
        path={AUTH_INVENTORY_CATALOGUE_ID}
        exact
        moduleIds={[ModuleTypes.ProductManagement]}
        Component={CreateCatalogue}
        MenuFilters={{
          MenuType: MenuTypes.Inventory,
          ModuleID: ModuleTypes.Inventory,
          ModuleDependency: [ModuleTypes.ProductManagement],
        }}
      />
    </Switch>
  );
};
