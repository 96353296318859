
const ImagePreview = (imageUrl: string) => {
  if (imageUrl) {
    const htmlContent = `
                   <!DOCTYPE html>
                     <html>
                     <head>
                         <title>Image Preview</title>
                         <style>
                             body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
                             img { max-width: 100%; max-height: 100%; }
                         </style>
                     </head>
                     <body>
                         <img src="${imageUrl}" alt="Image Preview" />
                     </body>
                     </html>
                 `;

    //   Create a Blob with the HTML content
    const blob = new Blob([htmlContent], {type: 'text/html'});
    const url = URL.createObjectURL(blob);

    //   Open the Blob URL in a new tab
    window.open(url, '_blank');

    //   Cleanup should happen after ensuring the new tab has loaded, so let's comment it out for now
    setTimeout(() => URL.revokeObjectURL(url), 1000); // Optional delayed cleanup
  } else {
    alert('No image URL provided');
  }
};

export default ImagePreview;
