import React from 'react';
import {useFormikContext} from 'formik';
import {Textarea} from 'spenda-ui-react';

import {IServiceJob, ServiceJobStatus} from '../../../model/service-management/serviceJob';

export default function ServiceNotes() {
  const {values, setFieldValue} = useFormikContext<IServiceJob>();
  return (
    <div className="mx-auto flex w-3/5 flex-col gap-y-8 lg:px-4 xl:px-12">
      <Textarea
        label="Internal notes"
        displayLength
        maxLength={200}
        className="min-h-[140px]"
        value={values?.internalNote}
        onChange={e => setFieldValue('internalNote', e.target.value)}
        data-autoid={'txtInternalNode'}
        disabled={values?.status === ServiceJobStatus.Completed}
      />
      <Textarea
        label="Notes for the customer"
        displayLength
        maxLength={200}
        className="min-h-[140px]"
        value={values?.externalNotes}
        onChange={e => setFieldValue('externalNotes', e.target.value)}
        data-autoid={'txtNotesForCustomer'}
        disabled={values?.status === ServiceJobStatus.Completed}
      />
    </div>
  );
}
