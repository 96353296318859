import React from 'react';
import {Typography} from 'spenda-ui-react';

import {ARTable} from '../../../../components/AccountsReceivable/ARTable';
import {WidgetDeleteLogo} from '../../../../assets/svg/WidgetDeleteLogo';

export const ManageCatalogueList = () => {
  const columns = [
    {
      title: 'Catalogue ID ',
      key: 'catalogueId ',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <Typography className="font-medium" data-autoid={`txtCode-${index}`}>
            10927521
          </Typography>
        );
      },
    },
    {
      title: 'Catalogue name',
      key: 'catalogueName',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <Typography className="font-medium" data-autoid={`txtCode-${index}`}>
            Genuine Catalogue
          </Typography>
        );
      },
    },
    {
      title: 'No. of products',
      key: 'noOfProducts',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtShortDesc-${index}`}>8</Typography>;
      },
    },
    {
      title: 'Subscribers',
      key: 'Subscribers',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtSubscribers-${index}`}>10</Typography>;
      },
    },
    {
      title: 'Created date',
      key: 'createdDate',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtCreatedDate-${index}`}>13 Aug 2024</Typography>;
      },
    },
    {
      title: 'Last edit date',
      key: 'lastEditDate',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtLastEditDate-${index}`}>23 Aug 2024</Typography>;
      },
    },
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <span className="cursor-pointer active:bg-transparent" data-autoid={`btnRemove-${index}`}>
            <WidgetDeleteLogo />
          </span>
        );
      },
    },
  ];

  return (
    <div className={`mt-5 h-[calc(100vh-268px)]`}>
      <ARTable
        columns={columns}
        rows={Array(5).fill(1)}
        isLoading={false}
        tableClass="!-mx-2.5 !w-[calc(100%+20px)] "
      />
    </div>
  );
};
