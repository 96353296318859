import React, {useMemo} from 'react';

import {useServiceContext} from '../../../context/serviceManagement/ServiceContext';
import {AttachmentDefaultView} from '../../quote-management/QuoteAttachment';
import ImagePreview from '../../quote-management/ImagePreview';

export default function ServicePhotos() {
  const {serviceJob} = useServiceContext();

  const attachments = useMemo(() => {
    return (
      serviceJob?.attachments.filter(attachment => {
        return attachment.isCreatedByServiceTechnician;
      }) || []
    );
  }, [serviceJob]);

  return (
    <div className="relative min-h-[300px] w-full overflow-y-auto bg-[#FFFFFF] lg:px-4 xl:px-12">
      <div className={`flex flex-wrap gap-x-5 gap-y-5`}>
        {attachments.map((photo, index) => (
          <AttachmentDefaultView
            key={photo.attachmentGuid}
            caption={photo.note || `${photo.type}_${index + 1}`}
            name={photo.note || `${photo.type}_${index + 1}`}
            uri={photo.uri}
            containerClassName="w-[15%] flex-wrap justify-start"
            isEditAble={false}
            handleVisibility={() => ImagePreview(photo.uri)}
          />
        ))}
      </div>
    </div>
  );
}
