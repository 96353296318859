import clsx from 'clsx';
import moment from 'moment';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {color} from 'spenda-ui-react/types/components/chip';
import {Button, Chip, Tab, Tabs, TabsBody, TabsHeader, Typography, useTabs} from 'spenda-ui-react';

import {ARInfoYellowIcon} from '../../../assets/svg/ARInfoYellowIcon';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import StartConvo from '../../../components/conversationChatBox/StartConvo';
import ARTooltip from '../../../components/data-display/ARTootip';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {usePSBLHook} from '../../../hooks/useARHook';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {IClaimRequestList, IClaimRequestListResponse} from '../../../model/claims/ClaimsAndReturns';
import {ClaimsAndReturnsStatus, ConversationType, StartConvoType} from '../../../model/constants/Constants';
import useClaimsAndReturnsAPI from '../../../services/useClaimsAndReturnsAPI';
import {PriceFormat} from '../../../utils/formatter';
import {ClaimsHighLevelToolbar} from './ClaimsHighLevelToolbar';
import AppContext from '../../../context/app/appContext';

interface IClaimsHighLevelProps {
  supplierId?: number;
  refecthClaimListCount: number;
  claimRequestId?: number;
  claimAndReturnsV272602: boolean;
}

interface IClaimsStatusFilter {
  AllClaims: undefined;
  Drafts?: ClaimsAndReturnsStatus[];
  Active: ClaimsAndReturnsStatus[];
  Reviewing?: ClaimsAndReturnsStatus[];
  Finalised: ClaimsAndReturnsStatus[];
}

export const claimStatusFilter: IClaimsStatusFilter = {
  AllClaims: undefined,
  Drafts: [ClaimsAndReturnsStatus.Draft],
  Active: [ClaimsAndReturnsStatus.Sent],
  Reviewing: [ClaimsAndReturnsStatus.Reviewed],
  Finalised: [
    ClaimsAndReturnsStatus.Finalised,
    ClaimsAndReturnsStatus.Cancelled,
    ClaimsAndReturnsStatus.Approved,
    ClaimsAndReturnsStatus.Rejected,
  ],
};

export const ClaimsHighLevel = (props: IClaimsHighLevelProps) => {
  // Props
  const {supplierId, refecthClaimListCount, claimRequestId, claimAndReturnsV272602} = props;

  // Context
  const {conversations} = useContext(PurchasingContext);
  const {marketplaceSupplier} = useContext(AppContext);

  // Hooks
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const history = useHistory();
  const {search} = useLocation();
  const {getSelectedSupplier} = usePSBLHook();

  // Constants
  const currentSupplier = getSelectedSupplier(supplierId);
  const showVendor = isInBuyerContext && claimAndReturnsV272602;
  const claimRequestStatusFilter = useMemo(() => ({...claimStatusFilter}), []);
  const query = new URLSearchParams(search);
  const selectedBucket = query.get('filterBy') || 'Active';

  if (!claimAndReturnsV272602) {
    delete claimRequestStatusFilter.Reviewing;
  }

  if (!claimAndReturnsV272602 && isInSupplierContext) {
    delete claimRequestStatusFilter.Drafts;
  }

  // APIs
  const {getClaimRequestListBuyer, getClaimRequestListSupplier} = useClaimsAndReturnsAPI();

  // States
  const [claimRequestDtl, setClaimRequestDtl] = useState<IClaimRequestListResponse>();
  const [searchQuery, setSearchQuery] = useState({searchKey: '', searchCategory: 'RefNumber'});
  const [isLoading, setIsLoading] = useState(false);

  // useEffect
  useEffect(() => {
    selectedBucket && fetchClaimRequestList();
  }, [supplierId, selectedBucket, refecthClaimListCount, searchQuery, marketplaceSupplier?.TenantName]);

  // Functions
  const fetchClaimRequestList = async () => {
    try {
      setIsLoading(true);
      if (isInBuyerContext && marketplaceSupplier?.TenantName) await fetchBuyerAllClaimsList();
      if (isInSupplierContext) await fetchSupplierReturnsList();
    } catch {
      setIsLoading(false);
    }
  };

  const getFilterByStatus = () => {
    if (selectedBucket === 'Reviewing' && !claimAndReturnsV272602) return;
    if (selectedBucket === 'Drafts' && !claimAndReturnsV272602 && isInSupplierContext) return;
    return claimRequestStatusFilter?.[selectedBucket as keyof IClaimsStatusFilter]?.toString();
  };

  const fetchBuyerAllClaimsList = async () => {
    try {
      if (!supplierId) return;
      const query = {
        status: getFilterByStatus(),
        linkedSupplierId: supplierId,
        searchString: searchQuery?.searchKey || undefined,
        searchField: searchQuery?.searchKey ? searchQuery?.searchCategory : undefined,
      };
      const response = await getClaimRequestListBuyer(query);
      const highLevelList = response?.values?.map(v => ({...v, cName: marketplaceSupplier?.TenantName}));
      setClaimRequestDtl({...response, values: highLevelList});
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSupplierReturnsList = async () => {
    try {
      const query = {
        status: getFilterByStatus(),
        searchString: searchQuery?.searchKey || undefined,
        searchField: searchQuery?.searchKey ? searchQuery?.searchCategory : undefined,
      };
      const response = await getClaimRequestListSupplier(query);
      const highLevelList = response?.values?.map(v => ({...v, cName: v?.accountCustomerName}));
      setClaimRequestDtl({...response, values: highLevelList});
    } finally {
      setIsLoading(false);
    }
  };

  const handleBucketClick = (viewMode: keyof IClaimsStatusFilter) => {
    query.set('filterBy', viewMode);
    history.replace({search: query?.toString()});
  };

  const isValidForConversation = (status: string) => {
    return ![ClaimsAndReturnsStatus.Draft, ClaimsAndReturnsStatus.Deleted]?.includes(status as ClaimsAndReturnsStatus);
  };

  //Buyer Table Columns
  const highLevelClaimColums = [
    {
      title: isInBuyerContext ? 'Supplier' : 'Customers',
      key: 'cName',
      width: showVendor ? '16%' : '17%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={isInBuyerContext ? `lblSupplier` : 'lblCustomers'}
          className="inline-flex items-center justify-between gap-1 overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {rowData?.cName}
          {claimAndReturnsV272602 && (rowData?.isDisputed || rowData?.claimRequestRefIDIsDisputed) && (
            <ARTooltip
              title={
                <p className="whitespace-nowrap text-center font-poppins text-[12px] font-medium text-spenda-primarytext">
                  {rowData?.isDisputed ? 'Disputed' : rowData?.claimRequestRefIDIsDisputed ? 'Previously Disputed' : ''}
                </p>
              }
            >
              <div
                className="flex h-auto w-auto cursor-pointer items-center bg-[#F4E8D1] p-[8px]"
                data-autoid={`iconInfoClaimsList`}
              >
                <ARInfoYellowIcon />
              </div>
            </ARTooltip>
          )}
        </Typography>
      ),
    },
    {
      title: 'Ref ID',
      key: 'claimCode',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lnkRefId`}
          onClick={() => {
            if (isInSupplierContext) {
              query?.set('accountCustomerId', String(rowData?.accountCustomerID));
            }
            query?.set('claimRequestId', String(rowData?.claimRequestID));
            history.replace({search: query?.toString()});
          }}
          className={clsx(
            'flex cursor-pointer items-center justify-between gap-2 overflow-ellipsis font-medium',
            'text-primary hover:underline hover:opacity-75',
            {underline: rowData?.claimRequestID === claimRequestId},
          )}
          variant="paragraph"
        >
          {rowData?.claimCode}
          {isValidForConversation(rowData?.status) && claimAndReturnsV272602 && (
            <StartConvo
              operationUniqueId={rowData?.claimGUID}
              conversationWith={(isInBuyerContext ? currentSupplier?.SupplierName : rowData?.accountCustomerName) || ''}
              refNumber={rowData?.refNumber}
              supplierID={isInBuyerContext ? supplierId : undefined}
              customerID={isInSupplierContext ? rowData?.accountCustomerID : undefined}
              datTypeID={rowData?.datTypeID}
              guid={rowData?.claimGUID}
              contextConvos={conversations}
              startConvoType={StartConvoType.ICON}
              convoType={ConversationType.DOCUMENT}
              transactionId={rowData?.claimRequestID}
              unreadMessages={rowData?.unreadMessages}
              conversationId={rowData?.conversationID}
            />
          )}
        </Typography>
      ),
    },
    {
      title: isInBuyerContext ? 'Raised on' : 'Claim raised on',
      key: 'createdDate',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lblRaisedOn`}
          className="inline overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {rowData?.createdDate && moment(rowData?.createdDate).format('DD MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Invoice ref',
      key: 'invoiceRefNumber',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lblInvoiceRef`}
          className="inline overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {rowData?.invoiceRefNumber}
        </Typography>
      ),
    },
    {
      title: 'Invoice date',
      key: 'invoiceDate',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lblInvoiceDate`}
          className="inline overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {rowData?.invoiceDate && moment(rowData?.invoiceDate).format('DD MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Items',
      key: 'noItems',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lblItems`}
          className="inline overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {rowData?.noItems}
        </Typography>
      ),
    },
    {
      title: 'Amount',
      key: 'totalInc',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lbAmount`}
          className="inline overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {PriceFormat(rowData?.totalInc)}
        </Typography>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: showVendor ? '12%' : '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => {
        const isShowWithCustomerStatus =
          [ClaimsAndReturnsStatus?.Sent, ClaimsAndReturnsStatus?.Reviewed]?.includes(
            rowData?.status as ClaimsAndReturnsStatus,
          ) && rowData?.isPendingCustomerAction;

        return (
          <div className="h-[20px]">
            <div data-autoid={`lblStatus`} className={`overflow-ellipsis font-poppins text-base font-medium`}>
              {isShowWithCustomerStatus
                ? renderClaimsAndReturnStatus('With Customer')
                : renderClaimsAndReturnStatus(rowData?.status)}
            </div>
          </div>
        );
      },
    },
  ];

  if (claimAndReturnsV272602 && (selectedBucket === 'Finalised' || selectedBucket === 'AllClaims')) {
    highLevelClaimColums?.splice(1, 0, {
      title: 'Supplier ref',
      key: 'vendorCreditNoteRefNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography data-autoid={`lblSupplierRef`} className="inline font-medium text-black-800" variant="paragraph">
          {rowData.vendorCreditNoteRefNumber}
        </Typography>
      ),
    });
  }

  if (showVendor) {
    highLevelClaimColums?.splice(1, 0, {
      title: 'Vendor',
      key: 'vendorName',
      width: '14%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      getRowClassConditionally: (r: IClaimRequestList) => (r.claimRequestID === claimRequestId ? '!bg-[#F5F8FA]' : ''),
      isSortable: true,
      rowRenderer: (rowData: IClaimRequestList) => (
        <Typography
          data-autoid={`lblVendor`}
          className="inline overflow-ellipsis font-medium text-black-800"
          variant="paragraph"
        >
          {rowData?.vendorName || ''}
        </Typography>
      ),
    });
  }

  const renderTable = () => {
    return (
      <ARTable
        isPaginated
        isHighlightRowOnHover
        isLoading={isLoading}
        tableClass="!mx-0"
        columns={highLevelClaimColums}
        rows={claimRequestDtl?.values || []}
        dataAutoId={isInBuyerContext ? 'ClaimRequests' : 'Claims'}
        getRowDataAutoId={rowData =>
          isInBuyerContext ? `rowClaimRequests${rowData?.claimCode}` : `rowClaims${rowData?.claimCode}`
        }
      />
    );
  };

  const getCount = (bucketName?: keyof typeof claimRequestStatusFilter) => {
    if (!claimRequestDtl) return '';

    switch (bucketName) {
      case 'Drafts':
        return claimRequestDtl?.countDraft;
      case 'Active':
        return claimRequestDtl?.countSent;
      case 'Reviewing':
        return claimRequestDtl?.countReviewed;
      case 'Finalised':
        return (
          claimRequestDtl?.countApproved +
          claimRequestDtl?.countRejected +
          claimRequestDtl?.countCancelled +
          claimRequestDtl?.countFinalised
        );
      default:
        return (
          claimRequestDtl?.countApproved +
          claimRequestDtl?.countRejected +
          claimRequestDtl?.countCancelled +
          claimRequestDtl?.countFinalised +
          claimRequestDtl?.countDraft +
          claimRequestDtl?.countSent
        );
    }
  };

  return (
    <div className={`relative flex h-full flex-col overflow-hidden rounded-md bg-primary-background`}>
      <>
        <div className="mb-1 h-[inherit] w-full rounded-[10px] py-1">
          <Tabs value={selectedBucket} className="h-[inherit]">
            <TabsHeader className="mb-2 rounded-lg bg-[#FFFFFF] px-0 shadow-sm">
              {(Object.keys(claimRequestStatusFilter) as Array<keyof typeof claimRequestStatusFilter>)?.map(cr => (
                <Tab key={cr} value={cr} className="px-[5px]" onClick={() => handleBucketClick(cr)}>
                  <TabButton label={cr} count={getCount(cr)} dataAutoId={`btnStatus${cr}`} />
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody className="h-[inherit] pb-[60px]">
              <div className="h-[inherit] !rounded-[10px] bg-white">
                <div className={`flex h-[inherit] w-full flex-col bg-white p-2.5 font-poppins`}>
                  <ClaimsHighLevelToolbar
                    setSearchQuery={setSearchQuery}
                    supplierId={supplierId}
                    claimAndReturnsV272602={claimAndReturnsV272602}
                    activeBucket={selectedBucket?.replace(/([A-Z])/g, ' $1').trim() || 'All Claims'}
                  />
                  <div className="h-[inherit] overflow-auto">{renderTable()}</div>
                </div>
              </div>
            </TabsBody>
          </Tabs>
        </div>
      </>
    </div>
  );
};

const statusClaimsAndReturnChipMap: {[key in string]: {value: string; color: color}} = {
  Draft: {value: 'Draft', color: 'warm-purple'},
  Sent: {value: 'New', color: 'primary'},
  Finalised: {value: 'Completed', color: 'success'},
  Processing: {value: 'In progress', color: 'primary'},
  SentAsPDF: {value: 'Sent as pdf', color: 'warning'},
  Rejected: {value: 'Rejected', color: 'error'},
  Cancelled: {value: 'Cancelled', color: 'warning'},
  NotSent: {value: 'Not sent', color: 'warning'},
  Approved: {value: 'Approved', color: 'success'},
  'With Customer': {value: 'With Customer', color: 'warning'},
};

export const renderClaimsAndReturnStatus = (status: string) => {
  const chipData = statusClaimsAndReturnChipMap?.[status];
  return (
    <div className="flex flex-col  items-center">
      <Chip
        value={chipData?.value || status}
        className="inline-block w-[120px] rounded-sm px-3 py-1 text-center text-xs font-medium"
        color={chipData?.color || 'warning'}
      />
    </div>
  );
};

interface ITabButtonProps {
  label: string;
  count: number | string;
  dataAutoId?: string;
}

const TabButton = (props: ITabButtonProps) => {
  const {state} = useTabs();
  const {active: currentActive} = state;
  const isActive = props.label === currentActive;

  return (
    <Button
      fullWidth
      wave
      ripple={false}
      variant={isActive ? 'filled' : 'outlined'}
      data-autoid={props.dataAutoId}
      className="h-12"
    >
      <div className="flex flex-col items-center">
        <Typography variant="xsmall" className="font-medium">
          {props.label?.replace(/([A-Z])/g, ' $1').trim()}
        </Typography>
        <Typography className="font-semibold">
          {props.count} {props.count === 1 ? 'Claim' : 'Claims'}
        </Typography>
      </div>
    </Button>
  );
};
