import React, {useContext, useEffect, useState} from 'react';
import {APFooter} from '../APFooter';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles} from '@material-ui/core';
import UserSettingsIcon from '../../../assets/svg/accounts-payable/UserSettingsIcon';
import {IUserAccountInfo, UserRoleEnum} from '../../../model/user/UserAccountInfo';
import {IUser} from '../../../model/user/User';
import UsersDialog from '../../dialog/UsersDialog';
import AppContext from '../../../context/app/appContext';
import {ZohoContext} from '../../../context/app/ZohoContext';
import {useAuth} from '../../../services/useAuth';
import {IActionResultsList} from '../../../model/ActionResults';
import useUsersAPI from '../../../services/useUsersAPI';
import APUserRoleEditIcon from '../../../assets/svg/accounts-payable/APUserRoleEditIcon';
import {NameInitials} from '../../../utils/formatter';
import {CommonPaymentPermission} from '../CommonPaymentPermission';
import {PrimaryButton} from '../../buttons/DefaultButtons';

export interface IUserSettingsProps {
  isAPOnboardingV1?: boolean;
  onNextClick: () => void;
}

const useUseSettingsStyle = makeStyles({
  userRoleTable: {
    '& .MuiTableHead-root': {
      background: '#f1f1f1',
      position: 'sticky',
      top: '0',
      zIndex: '9',
    },
    '& .MuiTableRow-root:last-child td': {
      border: '0px!important',
    },
  },
});

export const UserSettings = (props: IUserSettingsProps) => {
  const {isAPOnboardingV1, onNextClick} = props;

  const classes = useUseSettingsStyle();
  const appContext = useContext(AppContext);
  const zohoContext = useContext(ZohoContext)!;
  let user = appContext.user as IUser;

  const [showUsersDialog, setShowUsersDialog] = useState<boolean>(false);
  const [users, setUsers] = useState<IUserAccountInfo[]>([]);
  const [userToEdit, setUserToEdit] = useState<IUserAccountInfo>();
  const [showCommonPayment, setShowCommonPayment] = useState<boolean>(false);
  const {logout} = useAuth();

  const logOut = () => {
    logout().finally(() => {
      appContext.logout();
      // reset zoho chat window
      if (zohoContext) {
        zohoContext.resetChatWindow();
      }
    });
  };
  const onCloseUserDialog = () => {
    setShowUsersDialog(false);
    setUserToEdit(undefined);
  };

  const updateUser = (newUser: Partial<IUser>) => {
    appContext.setUser({...user, ...newUser});
  };

  const {get} = useUsersAPI();

  const fetchUser = () => {
    get({}).then((data: IActionResultsList<IUserAccountInfo>) => {
      if (data.IsSuccess) {
        setUsers(data.Items);
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, [user]);

  const onSaveUser = () => {
    fetchUser();
    setShowUsersDialog(false);
  };

  const toggleUsersDialog = () => {
    setShowUsersDialog(true);
  };

  const userGroups = [
    {
      name: 'Primary',
      filter: (u: IUserAccountInfo) => u.IsPrimaryContact,
    },
    {
      name: 'Admin',
      filter: (u: IUserAccountInfo) =>
        !u.IsPrimaryContact &&
        u.UserRoles.some(u => u.UserRoleEnum === UserRoleEnum.Administrator) &&
        u.UserRoles.every(u => u.UserRoleEnum !== UserRoleEnum.Support),
    },
    {
      name: 'Standard',
      filter: (u: IUserAccountInfo) =>
        !u.IsPrimaryContact &&
        u.UserRoles.every(
          u => u.UserRoleEnum !== UserRoleEnum.Administrator && u.UserRoleEnum !== UserRoleEnum.Support,
        ),
    },
  ];

  const handleNext = () => {
    setShowCommonPayment(true);
  };

  return (
    <div className={`wrapper  relative flex h-full max-w-full`}>
      {showCommonPayment ? (
        <div className={`mx-auto w-full max-w-[795px] text-left`}>
          <Box className="mb-5">
            <h2 className={`mb-1 w-full text-[24px] font-light text-[#121212]`}>Edit user roles & permissions</h2>
            <p className="text-sm font-normal text-spenda-labeltext">Assign user roles and permissions</p>
          </Box>
          <CommonPaymentPermission isAPOnboardingV1={isAPOnboardingV1} onNextClick={onNextClick} />
        </div>
      ) : (
        <>
          <div className={`${users.length <= 2 ? 'max-w-[500px]' : 'max-w-[840px]'} mx-auto w-full text-left`}>
            <Box>
              <h2 className={`mb-1 w-full text-[24px] font-light text-[#121212]`}>Invite users and manage roles</h2>
              {users.length > 2 && (
                <p className="text-sm font-normal text-spenda-labeltext">Assign user roles and permissions to users</p>
              )}
            </Box>

            {users.length <= 2 ? (
              <div className={`relative mt-5 w-full bg-[#FAFAFA] px-16 py-10`}>
                <Box className="flex flex-col items-center justify-center text-center">
                  <UserSettingsIcon width={'80px'} height={'80px'} />
                  <p className="py-6 font-poppins text-base text-[#707070]">
                    Here you can invite users and manage system permissions. Click ‘Invite User’ to continue.
                  </p>
                  <PrimaryButton label="Invite User" onClick={() => toggleUsersDialog()} />
                </Box>
              </div>
            ) : (
              <div className="mt-10 flex max-h-[350px]">
                <TableContainer>
                  <Table
                    className={`${classes.userRoleTable} overflow-hidden rounded-r-lg rounded-t-lg`}
                    aria-label="simple table"
                  >
                    <TableHead className="bg-[#f1f1f]">
                      <TableRow>
                        <TableCell align="left" className="!border-0 !px-4 !py-2.5" style={{width: '23%'}}>
                          <span className="font-poppins text-base font-semibold text-spenda-labeltext">Roles</span>
                        </TableCell>
                        <TableCell align="left" className="!border-0 !px-4 !py-2.5" style={{width: '77%'}}>
                          <span className="font-poppins text-base font-semibold text-spenda-labeltext">Users</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userGroups.map((ug, id) => {
                        const filteredUsers = users.filter(ug.filter);
                        return (
                          <TableRow key={id}>
                            <TableCell align="left" className="!border-[#F1F1F1] bg-spenda-cream !px-4 !py-2">
                              <p className="relative z-50 inline-block bg-white pr-1 font-poppins text-base text-spenda-primarytext">
                                {ug.name}
                              </p>
                            </TableCell>
                            <TableCell align="left" key={id} className="!border-[#F1F1F1] !px-4 !pb-0 !pt-2">
                              <div className="flex min-h-[44px] flex-wrap">
                                {filteredUsers.map((fu, id) => {
                                  return (
                                    <div
                                      key={id}
                                      className="mb-2 mr-2 flex w-[195px] items-center justify-start bg-[#f6f6f6] pl-2 font-poppins text-spenda-primarytext"
                                    >
                                      <div className="flex h-8 min-w-[32px] items-center justify-center rounded-full bg-[#C68A1920] text-[#C68A19]">
                                        <span>{NameInitials(fu.FirstName + ' ' + fu.LastName)}</span>
                                      </div>
                                      <div
                                        className="relative ml-2 flex items-center justify-between pr-4"
                                        style={{width: 'calc(100% - 40px)'}}
                                      >
                                        <div className="w-10/12 py-[6px]">
                                          <p className="truncate text-xs font-medium leading-[16px]">
                                            {fu.FirstName} {fu.LastName}
                                          </p>
                                          <p className="truncate text-[10px] leading-[16px] text-spenda-labeltext">
                                            {fu.EmailAddress}
                                          </p>
                                        </div>
                                        <div className="absolute right-0 top-0 flex h-[44px] min-w-[18px] items-center justify-center bg-primary/20">
                                          <APUserRoleEditIcon
                                            className="h-2.5 w-2.5 cursor-pointer"
                                            onClick={() => {
                                              setUserToEdit(fu);
                                              toggleUsersDialog();
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
          <APFooter
            onNextClick={() => handleNext()}
            buttonLabel={'Next'}
            isCancelButton={true}
            isAPOnboardingV1={isAPOnboardingV1}
            secondaryButtonLabel="Skip"
            handleCancelClick={() => setShowCommonPayment(true)}
            isDisabled={users.length <= 2}
            isInviteUser={users.length > 2 && true}
            handleInvite={() => toggleUsersDialog()}
          />
        </>
      )}
      {showUsersDialog && (
        <UsersDialog
          updateLoggedInUser={updateUser}
          title="User Management"
          actions={[
            {
              label: 'Close',
              action: onCloseUserDialog,
              variant: 'outlined',
            },
          ]}
          logOut={logOut}
          currentUser={user}
          isAPOnboardingV1={isAPOnboardingV1}
          APOnCloseUserDialog={onCloseUserDialog}
          APUser={userToEdit}
          APUserSave={onSaveUser}
        ></UsersDialog>
      )}
    </div>
  );
};
