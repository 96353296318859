import React, {useContext, useEffect} from 'react';
import {DropZoneComponent} from '../../inputs/DropZone';
import useTenantAPI from '../../../services/useTenantAPI';
import AppContext from '../../../context/app/appContext';
import {validateABN} from '../../../utils/validators';
import {LogoTypeEnum} from '../../../model/Tenant';
import {Formik, Form, FormikHelpers, Field, FieldProps} from 'formik';
import * as Yup from 'yup';
import {makeStyles} from '@material-ui/core/styles';
import {APFooter} from '../APFooter';
import {IntegrationContext} from '../../../context/IntegrationContext';
import {BusinessDetailsFormValues} from '../../onboarding/BusinessDetails';
import useGetTenantDetails from '../../../services/useGetTenantDetails';
import {Input} from 'spenda-ui-react';
import WebsiteArrowIcon from '../../../assets/svg/WebsiteArrowIcon';
import {Countries} from '../../../model/constants/Constants';

interface BusinessInformationProps {
  onNextClick: () => void;
  isBuyer: boolean;
  isAPOnboardingV1?: boolean;
  isPsblOnboarding?: boolean;
}

const newTenant: BusinessDetailsFormValues = {
  companyName: '',
  abn: '',
  companyPhone: '',
  website: '',
  companyLogo: '',
  companyIcon: '',
  companyBanner: '',
} as BusinessDetailsFormValues;

const useBusinessInformationStyles = makeStyles({
  businessDetailScroll: {
    height: 'calc(100vh - 335px)',
    fontFamily: 'Poppins, sans-serif !important',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      borderRadius: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D8D8D8',
      borderRadius: '7px',
    },
  },
});

export const BusinessInformation = (props: BusinessInformationProps) => {
  const tenantAPI = useTenantAPI();
  const appCtx = useContext(AppContext);
  const tenant = appCtx?.tenant;
  const classes = useBusinessInformationStyles();
  const {getTenantDetails, tenantCountry} = useGetTenantDetails();

  const {financialAdaptor} = useContext(IntegrationContext);

  const isNZTenant = tenantCountry === Countries.NewZealand;

  useEffect(() => {
    newTenant.companyName = tenant?.Name || '';
    newTenant.abn = tenant?.ABN || '';
    newTenant.companyPhone = tenant?.Phone || '';
    newTenant.website = tenant?.WebSiteURL || '';
    newTenant.arContactEmailAddress = tenant?.ARContactEmailAddress || '';

    if (tenant?.Logos?.length) {
      for (let logo of tenant.Logos) {
        if (logo.LogoTypeID == LogoTypeEnum.Banner) {
          newTenant.companyBanner = logo.URI || '';
        } else if (logo.LogoTypeID == LogoTypeEnum.Logo) {
          newTenant.companyLogo = logo.URI || '';
        } else if (logo.LogoTypeID == LogoTypeEnum.Icon) {
          newTenant.companyIcon = logo.URI || '';
        }
      }
    }
  }, []);

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Company Name is required')
      .max(100, 'Company Name cannot be longer than 100 characters'),
    companyPhone: Yup.string()
      .required('Company Phone is required')
      .min(isNZTenant ? 9 : 10, 'Invalid Phone no.')
      .max(11, 'Invalid Phone no.'),
  });

  const filesChanged = (file: File, fieldName: string, setFieldValue: (field: string, value?: any) => void) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 'reader.result' contains the data URL for the file
      const imgUrl = reader.result;
      setFieldValue(fieldName, imgUrl ?? '');
      setFieldValue(fieldName + 'Thumb', imgUrl ?? '');
    };

    reader.readAsDataURL(file);
    setFieldValue(`uploadedFiles.${fieldName}`, file);
  };

  const onUploadChange = (files: File[], fieldName: string, setFieldValue: (field: string, value: any) => void) => {
    if (!files.length) {
      return;
    }
    if (!files[0].name) {
      return;
    }

    filesChanged(files[0], fieldName, setFieldValue);
  };

  const onRemoveUpload = (fieldName: string, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue(fieldName, '');
    setFieldValue(`${fieldName}Thumb`, '');
    setFieldValue(`uploadedFiles.${fieldName}`, undefined);
  };

  const abnValidator = (value: string) => {
    const bnTerm = isNZTenant ? 'NZBN' : 'ABN';

    if (!value) {
      return `${bnTerm} is required`;
    }
    const result = validateABN(value, isNZTenant);
    let error = `please enter valid ${bnTerm}`;
    if (!result) {
      return error;
    } else if (!result.valid) {
      return error;
    }
    return undefined;
  };

  const onSubmit = async (
    businessDetails: BusinessDetailsFormValues,
    formikHelpers: FormikHelpers<BusinessDetailsFormValues>,
    initialBusinessDetails: BusinessDetailsFormValues,
  ) => {
    // submit Company Details
    formikHelpers.setTouched({companyName: true, companyPhone: true, abn: true, website: true});
    formikHelpers.setSubmitting(true);
    const tenantDetails = await getTenantDetails(businessDetails, initialBusinessDetails);
    if (!tenantDetails) {
      formikHelpers.setSubmitting(false);
      return;
    }
    const isSaved = (await tenantAPI.saveTenantDetails(tenantDetails, tenantDetails.ARContactEmailAddress)).IsSuccess;
    formikHelpers.setSubmitting(false);
    if (isSaved) {
      appCtx.setTenant(tenantDetails);
      props.onNextClick();
    }
  };

  const handleArrowIconClick = (url: string) => {
    if (url !== '') {
      window.open(url);
    }
  };

  return (
    <div className={`wrapper relative max-w-full`}>
      <Formik
        initialValues={newTenant}
        validationSchema={validationSchema}
        onSubmit={(value, helper) => onSubmit(value, helper, newTenant)}
        enableReinitialize
      >
        {({values, touched, errors, handleSubmit, isSubmitting, handleBlur, handleChange, setFieldValue, isValid}) => (
          <>
            <Form onSubmit={handleSubmit} className={`mx-auto max-w-[540px] text-left`}>
              <div className="relative pb-5">
                <div>
                  <h2 className={`mb-1 w-full text-[24px] font-light text-[#121212]`}>Business information</h2>
                  <p className="text-sm font-normal text-spenda-labeltext">
                    {props?.isPsblOnboarding
                      ? 'Let us know a little bit about yourself. You can tap on “Live Chat” below if you need any help along the way. Press ‘Next’ to get started.'
                      : 'Let us know a bit about your business. Press ‘Next’ to continue.'}
                  </p>
                </div>
                <div
                  className={`${classes.businessDetailScroll} mt-6 flex w-full justify-between overflow-y-auto overflow-x-hidden pr-4 pt-2`}
                >
                  <div className={'w-full'}>
                    <div className={`mb-6 flex justify-between`}>
                      <div className={` mr-2.5 md:w-1/2`}>
                        <Input
                          id="companyName"
                          name="companyName"
                          label={'Business name'}
                          variant={'outlined'}
                          helperText={(touched.companyName && errors.companyName) || ''}
                          error={touched.companyName && Boolean(errors.companyName)}
                          autoComplete="off"
                          value={values.companyName}
                          onChange={e => handleChange(e)}
                          onBlur={handleBlur}
                        />
                        {financialAdaptor &&
                          financialAdaptor.OrganisationName &&
                          values.companyName !== financialAdaptor.OrganisationName && (
                            <div className={`${`relative z-50`}`}>
                              <p className="text-[10px] font-medium">
                                Found '{financialAdaptor.OrganisationName}' in {financialAdaptor.Name}{' '}
                                <span
                                  data-autoid="btnUseInstead"
                                  className="cursor-pointer font-semibold text-primary"
                                  onClick={() => setFieldValue('companyName', financialAdaptor.OrganisationName)}
                                >
                                  Use Instead
                                </span>
                              </p>
                            </div>
                          )}
                      </div>
                      <div className={`ml-2.5 flex justify-end md:w-1/2`}>
                        <Field name="abn" validate={abnValidator}>
                          {({meta}: FieldProps) => {
                            return (
                              <Input
                                id="abn"
                                name="abn"
                                autoComplete="off"
                                label={isNZTenant ? 'NZBN' : 'ABN'}
                                variant={'outlined'}
                                helperText={(meta.touched && meta.error) || ''}
                                error={meta.touched && Boolean(meta.error)}
                                value={meta.value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                    <div className={` flex justify-between`}>
                      <div className={`relative mr-2.5 md:w-1/2 `}>
                        <Input
                          id="companyPhone"
                          name="companyPhone"
                          autoComplete="off"
                          label={'Company phone number'}
                          variant={'outlined'}
                          helperText={touched.companyPhone && errors.companyPhone ? errors.companyPhone : ''}
                          error={touched.companyPhone && Boolean(errors.companyPhone)}
                          value={values.companyPhone}
                          type="number"
                          onChange={e => {
                            setFieldValue('companyPhone', e.target.value);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className={'ml-2.5 flex justify-end md:w-1/2'}>
                        <Input
                          shrink
                          id="website"
                          name="website"
                          label="Company website"
                          className="w-full"
                          type="text"
                          helperText={errors.website || ''}
                          error={touched.website && Boolean(errors.website)}
                          value={values.website}
                          onChange={handleChange}
                          InputProp={{
                            endAdornment: (
                              <div
                                className="flex justify-end"
                                onClick={() => {
                                  handleArrowIconClick(values.website);
                                }}
                              >
                                <WebsiteArrowIcon className="cursor-pointer" />
                              </div>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div className="!mb-5 !mt-7 h-[1px] w-full !bg-[#cccccc]" />
                    <div className="mb-3 flex justify-between">
                      <div className="mr-2.5 md:w-1/2 ">
                        <div className={`w-full`}>
                          <div className={`mb-3 font-poppins text-base font-medium`}>Business logo</div>
                          <DropZoneComponent
                            uploadedFile={values.companyLogo}
                            onChange={files => onUploadChange(files, 'companyLogo', setFieldValue)}
                            placeholder={''}
                            onRemove={() => onRemoveUpload('companyLogo', setFieldValue)}
                            dialogTitle={'Delete Business Logo'}
                            dialogDescription={'Would you like to delete Business Logo?'}
                            isAPOnboardingV1Header={props.isAPOnboardingV1}
                          ></DropZoneComponent>
                        </div>
                      </div>
                      <div className={`ml-2.5 flex  justify-end md:w-1/2`}>
                        <div className={`w-full`}>
                          <div className={`mb-3 font-poppins text-base font-medium`}>Business icon</div>
                          <DropZoneComponent
                            uploadedFile={values.companyIcon}
                            onChange={files => onUploadChange(files, 'companyIcon', setFieldValue)}
                            placeholder={''}
                            onRemove={() => onRemoveUpload('companyIcon', setFieldValue)}
                            dialogTitle={'Delete Business Icon'}
                            dialogDescription={'Would you like to delete Business Icon?'}
                            isAPOnboardingV1Header={props.isAPOnboardingV1}
                          ></DropZoneComponent>
                        </div>
                      </div>
                    </div>
                    <div className="!mb-5 !mt-7 h-[1px] w-full !bg-[#cccccc]" />
                    <div className="mb-5 flex justify-between">
                      <div className="column w-full justify-center">
                        <div className={`mb-3 font-poppins text-base font-medium`}>Banner image</div>
                        <DropZoneComponent
                          uploadedFile={values.companyBanner}
                          onChange={files => onUploadChange(files, 'companyBanner', setFieldValue)}
                          placeholder={''}
                          onRemove={() => onRemoveUpload('companyBanner', setFieldValue)}
                          dialogTitle={'Delete Business Banner'}
                          dialogDescription={'Would you like to delete Business Banner?'}
                          isAPOnboardingV1Header={props.isAPOnboardingV1}
                        ></DropZoneComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <APFooter
                isDisabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                isAPOnboardingV1={props.isAPOnboardingV1}
                isPsblOnboarding={props?.isPsblOnboarding}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
