import React from 'react';
import {Redirect} from 'react-router-dom';
import {isWebsiteId17} from '../../config';
import APOrPsblOnboarding from './APOrPsblOnboarding';

const PSBLOnboarding = () => {
  if (!isWebsiteId17) {
    return <Redirect to={'/menu'} />;
  }

  return <APOrPsblOnboarding isPsblOnboarding />;
};

export default PSBLOnboarding;
