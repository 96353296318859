import React, {FC, ReactNode, useCallback, ChangeEvent, useContext, SetStateAction} from 'react';
import {FormikErrors, useFormikContext} from 'formik';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import _, {debounce} from 'lodash';

import QuoteInventoryAutocomplete from './QuoteInventoryAutocomplete';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {IQuoteLines, IQuotes} from '../../model/quotes/quotes';
import {discountToDiscountString, PriceFormat, validDecimalPlaces} from '../../utils/formatter';
import useQuotesAPI from '../../services/useQuotesAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import AppContext from '../../context/app/appContext';
import {UnitMarginTooltip} from './ProfitMarginTooltip';
import {CalculationTable} from './CalculationTable';
import {ColumnOptions} from '../form/QuoteCreateForm';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {Toast} from '../../utils/Toast';
import PriceCalculator from '../../utils/priceCalculator';

export interface IQTColumn {
  title: string;
  key: string;
  className?: string;
  width?: string;
  rowRender: (row: IQuoteLines, rowIndex?: number, colIndex?: number) => JSX.Element | string;
}
interface InputQProps extends React.ComponentProps<'input'> {
  icon?: ReactNode;
  containerProps?: React.ComponentProps<'div'>;
  showError?: boolean;
  disableField?: boolean;
}

export const InputQ: FC<InputQProps> = props => {
  const containerClass = `flex border border-transparent h-full p-2.5 ${
    props.containerProps?.className ? props.containerProps?.className : ''
  } ${props.showError && '!border-red-700'}`;
  const inputClass = `caret-primary outline-none w-full bg-transparent text-sm ${
    props.className ? props.className : ''
  }`;
  return (
    <div {...props.containerProps} className={containerClass}>
      <input type="text" {...props} className={inputClass} disabled={props.disableField} />
      {props.icon}
    </div>
  );
};

interface CreateQuoteTableProps {
  lineItemError?: boolean;
  columnOptions: ColumnOptions;
  updateQuote: (data: IQuotes) => void;
  updateVariantTotalAmount?: (amount: number) => void;
  setIsUpdatingLines?: React.Dispatch<SetStateAction<boolean>>;
}

const CreateQuoteTable: FC<CreateQuoteTableProps> = props => {
  const formikProps = useFormikContext<IQuotes>();
  const {updateQuote, isLoading} = useQuotesAPI();
  const {values, setFieldValue, errors, setValues} = formikProps;
  const {lineItemError, columnOptions, updateVariantTotalAmount, setIsUpdatingLines} = props;
  const {tenantInfo} = useContext(AppContext);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  const addLineInventory = () => {
    const newLineItem = [
      {
        code: '',
        shortDescription: '',
        inventoryID: 0,
        quantity: 0,
        sellPriceEx: 0,
        lineTotalEx: 0,
        lineTotalInc: 0,
        uoM: '',
        isActive: true,
        sellPriceExString: '',
        lineTotalExString: '',
      },
    ];
    if (Array.isArray(values.lines)) {
      const _lines = values.lines.map(line => {
        if (!line.inventoryID) {
          line.code = '';
          line.shortDescription = '';
          line.quantity = 0;
          line.sellPriceEx = 0;
          line.lineTotalEx = 0;
        }
        return line;
      });
      setFieldValue('lines', [..._lines, ...newLineItem]);
    } else {
      setFieldValue('lines', newLineItem);
    }
  };

  const handleQtyChange = (e: ChangeEvent<HTMLInputElement>, inventoryID: number, onBlur?: boolean) => {
    if (onBlur && e.target.value) return;
    if (!validDecimalPlaces(e.target.value)) return;

    if (Array.isArray(values.lines)) {
      const index = values?.lines?.findIndex(li => String(li.inventoryID) === String(inventoryID));
      const Lines = [...values.lines];
      const quantity =
        !e.target.value && onBlur
          ? 0
          : e.target.value && parseFloat(e.target.value) <= 0
            ? 0
            : parseFloat(e.target.value) || 0;
      Lines[index].quantity = quantity;
      const lineTotalEx = quantity * (Number(Lines?.[index]?.sellPriceEx?.toFixed(2)) || 0);
      setFieldValue(`lines[${index}].lineTotalEx`, lineTotalEx);
      setFieldValue(`lines[${index}].lineTotalExString`, lineTotalEx.toFixed(2));
      setFieldValue(`lines[${index}].quantity`, quantity);

      Lines[index].lineTotalEx = lineTotalEx;
      if (QuoteV289367) {
        if (Lines[index].marginMode === DISCOUNT_MODE.DOLLAR) {
          setFieldValue(`lines[${index}].marginString`, (Number(Lines[index].margin) * quantity).toFixed(2));
        }
      }

      if (Lines[index].inventoryID)
        debounceCalculateLineTotal(
          Lines,
          values,
          values.shipping,
          index,
          values.discountString,
          values.discountMode,
          false,
          false,
          true,
        );
    }
  };

  const handleLineTotalChange = (e: ChangeEvent<HTMLInputElement>, inventoryID: number) => {
    if (Array.isArray(values.lines)) {
      const index = values.lines.findIndex(li => String(li.inventoryID) === String(inventoryID));
      const Lines = [...values.lines];
      const lineTotal = parseFloat(e.target.value) < 0 ? 0 : parseFloat(e.target.value);
      Lines[index].lineTotalEx = parseFloat(lineTotal.toFixed(2));
      const sellPriceEx = lineTotal / (Lines[index].quantity || 1) || 0;
      Lines[index].sellPriceEx = sellPriceEx;
      setFieldValue(`lines[${index}].lineTotalEx`, lineTotal);
      setFieldValue(`lines[${index}].lineTotalExString`, e.target.value);
      setFieldValue(`lines[${index}].sellPriceEx`, sellPriceEx);

      if (QuoteV289367) {
        if (Lines[index].marginMode === DISCOUNT_MODE.DOLLAR) {
          Lines[index].margin = sellPriceEx - (Lines[index].costPriceEx || 0);
          setFieldValue(
            `lines[${index}].marginString`,
            ((sellPriceEx - (Lines[index].costPriceEx || 0)) * Lines[index].quantity).toFixed(2),
          );
        } else {
          Lines[index].margin = (sellPriceEx - (Lines[index].costPriceEx || 0)) / sellPriceEx;
          setFieldValue(
            `lines[${index}].marginString`,
            (((sellPriceEx - (Lines[index].costPriceEx || 0)) / sellPriceEx) * 100).toFixed(2),
          );
        }
      }
      if (Lines[index].inventoryID)
        debounceCalculateLineTotal(
          Lines,
          values,
          values.shipping,
          index,
          values.discountString,
          values.discountMode,
          false,
          false,
          true,
        );
    }
  };

  const debounceCalculateLineTotal = useCallback(
    debounce(
      (
        lines: IQuoteLines[],
        _values: IQuotes,
        shipping: number | string,
        rowIndex: number,
        discount?: string,
        discountMode?: DISCOUNT_MODE,
        isRemoveLine?: boolean,
        isUpdatingMargin?: boolean,
        isUpdatingQuantity?: boolean,
      ) => {
        calculateLineTotal(
          lines,
          _values,
          shipping,
          rowIndex,
          discount,
          discountMode,
          isRemoveLine,
          isUpdatingMargin,
          isUpdatingQuantity,
        );
      },
      2000,
    ),
    [],
  );

  const calculateLineTotal = async (
    _lines: IQuoteLines[],
    _values: IQuotes,
    shipping: number | string,
    rowIndex: number,
    discount?: string,
    discountMode?: DISCOUNT_MODE,
    isRemoveLine?: boolean,
    isUpdatingMargin?: boolean,
    isUpdatingQuantity?: boolean,
  ) => {
    if (!Array.isArray(_lines)) {
      return;
    }
    // clone lines to avoid mutation
    _lines = _.cloneDeep(_lines);

    setIsUpdatingLines?.(true);
    let _discount = 0;
    if (Number(discount) && discountMode === DISCOUNT_MODE.DOLLAR) {
      _discount = Number(discount);
      discountMode = DISCOUNT_MODE.DOLLAR;
    } else if (Number(discount) && discountMode === DISCOUNT_MODE.PERCENT) {
      _discount = Number(discount) / 100;
      discountMode = DISCOUNT_MODE.PERCENT;
    } else {
      discountMode = DISCOUNT_MODE.NONE;
    }

    const payload: Partial<IQuotes> = {
      name: _values.name,
      description: _values.description,
      isActive: true,
      quoteID: _values.quoteID,
      discount: _discount,
      discountMode: discountMode,
      shipping: Number(shipping),
    };

    if (isUpdatingMargin && QuoteV289367) {
      _lines[rowIndex].lineTotalEx = undefined;
    }
    if (isUpdatingQuantity && QuoteV289367) {
      _lines[rowIndex].sellPriceEx = undefined;
    }
    if (rowIndex !== -1 && !isRemoveLine && _lines[rowIndex]?.quoteLineGUID) {
      payload.lines = [_lines[rowIndex]];
    } else {
      payload.lines = _lines;
    }

    try {
      const updatedQuote: IQuotes = await updateQuote(payload);
      // update tax/total in formik
      setFieldValue('totalTax', updatedQuote.totalTax);
      setFieldValue('totalInc', updatedQuote.totalInc);
      updateVariantTotalAmount?.(updatedQuote.totalInc || 0);
      if (isRemoveLine || !_lines?.[rowIndex]?.quoteLineGUID || (isUpdatingMargin && QuoteV289367)) {
        setFieldValue(
          'lines',
          updatedQuote.lines.map(line => {
            return {
              ...line,
              marginString: discountToDiscountString(Number(line.margin), line.marginMode!, true, line.quantity),
              lineTotalExString: line.lineTotalEx?.toFixed(2),
            };
          }),
        );
        setFieldValue('discountString', discountToDiscountString(_discount, discountMode, false) || '0.00');
        setFieldValue('shipping', Number(shipping).toFixed(2));
      } else if (isUpdatingQuantity) {
        setFieldValue(`lines.[${rowIndex}].lineTotalExString`, _lines?.[rowIndex].lineTotalEx?.toFixed(2));
        setFieldValue(`lines.[${rowIndex}].lineTotalInc`, updatedQuote.lines?.[rowIndex].lineTotalInc);
      }
    } catch (_) {
      Toast.error('Failed to update quote');
      setIsUpdatingLines?.(false);
    }
  };

  const removeLine = async (id: number, rowIndex: number) => {
    let data = _.cloneDeep(values.lines);
    if (data[rowIndex]?.quoteLineID) {
      data[rowIndex].isActive = false;
    } else {
      if (rowIndex >= 0) {
        data.splice(rowIndex, 1);
      }
    }
    await setFieldValue('lines', data);
    calculateLineTotal(data, values, values.shipping, rowIndex, values.discountString, values.discountMode, true);
  };

  const onChangeMargin = (e: ChangeEvent<HTMLInputElement>, rowIndex: number, mode: DISCOUNT_MODE) => {
    if (!validDecimalPlaces(e.target.value)) return;

    if (Array.isArray(values.lines)) {
      const Lines = [...values.lines];

      const margin = Number(e.target.value);

      if (mode === DISCOUNT_MODE.PERCENT) {
        if (margin >= 100) {
          Toast.warning('Margin percentage should be less than 100%');
          return;
        }
        Lines[rowIndex].margin = Number(margin) / 100;
        Lines[rowIndex].marginMode = DISCOUNT_MODE.PERCENT;
      } else {
        Lines[rowIndex].margin = margin / Lines[rowIndex].quantity;
        Lines[rowIndex].marginMode = DISCOUNT_MODE.DOLLAR;
      }
      setFieldValue(`lines[${rowIndex}].margin`, margin / Lines[rowIndex].quantity);
      setFieldValue(`lines[${rowIndex}].marginString`, e.target.value);
      setFieldValue(`lines[${rowIndex}].marginMode`, mode);
      if (Lines[rowIndex].inventoryID)
        debounceCalculateLineTotal(
          Lines,
          values,
          values.shipping,
          rowIndex,
          values.discountString,
          values.discountMode,
          false,
          true,
        );
    }
  };

  const columns: IQTColumn[] = [
    {
      title: 'Code',
      key: 'code',
      width: '15%',
      className: 'text-left',
      rowRender: (row: IQuoteLines, rowIndex: number) => {
        return (
          <QuoteInventoryAutocomplete<IQuotes>
            by="code"
            index={rowIndex}
            alreadyFilled={values.lines.filter(a => a.isActive).map(item => item.inventoryID)}
            calculateLineTotal={calculateLineTotal}
          />
        );
      },
    },
    {
      title: 'Product',
      key: 'product',
      width: `${QuoteV289367 ? '34%' : '54%'}`,
      className: 'text-left',
      rowRender: (_row: IQuoteLines, rowIndex: number) => {
        return (
          <QuoteInventoryAutocomplete<IQuotes>
            by="product"
            index={rowIndex}
            alreadyFilled={values.lines.filter(a => a.isActive).map(item => item.inventoryID)}
            calculateLineTotal={calculateLineTotal}
          />
        );
      },
    },
    {
      title: 'Qty',
      key: 'qty',
      width: '5%',
      rowRender: (row: IQuoteLines, rowIndex: number) => {
        return (
          <InputQ
            type="number"
            data-autoid={`txtQty-${rowIndex}`}
            placeholder="0"
            value={parseFloat(row.quantity?.toString() || '0')?.toString()}
            onChange={e => handleQtyChange(e, row?.inventoryID)}
            className="text-center"
            containerProps={{
              className: `${QuoteV289367 ? 'border !border-[#707070] focus-within:border-primary' : ''}`,
            }}
            showError={
              errors?.lines &&
              (errors?.lines as FormikErrors<IQuoteLines>[])?.[rowIndex]?.quantity &&
              !Number(row.quantity || 0)
                ? true
                : false
            }
            onBlur={e => handleQtyChange(e, row?.inventoryID, true)}
          />
        );
      },
    },
    {
      title: 'UoM',
      key: 'uom',
      width: '5%',
      rowRender: (row: IQuoteLines, rowIndex: number) => {
        return (
          <Typography data-autoid={`txtUoM-${rowIndex}`} className="p-2.5 text-center" variant="small">
            {row.uoM}
          </Typography>
        );
      },
    },
    {
      title: 'Price ($)',
      key: 'price',
      width: '10%',
      className: 'text-right',
      rowRender: (row: IQuoteLines, rowIndex: number) => (
        <div
          className={`flex h-full items-center border border-transparent px-2.5 ${
            columnOptions.costPriceAndMargin && Number(row?.costPriceEx || 0) > 0 ? 'justify-between' : 'justify-end'
          } `}
        >
          {columnOptions.costPriceAndMargin && Number(row?.costPriceEx || 0) > 0 && (
            <UnitMarginTooltip
              costPrice={row.costPriceEx || 0}
              unitMargin={
                row.costPriceEx && row.costPriceEx > 0 ? Number(row?.sellPriceEx) - (row.costPriceEx || 0) : 0
              }
              lineMargin={
                row?.costPriceEx && row?.costPriceEx > 0
                  ? (Number(row.sellPriceEx) - row.costPriceEx) * row.quantity
                  : 0
              }
            />
          )}
          <Typography data-autoid={`txtPrice-${rowIndex}`} className="text-right" variant="small">
            {PriceFormat(row.sellPriceEx, '$', 2) || ''}
          </Typography>
        </div>
      ),
    },
    ...(columnOptions.marginAmountAndPercentage && QuoteV289367
      ? [
          {
            title: 'Margin amount ($)',
            key: 'marginAmount',
            width: '10%',
            rowRender: (row: IQuoteLines, rowIndex: number) => {
              return (
                <InputQ
                  data-autoid={`txtMarginAmount-${rowIndex}`}
                  type="number"
                  value={
                    row.marginMode === DISCOUNT_MODE.DOLLAR
                      ? row.marginString || ''
                      : (
                          PriceCalculator.getMarginAmount(row?.costPriceEx || 0, row.sellPriceEx || 0) * row.quantity
                        ).toFixed(2) || ''
                  }
                  placeholder="0.00"
                  onChange={e => onChangeMargin(e, rowIndex, DISCOUNT_MODE.DOLLAR)}
                  className="text-center"
                  containerProps={{className: '!p-0'}}
                />
              );
            },
          },
          {
            title: 'Margin percent (%)',
            key: 'marginPercent',
            width: '10%',
            rowRender: (row: IQuoteLines, rowIndex: number) => {
              return (
                <InputQ
                  data-autoid={`txtMarginPercent-${rowIndex}`}
                  type="number"
                  value={
                    row.marginMode === DISCOUNT_MODE.PERCENT
                      ? row.marginString
                      : PriceCalculator.getMarginPercent(row?.costPriceEx || 0, row.sellPriceEx || 0).toFixed(2) || ''
                  }
                  placeholder="0.00"
                  onChange={e => onChangeMargin(e, rowIndex, DISCOUNT_MODE.PERCENT)}
                  className="text-center"
                  containerProps={{className: '!p-0'}}
                />
              );
            },
          },
        ]
      : []),
    {
      title: 'Line Total ($)',
      key: 'lineTotal',
      width: '10%',
      className: 'text-right',
      rowRender: (row: IQuoteLines, rowIndex: number) => (
        <div
          className={`${
            errors?.lines &&
            (errors?.lines as FormikErrors<IQuoteLines>[])?.[rowIndex]?.lineTotalEx &&
            !Number(row.lineTotalEx || 0)
              ? 'hover:border-transparent'
              : 'hover:border-primary'
          } flex h-full items-center justify-between border border-transparent`}
        >
          <InputQ
            type="number"
            value={row.lineTotalExString || ''}
            placeholder="0.00"
            data-autoid={`txtLineTotal-${rowIndex}`}
            className="text-right"
            onChange={e => {
              if (!validDecimalPlaces(e.target.value)) return;
              handleLineTotalChange(e, row?.inventoryID);
            }}
            showError={
              errors?.lines &&
              (errors?.lines as FormikErrors<IQuoteLines>[])?.[rowIndex]?.lineTotalEx &&
              !Number(row.lineTotalEx || 0)
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      width: '1%',
      className: 'text-center',
      rowRender: (row: IQuoteLines, index: number) =>
        index === 0 && !row.code ? (
          <div className="w-10" />
        ) : (
          <IconButton
            variant="text"
            className="cursor-pointer active:bg-transparent"
            onClick={() => {
              removeLine(row?.inventoryID, index);
            }}
            name={`Remove-${index}`}
            disabled={isLoading}
            ripple={false}
          >
            {<WidgetDeleteLogo />}
          </IconButton>
        ),
    },
  ];

  const Table = (
    <table className="w-full pb-1">
      <TableHeader heading={columns} />
      <tbody>
        {values?.lines &&
          Array.isArray(values?.lines) &&
          values?.lines.map?.((item: IQuoteLines, rowIndex: number) => {
            if (
              !item.isActive ||
              item.inventoryID === tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId ||
              item.inventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
            )
              return null;
            return (
              <tr key={rowIndex} className="relative w-full">
                {columns.map((table: IQTColumn) => {
                  return (
                    <td
                      className={`m-auto h-10 border-b border-[#EAEAEA] p-0 odd:bg-[#FAFAFA] even:bg-[#FFFFFF] ${table.className}`}
                      width={table.width}
                      key={table.key}
                    >
                      {table.rowRender(item, rowIndex)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );

  const handleDiscountChange = (value: string, type: DISCOUNT_MODE) => {
    if (type === DISCOUNT_MODE.DOLLAR) {
      setValues({
        ...values,
        discountString: value,
        discount: Number(value),
        discountMode: DISCOUNT_MODE.DOLLAR,
      });
      debounceCalculateLineTotal(values.lines, values, values.shipping, -1, value, DISCOUNT_MODE.DOLLAR);
    } else if (type === DISCOUNT_MODE.PERCENT) {
      setValues({
        ...values,
        discountString: value,
        discount: Number(value) / 100,
        discountMode: DISCOUNT_MODE.PERCENT,
      });
      debounceCalculateLineTotal(values.lines, values, values.shipping, -1, value, DISCOUNT_MODE.PERCENT);
    }
  };

  const validateIsActive = (values: IQuotes) => {
    for (let i = 0; i < values.lines.length; i++) {
      if (values.lines[i].isActive && values.lines[i].code) {
        return false;
      }
    }
    return true;
  };

  return (
    <div
      className={`${QuoteV289367 ? 'h-[calc(100vh-440px)]' : 'h-[calc(100vh-270px)]'} relative flex flex-col justify-between overflow-auto`}
    >
      <LoadingIndicator
        isLoading={isLoading}
        position={{
          height: '100% !important',
          display: 'flex',
          position: 'absolute',
          left: '0',
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        size="md"
      />
      <div className="h-[calc(100vh-80px)]">
        <div>{Table}</div>
        <div className="px-5 py-2.5">
          <Button
            variant="text"
            ripple={false}
            disabled={isLoading}
            onClick={() => {
              addLineInventory();
            }}
            className="hover:no-underline focus:bg-transparent active:bg-transparent"
          >
            Add New Line
          </Button>
        </div>
        {lineItemError && (values.lines?.[0]?.code === '' || values.lines.length === 0 || validateIsActive(values)) && (
          <Typography className="text-error" data-autoid="txtErrorLineItem" variant="paragraph">
            Please provide line item details
          </Typography>
        )}
      </div>
      <div>
        <CalculationTable<IQuotes>
          handleDiscountChange={handleDiscountChange}
          handleShippingChange={value => {
            debounceCalculateLineTotal(
              values.lines,
              values,
              Number(value),
              -1,
              values.discountString,
              values.discountMode,
            );
          }}
          values={values}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

interface ITableColumnProps {
  heading: IQTColumn[];
}

export const TableHeader: FC<ITableColumnProps> = props => {
  return (
    <thead className="sticky top-0 z-[99] bg-[#F1F1F1] text-[#999999]">
      <tr>
        {props.heading.map(item => {
          return (
            <th
              key={item.key}
              className={`m-auto p-2.5 text-base font-semibold ${item.className ? item.className : ''}`}
            >
              {item.title}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default CreateQuoteTable;
