import useHttp from "../hooks/useHttp";
import { IBaseResponseV3 } from "../model/accounts-receivable/AccountsReceivable";
import { IUploadedClaimsRequestAttachment } from "../model/claims/ClaimsAndReturns";
import { AttachmentTypeEnum } from "../model/constants/Constants";

export const useAttachmentsAPI = () => {

    const { GET, POST, DELETE, isLoading } = useHttp();


    const uploadAttachments = (payload: any, headers: any): Promise<any> => {
        return POST(`Spenda/Attachments/File/Upload`, payload, true, false, headers);
      };
    
      const getUploadedAttachments = (
        attachmentTypeID: AttachmentTypeEnum,
        query: {guids: string}
      ): Promise<IBaseResponseV3<IUploadedClaimsRequestAttachment>> => {
        return GET(`Spenda/Attachments/File/${attachmentTypeID}`, query);
      };
    
      const deleteAttachment = (
        attachmentTypeID: AttachmentTypeEnum,
        attachmentGUID: string
      ): Promise<IBaseResponseV3<any>> => {
        return DELETE(`Spenda/Attachments/File/${attachmentTypeID}/${attachmentGUID}`);
      };

      const deleteAttachments = (
        attachmentTypeID: AttachmentTypeEnum,
        attachmentGUIDs: string[]
      ): Promise<IBaseResponseV3<any>> => {
        return DELETE(`Spenda/Attachments/File/${attachmentTypeID}`, attachmentGUIDs);
      }
    
    return {
        deleteAttachment,
        getUploadedAttachments,
        uploadAttachments,
        deleteAttachments,
        isLoading
    }
}