import React from 'react';

import {ServiceJobLabourAndMaterialTable} from '../../../components/service-management/ServiceJobLabourAndMaterialTable';

export default function MaterialAndLabour() {
  return (
    <div className="relative h-[calc(100vh-355px)] w-full overflow-y-auto bg-[#FFFFFF] lg:px-4 xl:px-12">
      <ServiceJobLabourAndMaterialTable />
    </div>
  );
}
