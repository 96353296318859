import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FormikProps} from 'formik';
import {omit, isEmpty} from 'lodash';
import {Accordion, AccordionBody, Button, IconButton, Input} from 'spenda-ui-react';
import {Divider, Grid, InputAdornment} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

import {ILocation} from '../../model/address/Location';
import {ICustomer} from '../../model/customer/Customer';
import {CONSIGNMENT_NOTE_STATUS, DatTypes, PICK_SLIP_STATUS, SALES_ORDER_STATUS} from '../../model/constants/Constants';
import {IPickSlipLine} from '../../model/sales-order/PickSlip';
import {IAddress} from '../../model/address/Address';
import {IConsignmentNotePackageLine} from '../../model/sales-order/ConsignmentNote';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {ISalesOrder, ISalesOrderLine, SalesOrderReservationStatus} from '../../model/sales-order/SalesOrder';
import {CustomerAutocomplete} from '../customer/CustomerAutocomplete';
import {CustomStepper} from '../Stepper/Stepper';
import {CustomerDialog} from '../dialog/CustomerDialog';
import {LinkOperationalCustomerDialog} from '../dialog/LinkOperationalCustomerDialog';
import {SelectShippingAddressDialog} from '../dialog/SelectShippingAddressDialog';
import {useCustomerLinking, useCustomersAPI, useLoadCustomers} from '../../services/useCustomersAPI';
import {PriceFormat} from '../../utils/formatter';
import {getAddressObject, getLocation} from '../../utils/customerAddressHelper';
import GoBackIcon from '../../assets/svg/GoBackIcon';
import {VisibleContent} from '../ui/VisibleContent';
import {AUTH_SELLING_SALES_ORDER_LIST} from '../../routes/SalesOrderRoutes';
import {CustomContent} from '../ui/CustomContent';
import {SalesOderStatus} from '../../screens/sales-order/SalesOrderList';

export type CreateSalesOrderValuesSP = Omit<ISalesOrder, 'Lines'> & {
  Lines: Partial<ISalesOrderLine & IPickSlipLine & IConsignmentNotePackageLine>[];
  CompanyName?: string;
  DefaultLocation?: ILocation;
  customerData: any;
  Locations?: ILocation[];
};

const getActiveStep = (values: CreateSalesOrderValuesSP): number => {
  const pickslipStatus =
    values.LinkedTrans?.filter(linkedTran => linkedTran.DatTypeID === DatTypes.PickSlip).map(
      linkedTran => linkedTran.Status,
    ) || [];
  const conNoteStatus =
    values.LinkedTrans?.filter(linkedTran => linkedTran.DatTypeID === DatTypes.ConsignmentNote).map(
      linkedTran => linkedTran.Status,
    ) || [];

  if (values.Status === SALES_ORDER_STATUS.FINALISED) {
    return 1;
  } else if (
    values.Status === SALES_ORDER_STATUS.APPROVED &&
    pickslipStatus.every(status => status === PICK_SLIP_STATUS.READY_TO_PICK)
  ) {
    return 1;
  } else if (
    pickslipStatus.every(status => status !== PICK_SLIP_STATUS.COMPLETE) &&
    conNoteStatus.every(status => status === CONSIGNMENT_NOTE_STATUS.OPEN)
  ) {
    return 2;
  } else if (
    pickslipStatus.every(status => status === PICK_SLIP_STATUS.COMPLETE) &&
    conNoteStatus.every(status => status === CONSIGNMENT_NOTE_STATUS.OPEN)
  ) {
    return 3;
  } else if (
    pickslipStatus.every(status => status === PICK_SLIP_STATUS.COMPLETE) &&
    conNoteStatus.every(status => status === CONSIGNMENT_NOTE_STATUS.OPEN)
  ) {
    return 4;
  } else if (
    pickslipStatus.every(status => status === PICK_SLIP_STATUS.COMPLETE) &&
    conNoteStatus.some(status => status === CONSIGNMENT_NOTE_STATUS.OPEN)
  ) {
    return 5;
  } else if (conNoteStatus.every(status => status === CONSIGNMENT_NOTE_STATUS.APPROVED)) {
    return 6;
  } else if (conNoteStatus.every(status => status === CONSIGNMENT_NOTE_STATUS.AWAITING_PICKUP)) {
    return 7;
  } else if (values.Status === SALES_ORDER_STATUS.CLOSED) {
    return 8;
  } else {
    return 0;
  }
};

export const SalesOrderCustomerForm = (
  props: FormikProps<CreateSalesOrderValuesSP> & {
    selectedCustomer: Partial<ICustomer> | null | undefined;
    setSelectedCustomer: any;
    createSalesOrder?: boolean;
    accountDetails?: any;
    customerInvoices?: any;
  },
) => {
  const {
    values,
    touched,
    errors,
    selectedCustomer,
    setSelectedCustomer,
    createSalesOrder,
    setFieldValue,
    handleChange,
    setValues,
    customerInvoices,
    accountDetails,
  } = props;

  const {getCustomer, updateCustomer, getcustomerStatements} = useCustomersAPI();
  const {getCustomersList} = useLoadCustomers(false);
  const {loading: customerLoader, linkAccountCustomers, linkGenericClass} = useCustomerLinking();

  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [openAccountCustomer, setOpenAccountCustomer] = useState<boolean>(false);
  const [searchGenericFilter, setSearchGenericFilter] = useState<any>();
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();
  const [customerDetails, setCustomerDetails] = useState<ICustomer>();
  const [checked, setChecked] = React.useState(false);
  const [edit, setEdit] = React.useState<boolean>(Boolean(createSalesOrder));
  const [addressType, setAddressType] = useState<string>();
  const [locationDialog, setLocationDialog] = useState<boolean>(false);
  const history = useHistory();

  const handleDialog = () => {
    setLocationDialog(!locationDialog);
  };

  const setCustomer = async (selected: Partial<ICustomer> | null, preserveCustomerDetails: Boolean = false) => {
    let newSelectedCustomer = selected;
    if (newSelectedCustomer?.ID && selected?.ID) {
      newSelectedCustomer = await getCustomer(newSelectedCustomer?.ID);
      setCustomerDetails(newSelectedCustomer);
      const newInvoiceDetails =
        (selected?.AccountCustomerID && (await getcustomerStatements(selected?.AccountCustomerID))) || {};
      newSelectedCustomer.TotalOverdueAmount = newInvoiceDetails?.TotalOverdueAmount;
      newSelectedCustomer.TotalDueAmount = newInvoiceDetails?.TotalDueAmount;
      if (newSelectedCustomer?.AccountCustomerID) {
        const newAccountCustomer = await getCustomer(newSelectedCustomer?.AccountCustomerID);
        newSelectedCustomer.CustomerClass = newAccountCustomer?.CustomerClass;
        newSelectedCustomer.AvailableCreditString = newAccountCustomer?.AvailableCreditString;
        newSelectedCustomer.AvailableCredit = newAccountCustomer?.AvailableCredit;
      }
    }
    let loc = newSelectedCustomer?.Locations?.map((d: ILocation) => ({
      Addresses: d?.Addresses,
      IsActive: d?.IsActive,
      Name: d?.Name,
      IsDefaultLocation: d?.IsDefaultLocation,
      ID: d?.ID,
    }));
    const {ShippingAddress, BillingAddress} = await getLocation(newSelectedCustomer?.Locations || []);
    setValues({
      ...values,
      ...(!preserveCustomerDetails
        ? {
            CustomerID: newSelectedCustomer?.ID,
            CustomerName: newSelectedCustomer?.Name || '',
            ContactEmailAddress: newSelectedCustomer?.PrimaryContactEmailAddress || '',
            ContactPhone: newSelectedCustomer?.PrimaryContactPhone || '',
            CompanyName: newSelectedCustomer?.CompanyName || '',
          }
        : {}),
      ...(newSelectedCustomer?.DefaultLocation?.Addresses?.length
        ? {
            ...getAddressObject(ShippingAddress, 'ShippingAddress'),
            ...getAddressObject(BillingAddress, 'BillingAddress'),
          }
        : {}),
      DefaultLocation: newSelectedCustomer?.DefaultLocation || {},
      Locations: loc || [],
    });
    setSelectedCustomer(newSelectedCustomer);
  };

  const handleUpdateOpCustomer = async (_location: ILocation[]) => {
    if (selectedCustomer?.ID && customerDetails && _location) {
      customerDetails?.AvailableCreditString && delete customerDetails?.AvailableCreditString;
      customerDetails?.AvailableCredit && delete customerDetails?.AvailableCredit;
      customerDetails?.TotalOverdueAmount && delete customerDetails?.TotalOverdueAmount;
      customerDetails?.TotalDueAmount && delete customerDetails?.TotalDueAmount;
      customerDetails?.CustomerClass && delete customerDetails?.CustomerClass;
      customerDetails?.Locations && delete customerDetails?.Locations;
      const res = await updateCustomer(selectedCustomer?.ID, {...customerDetails, Locations: _location}, 13);
      if (res) {
        const custDetails = await getCustomer(selectedCustomer?.ID);
        setCustomer(custDetails, true);
        const selCustomer = {...selectedCustomer};
        selCustomer.Locations = custDetails.Locations || [];
        selCustomer.DefaultLocation = custDetails?.DefaultLocation || {};
        setSelectedCustomer(selCustomer);
      }
    }
  };

  const handleSetAddress = async (_addressID: string, address: IAddress, addressType: string) => {
    setValues({
      ...values,
      ...(addressType === 'ShippingAddress' ? getAddressObject(address, 'ShippingAddress') : {}),
      ...(addressType === 'BillingAddress' ? getAddressObject(address, 'BillingAddress') : {}),
    });
  };

  const handleAddCustomer = () => {
    setOpenCustomer(true);
  };

  const handleCustomerClassFilter = (selected: Partial<ICreateCustomerClassValues> | null) => {
    if (setSearchGenericFilter) {
      setSearchGenericFilter({
        ...omit(searchGenericFilter, ['customerClassID', 'LastRowNumber']),
        ...(selected ? {customerClassID: selected?.ID} : {}),
      });
    }
    setCustomerClassFilter(selected || undefined);
  };

  const linkClick = async (customersId: number, AccountCustomerID: number) => {
    if (!customerLoader) {
      await linkAccountCustomers(customersId, AccountCustomerID);
      getCustomer(values.CustomerID).then(res => {
        if (res) setCustomer(res);
      });
      setOpenAccountCustomer(false);
    }
  };

  const linkGenericCustomer = async (customerId: number, customerClassFilter: number) => {
    if (!customerLoader) {
      await linkGenericClass(customerId, customerClassFilter ? customerClassFilter : 0);
      getCustomer(values.CustomerID).then(res => {
        if (res) setCustomer(res);
      });
      setOpenAccountCustomer(false);
    }
  };

  const handleCustomerDropdown = () => {
    setChecked(prev => !prev);
  };

  const handleMouseDownClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const linkedInvoice = values.LinkedTrans?.find(trans => trans.DatTypeID === DatTypes.Invoice);

  const activeStep = useMemo(() => getActiveStep(values), [values.Status, values.LinkedTrans]);

  const handleBackClick = () => {
    history.push(`${AUTH_SELLING_SALES_ORDER_LIST}`);
  };

  return (
    <>
      <CustomerDialog
        customerId={!createSalesOrder ? values.CustomerID : null}
        open={openCustomer}
        onClose={() => setOpenCustomer(false)}
        setOpenAccountCustomer={setOpenAccountCustomer}
        setCustomer={setCustomer}
      />
      <LinkOperationalCustomerDialog
        setCustomer={setCustomer}
        customerId={values.CustomerID}
        open={openAccountCustomer}
        onClose={() => setOpenAccountCustomer(false)}
        customerLinked={true}
        linkClick={linkClick}
        linkAccountCustomers={linkAccountCustomers}
        setShowLinkOperationalCustomerDialog={setOpenAccountCustomer}
        getCustomersList={getCustomersList}
        getCustomer={getCustomer}
        handleFilter={handleCustomerClassFilter}
        linkGeneric={linkGenericCustomer}
        customerClassFilter={customerClassFilter}
      ></LinkOperationalCustomerDialog>
      {locationDialog && (
        <SelectShippingAddressDialog
          {...props}
          addressType={addressType}
          values={values}
          open={locationDialog}
          onClose={handleDialog}
          customerDetails={customerDetails}
          handleSetAddress={handleSetAddress}
          handleUpdateOpCustomer={handleUpdateOpCustomer}
          isSalesModule={true}
        ></SelectShippingAddressDialog>
      )}
      <div className="relative flex flex-row">
        {values?.Status && values?.Status !== SALES_ORDER_STATUS.OPEN ? (
          <VisibleContent keyPath="salesOrders.customerOrderDetails.stepper">
            <div className="absolute" style={{top: '4px', right: '4px'}}>
              <CustomStepper
                steps={[
                  'Ready To Pick',
                  'In Picking',
                  'Picked',
                  'Ready to Pack',
                  'In Packing',
                  'Packed',
                  'Awaiting Collection',
                  'Completed',
                ]}
                isSalesModule={true}
                activeStep={activeStep}
              />
            </div>
          </VisibleContent>
        ) : null}
        {selectedCustomer && createSalesOrder ? (
          <div
            className={`mr-3 flex w-1/12 flex-col justify-between rounded-lg bg-white p-1.5 font-poppins shadow-[0_0_6px_0_hsl(var(--primary-header))]`}
          >
            <Button
              onClick={() => setCustomer(null)}
              variant="text"
              className="h-full w-full bg-primary/5 px-0 hover:no-underline"
              data-autoid="btnChangeCustomer"
            >
              <div className="flex flex-1 flex-col items-center justify-center">
                <GoBackIcon className="h-11 w-11" />
                <span className="text-base leading-5"> Change Customer</span>
              </div>
            </Button>
          </div>
        ) : null}
        <Accordion
          open={checked}
          animate={{
            mount: {scale: 1},
            unmount: {scale: 0.9},
          }}
        >
          <div
            className={`flex w-full flex-col justify-between rounded-lg bg-white px-5 pb-3 pt-4 font-poppins shadow-[0_0_6px_0_hsl(var(--primary-header))]`}
            id="create-sales-orders"
          >
            <div className="flex flex-row justify-items-start">
              <VisibleContent keyPath="salesOrders.customerOrderDetails.backButton" showContent={false}>
                <Button
                  onClick={handleBackClick}
                  variant="text"
                  className="mr-1 h-full bg-primary/5 py-1 hover:no-underline"
                  data-autoid={`salesOrdersBackButton`}
                >
                  <div className="flex flex-1 flex-col items-center justify-center">
                    <GoBackIcon className="h-7 w-7" />
                    <span className="text-base"> Back</span>
                  </div>
                </Button>
              </VisibleContent>
              <div className="my-auto flex items-center justify-between gap-x-4 text-xl font-medium text-spenda-primarytext">
                {createSalesOrder ? 'Create new sales order' : `Order - ${values.RefNumber || ''}`}{' '}
                {!createSalesOrder ? (
                  <CustomContent
                    keyPath={`salesOrders.customerOrderDetails.status.${values.Status === SALES_ORDER_STATUS.OPEN && values.Reservation?.Status === SalesOrderReservationStatus?.Active ? 'Reserved' : values.Status}`}
                    render={value => <SalesOderStatus status={value} data_autoid={`txt${value}`} />}
                  >
                    <span className="text-warning">{linkedInvoice?.Status === 'Paid' ? 'Paid' : 'Unpaid'}</span>
                  </CustomContent>
                ) : null}{' '}
                {!createSalesOrder ? (
                  <VisibleContent keyPath="salesOrders.customerOrderDetails.editCustomerButton">
                    <Button variant="outlined" className="7px 14px !important" onClick={() => setOpenCustomer(true)}>
                      Edit Customer
                    </Button>
                  </VisibleContent>
                ) : null}
              </div>
              {values?.Status && values?.Status !== SALES_ORDER_STATUS.OPEN && values.CustomerRefTransRefNumber ? (
                <span
                  className="my-auto ml-2 text-xs font-medium text-spenda-primarytext"
                  style={{marginRight: '130px'}}
                >
                  Customer Ref: {values.CustomerRefTransRefNumber || ''}
                </span>
              ) : null}
              {(!isEmpty(values) && !values?.Status) || values?.Status === SALES_ORDER_STATUS.OPEN ? (
                <VisibleContent keyPath="salesOrders.customerOrderDetails.customerRefInput">
                  <div>
                    <Input
                      id="CustomerRefTransRefNumber"
                      name="CustomerRefTransRefNumber"
                      label="Customer Ref"
                      value={values.CustomerRefTransRefNumber}
                      onChange={handleChange}
                      helperText={errors?.CustomerRefTransRefNumber}
                      error={Boolean(errors?.CustomerRefTransRefNumber)}
                    />
                  </div>
                </VisibleContent>
              ) : null}
            </div>
            <VisibleContent keyPath="salesOrders.customerOrderDetails.disclaimer">
              {!selectedCustomer?.AccountCustomerID && values.Status === SALES_ORDER_STATUS.OPEN ? (
                <p className="ml-2.5 text-sm text-error">
                  *The customer is not linked to any account/generic class {accountDetails?.AccountCustomerID}
                </p>
              ) : selectedCustomer?.AccountCustomerID && (customerInvoices?.TotalOverdueAmount || 0) > 0 ? (
                <p className="ml-2.5 text-sm text-error">*Invoices overdue for this customer</p>
              ) : selectedCustomer &&
                selectedCustomer?.AccountCustomerID &&
                (values?.TotalInc || 0) > (accountDetails?.AvailableCredit || 0) ? (
                <p className="ml-2.5 text-sm text-error">*Available credit is less than order Invoice</p>
              ) : null}
            </VisibleContent>
            <Divider
              style={{
                backgroundColor: '#F8F8F8',
                margin: '5px 10px 15px',
              }}
            />
            {!selectedCustomer ? (
              <div className="flex-column flex">
                <div>
                  <CustomerAutocomplete
                    isSalesModule={true}
                    additionalFilter={{isShowAccountCustomersOnly: false}}
                    selected={values.CustomerID ? {ID: values.CustomerID, Name: values.CustomerName} : null}
                    clearClick={() => setCustomer(null)}
                    onSuggestionSelected={(e, selected) => {
                      setCustomer(selected.suggestion);
                    }}
                    helperText={touched?.CustomerName && errors?.CustomerName}
                    error={touched?.CustomerName && Boolean(errors?.CustomerName)}
                    styles={{
                      marginTop: '-1.5rem',
                      border: '1px solid hsl(var(--primary))',
                      '& li': {
                        borderBottom: '1px solid #BBBBBB',
                      },
                    }}
                  />
                </div>
                <div>
                  <IconButton
                    ripple={false}
                    variant="outlined"
                    className="mx-4 rounded-lg border border-primary px-0 text-primary"
                    onClick={handleAddCustomer}
                    data-autoid="btnAddCustomer"
                  >
                    <AddRoundedIcon fontSize="large" />
                  </IconButton>
                </div>
              </div>
            ) : null}
            {selectedCustomer ? (
              <Grid container spacing={1}>
                <Grid container item spacing={1} md={6} alignItems="center">
                  <Grid item md={2}>
                    <label className="text-xs text-spenda-primarytext">Customer Name:</label>
                  </Grid>
                  <Grid item md={4}>
                    <span className="text-xs text-spenda-greytext">{values.CustomerName}</span>
                  </Grid>
                  <Grid item md={2}>
                    <label className="text-xs text-spenda-primarytext">Phone:</label>
                  </Grid>
                  <Grid item md={4} className="relative">
                    {edit ? (
                      <Input
                        id="ContactPhone"
                        name="ContactPhone"
                        label="Phone Number"
                        helperText={touched?.ContactPhone && errors?.ContactPhone ? errors?.ContactPhone : ''}
                        error={touched?.ContactPhone && Boolean(errors?.ContactPhone)}
                        value={values.ContactPhone}
                        onChange={handleChange}
                        InputProp={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                variant="text"
                                onMouseDown={handleMouseDownClear}
                                onClick={() => setFieldValue('ContactPhone', '')}
                                data-autoid="btnClearContactPhone"
                              >
                                <ClearRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <>
                        <span className="text-xs text-spenda-greytext">{values.ContactPhone}</span>
                        {createSalesOrder ? (
                          <IconButton
                            ripple={false}
                            variant="outlined"
                            className="text-#BBBBBB absolute left-36 top-1 mx-4 py-0 text-lg"
                            onClick={() => setEdit(true)}
                            data-autoid="btnEditContactPhone"
                          >
                            <EditRoundedIcon fontSize="inherit" />
                          </IconButton>
                        ) : null}
                      </>
                    )}
                  </Grid>
                  <Grid item md={2}>
                    <label className="text-xs text-spenda-primarytext">Company:</label>
                  </Grid>
                  <Grid item md={4}>
                    <span className="text-xs text-spenda-greytext">{selectedCustomer?.CompanyName}</span>
                  </Grid>
                  <Grid item md={2}>
                    <label className="text-xs text-spenda-primarytext">Sales Channel:</label>
                  </Grid>
                  <Grid item md={4}>
                    <span className="text-xs text-spenda-greytext">{values?.CreatedWith || '-'}</span>
                  </Grid>
                  <Grid item md={2}>
                    <label className="text-xs text-spenda-primarytext">Email:</label>
                  </Grid>
                  <Grid item md={4} className="relative">
                    {edit && createSalesOrder ? (
                      <Input
                        id="ContactEmailAddress"
                        name="ContactEmailAddress"
                        label="Email Address"
                        variant="outlined"
                        helperText={
                          touched?.ContactEmailAddress && errors?.ContactEmailAddress ? errors?.ContactEmailAddress : ''
                        }
                        value={values.ContactEmailAddress}
                        onChange={handleChange}
                        error={touched?.ContactEmailAddress && Boolean(errors?.ContactEmailAddress)}
                        InputProp={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                variant="text"
                                onMouseDown={handleMouseDownClear}
                                onClick={() => setFieldValue('ContactEmailAddress', '')}
                                data-autoid="btnClearContactEmail"
                              >
                                <ClearRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <>
                        <span className="text-xs text-spenda-greytext">{values.ContactEmailAddress}</span>
                        {createSalesOrder ? (
                          <IconButton
                            ripple={false}
                            variant="text"
                            className="text-#BBBBBB' absolute left-36 top-1 mx-4 py-0 text-lg"
                            onClick={() => setEdit(true)}
                            data-autoid="btnEditContactEmail"
                          >
                            <EditRoundedIcon fontSize="inherit" />
                          </IconButton>
                        ) : null}
                      </>
                    )}
                  </Grid>
                  <Grid item md={2}>
                    <label className="text-xs text-spenda-primarytext">Order Date:</label>
                  </Grid>
                  <Grid item md={4}>
                    <span className="text-xs text-spenda-greytext">{values.TransDateString || '-'}</span>
                  </Grid>
                </Grid>
                <Grid container item spacing={1} md={5} className="ml-2">
                  <Grid item md={6} className="relative">
                    <label className="text-xs text-spenda-primarytext">Billing Address:</label>
                    {createSalesOrder ? (
                      <IconButton
                        ripple={false}
                        variant="text"
                        className="hover:none absolute left-36 top-1 mx-4 py-0 text-lg text-spenda-greytext active:bg-transparent"
                        onClick={() => {
                          setAddressType('BillingAddress');
                          handleDialog();
                        }}
                        data-autoid="btnEditBillingAddress"
                      >
                        {!values.BillingAddress?.City &&
                        !values.BillingAddress?.Country &&
                        !values.BillingAddress?.StreetAddress ? (
                          <AddCircleOutlineRoundedIcon fontSize="inherit" />
                        ) : (
                          <EditRoundedIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    ) : null}
                  </Grid>
                  <Grid item md={6} className="relative">
                    <label className="text-xs text-spenda-greytext">Shipping Address:</label>
                    {createSalesOrder ? (
                      <IconButton
                        ripple={false}
                        variant="text"
                        className=" absolute left-36 top-1 mx-4 py-0 text-lg text-spenda-greytext active:bg-transparent"
                        onClick={() => {
                          setAddressType('ShippingAddress');
                          handleDialog();
                        }}
                        data-autoid="btnEditShippingAddress"
                      >
                        {!values.ShippingAddress?.City &&
                        !values.ShippingAddress?.Country &&
                        !values.ShippingAddress?.StreetAddress ? (
                          <AddCircleOutlineRoundedIcon fontSize="inherit" />
                        ) : (
                          <EditRoundedIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    ) : null}
                  </Grid>
                  <Grid item md={6} className="h-full">
                    {props?.errors?.BillingAddress && (
                      <div className="w-max text-xs text-error">
                        <p>Billing Address is required</p>
                      </div>
                    )}
                    <ul className="list-none text-xs text-spenda-labeltext">
                      <li>{values?.BillStreetAddress || values.BillingAddress?.StreetAddress}</li>
                      <li>{values.BillingAddress?.City}</li>
                      <li>{values.BillingAddress?.State}</li>
                      <li>
                        {values.BillingAddress?.Country} {values.BillingAddress?.PostCode}
                      </li>
                    </ul>
                  </Grid>
                  <Grid item md={6} className="relative h-full">
                    {props?.errors?.ShippingAddress && (
                      <div className="w-max text-xs text-error">
                        <p>Shipping Address is required</p>
                      </div>
                    )}
                    <ul className="list-none text-xs text-spenda-labeltext">
                      <li>{values.ShippingAddress?.StreetAddress}</li>
                      <li>{values.ShippingAddress?.City}</li>
                      <li>{values.ShippingAddress?.State}</li>
                      <li>
                        {values.ShippingAddress?.Country} {values.ShippingAddress?.PostCode}
                      </li>
                    </ul>
                  </Grid>
                </Grid>
                <Grid container item spacing={0} md={1}>
                  <div className="flex items-center justify-center">
                    <Button
                      ripple={false}
                      className="!h-[84px] !w-[84px] rounded-md bg-primary/10 text-5xl text-primary hover:bg-primary/10"
                      onClick={handleCustomerDropdown}
                      data-autoid="btnCustomerDetails"
                    >
                      <ArrowDropDownRoundedIcon
                        fontSize="inherit"
                        style={checked ? {transform: 'rotateX(180deg)'} : {}}
                      />
                    </Button>
                  </div>
                </Grid>
                <AccordionBody
                  className="py-0"
                  containerProps={{
                    className: 'w-full',
                  }}
                >
                  <p className="my-1 text-lg font-semibold text-spenda-primarytext">Credit Details</p>
                  {selectedCustomer?.ClassPostingBehaviour === 'Generic' ? (
                    <div>
                      <p className="text-center text-xs font-medium text-spenda-labeltext">
                        No Credit details Found as this customer is linked
                        <br></br>
                        with generic Class
                      </p>
                    </div>
                  ) : null}
                  {selectedCustomer?.ClassPostingBehaviour !== 'Generic' &&
                  selectedCustomer?.ID &&
                  !selectedCustomer?.AccountCustomerID ? (
                    <div>
                      <p className="text-center text-xs font-medium text-spenda-labeltext">
                        No Credit details Found as this customer is not linked
                        <br></br>
                        with any Account/generic Class
                      </p>
                    </div>
                  ) : (
                    <Grid container item spacing={0} md={12}>
                      <Grid item md={2}>
                        <label className="text-xs text-spenda-primarytext">Credit Limit:</label>
                      </Grid>
                      <Grid item md={2}>
                        <span className="text-xs text-spenda-greytext">{accountDetails?.CreditLimitString ?? '-'}</span>
                      </Grid>
                      <Grid item md={2}>
                        <label className="text-xs text-spenda-primarytext">Overdue Invoices:</label>
                      </Grid>
                      <Grid item md={2}>
                        <span className="text-xs text-red-700">
                          {customerInvoices?.TotalOverdueAmount
                            ? PriceFormat(customerInvoices?.TotalOverdueAmount)
                            : '-'}
                        </span>
                      </Grid>
                      <Grid item md={4}>
                        <label className="text-xs text-spenda-primarytext">Trading Terms:</label>
                      </Grid>
                      <Grid item md={2}>
                        <label className="text-xs text-spenda-primarytext">Available Credit:</label>
                      </Grid>
                      <Grid item md={2}>
                        <span className="text-xs text-spenda-greytext">
                          {accountDetails?.AvailableCreditString?.replace(/[{()}]/g, '') || '-'}
                        </span>
                      </Grid>
                      <Grid item md={2}>
                        <label className="text-xs text-spenda-primarytext">Unpaid Invoices:</label>
                      </Grid>
                      <Grid item md={2}>
                        <span className="text-xs text-red-700">
                          {customerInvoices?.TotalDueAmount ? PriceFormat(customerInvoices?.TotalDueAmount) : '-'}
                        </span>
                      </Grid>
                      <Grid item md={4}>
                        <span className="text-xs text-spenda-greytext">
                          {accountDetails?.CustomerClass?.TradingTermDays}{' '}
                          {accountDetails?.CustomerClass?.TradingTermType?.replaceAll('_', ' ') || '-'}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </AccordionBody>
              </Grid>
            ) : null}
          </div>
        </Accordion>
      </div>
    </>
  );
};
