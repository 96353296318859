import useHttp from "../hooks/useHttp"

interface IActionRes {
    isSuccess: boolean;
    message?: string;
    responseCode?: number;
    error?: any[];
}

export const useServiceJobStatusAPI = () => {

    const { POST } = useHttp();

    const setInProgress = async (serviceJobID: number): Promise<IActionRes> => {
        return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/SetAsInProgress`, {}).then((data) => data);
    }

    const setAsPaused = async (serviceJobID: number): Promise<IActionRes> => {
        return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/SetAsPaused`, {}).then((data) => data);
    }

    const setAsCompleted = async (serviceJobID: number): Promise<IActionRes> => {
        return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/Complete`, {}).then((data) => data);
    }

    const setScheduled = async (serviceJobID: number): Promise<IActionRes> => {
        return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/SetAsScheduled`, {}).then((data) => data);
    }

    const setAsReschedule = async (serviceJobID: number): Promise<IActionRes> => {
        return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/SetAsReschedule`, {}).then((data) => data);
    }


    return {
        setInProgress,
        setAsPaused,
        setAsCompleted,
        setScheduled,
        setAsReschedule
    }

}
