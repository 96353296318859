import useHttp from '../hooks/useHttp';
import {IActionRes, IActionResultsPayments} from '../model/ActionResults';
import {
  IQuotesList,
  IQuotes,
  IQuotesPackage,
  IQuoteActionPaginatedResult,
  IQuoteAttachment,
  IQuoteSummaryLines,
  ISendQuoteForApproval,
  ICustomerQuoteDetails,
  IActiveSession,
  IQuantityToggleInQuotePreview,
  IDuplicateQuotePackageParams,
} from '../model/quotes/quotes';
import {ISearchFilter} from '../model/search-filters/SearchFilter';

const useQuotesAPI = () => {
  const {GET, POST, DELETE, isLoading} = useHttp();

  const getQuotes = async (
    searchFilter: ISearchFilter,
  ): Promise<IQuoteActionPaginatedResult<IQuotesList> | undefined> => {
    return GET(`Spenda/Sales/Quotes`, searchFilter).then(data => data.value);
  };

  const getQuoteDetailByInviteCode = async (inviteCode: string): Promise<ICustomerQuoteDetails> => {
    return GET(`Spenda/Sales/QuotePackageInvite/${inviteCode}`, undefined, false, 'json', true).then(
      data => data.value,
    );
  };

  const createQuote = (data: IQuotes): Promise<IQuotes> => {
    return POST('Spenda/Sales/Quotes', data).then((data: IActionResultsPayments<IQuotes>) => data.value);
  };

  const createQuotePackage = (data: IQuotesPackage): Promise<IQuotesPackage> => {
    return POST('Spenda/Sales/QuotePackages', data).then(
      (data: IActionResultsPayments<IQuotesPackage>) => data.value as IQuotesPackage,
    );
  };

  const updateQuotePackage = (data: IQuotesPackage): Promise<IQuotesPackage> => {
    return POST(`Spenda/Sales/QuotePackages/${data.quotePackageID}`, data).then(
      (data: IActionResultsPayments<IQuotesPackage>) => data.value as IQuotesPackage,
    );
  };

  const updateQuote = (data: Partial<IQuotes>): Promise<IQuotes> => {
    return POST(`Spenda/Sales/Quotes/${data.quoteID}`, data).then(
      (data: IActionResultsPayments<IQuotes>) => data.value as IQuotes,
    );
  };

  const acceptQuote = (quoteID: number): Promise<IActionRes> => {
    return POST(`Spenda/Sales/Quotes/${quoteID}/Approve`, {}).then((data: IActionRes) => data);
  };

  const acceptQuoteByInviteCode = (
    quoteGUID: string,
    inviteCode: string,
    approvalSessionGUID: string,
  ): Promise<IActionRes> => {
    return POST(
      `Spenda/Sales/QuotePackageInvite/${inviteCode}/ApproveInvite`,
      {quoteGUID, approvalSessionGUID},
      true,
      false,
      undefined,
      true,
    ).then((data: IActionRes) => data);
  };

  const rejectQuoteByInviteCode = (
    quoteGUID: string,
    inviteCode: string,
    rejectReason?: string,
  ): Promise<IActionRes> => {
    return POST(
      `Spenda/Sales/QuotePackageInvite/${inviteCode}/RejectInvite`,
      {quoteGUID, rejectReason},
      true,
      false,
      undefined,
      true,
    ).then((data: IActionRes) => data);
  };

  const sendLimepayCheckoutToken = (inviteCode: string, paymentToken: string): Promise<IActionRes> => {
    return POST(
      `Spenda/Sales/QuotePackageInvite/${inviteCode}/PaymentToken`,
      {paymentToken},
      true,
      false,
      undefined,
      true,
    ).then((data: IActionRes) => data);
  };

  const rejectQuote = (quoteID: number, rejectReason?: string): Promise<IActionRes> => {
    return POST(`Spenda/Sales/Quotes/${quoteID}/Reject`, {rejectReason}).then((data: IActionRes) => data);
  };

  const getQuotePackage = async (quotePackageID: number): Promise<IQuotesPackage> => {
    return GET(`Spenda/Sales/QuotePackages/${quotePackageID}`).then(data => data.value);
  };

  const deleteQuotePackage = async (quotePackageID: number): Promise<void> => {
    return DELETE(`Spenda/Sales/QuotePackages/${quotePackageID}`).then(data => data.value);
  };

  const deleteQuote = async (quoteID: number): Promise<void> => {
    return DELETE(`Spenda/Sales/Quotes/${quoteID}`).then(data => data.value);
  };

  const getQuoteAttachments = async (quoteID: number): Promise<IQuoteAttachment[]> => {
    return GET(`Spenda/Sales/Quotes/${quoteID}/Attachments`).then(data => data?.value?.attachments);
  };

  const getSummaryLines = async (quoteID: number): Promise<IQuoteSummaryLines[]> => {
    return GET(`Spenda/Sales/Quotes/${quoteID}/QuoteSummary`).then(data => data.value?.quote?.lines);
  };

  const createUpdateSummaryLines = (
    quoteID: number,
    quoteLineIDs: number[],
    description: string,
    quoteLineSummaryID?: string,
  ): Promise<string> => {
    return POST(`Spenda/Sales/Quotes/${quoteID}/QuoteSummaryLines/`, {
      quoteLineIDs,
      description,
      quoteLineSummaryID,
    }).then(data => data.value?.quoteLineSummaryID);
  };

  const revertSummaryLines = (quoteSummaryLineID: string, quoteID: number): Promise<void> => {
    return DELETE(`Spenda/Sales/Quotes/${quoteID}/QuoteSummaryLines`, {
      quoteLinesSummaryIDs: [quoteSummaryLineID],
    }).then(data => data.value);
  };

  const sendQuotePackage = (quotePackageID: number, data: ISendQuoteForApproval): Promise<IActionRes> => {
    return POST(`Spenda/Sales/QuotePackages/${quotePackageID}/SendForApproval`, data).then(data => data.value);
  };

  const requestApprovalCode = (inviteCode: string): Promise<IActionRes> => {
    return POST(
      `Spenda/Sales/QuotePackageInvite/${inviteCode}/SendApprovalCode `,
      {},
      true,
      false,
      undefined,
      true,
    ).then(data => data.value);
  };

  const submitApprovalQuote = (inviteCode: string, approvalCode: string): Promise<IActiveSession> => {
    return POST(
      `Spenda/Sales/QuotePackageInvite/${inviteCode}/SubmitApprovalCode `,
      {approvalCode},
      true,
      false,
      undefined,
      true,
    ).then(data => data.value);
  };

  const showQuantityInPreviewQuote = (data: IQuantityToggleInQuotePreview): Promise<IQuantityToggleInQuotePreview> => {
    return POST(`Spenda/Sales/Quotes/SetShowQuantityInQuote`, data);
  };

  const duplicateQuotePackage = (params: IDuplicateQuotePackageParams): Promise<IQuotesPackage> => {
    return POST(`Spenda/Sales/QuotePackages/${params.quotePackageID}/Copy`, params).then(
      (data: IActionResultsPayments<IQuotesPackage>) => data.value as IQuotesPackage,
    );
  };

  return {
    createQuote,
    getQuotes,
    updateQuote,
    createQuotePackage,
    updateQuotePackage,
    getQuotePackage,
    deleteQuotePackage,
    deleteQuote,
    getQuoteAttachments,
    getSummaryLines,
    createUpdateSummaryLines,
    revertSummaryLines,
    sendQuotePackage,
    acceptQuote,
    rejectQuote,
    getQuoteDetailByInviteCode,
    acceptQuoteByInviteCode,
    rejectQuoteByInviteCode,
    requestApprovalCode,
    submitApprovalQuote,
    showQuantityInPreviewQuote,
    duplicateQuotePackage,
    sendLimepayCheckoutToken,
    isLoading,
  };
};

export default useQuotesAPI;
