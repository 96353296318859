import React from 'react';

import {List, ListItem, ListItemPrefix, Radio, Typography, Upload, Button} from 'spenda-ui-react';

import LoadingIndicator from '../../components/ui/LoadingIndicator';

interface RadioListItemProps {
  id: string;
  name: string;
  label: string;
}

interface SectionProps {
  title: string;
  items: RadioListItemProps[];
}

const RadioListItem: React.FC<RadioListItemProps> = ({id, name, label}) => (
  <ListItem className="p-0">
    <label htmlFor={id} className="flex w-full cursor-pointer items-center px-3 py-2">
      <ListItemPrefix className="mr-3">
        <Radio
          color="primary"
          name={name}
          id={id}
          ripple={false}
          className="hover:before:opacity-0"
          containerProps={{className: 'p-0'}}
        />
      </ListItemPrefix>
      <Typography color="black" className="font-medium">
        {label}
      </Typography>
    </label>
  </ListItem>
);

const Section: React.FC<SectionProps> = ({title, items}) => (
  <div>
    <Typography variant="h3">{title}</Typography>
    <List>
      {items.map((item, index) => (
        <RadioListItem key={index} {...item} />
      ))}
    </List>
  </div>
);

export const CustomisationSettingsForm = () => {
  const validityPeriodItems = [
    {id: 'vertical-list-7-days', name: 'vertical-list', label: '7 days'},
    {id: 'vertical-list-14-days', name: 'vertical-list', label: '14 days'},
    {id: 'vertical-list-21-days', name: 'vertical-list', label: '21 days'},
    {id: 'vertical-list-30-days', name: 'vertical-list', label: '30 days'},
  ];

  const depositAmountItems = [
    {id: 'vertical-list-percentage', name: 'vertical-list', label: 'Percentage'},
    {id: 'vertical-list-flat-fee', name: 'vertical-list', label: 'Flat Fee'},
    {id: 'vertical-list-no-deposit', name: 'vertical-list', label: 'No Deposit'},
  ];

  return (
    <div className="relative w-full ">
      <div className="absolute right-0 flex justify-end	mt-[-50px]">
        <Button type="submit" data-autoid="btnSave">
          Save <LoadingIndicator size="sm" color="#FFF" position={{right: '5px'}} />
        </Button>
      </div>
      <div className="w-full border border-[#ECECEC]" />
      <div className="ml-3 mt-6 flex flex-col gap-4">
        <Section title="Set a default validity period for quotes" items={validityPeriodItems} />
        <Section title="Set a default for the deposit amount" items={depositAmountItems} />
        <div>
          <Typography variant="h3">Upload your own Terms and Conditions</Typography>
          <Typography variant="paragraph">
            This will be attached to the quote package and must be agreed upon by the customer before accepting the
            quote.
          </Typography>
          <Upload
            data-autoid="uploadAttachment"
            label="Drop any additional documents here or click to upload"
            className="my-6 h-full min-h-[114px] max-w-[477px] text-sm font-normal text-black-800"
            multiple
          />
        </div>
      </div>
    </div>
  );
};
