import React, {useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';

import {Layout} from '../../../components/layout/Layout';
import {CatalogueImg} from '../../../assets/svg';
import {CreateCatalogueDialog} from '../../../components/dialog';
import {CatalogueAddingProductOptionsDialog} from '../../../components/dialog/CatalogueAddingProductOptionsDialog';
import {CatalogueDownloadAndUploadSheetDialog} from '../../../components/dialog/CatalogueDownloadAndUploadSheetDialog';

export const CatalogueDashboard = () => {
  const [isShowCreateCatelogueDialog, setIsShowCreateCatelogueDialog] = useState<boolean>(false);
  const [isShowAddingProductOptionsDialog, setIsShowAddingProductOptionsDialog] = useState<boolean>(false);
  const [isShowDownloadAndUploadSheetDialog, setIsShowDownloadAndUploadSheetDialog] = useState<boolean>(false);

  const dashboard = (
    <div className={`flex h-full flex-col items-center justify-center rounded bg-white p-2.5`}>
      <div className="flex w-full max-w-xl flex-col items-center justify-center text-center">
        <CatalogueImg className="mb-6" />
        <Typography variant="h1" className="mb-8 font-light text-black-800">
          Create your first catalogue
        </Typography>
        <Typography variant="h3" className="mb-12 text-[#BFBFBF]">
          Creating a shared catalogue provides consistent, up-to-date product information across all locations. This
          centralised resource provides consistent, up to date product information across all locations, streamlining
          product management.
        </Typography>
        <div className="flex items-center justify-center gap-2.5">
          <Button onClick={() => setIsShowCreateCatelogueDialog(true)}>Create your Catalogue</Button>
        </div>
      </div>
    </div>
  );
  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg font-poppins`}>
      <Layout leftPanel={dashboard} splitWidthType={4} />

      {isShowCreateCatelogueDialog && (
        <CreateCatalogueDialog
          handleCancel={() => setIsShowCreateCatelogueDialog(false)}
          handleContinue={() => {
            setIsShowCreateCatelogueDialog(false);
            setIsShowAddingProductOptionsDialog(true);
          }}
        />
      )}

      {isShowAddingProductOptionsDialog && (
        <CatalogueAddingProductOptionsDialog
          handleCancel={() => setIsShowAddingProductOptionsDialog(false)}
          handleContinue={() => {
            setIsShowAddingProductOptionsDialog(false);
            setIsShowDownloadAndUploadSheetDialog(true);
          }}
        />
      )}

      {isShowDownloadAndUploadSheetDialog && (
        <CatalogueDownloadAndUploadSheetDialog handleCancel={() => setIsShowDownloadAndUploadSheetDialog(false)} />
      )}
    </div>
  );
};
