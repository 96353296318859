import React, {useContext, useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Form, Formik, useFormik} from 'formik';
import {ARTable} from '../AccountsReceivable/ARTable';
import {
  ISupplierManagementData,
  ISupplierPrimaryContact,
  ITransactionsList,
} from '../../model/requires-attention/StagedTransactions';
import {APFooter} from './APFooter';
import {ISearchFilter} from '../../model/search-filters/SearchFilter';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {ISupplier, ISuppliers} from '../../model/supplier/Supplier';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {Toast} from '../../utils/Toast';
import {Countries, DatTypes} from '../../model/constants/Constants';
import {IntegrationContext} from '../../context/IntegrationContext';
import useConnectedSupplierAPI from '../../services/useConnectedSuppliersAPI';
import AppContext from '../../context/app/appContext';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import {Button, Dialog, Radio, SearchInput} from 'spenda-ui-react';
import {CompleteSupplierDetails, initSupplierDetails, ISupplierValues} from './CompleteSupplierDetails';
import {baseSupplierValidationSchema, abnNzbnValidationSchema} from '../../utils/supplierValidation';
import {ArRefreshBtn} from '../arRefresh/ArRefresh';
import useGetTenantDetails from '../../services/useGetTenantDetails';
import {useParams} from 'react-router-dom';

export interface IPostingTypeModelProps {
  handleClose: () => void;
  handleDone: (mergeWithSupplier?: boolean) => Promise<void>;
  selectedTransactionDetail?: ISupplierManagementData;
  selectedSupplierDetail?: ISupplier;
  supplierDetails?: ISupplierPrimaryContact;
  isVendorPostingType?: boolean;
  vendorPostingTypeDetail?: ITransactionsList;
  handleVendorPostAsIs?: (vendorDetails: ITransactionsList) => void;
  scope: 'AP' | 'PSBL';
  isReMapping?: boolean;
}

export const PostingTypeModal = (props: IPostingTypeModelProps) => {
  const {
    handleClose,
    supplierDetails,
    handleDone: handleDoneClick,
    isVendorPostingType,
    vendorPostingTypeDetail,
    selectedTransactionDetail,
    selectedSupplierDetail,
    handleVendorPostAsIs,
    scope,
    isReMapping,
  } = props;

  const isPSBLView = scope === 'PSBL';
  const isAPView = scope === 'AP';

  // Context
  const appContext = useContext(AppContext);
  const {financialAdaptor} = useContext(IntegrationContext);

  // Hooks
  const {tenantCountry} = useGetTenantDetails();
  const isNZTenant = tenantCountry === Countries.NewZealand;
  const {supplierId: sId} = useParams<{supplierId?: string}>();

  // APIs
  const {connectedSuppliersSearch} = useConnectedSupplierAPI();
  const {getSuppliers, isLoading} = useSupplierAPI();
  const {saveSupplier} = useSupplierAPI();
  const {mergeSupplier, postAsItIs, postVendor} = useStagedTransactionsAPI();

  // States
  const [mergeWithSupplier, setMergeWithSupplier] = useState<boolean>(isPSBLView ? true : false);
  const [selectedSupplier, setSelectedSupplier] = useState<ISuppliers>();
  const [searchString, setSearchString] = useState<string>();
  const [supppliers, setSuppliers] = useState<ISuppliers[]>([]);
  const [isPostAsLoading, setIsPostAsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Constant
  const supplierId = Number(sId);
  const vendorName = isReMapping
    ? isVendorPostingType
      ? selectedSupplierDetail?.Name || '-'
      : '-'
    : isVendorPostingType
      ? vendorPostingTypeDetail?.datTypeID === DatTypes.StagedPurchaseInvoice
        ? vendorPostingTypeDetail?.stagedPurchaseInvoice?.vendorName || '-'
        : vendorPostingTypeDetail?.stagedDebitNote?.vendorName || '-'
      : '-';

  const vendorRefNo = isReMapping
    ? isVendorPostingType
      ? selectedSupplierDetail?.RefNumber || '-'
      : '-'
    : isVendorPostingType
      ? vendorPostingTypeDetail?.datTypeID === DatTypes.StagedPurchaseInvoice
        ? vendorPostingTypeDetail?.stagedPurchaseInvoice?.vendorInvoiceRefNumber || '-'
        : vendorPostingTypeDetail?.stagedDebitNote?.vendorDebitNoteRefNumber || '-'
      : '-';

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchString && setSearchString(values.searchText);
    return Promise.resolve();
  };

  const fetchSuppliers = async () => {
    const filterState = {
      StartRow: 1,
      MaxResults: 100,
      SearchString: searchString,
      IsShowWithBSIDOnly: true,
    } as ISearchFilter;
    let supp = await getSuppliers(filterState);
    const filteredSupp = supp?.value?.filter(s => !s.isLinked);
    if (filteredSupp) {
      setSuppliers(filteredSupp);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, [searchString]);

  const setPostingType = async () => {
    try {
      setIsPostAsLoading(true);
      if (mergeWithSupplier) {
        if (!selectedSupplier) {
          Toast.error('Please select supplier');
          setIsPostAsLoading(false);
          return;
        }
        if (isVendorPostingType) {
          await postVendor(supplierDetails?.supplierID!, selectedSupplier?.supplierID, vendorName);
        } else {
          await mergeSupplier(selectedSupplier?.supplierID, supplierDetails?.supplierID!);
          // update connected suppliers
          const suppliers = await connectedSuppliersSearch({});
          appContext.setConnectedSuppliers(suppliers as IConnectedSupplier[]);
        }
        if (isPSBLView) {
          setTimeout(async () => {
            await handleDoneClick?.(mergeWithSupplier);
            setIsPostAsLoading(false);
          }, 2000);
        } else {
          await handleDoneClick?.();
          setIsPostAsLoading(false);
        }
      } else {
        if (isVendorPostingType) {
          // api to be implemented for supplier creation and post as vendor
          handleVendorPostAsIs && handleVendorPostAsIs(vendorPostingTypeDetail!);
        } else {
          // post as it is
          await postAsItIs(supplierDetails?.supplierID!);
          await handleDoneClick?.();
        }
        setIsPostAsLoading(false);
      }
    } catch (error) {
      console.error('error', error);
      setIsPostAsLoading(false);
    }
  };

  // Table Columns
  const columns = [
    {
      title: 'Reference ID',
      key: 'refNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5`,
      isSortable: isPSBLView ? true : false,
      getRowClassConditionally: (r: ISuppliers) =>
        r.supplierID === selectedSupplier?.supplierID && isPSBLView ? '!bg-primary/5' : '',
      rowRenderer: (suppplier: ISuppliers, rowIndex: number) => (
        <span
          data-autoid={`lblReferenceID${rowIndex}`}
          className={`flex font-poppins text-base font-semibold text-black-800`}
        >
          {suppplier?.refNumber}
        </span>
      ),
    },
    {
      title: 'Suppliers',
      key: 'name',
      width: '18%',
      align: 'left',
      isSortable: isPSBLView ? true : false,
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5`,
      getRowClassConditionally: (r: ISuppliers) =>
        r.supplierID === selectedSupplier?.supplierID && isPSBLView ? '!bg-primary/5' : '',
      rowRenderer: (suppplier: ISuppliers, rowIndex: number) => (
        <span data-autoid={`lblSuppliers${rowIndex}`} className="font-poppins text-base font-medium text-black-800">
          {suppplier?.name}
        </span>
      ),
    },
    {
      title: 'ABN/NZBN',
      key: 'abn',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5`,
      getRowClassConditionally: (r: ISuppliers) =>
        r.supplierID === selectedSupplier?.supplierID && isPSBLView ? '!bg-primary/5' : '',

      rowRenderer: (suppplier: ISuppliers, rowIndex: number) => (
        <span
          data-autoid={`lblABN${rowIndex}`}
          className="flex w-full items-center justify-between font-poppins text-base font-medium"
        >
          {suppplier?.abn}
        </span>
      ),
    },
    {
      title: 'Contact number',
      key: 'phoneMobile',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5`,
      getRowClassConditionally: (r: ISuppliers) =>
        r.supplierID === selectedSupplier?.supplierID && isPSBLView ? '!bg-primary/5' : '',

      rowRenderer: (suppplier: ISuppliers, rowIndex: number) => (
        <span data-autoid={`lblContactNumber${rowIndex}`} className="font-poppins text-base font-medium text-black-800">
          {suppplier?.phoneMobile}
        </span>
      ),
    },
  ];

  if (isAPView) {
    columns?.splice(4, 0, {
      title: 'Action',
      key: 'Action',
      width: '10%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: `p-2.5`,
      getRowClassConditionally: (r: ISuppliers) =>
        r.supplierID === selectedSupplier?.supplierID && isPSBLView ? '!bg-primary/5' : '',
      rowRenderer: (suppplier: ISuppliers, rowIndex: number) => (
        <>
          {!selectedSupplier || selectedSupplier?.supplierID !== suppplier?.supplierID ? (
            <span
              data-autoid={`lnkSelectSupplier${rowIndex}`}
              onClick={() => setSelectedSupplier(suppplier)}
              className="cursor-pointer font-poppins text-base font-semibold text-primary "
            >
              Select
            </span>
          ) : (
            selectedSupplier?.supplierID === suppplier?.supplierID && (
              <span
                data-autoid={`lnkUnselectSupplier${rowIndex}`}
                onClick={() => setSelectedSupplier(undefined)}
                className="cursor-pointer font-poppins text-base font-semibold text-spenda-error"
              >
                Unselect
              </span>
            )
          )}
        </>
      ),
    });
  }

  const handleCreateNewSupplier = async (values: ISupplierValues): Promise<void> => {
    try {
      if ((isReMapping && !supplierId) || (!isReMapping && !supplierDetails?.supplierID)) return;
      setIsSubmitting(true);
      const res = await saveSupplier(
        values,
        isVendorPostingType ? undefined : true,
        isVendorPostingType ? undefined : true,
      );

      if (res) {
        const supplierID = isReMapping ? supplierId : supplierDetails?.supplierID;

        if (supplierID) {
          if (isVendorPostingType) {
            if (isReMapping) {
              await postVendor(
                supplierID,
                res.ID,
                vendorName,
                selectedTransactionDetail?.supplierVendorPostingRuleID,
                'update',
              );
            } else {
              await postVendor(supplierID, res.ID, vendorName);
            }
          } else {
            await postAsItIs(supplierID);
          }

          setTimeout(async () => {
            await handleDoneClick?.(mergeWithSupplier);
            setIsSubmitting(false);
          }, 2000);
        } else {
          Toast.error('Supplier ID is missing.');
        }
      }
    } catch (error) {
      console.error('error:', error);
      setIsSubmitting(false);
    }
  };

  const {handleSubmit, ...formikProps} = useFormik({
    validateOnBlur: true,
    initialValues: {
      ...initSupplierDetails,
      RefNumber: isReMapping ? selectedSupplierDetail?.RefNumber : null,
      Name: isReMapping ? selectedSupplierDetail?.Name : isVendorPostingType ? vendorName : supplierDetails?.name,
    },
    onSubmit: handleCreateNewSupplier,
    validationSchema: isNZTenant ? abnNzbnValidationSchema : baseSupplierValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleReMapping = async () => {
    if (selectedTransactionDetail?.supplierVendorPostingRuleID && selectedTransactionDetail?.postingSupplierID) {
      try {
        setIsPostAsLoading(true);
        await postVendor(
          supplierId,
          selectedSupplier?.supplierID ?? 0,
          vendorName,
          selectedTransactionDetail?.supplierVendorPostingRuleID,
          'update',
        );
        setTimeout(async () => {
          await handleDoneClick?.(mergeWithSupplier);
          setIsPostAsLoading(false);
        }, 2000);
      } catch (error) {
        console.error('Error in handleReMapping:', error);
      }
    } else {
      console.error('Required IDs are missing');
    }
  };

  return (
    <Dialog
      open
      handler={() => {}}
      className={`${isPSBLView ? '!h-[754px] !min-h-[754px]' : '!h-[660px] !min-h-[660px]'} relative !w-[942px] !min-w-[942px]`}
    >
      <div className={`spenda-color flex h-full max-w-full items-center justify-center font-poppins text-xl`}>
        <div
          className={`modal-box ${isPSBLView ? '!h-[754px] !min-h-[754px]' : '!h-[660px] !min-h-[660px]'} max-w-[942px] px-2.5 pb-5 pt-2.5 text-spenda-primarytext`}
        >
          <div className={`-mx-5 flex justify-between border-[#CCCCCC] border-b-default px-4 pb-3`}>
            <h2 className={`w-full self-center text-center text-[22px] font-light`}>
              {isPSBLView
                ? isReMapping
                  ? `Edit supplier`
                  : `Select supplier`
                : `Select posting type  (In ${financialAdaptor?.Name})`}
            </h2>
            {isAPView && (
              <div className="flex justify-end text-right">
                <span className="ap-onboarding-close-icon">
                  <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
                </span>
              </div>
            )}
          </div>
          <div className={`relative mx-auto w-full rounded-lg bg-spenda-cream p-5`}>
            <div className="flex flex-row flex-wrap justify-between text-left text-base">
              <div className="mb-6 w-2/6 pr-3">
                <h1 className="text-left font-bold ">
                  {isPSBLView ? 'Supplier' : isVendorPostingType ? 'Vendor' : 'Supplier'}
                </h1>
                <p className=" font-medium ">{isVendorPostingType ? vendorName : supplierDetails?.name}</p>
              </div>
              <div className="mb-6 w-2/6 px-3">
                <h1 className="text-left font-bold ">Contact number</h1>
                <p className=" font-medium ">{isVendorPostingType ? '-' : supplierDetails?.phoneMobile}</p>
              </div>
              <div className="mb-6 w-2/6 pl-3">
                <h1 className="text-left font-bold ">Email</h1>
                <p className=" font-medium ">{isVendorPostingType ? '-' : supplierDetails?.emailAddress}</p>
              </div>
              <div className="w-2/6 pr-3">
                <h1 className="text-left font-bold ">ABN</h1>
                <p className=" font-medium ">
                  {isReMapping ? selectedSupplierDetail?.ABN || '-' : isVendorPostingType ? '-' : supplierDetails?.abn}
                </p>
              </div>
              <div className="w-2/6 px-3">
                <h1 className="text-left font-bold ">Reference ID</h1>
                <p className=" font-medium ">
                  {isReMapping
                    ? vendorRefNo
                    : isPSBLView
                      ? '-'
                      : isVendorPostingType
                        ? vendorRefNo
                        : supplierDetails?.refNumber || '-'}
                </p>
              </div>
              <div className="w-2/6 pl-3"></div>
            </div>
          </div>
          <div className={`my-6 flex flex-row px-3 text-base font-medium`}>
            <p className="mt-3 whitespace-nowrap font-medium">Choose their posting behaviour</p>

            <div className="ml-10 flex flex-row text-left">
              <div
                className={`flex !flex-row flex-wrap justify-start gap-x-1.5 ${isPSBLView && '!flex-row-reverse justify-end'}`}
              >
                <Radio
                  name="0"
                  label={
                    <span className="ml-2 font-poppins text-base font-semibold">{`${
                      isVendorPostingType
                        ? `Create new supplier (${vendorName})`
                        : `Create new supplier (${supplierDetails?.name})`
                    }`}</span>
                  }
                  checked={!mergeWithSupplier}
                  data-autoid="rbtnCreateNewSupplier"
                  onChange={() => {
                    setSelectedSupplier(undefined);
                    setMergeWithSupplier(false);
                  }}
                  color="primary"
                />
                <Radio
                  name="1"
                  label={
                    <span className="ml-2 font-poppins text-base font-semibold">{`${
                      isVendorPostingType ? 'Post as existing supplier' : 'Merge with existing supplier'
                    }`}</span>
                  }
                  checked={mergeWithSupplier}
                  data-autoid="rbtnExistingSupplier"
                  onChange={() => {
                    setSelectedSupplier(undefined);
                    setMergeWithSupplier(true);
                  }}
                  color="primary"
                />
              </div>
            </div>
          </div>
          {mergeWithSupplier && (
            <div className="pr-2">
              <Formik initialValues={{searchText: searchString as string}} onSubmit={onSubmitSearch}>
                {formik => (
                  <Form className={`flex flex-row-reverse justify-end gap-7`}>
                    {isPSBLView && (
                      <ArRefreshBtn
                        dataAutoID="btnSupplierRefresh"
                        datTypeId={DatTypes.Suppliers}
                        handleRefreshData={fetchSuppliers}
                      />
                    )}
                    <div className="!m-0 flex flex-row justify-end">
                      <SearchInput
                        data-autoid={'txtSearchText'}
                        name="searchText"
                        id="searchText"
                        className="!w-[380px]"
                        value={formik?.values?.searchText}
                        {...formik}
                        onChange={e => {
                          formik.setFieldValue('searchText', e?.target?.value);
                        }}
                        autoComplete="off"
                        clearIcon
                        clearIconProps={{
                          onClick: () => {
                            formik.setFieldValue('searchText', '');
                            setSearchString('');
                          },
                        }}
                        iconButtonProps={{
                          name: 'Search',
                          onClick: formik.submitForm,
                        }}
                        autoFocus
                        expanded
                        placeholder="Search by name, phone or address"
                        reverse={true}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="mt-2 flex w-[349px] text-left  font-poppins text-xs text-[#cccccc]">
                <p>{`*This will search for all the existing ${
                  isVendorPostingType ? 'vendors' : 'suppliers'
                } in your financial system.`}</p>
              </div>
              <div
                className={`mt-6 flex ${isPSBLView ? 'max-h-[260px]' : 'max-h-[150px]'} flex-col font-poppins text-lg font-medium text-[#333333]`}
              >
                <div className={`mb-2.5 overflow-y-auto ${isPSBLView ? 'relative min-h-[260px]' : ''}`}>
                  <ARTable
                    isHighlightRowOnHover
                    tableClass="mb-0 !mx-0"
                    rows={supppliers}
                    isLoading={isLoading}
                    columns={columns}
                    scope="AP"
                    onRowClick={(s: ISuppliers) => setSelectedSupplier(s)}
                    getRowDataAutoId={(rowData: ISuppliers) => `rowLineItem-${rowData?.supplierID}`}
                  />
                </div>
              </div>
            </div>
          )}
          {!mergeWithSupplier && isPSBLView && (
            <div className="max-h-[340px] overflow-y-auto">
              <CompleteSupplierDetails {...formikProps} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
            </div>
          )}
          {isPSBLView ? (
            <div className="absolute bottom-2.5 flex h-[60px] w-[922px] flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-2.5">
              <Button
                type="button"
                data-autoid={`btnCancel`}
                variant="outlined"
                className="!bg-white"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                loading={isPostAsLoading || isSubmitting}
                onClick={() => {
                  if (isReMapping && mergeWithSupplier) {
                    handleReMapping();
                  } else if (!mergeWithSupplier) {
                    handleSubmit();
                  } else {
                    setPostingType();
                  }
                }}
                type="button"
                data-autoid={`btnContinue`}
                variant="filled"
                color="primary"
              >
                {isReMapping ? 'Save' : 'Continue'}
              </Button>
            </div>
          ) : (
            <APFooter
              isCancelButton={true}
              buttonLabel={'Done'}
              onNextClick={() => {
                setPostingType();
              }}
              handleCancelClick={handleClose}
              isT2TSupplierModule={true}
              conditionallyClass={true}
              APFooterClass="!bottom-[10px] !left-[10px]"
              style={{width: 'calc(100% - 20px)'}}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
