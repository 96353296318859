import React, {useEffect, useMemo} from 'react';
import clsx from 'clsx';
import {TableSortLabel} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {css} from 'glamor';
import LoadingIndicator from '../ui/LoadingIndicator';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import _ from 'lodash';

const sortLabel = css({
  '& .MuiTableSortLabel-root': {
    paddingRight: '15px',
    color: '#999999 !important',
    '& .MuiTableSortLabel-icon': {
      margin: '0px -7px 0px 0px',
      top: 0,
      position: 'absolute',
      right: '0',
      opacity: '1 !important',
      fontSize: '20px',
      '&.MuiTableSortLabel-iconDirectionAsc': {
        color: '#999 !important',
        transform: 'rotate(0deg)',
      },
      '&.MuiTableSortLabel-iconDirectionDesc': {
        color: '#999 !important',
        transform: 'rotate(0deg)',
      },
    },
    '&.MuiTableSortLabel-active': {
      color: '#999999 !important',
      '& .MuiTableSortLabel-iconDirectionDesc': {
        color: '#1c78ad!important',
      },
      '& .MuiTableSortLabel-iconDirectionAsc': {
        color: '#1c78ad!important',
        transform: 'rotate(180deg)',
      },
    },
  },
});

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

// Stable sort means that it won't change the relative order of elements that are equal.
// This is important because if you were to sort a list of items by price, and two items had the same price, their relative order would change every time you sorted.
// So, we "stabilize" the array by giving each element an index, and then sorting by that index as a tiebreaker.
// See https://stackoverflow.com/a/12646864/2405537 for more info.
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return stabilizedThis.map(el => el[0]);
}

type Order = 'asc' | 'desc';

export interface IARColumns {
  key: string;
  width?: string;
  columClassName?: string;
  rowClassName?: string;
  isSortable?: boolean;
  title?: string;
  align?: string;
  rowRenderer: (rowData: any, rowIndex: number, columnIndex: number) => JSX.Element;
  headerRenderer?: () => JSX.Element;
  getRowClassConditionally?: (rowData: any, rowIndex: number) => string;
}

interface IARTableProps {
  dataAutoId?: string;
  isLoading: boolean;
  columns: IARColumns[];
  rows: any[];
  isNestedTable?: boolean;
  renderNestedTable?: (
    row: any,
    order: 'asc' | 'desc',
    orderBy: string,
    isPaginated?: boolean,
  ) => JSX.Element | undefined;
  order?: 'asc' | 'desc';
  orderBy?: string;
  tableClass?: string;
  isPaginated?: boolean;
  scope?: 'AR' | 'AP';
  getRowDataAutoId?: (rowData: any, rowIndex: number) => string;
  onRowClick?: (rowData: any) => void;
  emptyDataMessage?: React.ComponentType<{}>;
  isHighlightRowOnHover?: boolean;
}

interface IARPaginationFilter {
  page: number;
  pageSize: number;
  totalPages: number;
}

export const ARTable = (props: IARTableProps) => {
  const {
    columns,
    dataAutoId,
    rows,
    isLoading,
    isNestedTable,
    renderNestedTable,
    tableClass,
    order: pOrder,
    orderBy: pOrderBy,
    isPaginated,
    scope,
    getRowDataAutoId,
    onRowClick,
    emptyDataMessage: EmptyDataMessage,
    isHighlightRowOnHover,
  } = props;

  // Sorting state
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');

  // Pagination state
  const [arPaginationFilter, setARPaginationFilter] = React.useState<IARPaginationFilter>({
    page: 1,
    pageSize: 50,
    totalPages: 1,
  });

  useEffect(() => {
    setOrder(pOrder || 'asc');
    setOrderBy(pOrderBy || '');

    setARPaginationFilter({...arPaginationFilter, totalPages: Math.ceil(rows?.length / arPaginationFilter.pageSize)});
  }, [pOrderBy, pOrder, rows?.length]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const generateRowDataAutoId = (rowIndex: number, row: any) => {
    if (getRowDataAutoId) {
      return getRowDataAutoId(row, rowIndex);
    }
    if (dataAutoId) {
      return `row${dataAutoId}${row?.refNumber || ''}`;
    } else if (scope === 'AP') {
      return `APTblRow${rowIndex}`;
    } else {
      return `ARTblRow${rowIndex}`;
    }
  };

  const getHeader = useMemo(() => {
    return (
      <tr className="relative w-auto">
        {columns.map((column, index) => (
          <th
            data-autoid={`lblColumn${column.key}${index}`}
            key={`${column.key}column${index}`}
            className={clsx(
              `text-${column?.align || 'center'}`,
              column?.columClassName,
              `${sortLabel}`,
              'sticky top-0 z-10 bg-[#f1f1f1] p-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext',
            )}
          >
            {column?.isSortable ? (
              <TableSortLabel
                IconComponent={ArrowDropDownIcon}
                active={orderBy === column.key}
                direction={orderBy === column.key ? order : 'asc'}
                onClick={createSortHandler(column.key)}
              >
                {column?.headerRenderer ? (
                  column.headerRenderer()
                ) : (
                  <p className={`ml-auto text-${column?.align || 'center'}`}>{column?.title}</p>
                )}
              </TableSortLabel>
            ) : column?.headerRenderer ? (
              column.headerRenderer()
            ) : (
              <p className={`ml-auto text-${column?.align || 'center'}`}>{column?.title}</p>
            )}
          </th>
        ))}
      </tr>
    );
  }, [rows?.length, orderBy, order, columns]);

  const getRows = useMemo(() => {
    if (!rows?.length) return;

    let viewRows = stableSort(rows, getComparator(order, orderBy));

    if (isPaginated) {
      // const startIndex = arPaginationFilter.pageSize * (arPaginationFilter.page - 1);
      const endIndex = arPaginationFilter.pageSize * arPaginationFilter.page - 1;

      if (viewRows?.length) {
        viewRows = viewRows?.slice(0, endIndex);
      }
    }

    return viewRows.map((row, rowIndex) => (
      <React.Fragment key={rowIndex + 'tr'}>
        <tr
          data-autoid={generateRowDataAutoId(rowIndex, row)}
          className={clsx(
            `group relative border-b border-[#f1f1f1]`,
            {'!border-[#EAEAEA]': scope === 'AP'},
            {'group cursor-pointer': onRowClick},
          )}
          onClick={() => onRowClick?.(row)}
        >
          {columns.map((column, columnIndex) => (
            <td
              className={clsx(
                column?.rowClassName,
                column?.getRowClassConditionally && column?.getRowClassConditionally(row, rowIndex),
                `text-${column?.align || 'center'}`,
                {'bg-spenda-cream': columnIndex % 2 === 0 && (scope === 'AR' || !scope)},
                {'bg-spenda-cream': columnIndex % 2 !== 0 && scope === 'AP'},
                {
                  'group-hover:!bg-[#f1f1f1]/80': isHighlightRowOnHover,
                },
                {'cursor-pointer group-hover:bg-[#f1f1f1]/80': onRowClick},
              )}
              key={`${column.key}row${columnIndex}`}
              width={column.width}
            >
              {column.rowRenderer(row, rowIndex, columnIndex)}
            </td>
          ))}
        </tr>
        {renderNestedTable && renderNestedTable(row, order, orderBy, true)}
      </React.Fragment>
    ));
  }, [rows?.length, orderBy, order, columns, arPaginationFilter.page, _.cloneDeep(rows)]);

  const [infiniteRef, {rootRef}] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: isPaginated ? arPaginationFilter.page < arPaginationFilter.totalPages : false,
    onLoadMore: () => {
      setARPaginationFilter({...arPaginationFilter, page: arPaginationFilter.page + 1});
    },
    delayInMs: 250,
  });

  return (
    <>
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <LoadingIndicator isLoading={isLoading} size="md" />
        </div>
      ) : isNestedTable ? (
        <React.Fragment>
          {getRows}
          {/* This rows added for pagination support of inner table */}
          <tr aria-colspan={3}>
            <td colSpan={getRows?.length}>
              <div ref={infiniteRef} style={{minHeight: '20px', position: 'relative'}}>
                <LoadingIndicator
                  size="sm"
                  isLoading={isPaginated ? arPaginationFilter.page < arPaginationFilter.totalPages : false}
                />
              </div>
            </td>
          </tr>
        </React.Fragment>
      ) : (
        <div ref={rootRef} className={`h-full w-full overflow-y-auto px-2.5`}>
          <table
            className={`${tableClass || ''} relative w-full rounded-sm`}
            aria-label="collapsible table"
            data-autoid={`tbl${dataAutoId || ''}`}
          >
            <thead className="sticky top-0 z-[1]">{getHeader}</thead>
            <tbody>{getRows}</tbody>
          </table>
          {/* This div is added for pagination support of the outer table, sentry infiniteRef must be always displayed */}
          <div ref={infiniteRef} style={{minHeight: scope === 'AP' ? 'auto' : '20px', position: 'relative'}}>
            <LoadingIndicator
              size="sm"
              isLoading={isPaginated ? arPaginationFilter.page < arPaginationFilter.totalPages : false}
            />
          </div>
          {!rows?.length && EmptyDataMessage && (
            <div className="flex h-full items-center justify-center">
              <EmptyDataMessage />
            </div>
          )}
        </div>
      )}
    </>
  );
};
