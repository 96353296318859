import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IQuoteAcceptDialog {
  handleCancel: () => void;
  handleAccept: () => void;
  customerView?: boolean;
  quoteVariantsCount?: number;
  isLoading?: boolean;
}

export const QuoteAcceptDialog = (props: IQuoteAcceptDialog) => {
  const {handleCancel, handleAccept, quoteVariantsCount, isLoading} = props;

  return (
    <>
      <AlertDialogSlideV2
        title={'Accept quote'}
        headingTextSize="h2"
        headingClassess="justify-center w-full"
        footerClass="justify-between sm:w-auto w-full"
        dialogClassess="!min-w-[20%] !max-w-[497px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
        dialogBodyClassess="flex justify-center"
        actions={[
          {
            label: 'Cancel',
            action: handleCancel,
            color: 'primary',
            variant: 'outlined',
            disabled: isLoading,
          },
          {
            label: 'Accept Quote',
            action: handleAccept,
            color: 'primary',
            loading: isLoading,
          },
        ]}
      >
        <Typography variant="small" className="w-full max-w-[352px] py-10 text-center font-normal text-black-800">
          {`${
            quoteVariantsCount && quoteVariantsCount > 1
              ? `There are ${quoteVariantsCount} quotes in this package, are you sure you want to accept this one, the remaining
          will be automatically rejected.`
              : `Are you sure you want to accept this quote?`
          }`}
        </Typography>
      </AlertDialogSlideV2>
    </>
  );
};
