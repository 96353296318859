import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {FormikProps} from 'formik';
import {useHistory, useParams} from 'react-router-dom';

import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import QuoteCreateForm, {quoteVariantInitialValues} from '../../components/form/QuoteCreateForm';
import QuoteVariant from '../../components/quote-management/QuoteVariant';
import {ICustomer} from '../../model/customer/Customer';
import {CustomerDialog} from '../../components/dialog/CustomerDialog';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {QuoteDetailsDialog} from '../../components/dialog/QuoteDetailsDialog';
import {IQuoteLines, IQuotePackageDetails, IQuotes, IQuotesPackage} from '../../model/quotes/quotes';
import useQuotesAPI from '../../services/useQuotesAPI';
import {QuoteDeleteDialog} from '../../components/dialog/QuoteDeleteDialog';
import {DeleteQuotePackageDialog} from '../../components/dialog/DeleteQuotePackageDialog';
import {AUTH_SELLING_QUOTES_DETAILS, AUTH_SELLING_QUOTES_LIST} from '../../routes/QuoteManagementRoutes';
import {SelectQuoteCustomerDialog} from '../../components/dialog/SelectQuoteCustomerDialog';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import LoadingOverLay from '../../components/ui/LoadingOverlay';
import {Toast} from '../../utils/Toast';

const CreateQuote = () => {
  const {packageId: quotePackageID} = useParams<{packageId: string}>();

  const [quoteVariantShow, setQuoteVariantShow] = useState<boolean>(true);
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [showQuoteDetailsDialog, setShowQuoteDetailsDialog] = useState<boolean>(false);
  const [showSelectCustomerDialog, setShowSelectCustomerDialog] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
  const [updatedCustomer, setUpdatedCustomer] = useState<ICustomer>();
  const [quotePackageDetails, setQuotePackageDetails] = useState<IQuotePackageDetails>();
  const [quotePackage, setQuotesPackage] = useState<IQuotesPackage>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<number>(-1);
  const [internalNote, setInternalNote] = useState<string>();
  const [selectedVariantTotalAmount, setSelectedVariantTotalAmount] = useState<number>(0);
  const [showDeleteQuote, setShowDeleteQuote] = useState<boolean>(false);
  const [showDeleteQuotePackage, setShowDeleteQuotePackage] = useState<boolean>(false);
  const [deleteQuoteID, setDeleteQuoteID] = useState<number>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isSwitchingVariant, setIsSwitchingVariant] = useState<boolean>(false);
  const [isDuplicatingVariant, setIsDuplicatingVariant] = useState<boolean>(false);
  const [isUpdatingLines, setIsUpdatingLines] = useState<boolean>(false);

  const formRef = useRef<FormikProps<IQuotes>>(null);
  const history = useHistory();
  const {getCustomer} = useCustomersAPI();
  const {updateQuotePackage, getQuotePackage, deleteQuote, createQuote, updateQuote, deleteQuotePackage} =
    useQuotesAPI();

  const handleShowQuoteVariant = (value: boolean) => {
    setQuoteVariantShow(value);
  };

  const updateVariantTotalAmount = (amount: number) => {
    setSelectedVariantTotalAmount(amount);
    setIsUpdatingLines(false);
  };

  const createNewVariant = async () => {
    await handleSelectedQuoteVariant(-1);
    const payload = {
      ...quoteVariantInitialValues,
      quotePackageID: quotePackage?.quotePackageID!,
      name: `Quote ${(quotePackage?.quotes?.length || 0) + 1}`,
    };
    const createdQuote = await createQuote(payload);
    const quotePkg = quotePackage && {...quotePackage};
    quotePkg && quotePkg.quotes?.push(createdQuote);
    setQuotesPackage(quotePkg);
    setSelectedVariant(createdQuote?.quoteID!);
  };

  const duplicateVariant = async (quoteId: number) => {
    await handleSelectedQuoteVariant(-1);
    const exisitngQuoteDetails = quotePackage?.quotes?.find(quote => quote.quoteID === quoteId);
    const newVariantPayload: IQuotes = {
      name: `Quote ${(quotePackage?.quotes?.length || 0) + 1}`,
      quotePackageID: quotePackage?.quotePackageID!,
      description: '',
      isActive: true,
      discountMode: DISCOUNT_MODE.NONE,
      discountString: '',
      discount: 0,
      shipping: 0,
      lines: [],
    };
    newVariantPayload.lines =
      exisitngQuoteDetails?.lines?.map((line: IQuoteLines) => {
        return {
          code: line.code,
          inventoryID: line.inventoryID,
          isActive: true,
          marginMode: DISCOUNT_MODE.NONE,
          quantity: line.quantity,
          sellPriceEx: line.sellPriceEx,
          shortDescription: line.shortDescription,
        };
      }) || [];
    const createdQuote = await createQuote(newVariantPayload);
    const quotePkg = quotePackage && {...quotePackage};
    quotePkg && quotePkg.quotes?.push(createdQuote);
    setQuotesPackage(quotePkg);
    setSelectedVariant(createdQuote?.quoteID!);
    setSelectedVariantTotalAmount(createdQuote?.totalInc || 0);
    setIsDuplicatingVariant(false);
  };

  const fetchQuotePackage = async (quotePackageID: number) => {
    const quotePackages = await getQuotePackage(quotePackageID);
    if (quotePackages?.isSent) {
      history.push(`${AUTH_SELLING_QUOTES_DETAILS}${quotePackageID}`);
    }
    setQuotesPackage(quotePackages);
    const selectedCustomer = await getCustomer(quotePackages.customerID!);
    if (quotePackages?.quotes && quotePackages?.quotes?.length > 0 && selectedVariant === -1) {
      setSelectedVariant(quotePackages?.quotes[0]?.quoteID!);
      setSelectedVariantTotalAmount(quotePackages?.quotes[0]?.totalInc || 0);
    }
    setSelectedCustomer(selectedCustomer);
    setQuotePackageDetails({
      validFor: quotePackages.expiryDate!,
      assignQuoteToUserID: quotePackages.assignedToUserID!,
      expireInDays: quotePackages.expiresInDays,
    });
    setInternalNote(quotePackages.internalNote);
    setIsLoading(false);
    if (moment(quotePackages?.expiryDate).isBefore(moment(), 'day') && quotePackages?.isActive) {
      setShowQuoteDetailsDialog(true);
    }
  };

  const handleDeleteQuote = async (quoteID?: number) => {
    if (deleteQuoteID || quoteID) {
      const id = deleteQuoteID || quoteID;
      await deleteQuote(id!);
      const filteredQuotes = quotePackage?.quotes?.filter(quote => quote.quoteID !== id);
      const quotePkg = quotePackage && {...quotePackage, quotes: filteredQuotes};
      setQuotesPackage(quotePkg);
      setSelectedVariant(quotePkg?.quotes?.[0]?.quoteID || -1);
      setSelectedVariantTotalAmount(quotePkg?.quotes?.[0]?.totalInc || 0);
      setIsDeleting(false);
      setDeleteQuoteID(undefined);
      setShowDeleteQuote(false);
    }
  };

  const handleDeletePackage = async () => {
    setIsDeleting(true);
    await deleteQuotePackage(quotePackage?.quotePackageID!);
    setShowDeleteQuotePackage(false);
    setIsDeleting(false);
    setDeleteQuoteID(undefined);
    history.push(AUTH_SELLING_QUOTES_LIST);
  };

  useEffect(() => {
    if (quotePackageID) {
      setIsLoading(true);
      // get quote package details
      fetchQuotePackage(Number(quotePackageID));
    }
  }, [quotePackageID]);

  const _createQuote = selectedVariant && (
    <QuoteCreateForm
      quotePackage={quotePackage}
      setQuotesPackage={setQuotesPackage}
      selectedQuote={selectedVariant}
      handleShowQuoteVariant={handleShowQuoteVariant}
      showQuoteVariant={quoteVariantShow}
      selectedQuoteCustomer={selectedCustomer!}
      expiryDate={quotePackageDetails?.validFor!}
      assignedToUserID={quotePackageDetails?.assignQuoteToUserID!}
      setOpenCustomer={setOpenCustomer}
      setShowQuoteDetailsDialog={value => {
        setShowQuoteDetailsDialog(value);
        setUpdatedCustomer(selectedCustomer);
      }}
      createNewVariant={createNewVariant}
      handleDeleteQuote={handleDeleteQuote}
      formRef={formRef}
      updateVariantTotalAmount={updateVariantTotalAmount}
      setIsUpdatingLines={setIsUpdatingLines}
    />
  );

  const handleSelectedQuoteVariant = async (quoteId: number) => {
    if (formRef?.current?.dirty) {
      // save quote variant
      const updatedQuote: IQuotes = await updateQuote(formRef.current.values);
      const quotePkg = quotePackage && {...quotePackage};
      const index = quotePkg?.quotes?.findIndex(q => q.quoteID == selectedVariant);
      if (index != -1 && quotePkg && quotePkg?.quotes?.length) {
        quotePkg.quotes[index!] = updatedQuote;
        setQuotesPackage(quotePkg);
      }
      setSelectedVariant(quoteId);
    } else {
      setSelectedVariant(quoteId);
    }
    const amount = quotePackage?.quotes?.find(q => q.quoteID == quoteId)?.totalInc || 0;
    setSelectedVariantTotalAmount(amount);
    setIsSwitchingVariant(false);
  };

  const _quoteVariant = quoteVariantShow && selectedVariant && (
    <QuoteVariant
      isSaving={isUpdatingLines}
      isLoading={isSwitchingVariant}
      quotePackage={quotePackage!}
      handleDeleteQuote={(quoteID: number) => {
        setDeleteQuoteID(quoteID);
        if (quotePackage?.quotes && quotePackage?.quotes?.length <= 1) {
          setShowDeleteQuotePackage(true);
          setShowDeleteQuote(false);
        } else {
          setShowDeleteQuote(true);
          setShowDeleteQuotePackage(false);
        }
      }}
      selectedVariant={selectedVariant}
      setSelectedVariant={(quoteId: number) => {
        setIsSwitchingVariant(true);
        handleSelectedQuoteVariant(quoteId);
      }}
      internalNote={internalNote}
      setQuoteVariantShow={() => setQuoteVariantShow(!quoteVariantShow)}
      selectedCustomer={selectedCustomer!}
      setInternalNote={setInternalNote}
      updateQuotePackage={async () => {
        const packageID = parseInt(quotePackageID);
        updateQuotePackage({quotePackageID: packageID!, internalNote});
      }}
      selectedVariantTotalAmount={selectedVariantTotalAmount}
      handleDuplicateQuote={(quoteID: number) => {
        if (quotePackage?.quotes && quotePackage?.quotes?.length > 4) {
          Toast.error('Cannot create more than 5 variants.');
          return;
        }
        setIsDuplicatingVariant(true);
        duplicateVariant(quoteID);
      }}
    />
  );

  const fetchCustomerDetails = async () => {
    const response = await getCustomer(selectedCustomer?.ID!);
    setSelectedCustomer(response);
  };

  return (
    <div className={`relative h-full overflow-hidden bg-primary/5 font-poppins`}>
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} size="md" color="#1C78AD" />
      ) : (
        <>
          {quoteVariantShow ? (
            <LoadingOverLay
              isLoading={isDuplicatingVariant}
              overlayBgColor={'rgba(0, 0, 0, 0.1)'}
              isAPOnboardingV1={true}
            >
              <Layout leftPanel={_quoteVariant} rightPanel={_createQuote} splitWidthType={'Quote'} />
            </LoadingOverLay>
          ) : (
            <Layout leftPanel={_createQuote} splitWidthType={4} />
          )}
        </>
      )}
      {openCustomer && !isLoading && (
        <CustomerDialog
          customerId={selectedCustomer?.ID}
          open={openCustomer}
          onClose={() => {
            setOpenCustomer(false);
          }}
          setOpenAccountCustomer={() => {}}
          setCustomer={setSelectedCustomer}
          isQuoteCustomer
          onCustomerUpdate={() => {
            setOpenCustomer(false);
            fetchCustomerDetails();
          }}
        />
      )}
      {showQuoteDetailsDialog && !isLoading && (
        <QuoteDetailsDialog
          isEdit={true}
          open={showQuoteDetailsDialog}
          handleGoBack={() => {
            setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
          }}
          onChangeCustomer={() => {
            setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
          }}
          quotePackageDetails={quotePackageDetails || {validFor: '', assignQuoteToUserID: 0}}
          handleContinueClick={async (expiryDate, assignedToUserID, days) => {
            setQuotePackageDetails({
              validFor: expiryDate,
              assignQuoteToUserID: assignedToUserID,
              expireInDays: days,
            });
            const packageID = parseInt(quotePackageID);
            const updatedPackage = await updateQuotePackage({
              expiryDate: days ? undefined : expiryDate,
              expiresInDays: expiryDate ? undefined : days,
              assignedToUserID,
              quotePackageID: packageID,
              customerID: updatedCustomer?.ID!,
            });
            setSelectedCustomer(updatedCustomer);
            setQuotesPackage(updatedPackage);
            setShowQuoteDetailsDialog(false);
          }}
        />
      )}
      {showSelectCustomerDialog && !isLoading && (
        <SelectQuoteCustomerDialog
          isEdit={true}
          open={showSelectCustomerDialog}
          handleCancel={() => {
            setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
          }}
          handleConfirm={() => {
            setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
            setShowSelectCustomerDialog(!showSelectCustomerDialog);
          }}
          setSelectedQuoteCustomer={setUpdatedCustomer}
          selectedQuoteCustomer={updatedCustomer!}
        />
      )}
      {showDeleteQuote && deleteQuoteID && (
        <QuoteDeleteDialog
          onCancel={() => {
            setShowDeleteQuote(false);
            setDeleteQuoteID(undefined);
          }}
          deleteQuote={() => {
            setIsDeleting(true);
            handleDeleteQuote();
          }}
          isSubmitting={isDeleting}
        />
      )}
      {showDeleteQuotePackage && (
        <DeleteQuotePackageDialog
          isSubmitting={isDeleting}
          deleteQuotePackage={() => {
            handleDeletePackage();
          }}
          deleteQuote={() => {
            setIsDeleting(true);
            handleDeleteQuote();
          }}
          onCancel={() => setShowDeleteQuotePackage(false)}
          disableQuoteButton={!selectedVariant || selectedVariant === -1}
          showQuoteButton={quotePackage?.quotes! && quotePackage?.quotes?.length > 1}
        />
      )}
    </div>
  );
};

export default CreateQuote;
