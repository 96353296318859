import {IQuoteAttachment, IQuoteLines} from '../quotes/quotes';
import {Actions, DISCOUNT_MODE, PurchaseInvoiceStatusTypes, QuoteStatus} from '../constants/Constants';

export enum ServiceJobStatus {
  Open = 'Open',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Reschedule = 'Reschedule',
  ScheduledTentative = 'ScheduledTentative',
  AwaitingStock = 'AwaitingStock',
}

export enum ServiceJobScope {
  QuoteDetails = 'isQuoteDetails',
  CustomerDetails = 'isCustomerDetails',
  adhocJob = 'adhocJob',
}

export interface IServiceJobLines extends IQuoteLines {
  serviceJobID: number;
  serviceJobLineGUID?: string;
  serviceJobLineID?: number;
  serviceQuoteLineID?: number | null;
  sequenceNumber?: number;
  isEditable?: boolean;
}

export interface IServiceBooking {
  index?: number;
  isActive?: boolean;
  scheduleDate?: string;
  startTime?: string;
  endTime?: string;
  serviceBookingID?: number;
  startDateTime_utc?: string;
  endDateTime_utc?: string;
  action?: Actions;
}

export interface IQuoteLinkedTran {
  createdDateTime_utc: string;
  id: number;
  refNumber: string;
  totalInc: number;
  datTypeID: number;
  isSent?: boolean;
  status?: QuoteStatus | PurchaseInvoiceStatusTypes;
}

export interface IServiceJobTechnician {
  technicianUserID?: number;
  firstName?: string;
  lastName?: string;
  action?: Actions;
}

export interface ITechnician {
  firstName: string;
  lastName: string;
  displayName: string;
  isEnabledForService: boolean;
  isServiceTech: boolean;
  userID?: number;
  userName?: string;
}

export interface ICalendarEvents {
  title: string;
  date: Date;
}

export interface IDailySummaryJob {
  calendarDate: string;
  totalBookedJobHours: number;
  countJobs: number;
}
export interface IServiceJob {
  accountCustomerID?: string;
  accountCustomerName?: string;
  assetID?: number;
  balance?: number;
  billBusinessLocationAddressID?: string;
  billCity?: string;
  billCountry?: string;
  billPostCode?: string;
  billState?: string;
  billStreetAddress?: string;
  billStreetName?: string;
  billStreetNumber?: string;
  billUnitNumber?: string;
  billingBusinessContactID?: number;
  billingContactEmailAddress?: string;
  billingContactFirstName?: string;
  billingContactLastName?: string;
  billingContactPhone?: string;
  billingPhoneNumber?: string;
  createdByUserID?: number;
  createdByUserName?: string;
  createdDateTime_utc?: string;
  customerID?: number;
  customerName?: string;
  defaultWarehouseID?: string;
  description?: string;
  discount: number | string;
  discountString?: string;
  discountMode?: DISCOUNT_MODE;
  externalNotes?: string;
  invoiceID?: string;
  internalNote?: string;
  isActive?: boolean;
  isDiscountEditable?: boolean;
  isShippingEditable?: boolean;
  isSent?: boolean;
  lines: IServiceJobLines[];
  modifiedByUserID?: number;
  modifiedByUserName?: string;
  modifiedDateTime_utc?: string;
  onSiteBusinessContactID?: string;
  onSiteContactEmailAddress?: string;
  onSiteContactFirstName?: string;
  onSiteContactLastName?: string;
  onSiteContactPhone?: string;
  onSitePhoneNumber?: string;
  primaryTechnicianEmailAddress?: string;
  primaryTechnicianFirstName?: string;
  primaryTechnicianLastName?: string;
  primaryTechnicianUserID?: string;
  primaryTechnicianUserName?: string;
  refNumber?: string;
  refTransRefNumber?: string;
  repUserID?: string;
  repUserName?: string;
  serviceJobID?: number;
  serviceJobGUID?: string;
  serviceQuoteID?: number;
  serviceStatus?: string;
  siteAddress?: string;
  siteBusinessLocationAddressID?: string;
  siteCity?: string;
  siteCountry?: string;
  sitePostCode?: string;
  siteState?: string;
  siteStreetAddress?: string;
  siteStreetName?: string;
  siteStreetType?: string;
  siteUnitNumber?: string;
  shipping: number | string;
  status?: ServiceJobStatus;
  totalEx?: number;
  totalInc?: number;
  totalTax?: number;
  travelTimeBufferInMinutes?: number;
  bookings?: IServiceBooking[];
  technicians?: IServiceJobTechnician[];
  preServiceNote?: string;
  linkedTransactions?: IQuoteLinkedTran[];
  attachments: IQuoteAttachment[];
  signature?: IQuoteAttachment;
  estimatedLabourHours?: number;
  isWaitingForStock?: boolean;
  totalBookedJobHours?: number;
  isCurrentlyActive?: boolean;
}

export enum TravelTimeBuffer {
  NONE = 'None',
  MIN_15 = '15 minutes',
  MIN_30 = '30 minutes',
  MIN_45 = '45 minutes',
  MIN_60 = '1 hour',
}

export interface ISJRequiresAttentionErrors {
  documentID: number;
  datTypeID: number;
  code: string;
  description: string;
  type: string;
}

export interface IRequiresAttentionServiceJob {
  rowNumber: number;
  serviceJobID: number;
  description?: string;
  refNumber: string;
  customerID: number;
  customerName: string;
  totalInc: number;
  totalEx: number;
  totalTax: number;
  balance: number;
  status: ServiceJobStatus;
  createdDateTime_utc: string;
  modifiedDateTime_utc: string;
  isWaitingForStock: boolean;
  primaryTechnicianUserID?: string;
  assetID?: number;
  countBookings: number;
  countTechnicians: number;
  totalJobHours: number;
  totalBillableJobHours: number;
  isSent: boolean;
  errors: ISJRequiresAttentionErrors[];
  estimatedLabourHours?: number;
  primaryTechnicianFirstName: string;
  primaryTechnicianLastName: string;
  bookings?: IServiceBooking[];
  isCurrentlyActive?: boolean;
}

export interface IRequiresAttentionServiceJobRes {
  items: IRequiresAttentionServiceJob[];
  totalRecordCount: number;
}

export enum RequiresAttentionStatus {
  AwaitingStock = 'Awaiting Stock',
  ScheduledTentative = 'Scheduled Tentative',
  Open = 'Open',
  Reschedule = 'Reschedule',
  Cancelled = 'Cancelled',
  Scheduled = 'Scheduled',
  Paused = 'Paused',
  InProgress = 'In Progress',
  Completed = 'Completed',
}

export interface ISchedulerData {
  id: string;
  serviceJobID: number;
  startDate: string;
  endDate: string;
  occupancy?: number;
  title: string;
  subtitle?: string;
  description?: string;
  status?: string;
  quoteId?: number;
  technician?: {
    id: string;
    name: string;
  };
  bufferHeight: number;
  isWaitingForStock?: boolean;
  isSent?: boolean;
}

export interface ITechnicianViewJobs {
  customerID: number;
  customerName: string;
  description: string;
  discount: number;
  discountMode: DISCOUNT_MODE;
  endDateTime_utc: string;
  externalNotes: string;
  internalNote: string;
  primaryTechnicianFirstName: string;
  primaryTechnicianLastName: string;
  primaryTechnicianUserID: number;
  refNumber: string;
  serviceBookingID: number;
  serviceJobID: number;
  serviceQuoteID: number;
  shipping: number;
  startDateTime_utc: string;
  totalEx: number;
  totalInc: number;
  totalBookedJobHours: number;
  totalBillableJobHours: number;
  totalTax: number;
  status: string;
  technicians: Partial<IServiceJobTechnician>[];
  travelTimeBufferInMinutes?: number;
  isWaitingForStock?: boolean;
  isSent?: boolean;
}

export interface ICustomerListResponse {
  items: ICustomerList[];
  moreToGet: boolean;
  totalRecordCount: number;
}

export interface ICustomerList {
  rowNumber: string;
  customerID: number;
  customerName: string;
  companyName: string;
  onSiteContactFirstName: string;
  onSiteContactLastName: string;
  onSiteContactEmailAddress: string;
  onSiteContactPhone: string;
  siteCity: string;
  siteCountry: string;
  sitePostCode: string;
  siteState: string;
  siteStreetName: string;
  abn: string;
  countAssets: number;
  countOpen: number;
  countInProgress: number;
  lastServiceDate: string;
  createdDateTime_utc: string;
}
