import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Textarea, Typography} from 'spenda-ui-react';
import {IOnHoldSelectedRow} from '../templates/InvoiceFromCapricornTemplate';
import {useStagedTransactionsAPI} from '../../../services/useStagedTransactionsAPI';

interface IOnHoldModalProps {
  isOpen: boolean;
  isOnHold: boolean;
  onClose: () => void;
  handleDone: (isSave?: boolean, messageID?: number) => void;
  selectedRows: IOnHoldSelectedRow;
}

export const OnHoldModal = (props: IOnHoldModalProps) => {
  //Props
  const {isOpen, onClose, isOnHold, selectedRows, handleDone} = props;

  //Apis
  const {postOnHold, updateOnHoldNotes} = useStagedTransactionsAPI();

  //States
  const [notesVal, setNotesVal] = useState<string>(selectedRows?.notes || '');
  const [initialNotesVal, setInitialNotesVal] = useState<string>(selectedRows?.notes || '');
  const [isLoading, setIsLoading] = useState({
    onHold: false,
    save: false,
  });

  useEffect(() => {
    setInitialNotesVal(selectedRows?.notes || '');
  }, [selectedRows]);

  const setLoadingState = (key: string, value: boolean) => {
    setIsLoading(prevState => ({...prevState, [key]: value}));
  };

  const placeOnHold = async () => {
    setLoadingState('onHold', true);
    const onHoldData = {
      DatTypeID: selectedRows?.datTypeID,
      MessageID: selectedRows?.messageID,
      Notes: notesVal,
    };
    try {
      await postOnHold(onHoldData);
      handleDone();
    } finally {
      setLoadingState('onHold', false);
    }
  };

  const handleNotesChange = async () => {
    setLoadingState('save', true);
    const onHoldData = {
      MessageID: selectedRows?.messageID,
      Notes: notesVal,
    };
    try {
      await updateOnHoldNotes(onHoldData);
      handleDone(true, selectedRows?.messageID);
    } finally {
      setLoadingState('save', false);
    }
  };

  const handleMatchAndLink = () => {
    handleDone(false);
  };

  const isSaveDisabled = notesVal === initialNotesVal;

  return (
    <ARDialogTemplate
      dialogProps={{size: 'md', open: isOpen}}
      isFullScreen
      header={
        <p className="font-poppins text-xl font-light text-[#333333]">
          {isOnHold ? 'Transaction on hold' : 'Place transaction on hold'}
        </p>
      }
      body={
        <div className="mx-auto my-8 flex max-w-[525px] flex-col justify-between gap-6">
          <Typography
            variant="paragraph"
            className="text-center text-sm font-normal text-gray-800"
            data-autoid={`lblSelectedInvoices`}
          >
            {isOnHold
              ? `This transaction has been placed 'On Hold.' To remove this status, please click 'Match & Link' to either match the transaction to an existing invoice or post it as a new invoice.`
              : `Are you sure you would like to place invoice ${selectedRows?.refNo} on hold? `}
          </Typography>
          <div className="mx-auto">
            <Textarea
              label={isOnHold ? `Notes` : `Please provide a reason for placing this invoice on hold.`}
              displayLength
              maxLength={300}
              className="min-h-[150px]  min-w-[437px] leading-[1.35rem]"
              data-autoid={'txNotes'}
              containerProps={{className: 'pb-[7px]'}}
              value={notesVal}
              onChange={e => setNotesVal(e.target.value)}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          {isOnHold ? (
            <div className="flex flex-row gap-2">
              <Button data-autoid={`btnMatchAndLink`} variant="filled" color="primary" onClick={handleMatchAndLink}>
                Match & Link
              </Button>
              <Button
                data-autoid={`btnSave`}
                variant="filled"
                color="primary"
                disabled={isSaveDisabled}
                loading={isLoading?.save}
                onClick={handleNotesChange}
              >
                Save
              </Button>
            </div>
          ) : (
            <Button
              data-autoid={`btnPlaceonHold`}
              variant="filled"
              color="primary"
              loading={isLoading?.onHold}
              onClick={placeOnHold}
            >
              Place on Hold
            </Button>
          )}
        </div>
      }
    />
  );
};
