import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Typography, Upload} from 'spenda-ui-react';

import {UploadCatalogueIcon} from '../../assets/svg';
import {AUTH_INVENTORY_CATALOGUE_ID} from '../../routes/InventoryRoutes';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';

interface IDownloadAndUploadSheetProps {
  handleCancel?: () => void;
}

export const CatalogueDownloadAndUploadSheetDialog = (props: IDownloadAndUploadSheetProps) => {
  const {handleCancel} = props;

  const history = useHistory();

  const handleUploadDocs = () => {
    history.push(AUTH_INVENTORY_CATALOGUE_ID);
  };

  return (
    <AlertDialogSlideV2
      dialogActionsAlignment="justify-between"
      dialogClassess="!w-full !min-w-[20%] !max-w-[730px]"
      dialogBodyClassess="flex flex-col items-center justify-start gap-y-8 p-10 text-black-800"
      headingTextSize="h1"
      title="Download & upload spreadsheet"
      actions={[
        {
          action: handleCancel,
          label: 'Cancel',
          variant: 'outlined',
        },
        {
          action: handleUploadDocs,
          label: 'Continue',
        },
      ]}
    >
      <Button color="success" data-autoid="btnDownload">
        <div className="flex items-center gap-x-2">
          <UploadCatalogueIcon data-autoid="imgUpload" />
          Download Catalogue
        </div>
      </Button>
      <Upload
        className="h-[133px] !w-full !max-w-[521px] text-sm"
        label="Drop your spreadsheet here or click to upload"
        multiple
        onChange={function Ga() {}}
        data-autoid="uploadDocs"
      />
      <Typography variant="small" className="text-center">
        At any point you can upload additional products, edit product details and add existing product within Spenda so
        don’t worry if your shared catalogue is not yet complete.
      </Typography>
    </AlertDialogSlideV2>
  );
};
