import { useContext, useMemo } from "react";
import { BusinessDetailsFormValues } from "../components/onboarding/BusinessDetails";
import { ILogo, ITenant, LogoTypeEnum } from "../model/Tenant";
import { useAttachmentsAPI } from "./useAttachmentsAPI"
import AppContext from "../context/app/appContext";
import { IAttachmentRequest, IFileUploadResponse, IResponseObject } from "../model/FileDetails";
import { AttachmentTypeEnum } from "../model/constants/Constants";
import { IBaseResponseV3 } from "../model/accounts-receivable/AccountsReceivable";
import { ILocation } from "../model/address/Location";

export default function useGetTenantDetails() {

    const { deleteAttachments, uploadAttachments } = useAttachmentsAPI();
    const { tenant } = useContext(AppContext);

    const getTenantDetails = async (
        businessDetails: BusinessDetailsFormValues,
        initialBusinessDetails: BusinessDetailsFormValues
    ) => {
        const tenantDetails: ITenant = { ...tenant! };
        tenantDetails.Name = businessDetails.companyName;
        tenantDetails.ABN = businessDetails.abn;
        tenantDetails.Phone = businessDetails.companyPhone;
        tenantDetails.WebSiteURL = businessDetails.website;
        tenantDetails.ARContactEmailAddress = businessDetails.arContactEmailAddress;
        tenantDetails.MarketPlacePath = businessDetails.mySpendaUrl ?? tenantDetails.MarketPlacePath;        

        const formData = new FormData();
        const attachments: IAttachmentRequest[] = [];

        const logoTypeIDSet = new Set();
        for (let i = 0; i < tenantDetails.Logos.length; i++) {
            logoTypeIDSet.add(tenantDetails.Logos[i].LogoTypeID);
        }

        const updateLogos = (deleteLogoTypeIDSet: Set<LogoTypeEnum>, logos: ILogo[]) => {
            type Result = {
                logos: ILogo[];
                GUID: string[];
            };
            const result: Result = {
                logos: [],
                GUID: [],
            };
            result.logos = logos.map(logo => {
                if (deleteLogoTypeIDSet.has(`${logo.LogoTypeID}` as LogoTypeEnum)) {
                    if (logo.GUID) result.GUID.push(logo.GUID);
                    return {
                        LogoTypeID: logo.LogoTypeID,
                        ThumbnailURL: '',
                        URI: '',
                        GUID: '',
                    };
                }
                return logo;
            });
            return result;
        };

        const checkValidForDelete = (initialValue?: string, currentValue?: string) => {
            return initialValue !== '' && (currentValue === '' || currentValue?.includes('base64'));
        };

        if (tenant && tenant.Logos) {
            const deleteLogoTypeIDSet = new Set<LogoTypeEnum>();
            if (checkValidForDelete(initialBusinessDetails.companyLogo, businessDetails.companyLogo)) {
                deleteLogoTypeIDSet.add(LogoTypeEnum.Logo);
            }
            if (checkValidForDelete(initialBusinessDetails.companyIcon, businessDetails.companyIcon)) {
                deleteLogoTypeIDSet.add(LogoTypeEnum.Icon);
            }
            if (checkValidForDelete(initialBusinessDetails.companyBanner, businessDetails.companyBanner)) {
                deleteLogoTypeIDSet.add(LogoTypeEnum.Banner);
            }
            if (deleteLogoTypeIDSet.size > 0) {
                const { logos, GUID: attachments } = updateLogos(deleteLogoTypeIDSet, tenantDetails.Logos);
                tenantDetails.Logos = logos;
                try {
                    await deleteAttachments(AttachmentTypeEnum.TenantManagementLogos, attachments);
                } catch (e) {
                    console.error(e);
                    return;
                }
            }
        }

        for (let key in businessDetails.uploadedFiles) {
            const file = businessDetails.uploadedFiles[key as keyof BusinessDetailsFormValues['uploadedFiles']];
            if (file instanceof File) {
                formData.append(key, file, file.name);
                attachments.push({
                    Caption: file.name,
                    Note: file.name,
                    Action: 0,
                    LogoTypeID: LogoTypeEnum[key.substring(7) as keyof typeof LogoTypeEnum],
                });
            }
        }

        let responseObject: IResponseObject[] = [];

        const saveAttachments = async (attachments: IAttachmentRequest[], formData: FormData) => {
            const jsonRequest = {
                Attachments: attachments || [],
            };

            formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.TenantManagementLogos));
            formData.append('JsonRequest', JSON.stringify(jsonRequest));

            return uploadAttachments(formData, { 'content-type': 'multipart/form-data' }).then(
                (response: IBaseResponseV3<IFileUploadResponse>) => {
                    return response;
                }
            );
        };

        if (attachments.length > 0) {
            try {
                const savedAttachment = await saveAttachments(attachments, formData);
                if (savedAttachment.isSuccess) {
                    responseObject = savedAttachment.value.responseObject;
                    responseObject.forEach(response => {
                        if (logoTypeIDSet.has(response.logoTypeID)) {
                            tenantDetails.Logos = tenantDetails.Logos.map(logo => {
                                if (logo.LogoTypeID == response.logoTypeID) {
                                    return {
                                        LogoTypeID: response.logoTypeID,
                                        ThumbnailURL: response.thumbnailUri,
                                        URI: response.uri,
                                        GUID: response.mediaGUID,
                                    };
                                }
                                return logo;
                            });
                        } else {
                            tenantDetails.Logos.push({
                                LogoTypeID: response.logoTypeID,
                                ThumbnailURL: response.thumbnailUri,
                                URI: response.uri,
                                GUID: response.mediaGUID,
                            });
                        }
                    });
                }
            } catch (e) {
                console.error(e);
                return;
            }
        }
        return tenantDetails;
    };

    // Utility function to get the default location
    const getDefaultLocation = () => {
        return tenant?.Locations?.find(location => location.IsDefaultLocation) || null;
    };

    // Utility function to get the default billing address
    const getBillingAddress = (location: ILocation) => {
        return location?.Addresses?.find(
            address => address.IsActive && address.IsDefaultBilling
        ) || null;
    };

    // Memoized value for the tenant's country
    const tenantCountry = useMemo(() => {
        const defaultLocation = getDefaultLocation();
        const billingAddress = defaultLocation ? getBillingAddress(defaultLocation) : null;

        if (!billingAddress) return null;

        return billingAddress.Country;

    }, [tenant]);

    return {
        getTenantDetails,
        tenantCountry
    }
}
