import React from 'react';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from 'spenda-ui-react';
import {Layout} from '../../components/layout/Layout';
import {BrushIcon} from '../../assets/svg';
import {CustomisationSettingsForm} from './CustomisationSettings';

export const QuoteSettings = () => {
  const tabData = [
    {
      label: 'Customisation',
      value: 'customisation',
      icon: <BrushIcon />,
      desc: <CustomisationSettingsForm />,
    },
  ];

  const _serviceDetails = (
    <Tabs value="customisation" orientation="vertical" className="h-full gap-2">
      <div className="h-[calc(100vh-120px)] min-w-[180px] bg-white">
        <TabsHeader
          className="w-full bg-transparent px-0 pt-4"
          indicatorProps={{
            className: 'bg-primary/10 rounded-none',
          }}
        >
          {tabData.map(({label, value, icon}) => (
            <Tab key={value} value={value} className="justify-start p-4 text-left font-medium">
              <div className="flex items-center justify-start gap-4">
                {icon}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody>
        {tabData.map(({value, desc}) => (
          <div key={value} className={`h-full w-full bg-white px-5 py-6 font-poppins shadow-md rounded-md`}>
            <TabPanel value={value}>
              <h2 className={`font-light text-spenda-primarytext text-[22px] -mt-4`}>Customisation</h2>
              <div className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2.5">
                {desc}
              </div>
            </TabPanel>
          </div>
        ))}
      </TabsBody>
    </Tabs>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
      <Layout leftPanel={_serviceDetails} splitWidthType={4} />
    </div>
  );
};