import {
  AUTH_SELLING_COLLECT_ROUTE,
  AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE,
  AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE,
  AUTH_SELLING_AR_PAID_INVOICES_ROUTE,
  AUTH_MERCHANT_PORTAL_ROUTE,
  AUTH_TRADE_ACCOUNT_ROUTE,
} from '../routes/AuthenticatedRoutes';
import {
  AUTH_PURCHASING_PURCHASE_INVOICE_LIST,
  AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
} from '../routes/PurchasingRoutes';

import {SVGName} from './../components/ui/SVGIcon';
import {MenuTypes, ModuleTypes} from './../model/constants/Constants';
import {
  IMenuOption,
  IMenuServiceModules,
  IMenuServiceModule,
  ParamentersToSelectMenu,
} from './../model/tenant/TenantModuleDetails';
import {MenuConfig} from '../components/menu/MenuConfig';
import {FeatureFlagKeys} from '../hooks/useFeatureFlags';
import {
  AUTH_INVENTORY_CATALOGUE_DASHBOARD,
  AUTH_INVENTORY_PRODUCTS_LIST,
  AUTH_INVENTORY_PRODUCT_CLASSES_LIST,
  AUTH_INVENTORY_PRODUCT_MANAGEMENT,
} from '../routes/InventoryRoutes';
import {
  AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST,
  AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST,
  AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST,
} from '../routes/CustomerRoutes';
import {AUTH_SELLING_SALES_ORDER_LIST} from '../routes/SalesOrderRoutes';
import {
  AUTH_BUYING_AP_APPROVE_REQUESTS,
  AUTH_BUYING_AP_AUTHORISE_PAYMENT,
  AUTH_BUYING_AP_BATCH,
  AUTH_BUYING_AP_REPORTS,
  AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE,
  AUTH_BUYING_AP_USER_SETTINGS,
  AUTH_PURCHASING_PAY_BILL_ROUTE,
  AUTH_PURCHASING_PAY_ROUTE,
} from '../routes/AccountsPayableRoutes';
import {AUTH_BUYING_SUPPLIERS} from '../routes/SupplierManagementRoutes';
import {AUTH_SELLING_QUOTES_LIST, AUTH_SELLING_QUOTES_SETTINGS} from '../routes/QuoteManagementRoutes';
import {
  AUTH_SELLING_SERVICE_CUSTOMERS,
  AUTH_SELLING_SERVICE_DASHBOARD,
  // AUTH_SELLING_SERVICE_REPORTS,
  // AUTH_SELLING_SERVICE_SETTINGS,
} from '../routes/ServiceManagementRoutes';
import {isWebsiteId17} from '../config';

const spendaCollectConfig = MenuConfig.find(config => config.ModuleID === ModuleTypes.SpendaCollectAR);
const SellingMenu: Partial<IMenuServiceModules> = {
  Description: 'Selling',
  MenuType: MenuTypes.Selling,
  Modules: [
    {
      ModuleID: ModuleTypes.SpendaMarketSelling,
      MenuItems: [
        {
          Parent: {
            ID: 1,
            Name: spendaCollectConfig?.Name!,
            Route: spendaCollectConfig?.Route,
            Src: spendaCollectConfig?.Src,
            dataAutoId: 'lnkCollect',
          },
          ModuleDependencies: [ModuleTypes.SpendaCollectAR],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Accounts Receivable',
              Route: AUTH_SELLING_COLLECT_ROUTE,
              Src: SVGName.AR,
              ModuleDependencies: [ModuleTypes.SpendaCollectAR],
              dataAutoId: 'lnkAccountsRecievable',
              keyPath: 'accountsReceivable.menu.accountsReceivable',
            },
            {
              ID: 3,
              Name: 'Payment History',
              Route: AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE,
              Src: SVGName.Reports,
              ModuleDependencies: [ModuleTypes.SpendaCollectAR],
              dataAutoId: 'lnkPaymentHistory',
              FeatureFlags: {[FeatureFlagKeys.scheduledPayments83107]: false},
            },
            {
              ID: 4,
              Name: 'Payments',
              Route: AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE,
              Src: SVGName.Reports,
              ModuleDependencies: [ModuleTypes.SpendaCollectAR],
              dataAutoId: 'lnkPaymentHistory',
              FeatureFlags: {[FeatureFlagKeys.scheduledPayments83107]: true},
              keyPath: 'accountsReceivable.menu.payments',
            },

            {
              ID: 5,
              Name: 'Paid Invoices',
              Route: AUTH_SELLING_AR_PAID_INVOICES_ROUTE,
              Src: SVGName.PaidInvoices,
              ModuleDependencies: [ModuleTypes.SpendaCollectAR],
              dataAutoId: 'lnkPaidInvoices',
              FeatureFlags: {[FeatureFlagKeys.paidInvoicesView89020]: true},
            },
            {
              ID: 6,
              Name: 'Claim Requests',
              Route: AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE,
              queryString: 'filterBy=Active',
              Src: SVGName.AR,
              ModuleDependencies: [ModuleTypes.SpendaCollectAR],
              dataAutoId: 'lnkClaimRequests',
              FeatureFlags: {
                [FeatureFlagKeys.claimAndReturnsV272602]: true,
              },
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.CustomerManagement,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Customer Management',
            Route: AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST,
            Src: SVGName.Customers,
            dataAutoId: 'lnkCustomerManagement',
            isHidden: true,
          },
          ModuleDependencies: [ModuleTypes.CustomerManagement],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Operational Customers',
              Route: AUTH_CUSTOMER_OPERATIONAL_CUSTOMERS_LIST,
              Src: SVGName.OperationalCustomer,
              ModuleDependencies: [ModuleTypes.CustomerManagement],
              dataAutoId: 'lnkOperationalCustomer',
            },
            {
              ID: 2,
              Name: 'Account Customers',
              Route: AUTH_CUSTOMER_ACCOUNT_CUSTOMERS_LIST,
              Src: SVGName.AccountCustomers,
              ModuleDependencies: [ModuleTypes.CustomerManagement],
              dataAutoId: 'lnkAccountCustomer',
            },
            {
              ID: 3,
              Name: 'Customer Classes',
              Route: AUTH_CUSTOMER_CUSTOMERS_CLASSES_LIST,
              Src: SVGName.CustomersClasses,
              ModuleDependencies: [ModuleTypes.CustomerManagement],
              dataAutoId: 'lnkCustomerClasses',
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.SalesOrderManagement,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Sales Orders',
            Route: AUTH_SELLING_SALES_ORDER_LIST,
            Src: SVGName.SalesOrdersManagement,
            dataAutoId: 'lnkSalesOrderManagement',
            isHidden: true,
          },
          ModuleDependencies: [ModuleTypes.SalesOrderManagement],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Sales Orders',
              Route: AUTH_SELLING_SALES_ORDER_LIST,
              Src: SVGName.SalesOrdersListing,
              ModuleDependencies: [ModuleTypes.SalesOrderManagement],
              dataAutoId: 'lnkSalesOrders',
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.Quotes,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Quote Management',
            Route: AUTH_SELLING_QUOTES_LIST,
            Src: SVGName.Quotes,
            dataAutoId: 'lnkQuoteManagement',
            isHidden: true,
          },
          ModuleDependencies: [ModuleTypes.Quotes],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Manage Quotes',
              Route: AUTH_SELLING_QUOTES_LIST,
              Src: SVGName.QuotesListing,
              ModuleDependencies: [ModuleTypes.Quotes],
              dataAutoId: 'lnkManageQuotes',
              FeatureFlags: {[FeatureFlagKeys.quoteManagementV1]: true},
            },
            {
              ID: 2,
              Name: 'Settings',
              Route: AUTH_SELLING_QUOTES_SETTINGS,
              Src: SVGName.Settings,
              ModuleDependencies: [ModuleTypes.Quotes],
              dataAutoId: 'lnkQuoteSettings',
              FeatureFlags: { [FeatureFlagKeys.QuoteV289367]: true },
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.Service,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Service Management',
            Route: AUTH_SELLING_SERVICE_DASHBOARD,
            Src: SVGName.Service,
            dataAutoId: 'lnkServiceManagement',
            isHidden: true,
          },
          ModuleDependencies: [ModuleTypes.Service],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Booking Diary',
              Route: AUTH_SELLING_SERVICE_DASHBOARD,
              Src: SVGName.BookingDiary,
              ModuleDependencies: [ModuleTypes.Service],
              dataAutoId: 'lnkManageService',
              FeatureFlags: {[FeatureFlagKeys.serviceManagementV1]: true},
            },
            {
              ID: 2,
              Name: 'Customers',
              Route: AUTH_SELLING_SERVICE_CUSTOMERS,
              Src: SVGName.ServiceCustomer,
              ModuleDependencies: [ModuleTypes.Service],
              dataAutoId: 'lnkManageServiceCustomers',
              FeatureFlags: {[FeatureFlagKeys.serviceManagementV1]: true},
            },
            // TODO: implement the settings page for service management later
            // {
            //   ID: 3,
            //   Name: 'Settings',
            //   Route: AUTH_SELLING_SERVICE_SETTINGS,
            //   Src: SVGName.Settings,
            //   ModuleDependencies: [ModuleTypes.Service],
            //   dataAutoId: 'lnkManageServiceSettings',
            //   FeatureFlags: {[FeatureFlagKeys.serviceManagementV1]: true},
            // },
            // {
            //   ID: 4,
            //   Name: 'Reports',
            //   Route: AUTH_SELLING_SERVICE_REPORTS,
            //   Src: SVGName.Reports,
            //   ModuleDependencies: [ModuleTypes.Service],
            //   dataAutoId: 'lnkManageServiceReports',
            //   FeatureFlags: { [FeatureFlagKeys.serviceManagementV1]: true },
            // },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.MerchantPortal,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Merchant Portal',
            Route: AUTH_MERCHANT_PORTAL_ROUTE,
            Src: SVGName.MerchantPortal,
            dataAutoId: 'lnkMerchantPortal',
            isHidden: true,
          },
          ModuleDependencies: [ModuleTypes.MerchantPortal],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Merchant Portal',
              Route: AUTH_MERCHANT_PORTAL_ROUTE,
              Src: SVGName.AR,
              ModuleDependencies: [ModuleTypes.MerchantPortal],
              dataAutoId: 'lnkMerchantPortalTab',
              FeatureFlags: {[FeatureFlagKeys.payfacMerchantPortal88470]: true},
            },
          ],
        },
      ],
    },
  ],
};

const BuyingSubMenuItems = [
  {
    ID: 1,
    Name: 'Requires Attention',
    Route: '/supplier/:supplierId/requires-attention',
    Src: SVGName.PSBLRequiresAttention,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkRequiresAttention',
    FeatureFlags: {[FeatureFlagKeys.payStatementByLinkV289938]: true},
  },
  {
    ID: 2,
    Name: 'Purchases',
    Src: SVGName.Order,
    Route: '/supplier/:supplierId/order',
    ModuleDependencies: [ModuleTypes.SpendaMarketBuying],
    AttachSupplierName: false,
    dataAutoId: 'lnkPurchases',
  },
  {
    ID: 3,
    Name: 'Bills',
    Route: '/supplier/:supplierId/bills',
    Src: SVGName.Bills,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    AttachSupplierName: false,
    dataAutoId: 'lnkBills',
  },
  {
    ID: 4,
    Name: 'Payments',
    Route: '/supplier/:supplierId/payment-history',
    Src: SVGName.Reports,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkPaymentHistory',
    FeatureFlags: {[FeatureFlagKeys.scheduledPayments83107]: true},
  },
  {
    ID: 5,
    Name: 'Paid Invoices',
    Route: '/supplier/:supplierId/paid-invoices',
    Src: SVGName.PaidInvoices,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkPaidInvoices',
    FeatureFlags: {[FeatureFlagKeys.paidInvoicesView89020]: true},
  },
  {
    ID: 6,
    Name: 'Claims',
    Route: '/supplier/:supplierId/claim-request',
    Src: SVGName.AR,
    queryString: 'filterBy=Active',
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkClaims',
    FeatureFlags: {
      [FeatureFlagKeys.claimAndReturnsV272602]: true,
      [FeatureFlagKeys.scheduledPayments83107]: true,
    },
  },
  {
    ID: 7,
    Name: 'Payment Options',
    Route: '/supplier/:supplierId/payment-options',
    Src: 'creditCards',
    ModuleDependencies: [ModuleTypes.ConnectedPayments],
    dataAutoId: 'lnkPaymentOptions',
    FeatureFlags: {
      [FeatureFlagKeys.scheduledPayments83107]: false,
    },
  },
  {
    ID: 8,
    Name: 'Payment History',
    Route: '/supplier/:supplierId/payment-history',
    Src: SVGName.Reports,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkPaymentHistory',
    FeatureFlags: {[FeatureFlagKeys.scheduledPayments83107]: false},
  },
  {
    ID: 9,
    Name: 'Make a payment',
    Route: '/supplier/:supplierId/prepayment',
    Src: SVGName.AuthorisePayment,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkPrePayment',
    FeatureFlags: {[FeatureFlagKeys.prepayments72511]: true},
  },
  {
    ID: 10,
    Name: 'Claims',
    Route: '/supplier/:supplierId/claim-request',
    queryString: 'filterBy=Active',
    Src: SVGName.AR,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkClaims',
    FeatureFlags: {
      [FeatureFlagKeys.claimAndReturnsV272602]: true,
      [FeatureFlagKeys.scheduledPayments83107]: false,
    },
  },
  {
    ID: 11,
    Name: 'Payment Options',
    Route: '/supplier/:supplierId/payment-options',
    Src: 'creditCards',
    ModuleDependencies: [ModuleTypes.ConnectedPayments],
    dataAutoId: 'lnkPaymentOptions',
    FeatureFlags: {
      [FeatureFlagKeys.scheduledPayments83107]: true,
      [FeatureFlagKeys.payStatementByLinkV289938]: false,
    },
  },
  {
    ID: 12,
    Name: 'Settings',
    Route: '/supplier/:supplierId/settings',
    Src: SVGName.Settings,
    ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
    dataAutoId: 'lnkLMISettings',
    FeatureFlags: {[FeatureFlagKeys.payStatementByLinkV289938]: true},
  },
];

if (isWebsiteId17) {
  // Find the index of the element with data-autoid = lnkPurchases
  const index = BuyingSubMenuItems.findIndex(item => item.dataAutoId === 'lnkPurchases');
  // Remove the element from the BuyingSubMenuItems array
  BuyingSubMenuItems.splice(index, 1);
}

const BuyingMenu: IMenuServiceModules = {
  Description: 'Buying',
  MenuType: MenuTypes.Selling,
  Modules: [
    {
      ModuleID: ModuleTypes.SpendaMarketBuying,
      MenuItems: [
        {
          ModuleDependencies: [
            ModuleTypes.SpendaMarketBuying,
            ModuleTypes.SpendaCollectConnectedStatements,
            ModuleTypes.ConnectedPayments,
          ],
          SubMenuItems: BuyingSubMenuItems,
        },
      ],
    },
    {
      ModuleID: ModuleTypes.TradeFinance,
      MenuItems: [
        {
          ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements, ModuleTypes.ConnectedPayments],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Statements',
              Route: '/finance/:supplierId/statements',
              Src: SVGName.Bills,
              ModuleDependencies: [ModuleTypes.SpendaCollectConnectedStatements],
              AttachSupplierName: false,
              dataAutoId: 'lnkStatements',
            },
            {
              ID: 1,
              Name: 'Payment Options',
              Route: '/finance/:supplierId/payment-options',
              Src: 'creditCards',
              ModuleDependencies: [ModuleTypes.ConnectedPayments],
              dataAutoId: 'lnkPaymentOptions',
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.SpendaPay,
      MenuItems: [
        {
          Parent: {
            ID: 1,
            Name: 'Accounts Payable',
            Route: AUTH_PURCHASING_PAY_ROUTE,
            Src: SVGName.Pay,
            ModuleDependencies: [ModuleTypes.SpendaPay],
            dataAutoId: 'lnkPay',
          },
          ModuleDependencies: [ModuleTypes.SpendaPay],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Requires attention',
              Route: AUTH_BUYING_AP_REQUIRES_ATTENTION_ROUTE,
              Src: SVGName.RequiresAttention,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkRequireAttention',
              FeatureFlags: {
                [FeatureFlagKeys.T2TPhase280729]: true,
              },
            },
            {
              ID: 2,
              Name: 'Bills',
              Route: AUTH_PURCHASING_PAY_BILL_ROUTE,
              Src: SVGName.Invoices,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkBills',
            },
            {
              ID: 3,
              Name: 'Upload batch',
              Route: AUTH_BUYING_AP_BATCH,
              Src: SVGName.Upload,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkInvoices',
            },
            {
              ID: 4,
              Name: 'Batch approval',
              Route: AUTH_BUYING_AP_APPROVE_REQUESTS,
              Src: SVGName.ApproveRequest,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkApproveRequests',
            },
            {
              ID: 5,
              Name: 'Authorise payments',
              Route: AUTH_BUYING_AP_AUTHORISE_PAYMENT,
              Src: SVGName.AuthorisePayment,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkAuthorisePayment',
            },
            {
              ID: 6,
              Name: 'Reports',
              Route: AUTH_BUYING_AP_REPORTS,
              Src: SVGName.Reports,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkReports',
            },
            {
              ID: 7,
              Name: 'Settings',
              Route: AUTH_BUYING_AP_USER_SETTINGS,
              Src: SVGName.Settings,
              ModuleDependencies: [ModuleTypes.SpendaPay],
              dataAutoId: 'lnkSettings',
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.SpendaBuy,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Purchasing',
            Route: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
            Src: SVGName.Buying,
            dataAutoId: 'lnkPurchasing',
          },
          ModuleDependencies: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Purchase Orders',
              Src: SVGName.PurchaseOrders,
              Route: AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
              ModuleDependencies: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
              AttachSupplierName: false,
              dataAutoId: 'lnkPurchaseOrders',
              FeatureFlags: {[FeatureFlagKeys.purchaseRequisitionsV1]: true},
            },
            {
              ID: 2,
              Name: 'Purchase Invoices',
              Route: AUTH_PURCHASING_PURCHASE_INVOICE_LIST,
              Src: SVGName.PurchaseInvoices,
              ModuleDependencies: [ModuleTypes.SpendaBuy, ModuleTypes.SpendaPOS],
              AttachSupplierName: false,
              dataAutoId: 'lnkPurchaseInvoices',
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.Suppliers,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Supplier Management',
            Route: AUTH_BUYING_SUPPLIERS,
            RouteToMatch: AUTH_BUYING_SUPPLIERS,
            Src: SVGName.AccountReceivables,
            dataAutoId: 'lnkSupplierManagement',
          },
          ModuleDependencies: [ModuleTypes.Suppliers],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Suppliers',
              Route: AUTH_BUYING_SUPPLIERS,
              Src: SVGName.SupplierManagement,
              ModuleDependencies: [ModuleTypes.Suppliers],
              dataAutoId: 'lnkSupplierManagement',
            },
          ],
        },
      ],
    },
    {
      ModuleID: ModuleTypes.TradeAccount,
      MenuItems: [
        {
          Parent: {
            ID: 0,
            Name: 'Transaction History',
            Route: AUTH_TRADE_ACCOUNT_ROUTE,
            Src: SVGName.MerchantPortal,
            dataAutoId: 'lnkTradeAccount',
            isHidden: false,
          },
          ModuleDependencies: [ModuleTypes.TradeAccount],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Transaction History',
              Route: AUTH_TRADE_ACCOUNT_ROUTE,
              Src: SVGName.AR,
              ModuleDependencies: [ModuleTypes.TradeAccount],
              dataAutoId: 'lnkTransactionHistory',
              FeatureFlags: {[FeatureFlagKeys.eCommerceAndTransactionsView89915]: true},
            },
          ],
        },
      ],
    },
  ],
};

const InventoryMenu: Partial<IMenuServiceModules> = {
  Description: 'Inventory',
  MenuType: MenuTypes.Inventory,
  Modules: [
    {
      ModuleID: ModuleTypes.Inventory,
      MenuItems: [
        {
          Parent: {
            ID: 1,
            Name: 'Product Management',
            Route: AUTH_INVENTORY_PRODUCTS_LIST,
            RouteToMatch: AUTH_INVENTORY_PRODUCT_MANAGEMENT,
            Src: SVGName.ProductManagement,
            dataAutoId: 'lnkProductMangment',
          },
          ModuleDependencies: [ModuleTypes.ProductManagement],
          SubMenuItems: [
            {
              ID: 1,
              Name: 'Products',
              Route: AUTH_INVENTORY_PRODUCTS_LIST,
              Src: SVGName.Products,
              ModuleDependencies: [ModuleTypes.ProductManagement],
              dataAutoId: 'lnkProducts',
            },
            {
              ID: 2,
              Name: 'Product Classes',
              Route: AUTH_INVENTORY_PRODUCT_CLASSES_LIST,
              Src: SVGName.ProductClasses,
              ModuleDependencies: [ModuleTypes.ProductManagement],
              dataAutoId: 'lnkProductClasses',
            },
            {
              ID: 3,
              Name: 'Manage Catalogue',
              Route: AUTH_INVENTORY_CATALOGUE_DASHBOARD,
              Src: SVGName.Catalogue,
              ModuleDependencies: [ModuleTypes.ProductManagement],
              dataAutoId: 'lnkManageCatalogue',
              FeatureFlags: { [FeatureFlagKeys.productManagementV2]: true },
            },
          ],
        },
      ],
    },
  ],
};

export interface IUserMenu {
  Selling: {
    Title: string | undefined;
    MenuItems: IMenuOption[] | undefined;
    SubMenuItems: IMenuOption[] | undefined;
    IsSelected: boolean;
    selectedMenu: IMenuOption | undefined;
  };
  Buying: {
    Title: string | undefined;
    MenuItems: IMenuOption[] | undefined;
    SubMenuItems: IMenuOption[] | undefined;
    IsSelected: boolean;
    selectedMenu: IMenuOption | undefined;
  };
  Inventory: {
    Title: string | undefined;
    MenuItems: IMenuOption[] | undefined;
    SubMenuItems: IMenuOption[] | undefined;
    IsSelected: boolean;
    selectedMenu: IMenuOption | undefined;
  };
}

const GetUserMenu = (modules: string | number[], {MenuType, ModuleID, ModuleDependency}: ParamentersToSelectMenu) => {
  // 1. Get menu options to display based on the tenant modules
  let userModules: number[] = [];

  if (modules) {
    userModules = typeof modules === 'string' ? modules.split(',').map(m => parseInt(m)) : modules;
    userModules.push(99);
  }

  const menu: IUserMenu = {
    Selling: {
      Title: 'Selling',
      MenuItems: getMenuItems(MenuService.Selling.Modules, userModules),
      SubMenuItems: undefined,
      IsSelected: false,
      selectedMenu: undefined,
    },
    Buying: {
      Title: 'Buying',
      MenuItems: getMenuItems(MenuService.Buying.Modules, userModules),
      SubMenuItems: undefined,
      IsSelected: false,
      selectedMenu: undefined,
    },
    Inventory: {
      Title: 'Inventory',
      MenuItems: getMenuItems(MenuService.Inventory.Modules, userModules),
      SubMenuItems: undefined,
      IsSelected: false,
      selectedMenu: undefined,
    },
  };

  const getMenuServiceToSelect = (_MenuType: MenuTypes) => {
    switch (_MenuType) {
      case MenuTypes.Selling:
        return MenuService.Selling;
      case MenuTypes.Inventory:
        return MenuService.Inventory;
      default:
        return MenuService.Buying;
    }
  };

  if (MenuType && ModuleID && ModuleDependency) {
    const MenuServiceToSelect = getMenuServiceToSelect(MenuType);

    const SelectedModule = MenuServiceToSelect?.Modules?.find(md => md.ModuleID === ModuleID);

    const SelectedMenuItems = SelectedModule?.MenuItems?.find(mi =>
      mi.ModuleDependencies?.every(md => ModuleDependency?.indexOf(md) >= 0),
    );

    if (SelectedModule && SelectedMenuItems) {
      menu[MenuType].IsSelected = true;
      menu[MenuType].SubMenuItems = SelectedMenuItems.SubMenuItems?.filter(sm =>
        sm.ModuleDependencies?.every(md => userModules?.indexOf(md) >= 0),
      );
      menu[MenuType].selectedMenu = SelectedMenuItems?.Parent;
    }
  }
  return menu;
};

const MenuService = {
  Buying: BuyingMenu,
  Selling: SellingMenu,
  Inventory: InventoryMenu,
  GetUserMenu,
};

const getMenuItems = (Modules: IMenuServiceModule[] | undefined, userModules: number[]) => {
  const MenuItems: IMenuOption[] = [];
  Array.isArray(Modules) &&
    Modules.forEach(module => {
      module.MenuItems.forEach(mi => {
        if (mi.Parent && mi.ModuleDependencies?.every(md => userModules?.indexOf(md) >= 0)) {
          MenuItems.push(mi.Parent);
        }
      });
    });

  return MenuItems;
};

export default MenuService;
