import React, {SetStateAction} from 'react';
import {IconButton, Progress, Typography, Upload} from 'spenda-ui-react';
import {Visibility} from '@material-ui/icons';

import {Toast} from '../../utils/Toast';
import {IQuoteAttachment} from '../../model/quotes/quotes';
import {AttachmentType} from '../../model/FileDetails';

import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {DownloadIcon} from '../../assets/svg/DownloadIcon';
import CrossIcon from '../../assets/svg/Cross';
import {PDFIcon, WordIcon} from '../../assets/svg';
import ImagePreview from './ImagePreview';

import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {FileType} from '../../components/dialog/QuoteAttachmentDialog';

const getFileIcon = (fileName: string) => {
  const fileTypeToIconMap: {[key: string]: JSX.Element} = {
    pdf: <PDFIcon className="h-[60px] w-[60px] text-gray-600" />,
    doc: <WordIcon className="h-[60px] w-[60px] text-gray-600" />,
    docx: <WordIcon className="h-[60px] w-[60px] text-gray-600" />,
  };

  const extension = fileName.split('.').pop()?.toLowerCase() || '';
  return fileTypeToIconMap[extension] || null;
};

export const AttachmentDefaultView = ({
  uri: fileURL,
  name,
  onDelete,
  caption,
  handleVisibility,
  isEditAble,
  containerClassName,
  disabled,
}: {
  uri: string;
  name: string;
  caption: string;
  isEditAble?: boolean;
  containerClassName?: string;
  onDelete?: () => void;
  handleVisibility?: (fileURL: string) => void;
  disabled?: boolean;
}) => {
  const icon = getFileIcon(name);

  const handleViewImage = (fileURL: string) => {
    if (handleVisibility && !icon) {
      handleVisibility(fileURL);
      return;
    }
    window.open(fileURL, '_blank');
  };

  return (
    <div className={`w-1/5 space-y-1 rounded-md ${containerClassName ?? null}`}>
      <div className="relative flex h-[110px] w-full flex-col">
        <div className="absolute inset-0 flex items-center justify-center rounded-md bg-[#00000040] opacity-0 hover:opacity-100">
          <div className="flex space-x-3">
            <Visibility
              data-autoid="btnViewEvidence"
              className="cursor-pointer !fill-[#fff]"
              onClick={() => {
                handleViewImage(fileURL);
              }}
            />
            <a data-autoid="btnDownloadEvidence" href={fileURL} target="_blank" download>
              <DownloadIcon data-autoid="EditEvidenceDialogDownloadIcon" className="cursor-pointer !fill-[#fff]" />
            </a>
          </div>
          {isEditAble && (
            <div className="absolute -right-2 -top-2 flex h-[20px] w-[20px] cursor-pointer items-center justify-center">
              <IconButton
                className="h-[20px] rounded-full bg-error/30"
                color="error"
                disabled={disabled}
                name="DeleteEvidence"
                onClick={onDelete}
                size="sm"
                variant="text"
              >
                <CrossIcon data-autoid="CrossIcon" className="h-[8px] w-[8px] fill-error" />
              </IconButton>
            </div>
          )}
        </div>
        {icon ? (
          <div className="flex h-full items-center justify-center rounded-md bg-gray-200">{icon}</div>
        ) : (
          <img loading="lazy" src={fileURL} alt={name} className="h-[110px] w-full rounded-md object-cover object-center" />
        )}
        <Typography
          variant="xsmall"
          className="absolute bottom-0 w-full truncate rounded-b-lg bg-[#212121] bg-opacity-30 px-2.5 text-center text-sm text-white"
        >
          {caption}
        </Typography>
      </div>
    </div>
  );
};

const QuoteAttachment = (props: {
  addedFilesToUpload?: FileType[];
  invalidFiles?: FileType[];
  setAddedFilesToUpload?: React.Dispatch<SetStateAction<FileType[]>>;
  setInvalidFiles?: React.Dispatch<SetStateAction<FileType[]>>;
  deleteFile?: (index: number, isInvalid: boolean) => void;
  isUploading?: boolean;
  uploadedAttachments?: IQuoteAttachment[];
  deleteQuoteAttachment?: (guid: string) => void;
  isServiceJobView?: boolean;
  disabled?: boolean;
  isEditAble?: boolean;
}) => {
  const {
    addedFilesToUpload,
    invalidFiles,
    setAddedFilesToUpload,
    setInvalidFiles,
    deleteFile,
    uploadedAttachments,
    deleteQuoteAttachment,
    isServiceJobView,
    disabled,
    isEditAble,
  } = props;

  const allowedTypes = Object.values(AttachmentType);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  const handleOnChange = (files: FileList) => {
    if (files && files.length > 0) {
      const newInvalidFiles: FileType[] = [];
      const validFiles: FileType[] = [];
      let invalidFilename: string = '';

      Promise.all(
        Array.from(files).map(file => {
          if (allowedTypes.includes(file.type as AttachmentType)) {
            return new Promise(resolve => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  file,
                  uri: reader.result as string,
                });
              };

              reader.readAsDataURL(file);
            });
          } else {
            newInvalidFiles.push({file, uri: ''});
            invalidFilename = invalidFilename.concat(file.name + ',');
            return Promise.resolve({} as FileType);
          }
        }),
      ).then(data => {
        setAddedFilesToUpload?.(prevState => [...prevState, ...(data as FileType[])]);
      });

      if (invalidFilename.length > 0) {
        Toast.error(`invalid file type:${invalidFilename.slice(0, -1)}`);
      }
      setInvalidFiles?.(prevState => [...prevState, ...newInvalidFiles]);

      setAddedFilesToUpload?.(prevState => {
        const currentFiles = prevState || [];
        return [...currentFiles, ...validFiles];
      });
    }
  };

  const FileItem = ({name, onDelete, isInvalid}: {name: string; onDelete: () => void; isInvalid: boolean}) => (
    <>
      <div
        className={`flex ${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} w-full flex-col gap-y-2 rounded-lg border border-[#ECECEC] bg-white px-2.5 py-2 shadow-[0_0_6px_0_rgba(211,229,239,1)]`}
      >
        <div className="flex w-full items-center justify-between">
          <span className="w-3/4 truncate font-normal text-black-800" data-autoid={`txt${name}`}>
            {name}
          </span>
          <IconButton
            disabled={disabled}
            className="cursor-pointer active:bg-transparent"
            ripple={false}
            variant="text"
            onClick={onDelete}
            name={`Delete${name}`}
          >
            <WidgetDeleteLogo />
          </IconButton>
        </div>
        <Progress value={100} color={isInvalid ? 'error' : 'success'} />
      </div>
    </>
  );

  return (
    <>
      {isEditAble && (
        <Upload
          data-autoid="uploadAttachment"
          label="Drop any additional documents here or click to upload"
          className={`${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} h-full min-h-[114px] text-sm font-normal text-black-800`}
          onChange={handleOnChange}
          disabled={disabled}
          multiple
        />
      )}

      {QuoteV289367 ? (
        <div className={`flex w-full ${isServiceJobView ? '' : 'mt-2'}`}>
          <div
            className={`flex w-full flex-wrap ${isServiceJobView ? 'justify-start gap-x-5 gap-y-5' : 'justify-center gap-x-3 gap-y-3'}  `}
          >
            {uploadedAttachments &&
              uploadedAttachments.map((item, index) => {
                return (
                  <AttachmentDefaultView
                    key={index}
                    uri={item.uri}
                    name={item.caption}
                    caption={item.caption}
                    containerClassName={isServiceJobView ? 'w-[15%] flex-wrap justify-start' : ''}
                    handleVisibility={() => ImagePreview(item.uri)}
                    onDelete={() => deleteQuoteAttachment?.(item.attachmentGuid)}
                    isEditAble={isEditAble}
                    disabled={disabled}
                  />
                );
              })}

            {addedFilesToUpload &&
              addedFilesToUpload.map((data, index) => (
                <AttachmentDefaultView
                  isEditAble
                  key={`added-${index}`}
                  caption={data.file.name}
                  name={data.file.name}
                  uri={data.uri}
                  containerClassName={isServiceJobView ? 'w-[15%] flex-wrap justify-start' : ''}
                  onDelete={() => deleteFile?.(index, false)}
                />
              ))}

            {invalidFiles &&
              invalidFiles.map((data, index) => (
                <AttachmentDefaultView
                  isEditAble
                  key={`invalid-${index}`}
                  caption={data.file.name}
                  name={data.file.name}
                  uri={data.uri}
                  containerClassName={isServiceJobView ? 'w-[15%] flex-wrap justify-start' : ''}
                  onDelete={() => deleteFile?.(index, true)}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className={`flex w-full flex-col gap-y-1.5 ${isServiceJobView ? '' : 'items-center justify-center'}`}>
          {uploadedAttachments &&
            uploadedAttachments?.map((item, index) => (
              <div
                key={index}
                className={`flex ${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} w-full flex-col gap-y-2 rounded-lg border border-[#ECECEC] bg-white px-2.5 py-2 shadow-[0_0_6px_0_rgba(211,229,239,1)]`}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="w-3/4 truncate font-normal text-black-800" data-autoid={`txt${item.caption}`}>
                    <a href={item.uri} download={item.caption}>
                      {item?.caption}
                    </a>
                  </span>
                  {isEditAble && (
                    <IconButton
                      disabled={disabled}
                      className="cursor-pointer active:bg-transparent"
                      ripple={false}
                      variant="text"
                      onClick={() => {
                        deleteQuoteAttachment?.(item.attachmentGuid);
                      }}
                      name={`Delete${item.caption}`}
                    >
                      <WidgetDeleteLogo />
                    </IconButton>
                  )}
                </div>
                {isEditAble && <Progress value={100} color="success" />}
              </div>
            ))}

          {addedFilesToUpload?.map((data, index) => (
            <FileItem key={index} name={data.file.name} onDelete={() => deleteFile?.(index, false)} isInvalid={false} />
          ))}

          {invalidFiles?.map((data, index) => (
            <FileItem key={index} name={data.file.name} onDelete={() => deleteFile?.(index, true)} isInvalid={true} />
          ))}
        </div>
      )}
    </>
  );
};

export default QuoteAttachment;
