import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Formik, FormikProps, Form} from 'formik';
import * as Yup from 'yup';
import {isEqual} from 'lodash';

import {IActionResults} from '../../model/ActionResults';
import {Inventory} from '../../model/inventory/Inventory';
import {InventorySOH} from '../../model/inventory/InventorySOH';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../routes/InventoryRoutes';
import {AddInventory, AddInventoryValues} from '../../components/form/InventoryForm';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {useInventoryAPI} from '../../services/useInventoryAPI';

import {Toast} from '../../utils/Toast';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import AppContext from '../../context/app/appContext';
import {APFooter} from '../../components/accountsPayableOnboarding/APFooter';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {Actions} from '../../model/constants/Constants';
import {IPostingInventoryRequest} from '../../model/requires-attention/StagedTransactions';
import {Button, Checkbox, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from '../../components/dialog/AlertDialogSlideV2';

const validationSchema = Yup.object({
  ShortDescription: Yup.string()
    .required('Product Name is required.')
    .max(50, 'Product Name cannot be longer than 50 characters.'),
  InventoryCode: Yup.string()
    .required('Product Code is required.')
    .max(50, 'Product Code cannot be longer than 50 characters.'),
  UoM: Yup.string().required('Product UoM is required.'),
  UoMDescription: Yup.string().required('Product UoM Description is required.'),
  Type: Yup.string().required('Product Type Description is required.'),
  MinMaxLevels: Yup.array().of(
    Yup.object().shape({
      MinSOHQuantity: Yup.number()
        .nullable()
        .test('min_max_check', 'Enter positive integer', function (val) {
          const MaxSOHQuantity = this.parent.MaxSOHQuantity;
          if (val) {
            if (!Number.isInteger(val) || val < 0) {
              return false;
            } else if (Number.isInteger(val) && Number.isInteger(MaxSOHQuantity) && MaxSOHQuantity <= val) {
              return this.createError({message: 'Min should be less than Max!'});
            }
          }
          return true;
        }),
      MaxSOHQuantity: Yup.number()
        .nullable()
        .test('min_max_check', 'Enter positive integer', function (val) {
          const MinSOHQuantity = this.parent.MinSOHQuantity;
          if (val) {
            if (!Number.isInteger(val) || val < 0) {
              return false;
            } else if (Number.isInteger(val) && Number.isInteger(MinSOHQuantity) && MinSOHQuantity >= val) {
              return this.createError({message: 'Max should be greater than Min!'});
            }
          }
          return true;
        }),
    }),
  ),
});

interface IAddProductProps {
  context?: 'AP' | 'PM';
  handleClose?: () => void;
  handleCreateAction?: () => void;
  supplierID?: number;
  inventoryCode?: string;
  action?: Actions;
  requestItems?: IPostingInventoryRequest[];
  isMultiplePostingSelected?: boolean;
  selectedRuleID?: number;
}

export const AddProduct = (props: IAddProductProps) => {
  const {
    context,
    handleClose,
    action,
    handleCreateAction,
    supplierID,
    inventoryCode,
    requestItems,
    isMultiplePostingSelected,
    selectedRuleID,
  } = props;
  const isInAPContext = context === 'AP';
  const history = useHistory();
  const {productID} = useParams<{productID?: string | undefined}>();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const selectedTab = query.get('tab') || undefined;
  const {tenantInfo, setTenantInfo} = useContext(AppContext);
  const {
    getInventoryById,
    createInventory,
    updateInventory,
    getInventorySOH,
    getInventoryVariants,
    inventoryCodeUnique,
    checkVariantsCanUnlink,
    unlinkVariants,
    linkVariants,
    isLoading,
  } = useInventoryAPI();

  const newProduct: AddInventoryValues = {
    ShortDescription: '',
    InventoryCode: '',
    InventoryClassID: '',
    InventoryClassName: '',
    SKU: '',
    UoM: '',
    UoMDescription: '',
    Barcode: '',
    Type: '',
    Description: '',
    ExpenseAccount: '',
    RevenueAccount: '',
    HTMLDescription: '',
    IsSold: false,
    SellPriceEx: 0,
    RRP: 0,
    StandardSellPriceEx: 0,
    StandardSellPriceInc: 0,
    LastBuyPriceEx: 0,
    IsOrderTaxExempt: false,
    IsPublished: false,
    IsPurchased: false,
    IsSOHTracked: false,
    IsVariantMaster: false,
    CostPriceEx: 0,
    IsPhysical: false,
    Width_m: '',
    Length_m: '',
    Depth_m: '',
    Weight_kg: '',
    Volume: '',
    IsActive: true,
  } as AddInventoryValues;

  const [inventoryToEdit, setInventoryToEdit] = React.useState<AddInventoryValues>(newProduct);
  const [inventorySOH, setInventorySOH] = React.useState<InventorySOH | undefined>(undefined);
  const {T2TPhase280729} = useFeatureFlags().tenantOwned();
  const {postInventoryPostingRule} = useStagedTransactionsAPI();

  useEffect(() => {
    if (productID) {
      getInventoryById(productID).then(async (res: Inventory) => {
        if (res) {
          const _inventory = {
            ID: res.ID,
            ShortDescription: res.ShortDescription || '',
            InventoryCode: res.InventoryCode,
            InventoryClassID: res.InventoryClassID,
            InventoryClassName: res.InventoryClassName,
            SKU: res.SKU,
            UoM: res.UoM,
            Brand: res.Brand,
            Categories: res.Categories,
            UoMDescription: res.UoMDescription,
            Barcode: res.Barcode || '',
            Type: res.Type,
            Description: res.Description || '',
            ExpenseAccount: res.ExpenseAccount,
            RevenueAccount: res.RevenueAccount,
            HTMLDescription: res.HTMLDescription || '',
            IsSold: res.IsSold,
            SellPriceEx: res.SellPriceEx,
            LastBuyPriceEx: res.LastBuyPriceEx || '',
            RRP: res.RRP,
            StandardSellPriceEx: res.StandardSellPriceEx,
            StandardSellPriceInc: res.StandardSellPriceInc,
            IsOrderTaxExempt: res.IsOrderTaxExempt,
            IsPublished: res.IsPublished,
            IsPurchased: res.IsPurchased,
            IsApproved: res.IsApproved,
            AssetAccount: res.AssetAccount,
            IsSOHTracked: res.IsSOHTracked,
            IsVariantMaster: res.IsVariantMaster,
            CostPriceEx: res.CostPriceEx,
            IsPhysical: res.IsPhysical,
            Width_m: res.Width_m,
            Length_m: res.Length_m,
            Depth_m: res.Depth_m,
            Weight_kg: res.Weight_kg,
            Volume: res.Volume,
            ChildVariants: res.ChildVariants,
            ChildIsSold: res.ChildIsSold,
            MediaFiles: res.MediaFiles,
            IsActive: res.IsActive,
            DefaultSupplierID: res.DefaultSupplierID,
            DefaultSupplierName: res.DefaultSupplierName,
          } as AddInventoryValues;
          if (Array.isArray(res.MinMaxLevels)) {
            _inventory.MinMaxLevels = res.MinMaxLevels.sort((a, b) => a.WarehouseID - b.WarehouseID);
          }
          if (res.IsVariantMaster) {
            const _childVariants = await getInventoryVariants(productID);
            if (_childVariants.IsSuccess) {
              _inventory.ChildVariants = _childVariants.Value;
            }
          }
          setInventoryToEdit(_inventory);
        }
      });
      getInventorySOH(productID).then((res: InventorySOH) => {
        if (res) {
          res.StockOnHand = res.StockOnHand.sort((a, b) => a.WarehouseID - b.WarehouseID);
          setInventorySOH(res);
        }
      });
    } else {
      const _inventory = newProduct;
      _inventory.UoM = 'EA';
      _inventory.UoMDescription = 'Each';
      _inventory.Type = 'Inventory';
      setInventoryToEdit(_inventory);
    }
  }, []);

  const onSubmit = async (values: AddInventoryValues): Promise<void> => {
    if (inventoryToEdit.Brand && !values.Brand) {
      values.Brand = {id: 0} as any;
    }
    if (inventoryToEdit.InventoryClassID && !values.InventoryClassID) {
      values.InventoryClassID = -1 as any;
    }
    const inventoryCodeRes = await inventoryCodeUnique({
      InventoryCode: values.InventoryCode,
      InventoryID: values?.ID || undefined,
    });
    if (productID) {
      if (inventoryCodeRes.Value > 0) {
        return Promise.reject();
      }
      if (
        values.IsVariantMaster &&
        values.ChildVariants &&
        !isEqual(values.ChildVariants, inventoryToEdit.ChildVariants)
      ) {
        const canUnlink = await checkVariantsCanUnlink(productID);
        if (canUnlink.Value && canUnlink.IsSuccess) {
          const unlinkRes = await unlinkVariants(productID);
          if (!unlinkRes) {
            return Promise.reject();
          }
          const variantToLink = values.ChildVariants.filter(variant => variant.IsActive);
          const linkRes = await Promise.all([variantToLink.map(variants => linkVariants(productID, variants))]);
          if (linkRes.some(res => !res)) {
            return Promise.reject();
          }
        }
      }
      return updateInventory(values).then(async (res: IActionResults<Inventory>) => {
        if (res.IsSuccess) {
          if (T2TPhase280729) {
            if (
              tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId === values?.ID &&
              values?.ExpenseAccount?.toString() !==
                tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryExpenseAccountCode?.toString()
            ) {
              //update app context
              const tenantUserDetails = tenantInfo?.TenantUserDetails;
              if (tenantInfo && tenantUserDetails) {
                tenantUserDetails.DefaultGenericLineItemInventoryExpenseAccountCode = values?.ExpenseAccount
                  ? parseInt(values?.ExpenseAccount)
                  : undefined;
                const info = {Modules: tenantInfo.Modules, TenantUserDetails: tenantUserDetails};
                setTenantInfo(info);
              }
            }
          }
          Toast.info(`Product '${values.ShortDescription}' updated`);
          if (isInAPContext) {
            handleClose?.();
          } else {
            history.push(AUTH_INVENTORY_PRODUCTS_LIST);
          }
        }
      });
    } else {
      if (inventoryCodeRes.Value > 0) {
        Toast.error(`Inventory code already exist.`);
        return Promise.reject();
      }
      const response: IActionResults<Inventory> = await createInventory(values);
      if (response.IsSuccess) {
        if (isInAPContext && T2TPhase280729) {
          try {
            if (!isMultiplePostingSelected) {
              await postInventoryPostingRule(supplierID!, [
                {
                  SourceSupplierID: supplierID!,
                  InventoryCode: inventoryCode!,
                  PostingInventoryID: response?.Value?.ID,
                  Action: action!,
                  ID: selectedRuleID,
                },
              ]);
            } else {
              const requestArr = requestItems?.map(item => {
                item.PostingInventoryID = response?.Value?.ID;
                return item;
              });
              requestArr && (await postInventoryPostingRule(supplierID!, requestArr));
            }
            handleCreateAction?.();
          } catch (error) {
            console.error('error', error);
          }
        }
        if (!isInAPContext) {
          Toast.info(`Product '${values.ShortDescription}' created`);
          history.push(AUTH_INVENTORY_PRODUCTS_LIST);
        }
      }
    }
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleArchiveProduct = (
    event: React.ChangeEvent<HTMLInputElement>,
    fProps: FormikProps<AddInventoryValues>,
  ) => {
    fProps.setFieldValue('IsActive', !event.target.checked);
  };

  const handleCloseClick = (props: FormikProps<AddInventoryValues>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else if (isInAPContext) {
      handleClose?.();
    } else {
      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
    }
  };

  const handleDiscard = () => {
    if (isInAPContext) {
      setOpenConfirmationDialog(false);
      handleClose?.();
    } else {
      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
    }
  };

  const _addProduct = (
    <div className={` relative flex h-full w-full flex-col rounded-lg bg-white`} id="sales-orders-list">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={inventoryToEdit}
        onSubmit={onSubmit}
      >
        {props => (
          <Form
            onKeyDown={onKeyDown}
            onSubmit={props.handleSubmit}
            className={`relative flex h-full flex-col  px-5 ${isInAPContext ? '' : 'py-4'}`}
          >
            <div
              className={`${
                isInAPContext ? 'absolute right-0 top-0 h-[44px] items-center justify-end mr-10' : 'justify-between p-4'
              } flex w-full`}
            >
              {!isInAPContext && (
                <Typography className="text-xl font-medium text-[#4D4D4D]">Product Details</Typography>
              )}
              <div className="flex">
                <Checkbox
                  ripple={false}
                  label="Archive Product"
                  className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0 "
                  checked={!props.values.IsActive}
                  onChange={e => handleArchiveProduct(e, props)}
                />
              </div>
            </div>
            <AddInventory
              {...props}
              inventorySOH={inventorySOH}
              selectedTab={selectedTab}
              isInAPContext={isInAPContext}
            />
            {isInAPContext ? (
              <APFooter
                isCancelButton={true}
                buttonLabel={'Done'}
                onNextClick={() => props.isSubmitting}
                isDisabled={props.isSubmitting || !props.dirty}
                handleCancelClick={() => handleCloseClick(props)}
                isT2TSupplierModule={true}
                conditionallyClass={true}
                APFooterClass="!bottom-[10px] !left-[10px]"
                style={{width: 'calc(100% - 20px)'}}
              />
            ) : (
              <div
                className={`bg-#FFFFFF absolute bottom-[30px] z-[2] -mx-5 -mb-5 flex w-full justify-end p-3 shadow-inner`}
              >
                <Button
                  className="mr-2"
                  variant="outlined"
                  onClick={() => handleCloseClick(props)}
                  data-autoid="btnClose"
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  disabled={props.isSubmitting || !props.dirty}
                  data-autoid="btnSave"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlideV2
          id={isInAPContext ? 'discardPopup' : ''}
          headingTextSize="h2"
          size="md"
          title={'Discard Changes'}
          footer={
            <div className={`${isInAPContext ? '' : 'px-4'} flex w-full justify-between`}>
              <Button variant="outlined" data-autoid="btnCancel" onClick={() => setOpenConfirmationDialog(false)}>
                Cancel
              </Button>
              <Button data-autoid="btnDiscard" onClick={() => handleDiscard()}>
                Discard
              </Button>
            </div>
          }
        >
          <Typography className={`${isInAPContext ? 'py-12' : 'pb-6 pt-6'} text-center font-medium text-black`}>
            You have unsaved changes, are you sure you want to discard changes?
          </Typography>
        </AlertDialogSlideV2>
      )}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg ${isInAPContext ? 'rounded-xl' : ''}`}>
        {isInAPContext ? _addProduct : <Layout leftPanel={_addProduct} splitWidthType={4} />}
        <LoadingIndicator isLoading={isLoading} size="md" color="#1C78AD" />
      </div>
    </>
  );
};
