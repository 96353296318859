import React, {useState} from 'react';
import {Button, SearchInput, Typography} from 'spenda-ui-react';

import IconDownload from '../../../../assets/svg/IconDownload';
import {CreateCatalogueDialog} from '../../../../components/dialog';

export const ManageCatalogueHeader = () => {
  const [isShowCreateCatelogueDialog, setIsShowCreateCatelogueDialog] = useState<boolean>(false);

  return (
    <div className="flex items-center justify-between border-b border-[#ECECEC] px-2.5 pb-2.5">
      <Typography variant="h2">Catalogues(3)</Typography>
      <div className="flex items-center gap-2.5">
        <SearchInput
          reverse={true}
          clearIcon
          iconButtonProps={{
            name: 'Search',
          }}
          data-autoid="txtSearchInput"
          autoFocus
          placeholder="Search..."
        />
        <Button variant="outlined" className="min-w-[256px]">
          <div className="flex items-center gap-x-2">
            <IconDownload height="18px" width="18px" />
            Download Template(.xlsx)
          </div>
        </Button>
        <Button className="min-w-[168px]" onClick={() => setIsShowCreateCatelogueDialog(true)}>
          Create catalogue
        </Button>
      </div>

      {isShowCreateCatelogueDialog && (
        <CreateCatalogueDialog handleCancel={() => setIsShowCreateCatelogueDialog(false)} handleContinue={() => {}} />
      )}
    </div>
  );
};
