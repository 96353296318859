import React, {SVGProps} from 'react';

export const AttachDocs = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19px"
      height="10px"
      className={props.className}
      viewBox="0 0 19 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>Files</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Buyer-screen/start-conv-Copy-17" transform="translate(-1258.000000, -738.000000)" fill="#0082BA">
          <g id="Group-11" transform="translate(986.000000, 268.000000)">
            <g
              id="General/-63-Copy"
              transform="translate(281.805451, 474.787797) rotate(45.000000) translate(-281.805451, -474.787797) translate(274.093068, 466.800280)"
            >
              <path
                d="M15.228238,6.37212913 C14.9679048,6.1118745 14.5459046,6.1118745 14.2855714,6.37212913 L7.02423802,13.6647958 C5.72250286,14.966715 3.61182394,14.9668643 2.30990469,13.6651291 C1.00798544,12.363394 1.0078362,10.252715 2.30957135,8.95079579 L9.34223802,1.88946246 C10.125,1.11916414 11.3824572,1.12413565 12.1591039,1.90059928 C12.9357506,2.67706292 12.9410185,3.93451893 12.1709047,4.71746246 L5.13823802,11.7787958 C4.8747211,12.0312457 4.45908827,12.0312457 4.19557135,11.7787958 C3.93531673,11.5184626 3.93531673,11.0964624 4.19557135,10.8361291 L10.4569047,4.54612913 C10.7095528,4.2845432 10.7059396,3.86873927 10.4487837,3.61158341 C10.1916279,3.35442754 9.77582394,3.35081432 9.51423802,3.60346246 L3.25290469,9.89346246 C2.87775866,10.268545 2.66699847,10.7773041 2.66699847,11.3077958 C2.66699847,11.8382875 2.87775866,12.3470466 3.25290469,12.7221291 C4.04450026,13.4775172 5.28997578,13.4775172 6.08157135,12.7221291 L13.1135714,5.66012913 C14.3871247,4.35369606 14.3737494,2.26615116 13.0835602,0.976144453 C11.7933711,-0.313862257 9.70582428,-0.326942284 8.39957135,0.946795793 L1.36690469,8.00812913 C-0.455634923,9.83066881 -0.455634891,12.7855894 1.36690476,14.6081291 C3.1894444,16.4306687 6.144365,16.4306687 7.96690469,14.6081291 L15.228238,7.31746246 C15.3540362,7.19233702 15.4247657,7.02222622 15.4247657,6.84479579 C15.4247657,6.66736536 15.3540362,6.49725457 15.228238,6.37212913 Z"
                id="Path-Attach"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
