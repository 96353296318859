import React, {PropsWithChildren} from 'react';
import {IPaymentT, ITxDeduction} from '../../model/payment/PaymentT';
import {PriceFormat} from '../../utils/formatter';
import {Box, makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import Moment from 'react-moment';
import {css} from 'glamor';
import {SPopover} from '../modals/modalSpendaMeterialUI';
import {PaymentStatusType} from '../../model/constants/Constants';

interface IPaymentSummary {
  payments?: IPaymentT[];
  showAmountFieldOnPaymentSummaryPopover?: boolean;
  showCreditsApplied?: boolean;
  isCreditNote?: boolean;
}

export const PaymentSummary = (props: PropsWithChildren<IPaymentSummary>) => {
  const payments = props.payments || [];
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const priceFormat = (amount?: number) => PriceFormat(amount);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(320, 415));

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const paymentIsPaid = props.payments && props.payments.some(p => p.Status === PaymentStatusType.Paid);

  const paymentLine = css({
    fontSize: matches ? '14px' : '16px',
    fontWeight: '500',
    color: paymentIsPaid || props.showCreditsApplied || props?.isCreditNote ? '#4ea7c6' : '#C68A19',
    textAlign: 'right',
  });

  const useStyles = makeStyles({
    root: {
      '& .MuiPaper-root': {
        backgroundColor: 'transparent !important',
        overflow: 'hidden',
        height: '165px',
        display: 'flex',
        alignItems: 'flex-end',
      },
      '& .MuiPaper-rounded': {
        borderRadius: '10px',
      },
      '& .MuiPaper-elevation8': {
        boxShadow: 'none !important',
      },
    },
  });
  const classes = useStyles();

  const reducer = (prev: number, curr: ITxDeduction) => {
    let sum: number;
    sum = prev + curr.AppliedAmount!;
    return sum;
  };

  const sumOfPaymentAmount = priceFormat(payments.reduce(reducer, 0));

  const PaymentHeading = () => {
    switch (true) {
      case props?.isCreditNote:
        return 'Applied To Invoices';
      case props?.showCreditsApplied:
        return 'Credit Applied';
      case paymentIsPaid:
        return 'Payment Applied';
      default:
        return 'Failed Payment';
    }
  };

  return (
    <>
      {payments.length > 0 ? (
        <>
          <p className={`${paymentLine}`}>
            <span onClick={handleClick} className="cursor-pointer flex-1">
              {PaymentHeading()} ({payments.length})
            </span>
          </p>
          <div className={`${paymentLine}`}>
            {(paymentIsPaid || props.showCreditsApplied || props?.isCreditNote) && (
              // ALEX: Please do not squash this change again this is needed because of payment list growing in the UI.
              // UI will break if you map the object again which paymentSummary has mapped on the parent.
              <Box className={`text-right`}>
                <Box className="mb-1">
                  <Box>{sumOfPaymentAmount}</Box>
                </Box>
              </Box>
            )}
          </div>
        </>
      ) : (
        ''
      )}

      <SPopover
        id={id}
        open={open}
        classes={{root: classes.root}}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PaymentListItems
          payments={payments}
          isPaid={paymentIsPaid}
          showCreditsApplied={props.showCreditsApplied}
          showAmountFieldOnPaymentSummaryPopover={props.showAmountFieldOnPaymentSummaryPopover}
          isCreditNote={props?.isCreditNote}
        />
      </SPopover>
    </>
  );
};

const PaymentListItems = (props: {
  payments: IPaymentT[];
  isPaid?: boolean;
  showAmountFieldOnPaymentSummaryPopover?: boolean;
  showCreditsApplied?: boolean;
  isCreditNote?: boolean;
}) => {
  const paymentLine = css({
    width: '270px',
    background: '#FFF',
    borderBottom: '5px solid #6A0136',
    position: 'relative',
    height: '150px',
    borderRadius: '10px',
    border: '1px solid #D6D2D2',
    bottom: '0px',
    boxShadow: '0 6px 7px 2px rgb(0 0 0 / 20%)',
    padding: '10px 16px',
    '&:after': {
      height: '17px',
      width: '17px',
      position: 'absolute',
      content: "''",
      background: '#ffffff',
      border: '1px solid #D6D2D2',
      borderBottomWidth: '0px',
      borderRightWidth: '0px',
      transform: 'rotate(45deg)',
      top: '-9px',
      right: '25px',
    },
  });
  const paymentLineInner = css({
    height: '146px',
    overflow: 'auto',
  });
  const widthClass = props.showAmountFieldOnPaymentSummaryPopover ? 'w-1/3' : 'w-1/2 pr-1';
  const columnHeading = () => {
    switch (true) {
      case props?.isCreditNote:
        return 'Invoice Ref';
      case props?.showCreditsApplied:
        return 'Credit Ref';
      default:
        return 'Payment Ref';
    }
  };
  return (
    <>
      <div className={`${paymentLine}`}>
        <div className={`${paymentLineInner}`}>
          <div className="flex text-xs my-1 font-medium text-gray-500">
            <p className={`${widthClass}`}>{columnHeading()}</p>
            <p className={`${widthClass} text-right`}>
              {props.isPaid || props.showCreditsApplied ? 'Applied On' : 'Failed On'}
            </p>
            {props.showAmountFieldOnPaymentSummaryPopover && <p className={`${widthClass} text-right`}>Amount</p>}
          </div>
          {props.payments.map((payment: IPaymentT, id: number) => (
            <div className="flex text-base my-1 font-normal text-gray-600" key={id}>
              <p className={`${widthClass}`}>{payment.RefNumber}</p>
              <p className={`${widthClass} text-right text-gray-600`}>
                {<Moment date={payment.AppliedDate} format="Do MMM YYYY" local></Moment>}
              </p>
              {props.showAmountFieldOnPaymentSummaryPopover && (
                <p className={`${widthClass} text-right text-gray-600`}>{payment?.AppliedAmount?.toFixed(2)}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
