import React, {SetStateAction, useEffect, useRef, useState} from 'react';
import {Dialog, DialogBody} from 'spenda-ui-react';

import {IQuotes} from '../../model/quotes/quotes';
import useQuotesAPI from '../../services/useQuotesAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Toast} from '../../utils/Toast';
import environment from '../../utils/environment';

interface ILimepayCheckout {
  quoteDetails: IQuotes;
  inviteCode: string;
  open: boolean;
  setShowLimepayCheckout: React.Dispatch<SetStateAction<boolean>>;
}

export const LimepayCheckout = (props: ILimepayCheckout) => {
  const {open, inviteCode, quoteDetails, setShowLimepayCheckout} = props;
  const checkoutRef = useRef<HTMLDivElement | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const {sendLimepayCheckoutToken} = useQuotesAPI();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://checkout-v3.au.meetapril.io/v3/checkout-v3.0.0.min.js';
    script.async = true;
    script.onload = () => {
      setIsScriptLoaded(true); // Mark script as loaded when it finishes loading
    };
    script.onerror = () => {
      console.error('Failed to load AprilCheckout script.');
    };

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded && quoteDetails && (quoteDetails.balance || 0) && typeof window.AprilCheckout !== 'undefined') {
      const AprilCheckout = window.AprilCheckout.createCheckout();
      AprilCheckout.init({
        publicKey: environment.LIMEPAY_KEY,
        hidePayLaterOption: true /* optional (default false),
            can pass (true or false), it will show/hide the BNPL option */,
        paymentToken: function (_token: any, _paymentData: any) {
          setIsLoading(true);
          /* Once the checkout is complete this function will be called.
            Use the `token` on your server to complete the transaction. */
          sendLimepayCheckoutToken(inviteCode, _token)
            .then(() => {
              setIsLoading(false);
              Toast.info('Payment Successful');
              setShowLimepayCheckout(false);
            })
            .catch(error => {
              setIsLoading(false);
              Toast.error(error);
            });
        },
      });
      AprilCheckout.render({
        elementId: 'april-cont',
        currency: 'AUD',
        amount: (quoteDetails.balance || 0) * 100, // should be in cents (integer)
        paymentType: 'paycard' /* Optional (default "paycard"),
            selected payment option ("paycard" or "payplan") */,
        showPayNow: true /* Optional,
            enables Pay Now button for pay by card option */,
        showPayPlanSubmit: true /* Optional,
            enables Submit Payment Plan button for split payments option */,
        primaryColor: '#1C78AD', // Optional, theme primary color (HEX)
      });
      AprilCheckout.errorHandler(function (_err: any) {
        setIsLoading(false);
        // Handle errors
      });
      AprilCheckout.eventHandler(function (_event: any) {
        setIsLoading(false);
        // Handle errors
      });
    }
  }, [isScriptLoaded, quoteDetails]);

  return (
    <div className="relative h-full overflow-hidden bg-white">
      <Dialog
        open={open}
        handler={() => {}}
        className={`flex h-full !w-full !min-w-[20%] !max-w-full flex-col items-center justify-center p-8 sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
      >
        <DialogBody className="my-[4.5rem] flex flex-col items-center justify-center gap-10">
          {isLoading ? (
            <LoadingIndicator isLoading={true} size="md" color="#1C78AD" />
          ) : (
            <div id="april-cont" ref={checkoutRef}></div>
          )}
        </DialogBody>
      </Dialog>
    </div>
  );
};
