import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {ServiceDashboard} from '../screens/service-management/ServiceDashboard';
import {ServiceHistory} from '../screens/service-management/service-history/ServiceHistory';
import ServiceDetailsDashboard from '../screens/service-management/service-history-details/ServiceDetailsDashboard';

export const AUTH_SELLING_SERVICE_DASHBOARD = '/service/booking-diary';
export const AUTH_SELLING_SERVICE_CUSTOMERS = '/service/customers';
export const AUTH_SELLING_SERVICE_CUSTOMERS_DETAILS = '/service/customers/details/:customerID';
export const AUTH_SELLING_SERVICE_REPORTS = '/service/reports';
export const AUTH_SELLING_SERVICE_SETTINGS = '/service/settings';

export const ServiceManagementRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={AUTH_SELLING_SERVICE_DASHBOARD}
        exact
        moduleIds={[ModuleTypes.Service]}
        Component={ServiceDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Service,
          ModuleDependency: [ModuleTypes.Service],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SERVICE_CUSTOMERS}
        exact
        moduleIds={[ModuleTypes.Service]}
        Component={ServiceHistory}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Service,
          ModuleDependency: [ModuleTypes.Service],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SERVICE_CUSTOMERS_DETAILS}
        exact
        moduleIds={[ModuleTypes.Service]}
        Component={ServiceDetailsDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Service,
          ModuleDependency: [ModuleTypes.Service],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SERVICE_REPORTS}
        exact
        moduleIds={[ModuleTypes.Service]}
        Component={ServiceDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Service,
          ModuleDependency: [ModuleTypes.Service],
        }}
      />
      <PrivateRoute
        path={AUTH_SELLING_SERVICE_SETTINGS}
        exact
        moduleIds={[ModuleTypes.Service]}
        Component={ServiceDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Service,
          ModuleDependency: [ModuleTypes.Service],
        }}
      />
    </Switch>
  );
};
