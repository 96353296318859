import {ILocation} from './address/Location';
import {IBaseUser, IUserRole} from './user/UserAccountInfo';

export enum LogoTypeEnum {
  Logo = '1',
  Icon = '2',
  Banner = '3',
}

export interface IUsers extends IBaseUser {
  Barcode: string | null;
  DisplayName: string | null;
  EmailAddress: string;
  IsActive: boolean;
  IsEnabled: boolean;
  IsPrimaryContact: string | null;
  Position: string | null;
  UserGlobalID: string;
}

export interface ILogo {
  URI: string;
  ThumbnailURL?: string;
  LogoTypeID: LogoTypeEnum | 1 | 2 | 3;
  RedirectURI?: string;
  GUID?: string;
}

export interface ITenant {
  AgreedTsAndCs: boolean | undefined;
  ABN: string | undefined;
  ARContactEmailAddress: string | undefined;
  GlobalCode: string | undefined;
  GlobalID: string; // will not be undefined because DB schema is NOT NULL
  GoodToTrade: boolean;
  IsCapricornPreferredSupplier: boolean | undefined;
  IsIndividual: boolean | undefined;
  IsSetupComplete: boolean | undefined;
  FirstName: string | undefined;
  LastName: string | undefined;
  Locations: ILocation[];
  Logos: ILogo[];
  LogoThumbnailUri: string | undefined;
  LogoURI: string | undefined;
  MarketPlacePath: string | undefined;
  Name: string | undefined;
  Phone: string | undefined;
  SupplierName: string | undefined;
  Users: IUsers[];
  WebSiteURL: string | undefined;
  Email: string;
}

export interface ITenantTemplate {
  TemplateID: number | undefined;
  Name: string | undefined;
  Description: string | undefined;
  IsHidden: boolean | undefined;
}

export interface ITenantUserDetails {
  AgreedTsAndCs: boolean | undefined;
  TenantID: number | undefined;
  UserID: number | undefined;
  UserName: string | undefined;
  UserFirstName: string | undefined;
  UserLastName: string | undefined;
  IsAdministrator: boolean | undefined;
  IsTempPassword: boolean | undefined;
  IsNewTenant: boolean | undefined;
  TenantName: string | undefined;
  TenantGlobalCode: string | undefined;
  IsMigration: boolean | undefined;
  IsSetupComplete: boolean | undefined;
  IsDemoTenant: boolean | undefined;
  IsDemo: boolean | undefined;
  TemplateTenantID: number | undefined;
  DefaultCashSaleCustomerId: number | undefined;
  DefaultShippingLineItemInventoryId: number | undefined;
  DefaultDiscountLineItemInventoryId: number | undefined;
  DefaultGenericLineItemInventoryId: number | undefined;
  DefaultGenericLineItemInventoryExpenseAccountCode: number | undefined;
  DefaultWarehouseID: number | undefined;
  EmailAddress: string | undefined;
  ModuleTypes: Array<number> | undefined;
  WebAppDisplayableModules: Array<number> | undefined;
  UserRoles: IUserRole[];
  AreAllAPOnboardingStepsCompletedForTenant: boolean;
  ShouldSendPaymentAdviceToUser: boolean;
  IsAirplusVirtualCardEnable: boolean;
  IsPayfacMerchant: boolean;
  IsServiceTechnician?: boolean;
  IsLendingProvider: boolean;
  IsShowQuantityInQuote: boolean;
  IsAuthorisedToAccessWebsite: boolean;
  IsOnboardingMeetingCompleted: boolean;
}

export interface ITenantModules {
  Description: string | undefined;
  IsActive: boolean | undefined;
  IsDisableAdaptorActionRefresh: boolean | undefined;
  IsExpired: boolean | undefined;
  IsModuleSetup: boolean | undefined;
  IsSystem: boolean | undefined;
  IsTenantOwned: boolean | undefined;
  LogoUrl: string | undefined;
  ModuleID: number;
  Name: string | undefined;
  Status: string | undefined;
  TrialExpiryDate: string | undefined;
  TrialPeriodDays: number | undefined;
  TrialRemainingDays: number | undefined;
  TrialStartDate: string | undefined;
  Type: string | undefined;
}
export interface ITenantInfo {
  TenantUserDetails: ITenantUserDetails;
  Modules: ITenantModules[];
}
