import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {useAuth0} from '@auth0/auth0-react';
import {withLDProvider} from 'launchdarkly-react-client-sdk';
import {Box} from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import {AuthenticatedApp} from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import AppContext from './context/app/appContext';
import Theme from './Theme';
import {MuiThemeProvider} from '@material-ui/core/styles';
import environment from './utils/environment';
import LoadingIndicator from './components/ui/LoadingIndicator';
import {LogoName} from './components/LogoName';
import {useZohoChat} from './hooks/useZohoChat';
import {ZohoContext, ZohoValueType} from './context/app/ZohoContext';
import {IUser} from './model/user/User';
import {useAuth} from './services/useAuth';
import ThemeContext from './context/theme-context/ThemeContext';
import {ErrorBoundary} from './exception/ErrorBoundary';

const App = (props: any) => {
  const {user, loadUserSession} = useContext(AppContext);
  const [isLoadingSession, setIsLoadingSession] = useState(false);

  const {user: auth0User, isAuthenticated, isLoading: isAuthenticating} = useAuth0();
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && auth0User) {
      setIsLoadingSession(true);
      setAuth0User();
    }

    if (!isAuthenticated && !user) {
      setIsLoadingSession(false);
    }
  }, [isAuthenticated, auth0User]);

  const setAuth0User = async () => {
    try {
      await auth.setToken(auth0User);
      const loadedSession = await loadUserSession(auth0User as IUser);

      if (!loadedSession) {
        console.error("Couldn't load user session");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSession(false);
    }
  };

  /*
  TODO: the zoho URL and widgetcode must be moved into environment variables
  It should be something like:
        const zohoValue = useZohoChat(process.env.REACT_APP_ZOHO_CHAT_URL, process.env.REACT_APP_ZOHO_CHAT_WIDGETCODE);
 */
  const zohoValue = useZohoChat(
    'https://salesiq.zoho.com/widget',
    'e54a9044aa3660f144c47b05c1522150e59ba5262d5c15bf161801eee9281068dc71dc0f776482752da1dd780ff80b9f',
  );
  // test widget code
  // const zohoValue = useZohoChat('https://salesiq.zoho.com/widget', '3f9dbd9d0774b70f194028fb37127e9f7db2fee14adf5a3e826106437a48e198');
  const [zohoContext, setZohoContext] = useState<ZohoValueType>(zohoValue);

  useEffect(() => {
    setZohoContext(zohoValue);
    if (zohoValue && zohoValue.isReady && !user) {
      zohoContext.closeChatWindow();
    }
  }, [zohoValue]);

  const {logo: Logo} = useContext(ThemeContext);

  if (isAuthenticating || isLoadingSession) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="bg-spenda-newbg"
      >
        {Logo ? <Logo /> : <LogoName />}
        <Box display="flex">
          <LoadingIndicator isLoading={true} position={{position: 'relative'}} size="md" />
          <p className="font-poppins font-light text-primary">Loading...</p>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <ZohoContext.Provider value={zohoContext}>
        <MuiThemeProvider theme={Theme}>
          <ToastContainer style={{zIndex: 99999}}></ToastContainer>
          {user ? (
            <ErrorBoundary onReset={() => history.push('/menu')}>
              <AuthenticatedApp {...props} />
            </ErrorBoundary>
          ) : (
            <UnauthenticatedApp />
          )}
        </MuiThemeProvider>
      </ZohoContext.Provider>
    </>
  );
};

// Update the export default to use your environment-specific client ID and a user:
export default withLDProvider({
  clientSideID: environment.LD_CLIENT_ID!,
})(App);
