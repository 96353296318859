import React from 'react';
import {Typography} from 'spenda-ui-react';

export const CreateCatalogueRightPanel = () => {
  return (
    <div className={`h-full rounded bg-white p-2.5`}>
      <Typography variant="h2" className="border-b border-[#ECECEC] px-2.5 pb-3 pt-2 font-light">
        Logs
      </Typography>
      <div>
        {[1, 2, 3, 4].map(index => {
          return (
            <div key={index} className="flex items-center justify-start border-b border-[#ECECEC] py-4">
              <Typography className="mr-2.5 flex h-8 w-8 min-w-8 items-center justify-center rounded-full bg-warning/10 p-1 text-warning">
                RT
              </Typography>
              <Typography className="w-[calc(100%-110px)]">
                Rishabh has added 3 line items, deleted 2 line items.
              </Typography>
              <Typography variant="small" className="flex w-[68px] items-center justify-end pl-4 text-spenda-greytext">
                30 min
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};
