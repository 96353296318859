import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import {ARTable} from '../ARTable';
import clsx from 'clsx';
import {ISelectedRow} from '../../../screens/AccountsReceivable/requires-attention/RequiresAttention';
import {DatTypes} from '../../../model/constants/Constants';
import {cloneDeep} from 'lodash';
import {PriceFormat} from '../../../utils/formatter';

interface IVerifyingTaxAmountDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: ISelectedRow[];
  handleDone: (updatedSelectedRows: ISelectedRow[]) => void;
}

export const VerifyingTaxAmountDialog = (props: IVerifyingTaxAmountDialogProps) => {
  // Props
  const {isOpen, onClose, selectedRows, handleDone} = props;

  // State
  const [updatedSelectedRows, setUpdatedSelectedRows] = useState<ISelectedRow[]>([]);

  useEffect(() => {
    setUpdatedSelectedRows(cloneDeep(selectedRows));
  }, [selectedRows]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {value} = e.target;

    setUpdatedSelectedRows(prev => {
      const updatedRows = [...prev];
      if (/^\d*(\.\d{0,2})?$/.test(value) && Number(value) >= 0 && Number(value) <= selectedRows?.[index]?.totalInc) {
        updatedRows[index].totalTax = Number(value);
      }
      return updatedRows;
    });
  };

  const columns = [
    {
      title: 'Ref ID',
      key: 'refID',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow) => {
        return (
          <Typography
            data-autoid={`lblRefID`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium text-black-800"
          >
            {rowData?.documentID}
          </Typography>
        );
      },
    },
    {
      title: 'Suppliers',
      key: 'suppliers',
      width: '40%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow) => {
        return (
          <Typography
            data-autoid={`lblSuppliers`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium text-black-800"
          >
            {rowData?.supplier}
          </Typography>
        );
      },
    },
    {
      title: 'Invoice total',
      key: 'invoiceTotal',
      width: '20%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow) => {
        return (
          <Typography
            data-autoid={`lblInvoiceTotal`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium text-black-800"
          >
            {PriceFormat(rowData?.totalInc)}
          </Typography>
        );
      },
    },
    {
      title: 'Tax amount',
      key: 'taxAmount',
      width: '20%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-0',
      isSortable: false,
      rowRenderer: (rowData: ISelectedRow, index: number) => {
        return (
          <>
            {rowData?.datTypeID === DatTypes.StagedDebitNote ? (
              <Typography
                data-autoid={`lblTaxAmount`}
                variant="paragraph"
                className="inline overflow-ellipsis p-2.5 font-medium text-black-800"
              >
                {PriceFormat(rowData?.totalTax)}
              </Typography>
            ) : (
              <div className="relative flex w-full flex-row">
                <span className="absolute left-1 top-2.5 text-base font-semibold text-black-800">$</span>
                <input
                  type="number"
                  placeholder="Quantity"
                  data-autoid={`txtTaxAmount`}
                  className={clsx(
                    'm-0 h-full w-full !rounded-[3px] border-[1px] border-solid border-[#777] !p-2.5 !pl-3 text-right font-poppins text-base font-semibold leading-normal text-black-800 placeholder:text-base placeholder:text-[#ccc]',
                  )}
                  value={(Math.trunc(rowData?.totalTax * 100) / 100).toFixed(2)}
                  name={rowData?.documentID?.toString()}
                  onChange={e => handleChange(e, index)}
                  onKeyDown={e => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  onWheel={e => e.currentTarget.blur()}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <ARDialogTemplate
        dialogProps={{
          size: 'lg',
          open: isOpen,
          className: '2xl:!max-w-[793px] 2xl:!min-w-[793px] 2xl:!w-[793px] xl:!max-w-[65%] xl:!min-w-[65%] xl:!w-[65%]',
        }}
        isFullScreen
        header={
          <p data-autoid={'lblVerifyTaxAmountTitle'} className=" font-poppins text-xl font-light text-[#333333]">
            Verifying tax amount
          </p>
        }
        body={
          <div className="flex h-[inherit] max-h-[548px] w-full flex-col">
            <Typography className="my-5 text-center font-normal text-black-800" variant="paragraph">
              Please ensure the tax amount displayed on the transaction from your statement provider invoice is
              consistent with the tax shown on your invoice. If it isn’t, please enter the tax amount below.
            </Typography>
            <ARTable
              isHighlightRowOnHover
              isLoading={false}
              columns={columns}
              rows={updatedSelectedRows}
              getRowDataAutoId={rowData => `rowVerifyTaxAmount${rowData?.documentID}`}
            />
          </div>
        }
        footer={
          <div className="flex w-full flex-row items-center justify-between">
            <Button data-autoid={`btnCancel`} variant="outlined" color="error" className="bg-white" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="filled"
              color="primary"
              data-autoid={`btnContinue`}
              onClick={() => handleDone(updatedSelectedRows)}
            >
              Continue
            </Button>
          </div>
        }
      />
    </>
  );
};
