import React, {useContext, useEffect, useState} from 'react';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {Typography} from 'spenda-ui-react';
import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {DBIConfigField} from '../../../components/inputs/DBIConfigField';
import {AirPlusdbiFieldAbbreviations, IAirPlusDbiData, IDbifield, IDbiValues} from '../../../model/payment/AirPlusDBI';
import {cloneDeep} from 'lodash';
import Skeleton from '../../../components/Skeleton';

const getValidationRegex = (type: string, min: number, max: number) => {
  const rangeQuantifier = `{${min},${max}}`;

  switch (type) {
    case 'X':
      return new RegExp(`^[0-9\/()*$#%. \\\-a-zA-ZäöüßÄÖÜ]${rangeQuantifier}$`);
    case 'A':
      return new RegExp(`^[\/()*$#%. \\\-a-zA-ZäöüßÄÖÜ]${rangeQuantifier}$`);
    case 'N':
      return new RegExp(`^[0-9.]${rangeQuantifier}$`);
    default:
      return new RegExp(`^[0-9\/()*$#%. \\\-a-zA-ZäöüßÄÖÜ]${rangeQuantifier}$`);
  }
};

const getValidationRules = (rule: string, min: number, max: number, type: string) => {
  switch (rule) {
    case 'M':
      return {
        required: true,
        errorMessage: `Length must be between ${min} and ${max}`,
        regex: getValidationRegex(type, min, max),
        min,
        max,
      };
    case 'O':
      return {
        required: false,
        errorMessage: `Length must be between ${min} and ${max}`,
        regex: getValidationRegex(type, min, max),
        min,
        max,
      };
    default:
      return {
        required: Number(rule),
        errorMessage: `Length must be between ${min} and ${max}`,
        regex: getValidationRegex(type, min, max),
        min,
        max,
      };
  }
};

export const PaymentWidgetStepAirPlusDBI = () => {
  //Context
  const {
    selectedPaymentMethod,
    setStep,
    setPwStepsData,
    fetchAirPlusDBIConfig,
    setAirPlusDbiData,
    widgetScope,
    defaultDbiDetails,
  } = useContext(PaymentWidgetContext);

  // States
  const [dbiConfig, setDbiConfig] = useState<IDbifield[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dbiValues, setDbiValues] = useState<IDbiValues[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    getDbiConfig();
  }, []);

  const getDbiDefaultValue = (dbiCode: string) => {
    if (['AU', 'IK'].includes(dbiCode)) return defaultDbiDetails?.[dbiCode as 'AU' | 'IK'];
    else return '';
  };

  // Functions
  const getDbiConfig = async () => {
    setIsLoading(true);
    const data = await fetchAirPlusDBIConfig?.(selectedPaymentMethod?.PaymentAccountGUID as string);
    if (data?.error === 4 || data?.error === 1) {
      return handleSubmit([]);
    }

    const dbiValues = data?.dbifields?.map(d => {
      return {
        name: d.dbiCode,
        value: getDbiDefaultValue(d.dbiCode),
        error: '',
        rule: d.rule,
        type: d.type,
        validationRule: getValidationRules(d.rule, d.minLength, d.maxLength, d.type),
      };
    });

    if (dbiValues?.length === 2 && dbiValues?.some(d => d.name === 'AU') && dbiValues?.some(d => d.name === 'IK')) {
      setIsLoading(false);
      return handleSubmit(dbiValues as IDbiValues[]);
    }
    setDbiConfig(data?.dbifields as IDbifield[]);
    setDbiValues(dbiValues as unknown as IDbiValues[]);
    setIsLoading(false);
  };

  const onCancel = () => {
    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  const handleSubmit = (values?: IDbiValues[]) => {
    const fieldValues = values?.length ? values : dbiValues;

    if (fieldValues?.filter(a => a.rule === 'M')?.some(a => !a.value) || fieldValues?.some(d => d.error)) {
      handleCheckAllError();
      return;
    }

    // Logic for contional dbiRule
    let conditionalRule = 0;
    const conditionalDbiFilelds = fieldValues?.filter(d => {
      if (d.rule !== 'M' && d.rule !== 'O') {
        conditionalRule = Number(d.rule);
        return true;
      }
    });

    if (conditionalDbiFilelds?.filter(a => a.value)?.length < conditionalRule) {
      const conditionalFiledsName = conditionalDbiFilelds.reduce((acc, cur) => {
        return `${acc} ${AirPlusdbiFieldAbbreviations[cur.name]}`;
      }, '');
      setError(
        `At least ${conditionalRule} of the ${conditionalFiledsName} ${conditionalRule < 2 ? 'is' : 'are'} required`,
      );
      return;
    }

    const dbiMetaData: IAirPlusDbiData = {};
    fieldValues?.forEach((item: IDbiValues) => {
      dbiMetaData[item.name.toLowerCase()] = item.value;
    });

    setAirPlusDbiData?.(fieldValues?.length ? dbiMetaData : null);
    setPwStepsData?.({lastStep: PaymentWidgetPaymentSteps.AIRPLUS_DBI});
    setStep?.(PaymentWidgetPaymentSteps.NEW);
  };

  const handleChange = (value: string, index: number, bluredOnce: boolean) => {
    if (bluredOnce) {
      handleCheckError(index, value);
    } else {
      const dbiValuesArray = cloneDeep(dbiValues);
      const data = dbiValuesArray[index];
      data.value = value;
      setDbiValues(dbiValuesArray);
    }
  };

  const handleCheckError = (index: number, text?: string) => {
    const dbiValuesArray = cloneDeep(dbiValues);
    const dbi = dbiValuesArray[index];
    dbi.error = '';
    if (text !== undefined) {
      dbi.value = text;
    }
    const {name, value, validationRule} = dbi;
    const valid = validationRule.regex.test(value);
    if (dbi.type === 'D') {
      if (dbi.value === 'Invalid date') {
        dbi.error = 'Invalid date';
      } else if (validationRule.required === true && !value) {
        dbi.error = `${AirPlusdbiFieldAbbreviations[name]} is required`;
      }
    } else if (validationRule.required === true) {
      if (!value) {
        dbi.error = `${AirPlusdbiFieldAbbreviations[name]} is required`;
      } else if (!valid) {
        if (dbi.type === 'A' && dbi.value.length < validationRule.max && dbi.value.length > validationRule.min) {
          dbi.error = 'Numbers are not allowed';
        } else {
          dbi.error = validationRule.errorMessage as string;
        }
      }
    } else if (!valid && value) {
      if (dbi.type === 'A' && dbi.value.length < validationRule.max && dbi.value.length > validationRule.min) {
        dbi.error = 'Numbers are not allowed';
      } else {
        dbi.error = validationRule.errorMessage as string;
      }
    }

    setDbiValues(dbiValuesArray);
  };

  const handleCheckAllError = () => {
    const dbiValuesArray = cloneDeep(dbiValues);
    dbiValuesArray.forEach(dbi => {
      const {name, value, validationRule} = dbi;
      const valid = validationRule.regex.test(value);
      if (dbi.type === 'D') {
        if (dbi.value === 'Invalid date') {
          dbi.error = 'Invalid date';
        } else if (validationRule.required === true && !value) {
          dbi.error = `${AirPlusdbiFieldAbbreviations[name]} is required`;
        }
      } else if (validationRule.required === true) {
        if (!value) {
          dbi.error = `${AirPlusdbiFieldAbbreviations[name]} field is required`;
        } else if (!valid) {
          dbi.error = validationRule.errorMessage as string;
        }
      } else {
        if (!valid && value) {
          dbi.error = validationRule.errorMessage as string;
        }
      }
    });
    setDbiValues(dbiValuesArray);
  };

  const title = 'Enter descriptive billing information';
  const body = (
    <div className="font-poppins ">
      <div className="-mb-2 max-h-[355px] overflow-y-auto px-6 pt-2">
        {isLoading ? (
          <Skeleton gapY={4} height="38px" count={5} />
        ) : (
          dbiConfig?.map((dbi, index) => (
            <div key={index}>
              <DBIConfigField
                configuration={dbi}
                dbiValue={dbiValues[index]}
                index={index}
                handleChange={handleChange}
                handleBlur={handleCheckError}
                widgetScope={widgetScope}
              />
            </div>
          ))
        )}
      </div>
      {error ? <Typography className="mt-4 font-poppins text-sm text-spenda-error">{error}</Typography> : null}
    </div>
  );
  const footer = <Footer isConfigLoading={isLoading} onCancel={onCancel} handleSubmit={handleSubmit} />;
  return <PaymentWidgetStep title={title} body={body} footer={footer} titleType="bank" />;
};

interface IFooterProps {
  onCancel?: () => void;
  handleSubmit?: () => void;
  isConfigLoading: boolean;
}

export const Footer = ({onCancel, handleSubmit, isConfigLoading}: IFooterProps) => {
  return (
    <>
      <SecondaryButton data-autoid="btnClose" onClick={onCancel} label={'Cancel'} />
      <PrimaryButton disabled={isConfigLoading} label={'Submit'} onClick={handleSubmit} data-autoid="btnSubmit" />
    </>
  );
};
