import {LogoTypeEnum} from './Tenant';
export interface FileDetails {
  ID?: number;
  Name: string;
  Path: string;
  Type: string;
  Url: string;
  URL?: string;
  ThumbnailURL?: string;
  Size: number;
  SizeFriendly: string;
  Thumb: string;
  IsImage: boolean;
  IsDefault?: boolean;
  IsSuccess: boolean;
  Messages: any[];
}

export interface IAttachmentRequest {
  Caption?: string;
  Note?: string;
  Action?: number;
  LogoTypeID?: LogoTypeEnum;
}

export interface IFileUploadResponse {
  requestID: string;
  attachmentType: number;
  responseObject: IResponseObject[];
}

export interface IResponseObject {
  logoTypeID: LogoTypeEnum;
  redirectURI: string;
  type: string;
  isActive: boolean;
  mediaGUID: string;
  uri: string;
  thumbnailUri: string;
}

export enum AttachmentType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  WORD = 'application/msword',
  PDF = 'application/pdf',
}
