import React, {useState} from 'react';

import {useBatchUpdateAPI} from '../../services/useBatchUpdateAPI';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';
import {IBatchUpdate, IBatchUpdatesArray} from '../../model/BatchUpdate';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {CustomerClassesAutocomplete} from '../customer/CustomerAutocomplete';
import {SButton} from '../buttons/Button';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {Box, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useCustomerClassStyles = makeStyles(theme => ({
  dialogActions: {
    borderTop: '1px solid lightgrey',
    margin: '0 1rem',
    padding: '1rem 0',
  },
  dialogContent: {
    margin: '20px',
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper: {
    minHeight: '60vh',
  },
}));

export interface IModifyDialog {
  open: boolean;
  selectedRows: number[];
  onClose: () => void;
  getCustomersList?: () => void;
  setRefetch: any;
  refetch: number;
}

export const ModifyDialog: React.FunctionComponent<IModifyDialog> = ({
  open,
  selectedRows,
  onClose,
  setRefetch,
  refetch,
}: IModifyDialog) => {
  const classes = useCustomerClassStyles();
  const [customerClassFilter, setCustomerClassFilter] = useState<Partial<ICreateCustomerClassValues>>();

  const {isLoading, batchUpdate} = useBatchUpdateAPI();

  const handleCloseClick = () => {
    onClose();
  };

  const onSave = () => {
    const batchUpdateArray = {
      DatTypeID: 1,
      ColumnToUpdate: 'CustomerClassID',
      Value: customerClassFilter?.ID,
      IDs: selectedRows,
      IsRemoveFromCollection: false,
    } as IBatchUpdatesArray;
    const batchUpdatePayload: IBatchUpdate = {
      Updates: [batchUpdateArray],
      WebsiteID: ApplicationInfo.WebsiteId,
    } as IBatchUpdate;
    batchUpdate(batchUpdatePayload).then(() => {
      handleCloseClick();
      setCustomerClassFilter(undefined);
      setRefetch(refetch + 1);
    });
  };

  const handleCustomerClassFilter = (selected: Partial<ICreateCustomerClassValues> | null) => {
    setCustomerClassFilter(selected || undefined);
  };

  return (
    <div>
      <SDialog classes={{paper: classes.dialogPaper}} maxWidth="sm" fullWidth open={open!} onClose={onClose}>
        <DialogTitle>
          <Box display="flex" justifyContent="center" marginTop="10px">
            {` Modify Selected Customers`}
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          <div className="p-4 flex flex-col" style={{minWidth: '275px'}}>
            <p>Select Customer Class</p>
            <CustomerClassesAutocomplete
              selected={customerClassFilter ? customerClassFilter : null}
              clearClick={() => handleCustomerClassFilter(null)}
              onSuggestionSelected={(e, selected) => handleCustomerClassFilter(selected.suggestion)}
            />
          </div>
        </DialogContent>

        <DialogActions classes={{root: classes.dialogActions}}>
          <Box display="flex" justifyContent="space-between" width="100%" paddingY="8px">
            <SButton
              margin="0 0.25rem"
              color="transparent"
              border="1px solid #1C78AD"
              textColor="blueShade"
              lineHeight="m"
              width="m"
              type="button"
              onClick={() => handleCloseClick()}
            >
              Cancel
            </SButton>
            <SButton
              margin="0 0.25rem"
              color="blueShade"
              border="1px solid #1C78AD"
              textColor="white"
              lineHeight="m"
              width="m"
              onClick={() => onSave()}
              isSubmitting={isLoading}
              disabled={isLoading}
            >
              Save
            </SButton>
          </Box>
        </DialogActions>
      </SDialog>
    </div>
  );
};
